import { useErrorContext } from "@app/errorContext";
import { useUserContext } from "@app/userContext";
import { PasswordErrorsObject, checkIfAllPasswordRequirementsAreMet, checkPasswordValidity } from "@app/utils/helpers/passwordValidator";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React, { useCallback, useState, useEffect } from "react";
import { Col, Row, Input } from "antd";
import { Typography, Button } from "@app/components/songtradr-components";
import { styles } from './styles';
import { PasswordValidation } from "@app/components";
import "styled-components/macro";
import { useNavigate } from "react-router-dom";

interface IForceResetProps {
    handleSuccess: (session: CognitoUserSession) => void;
    handleFailure: (err: any) => void;
}

const ForceReset = ({ handleSuccess, handleFailure }: IForceResetProps) => {
    const [ passwordField, setPasswordField ] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<PasswordErrorsObject[]>(checkPasswordValidity(''));
    const [userStartedTypingPassword, setUserStartedTypingPassword] = useState(false);
    const { errorState } = useErrorContext();
    const { userState } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userState.userRef || !userState.userAttr) {
            navigate('/signin');
        }
    }, [navigate, userState]);

    const handlePasswordFieldChange = (password: string) => {
        setPasswordField(password)
        if (!userStartedTypingPassword) {
            setUserStartedTypingPassword(true)
        }

        const errors = checkPasswordValidity(password);
        setPasswordErrors(errors);
        
    } 


    const handleForcePasswordChange = useCallback(() => {
        const {userRef, userAttr} = userState;
        if (passwordField !== '' && userRef && userAttr && checkIfAllPasswordRequirementsAreMet(passwordErrors).length === 0) {
            userRef.completeNewPasswordChallenge(passwordField, userAttr, {
                onSuccess(session) {
                    handleSuccess(session);
                },
                onFailure(err) {
                   handleFailure(err);
                },
            })
        }
    }, [handleFailure, handleSuccess, passwordErrors, passwordField, userState])
    
        
    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleForcePasswordChange()
        }
    }, [handleForcePasswordChange])


    return (
        <div css={styles.topOffset}>
            <Row gutter={[16,16]}>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                    <Row gutter={[0,16]}>
                        {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                        <Typography variant="h3">Please change your password</Typography>
                        <Col span={24}>
                            <form autoComplete="off">
                                <Input 
                                    autoComplete="new-password"
                                    css={styles.inputHeight}
                                    value={passwordField}
                                    type="password"
                                    placeholder="Password"
                                    onKeyDown={handleEnterKeyDown}
                                    onFocus={() => { handlePasswordFieldChange(passwordField)}}
                                    onChange={(event) => handlePasswordFieldChange(event.target.value)} /> 
                                    {userStartedTypingPassword && <PasswordValidation errors={passwordErrors} />}   
                            </form>
                        </Col>
                        <Col span={24}>
                            <Button variant="primary" onClick={handleForcePasswordChange}>Save</Button> 
                        </Col>
                       
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
            </Row>
        </div>
    )

}

export default ForceReset;