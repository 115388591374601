import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type mediaTypeText = "episode" | "movie" | "game" | "ad";

const useMediaTypeText = (): mediaTypeText => {
  const { pathname } = useLocation();

  const mediaText = useMemo(() => {
    const mediaTypeFromPath = pathname.split("/")[1];
    if (mediaTypeFromPath === "show") return "episode";
    else return mediaTypeFromPath;
  }, [pathname]);

  return mediaText as mediaTypeText;
};

export default useMediaTypeText;
