import { WindowSize, maxSmall, maxMedium, maxLarge } from "./sizes";

export interface WindowDimensions {
  size: WindowSize;
  width: number;
  height: number;
}

const getSize = (): WindowSize => {
  // ORDER IS IMPORTANT!!
  if (window.matchMedia(maxSmall).matches) {
    return "small";
  }

  if (window.matchMedia(maxMedium).matches) {
    return "medium";
  }

  if (window.matchMedia(maxLarge).matches) {
    return "large";
  }

  return "xlarge";
};

export const getWindowDimensions = (): WindowDimensions => ({
  size: getSize(),
  width: window.innerWidth,
  height: window.innerHeight,
});
