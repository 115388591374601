import React from "react";
import { useNavigate } from "react-router-dom";

interface IUserGuardProps {
    children: React.ReactNode;
}

const UserGuard: React.FC<IUserGuardProps> = props => {
    const { children } = props;
    const navigate = useNavigate();
    const idToken = localStorage.getItem("tf_auth_id_token");

    if (!idToken) {
        navigate('/');    
    }
    
    return <>{children}</>
}

export default UserGuard;