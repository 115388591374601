import React from "react";
import { SongListItemWrapper, styles } from "./styles";
import { ISong } from "@app/api/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faArrowRight,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { Col, Row } from "antd";
import { SongPreviewPlayer } from "@app/components";

import "styled-components/macro";
import FeaturedOn from "./FeaturedOn";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import ShareButton from "../buttons/ShareButton";
import StoreLinks from "../store-links";
import { Typography } from "../songtradr-components";

interface ISongListItemProps {
  song: ISong;
  showTrend: boolean;
}

const SongListItem = ({
  song: { ranking, trend, name, artists, audioPreview, storeLinks, featuredOn, appearanceId },
  ...rest
}: ISongListItemProps) => {
  const rankingConfigurer = (
    ranking?: ISong["ranking"],
    trend?: ISong["trend"]
  ) => {
    if (!trend || !ranking) return null;

    switch (trend) {
      case "rising":
        return displayRanking(faArrowUp, "var(--success-fg)", ranking);
      case "falling":
        return displayRanking(faArrowDown, "var(--error-fg)", ranking);
      case "static":
        return displayRanking(faArrowRight, "var(--fg)", ranking);
      case "new":
        return displayRanking(faSparkles, "var(--fg)", ranking);
      default:
        return null;
    }
  };

  const displayRanking = (
    icon: IconDefinition,
    iconColor: string,
    ranking?: ISong["ranking"]
  ) => {
    return (
      <div css={styles.rankingContainer}>
        <FontAwesomeIcon
          css={styles.rankingContent}
          size="2x"
          icon={icon}
          color={iconColor}
        />
        <Typography css={styles.rankingNumber} margin={false} variant="h1">
          {ranking}
        </Typography>
      </div>
    );
  };

  //RENDER
  return (
    <SongListItemWrapper {...rest}>
      <Row justify="space-between" align="middle" css={styles.headerBar}>
        <Col
          xl={13}
          lg={13}
          md={13}
          sm={24}
          xs={24}
          css={styles.leftSideOfHeaderBar}
        >
          {rankingConfigurer(ranking, trend)}
          <SongPreviewPlayer
            name={name}
            artists={artists}
            audioPreview={audioPreview}
          />
        </Col>
        <Col flex={1}>
          <Row justify="space-between" wrap={false}>
            <Col css={styles.socialMediaContainer}>
              <StoreLinks links={storeLinks} songId={appearanceId}/>
            </Col>
            <Row align="middle" gutter={[16, 0]} justify={"end"} wrap={false}>
              <Col>
                <ShareButton title={name} artists={artists} />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <FeaturedOn featuredOn={featuredOn} />
    </SongListItemWrapper>
  );
};

export default SongListItem;
