import React, { useState, useEffect } from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import {
  Button,
  ButtonLoadingIcon,
  Typography,
} from "../songtradr-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVoteRequest } from "@app/api";
import { eCorrectness } from "@app/api/types";
import { useMediaTypeText } from "@app/utils";
import { VoteTargetType } from "../buttons/useVoteButton";
import { StyledModal, styles } from "./styles";

const useVoteModal = (targetType: VoteTargetType) => {
  const mediaText = useMediaTypeText();

  const { pendingVote, postVoteRequest } = useVoteRequest();

  const [modalOpen, setModalOpen] = useState(false);
  const [targetId, setTargetId] = useState<number>();

  // HANDLE BODY SCROLLING BEHIND MODAL
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = modalOpen ? "hidden" : "auto";
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleVoteButtonClick = (value: eCorrectness) => {
    if (targetId) {
      postVoteRequest(targetType, targetId, value, () =>
        setModalOpen(!modalOpen)
      );
    }
  };

  // EXPORTED TOGGLE
  const toggleModal = (selectedId?: number) => {
    if (selectedId) {
      setTargetId(selectedId);
      setModalOpen(!modalOpen);
    } else closeModal();
  };

  const descriptionText =
    targetType === "appearance" ? (
      <Typography css={styles.gutter} variant="body">
        Did you hear this song in this {mediaText}?
      </Typography>
    ) : (
      <Typography css={styles.gutter} variant="body">
        Excluding songs from the official score, you didn't hear any music in
        this {mediaText}?
      </Typography>
    );

  //MODAL EXPORT
  const VoteModal = (
    <StyledModal
      confirmationModal={true}
      destroyOnClose={true}
      mask={true}
      open={modalOpen}
      closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
      onOk={closeModal}
      onCancel={closeModal}
      footer={false}
    >
      <Typography margin={false} css={styles.gutter} variant="h2">
        Vote to confirm
      </Typography>
      {descriptionText}

      <Row gutter={[24, 0]} justify="space-between">
        <Col span={12}>
          <Button
            css={styles.yesButton}
            variant="important"
            onClick={() => handleVoteButtonClick(eCorrectness.right)}
          >
            {pendingVote === eCorrectness.right && <ButtonLoadingIcon />}
            Yes
          </Button>
        </Col>
        <Col span={12}>
          <Button
            css={styles.noButton}
            variant="destructive"
            onClick={() => handleVoteButtonClick(eCorrectness.wrong)}
          >
            {pendingVote === eCorrectness.wrong && <ButtonLoadingIcon />}
            No
          </Button>
        </Col>
      </Row>
    </StyledModal>
  );

  return { VoteModal, toggleVoteModal: toggleModal };
};

export default useVoteModal;
