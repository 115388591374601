import styled, { css } from "styled-components";
import {
  maxMedium,
  maxSmall,
  minLarge,
  pretzelColors,
} from "../../components/songtradr-components";
import { useContentMargins } from "@app/styles/helpers";

// STYLED COMPONENTS
export const ProfilePageWrapper = styled.div`
  margin-top: 5rem;
`;

export const UserProfilePageWrapper = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    background: var(--semantic-background-middle);
    min-height: 100vh;

    @media ${minLarge} {
      padding: 6.5rem ${margin} 4rem ${margin};
      /* margin-top: 5rem; */
    }
    @media ${maxMedium} {
      padding: 2.5rem ${margin} 2rem ${margin};
      /* margin-top: 5rem; */
    }
  `;
});

export const StyledCardBorder = styled.div(() => {
  return css`
    padding: 2rem;
    background: var(--semantic-background-base);
    border-radius: 8px;
    margin-bottom: 3rem;
  `;
});

export const UserProfilePageContent = styled.div`
  @media ${minLarge} {
    margin-top: 3.5rem;
    flex-direction: row;
  }
  @media ${maxMedium} {
    margin-top: 1rem;
    row-gap: 2rem;
    flex-direction: column;
  }
`;

export const MetricsBoxWrapper = styled.div`
  background: var(--semantic-background-base);
  /* border: 1px solid var(--semantic-gray-bg-medium); */
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
`;

export const StyledCircle = styled.circle<{
  offset: number;
  circumference: number;
  strokeWidth: number;
}>`
  fill: none;
  stroke: var(--semantic-gray-bg);
  stroke-width: ${(props) => props.strokeWidth};
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.offset};
`;

export const StatusMessageWrapper = styled.div`
  background: var(--bg-success);
  border-radius: 0.5rem;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 3rem;
`;

// CSS PROP STYLES
export const styles = {
  leftCol: css`
    @media ${minLarge} {
      margin-right: 3rem;
    }
  `,

  leftSuperCol: css`
    margin-right: 3rem;
  `,

  rightCol: css`
    width: 100%;

    @media ${minLarge} {
      min-width: 408px;
      max-width: 408px;
    }
  `,

  circlesContainer: css`
    position: relative;
  `,

  circlesAndStatsContainer: css`
    display: flex;
    align-items: center;

    @media ${maxSmall} {
      flex-direction: column;
    }
  `,

  circleBackground: css`
    fill: none;
    stroke: var(--pb-background);
    stroke-width: 10;
  `,

  circleBackgroundContainer: css`
    margin: 0.75rem;
  `,

  circleContainer: css`
    margin: 0.75rem;
    position: absolute;
    left: 0;
    z-index: 1;
    transform: rotate(-90deg);
  `,

  circleStatsContainer: css`
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  featuredMediaImage: css`
    border-radius: 0.5rem !important;
    margin-right: 1.5rem !important;
  `,

  statsContainer: css`
    flex: 1;
    margin: 0.75rem;
    color: ${pretzelColors.toasted};
  `,

  achievementRow: css`
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  `,

  achievementIcon: css`
    display: flex;
    align-items: center;
    margin: 0.75rem;
  `,

  headerText: css`
    margin: 0.75rem;
  `,

  textContainer: css`
    position: relative;
    width: 100%;

    @media ${minLarge} {
      position: absolute;
      bottom: 5%;
      left: 5%;
      max-width: 75%;
    }
  `,
  divider: css`
    background: var(--semantic-gray-bg);
  `,
  link: css`
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,
};
