import React, { useState } from "react";
import "styled-components/macro";
import { Typography, Button, IconButton, useWindowDimensions } from "../songtradr-components";
import { Dropdown, Col, Row, Tooltip, Typography as antTypography } from "antd";
import { shareButtonStyles as styles } from "./styles";
import useTwitter from "@app/utils/hooks/useTwitter";
import { IArtist } from "@app/api/types";

const { Text } = antTypography;

const ShareButton = ({
  title,
  artists,
  question,
  textualButton,
}: {
  title: string;
  artists?: IArtist[] | string;
  question?: string;
  textualButton?: boolean;
}) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const TwitterShareButton = useTwitter();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const CopyIcon = () => {
    return (
      <Row align="middle" css={styles.copyIcons}>
        <Typography margin={false} inverted variant="body">
          Copy Url
        </Typography>
      </Row>
    );
  };
  const CopiedIcon = () => {
    return (
      <div css={styles.copyIcons}>
        <Typography margin={false} inverted variant="body">
          Copied
        </Typography>
      </div>
    );
  };

  const dropdown = () => {
    return (
      <div css={styles.overlay(isMobile)}>
        <Row align="middle" wrap={false} gutter={16}>
          <Col
            data-tooltip="Share to Twitter"
            css={styles.socialContainer()}
            onClick={() => setDropdownOpen(false)}
          >
            <TwitterShareButton
              title={title}
              artists={artists ?? undefined}
              question={question ?? undefined}
            />
          </Col>
          <Col>
            <Text
              css={styles.copyableContainer}
              copyable={{
                text: window.location.href,
                icon: [<CopyIcon />, <CopiedIcon />],
                tooltips: false,
                onCopy: () => setTimeout(() => setDropdownOpen(false), 500),
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      open={dropdownOpen}
      onOpenChange={(open) => setDropdownOpen(open)}
      dropdownRender={dropdown}
      arrow={isMobile ? false : { pointAtCenter: true }}
      destroyPopupOnHide
    >
      {textualButton ? (
        <Button variant="important" size="small">
          Share
        </Button>
      ) : (
        <Tooltip
          placement="top"
          title={
            <Typography
              variant="xsmall"
              css={{ color: "var(--semantic-background-top)" }}
            >
              Share
            </Typography>
          }
        >
          <IconButton>
            <img
              src="https://img-sys.songtradr.com/5434facbaa7b530dbd13c877566e3bdd69ae7829e0c65969731508bdc035c274.svg"
              alt="Share Icon"
            />
          </IconButton>
        </Tooltip>
      )}
    </Dropdown>
  );
};

export default ShareButton;
