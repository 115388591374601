import { ISong } from "@app/api/types";
import { IGoogleAd, ISongListSchema } from "@app/sanity-generated-data/types";
import { Pagination } from "antd";
import React, { Suspense, useState } from "react";
import SongListItem from "./SongListItem";
import { faHeart as solidHeart } from "@fortawesome/pro-solid-svg-icons";
import { faHeart as lightHeart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "./styles";
import "styled-components/macro";
import { Typography } from "../songtradr-components";
import { Col, Row } from "antd";
const AdsWrapper = React.lazy(() => import("../advertisement/AdsWrapper"));

interface ISongListProps {
  data: ISong[];
  showTrend?: boolean;
  showPagination?: boolean;
  schema: ISongListSchema;
  isMobile: boolean;
  userLoggedIn: boolean;
}

const findAllDisplayAds = (
  ads: IGoogleAd[],
  index: number,
  adIndex: number[],
  isMobile: boolean,
  userLoggedIn: boolean,
) => {
  const showAd = adIndex.findIndex((num) => num === index + 1);
  if (ads && showAd > -1) {
    if (showAd === adIndex.length - 1) {
      return <Suspense>
          <AdsWrapper centered={true} ads={[ads[showAd]]} isMobile={isMobile} userLoggedIn={userLoggedIn} />;
        </Suspense>
    } else {
      return (
        <div style={{ marginBottom: "3rem" }}>
          <Suspense>
          <AdsWrapper centered={true} ads={[ads[showAd]]} isMobile={isMobile} userLoggedIn={userLoggedIn} />
            </Suspense>
        </div>
      );
    }
  }
};

const SongList = ({
  data,
  showTrend = false,
  schema,
  showPagination = false,
  isMobile,
  userLoggedIn,
  ...rest
}: ISongListProps) => {
  const [paginatedData, setPaginatedData] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 20,
  });
  
  const filteredAds = [...(schema?.contentAds || [])].filter(
    (ad) =>
      ad.displayOn === (isMobile ? "small" : "large") ||
      ad.displayOn === "always"
  );
  let splitAt = data.length / filteredAds.length;
  const adIndex: number[] = [];
  while (splitAt <= 10 && splitAt > 0) {
    adIndex.push(splitAt);
    splitAt += splitAt;
  }

  const handleChange = (page: number) => {
    setPaginatedData({
      current: page,
      minIndex: (page - 1) * 20,
      maxIndex: page * 20,
    });
  };

  return data.length || showTrend ? (
    <div {...rest}>
      {data.map((song, index) => {
        if (index >= paginatedData.minIndex && index < paginatedData.maxIndex) {
          return (
            <React.Fragment key={song.id}>
              <SongListItem song={song} showTrend={showTrend} />
              {schema?.contentAds &&
                findAllDisplayAds(filteredAds, index, adIndex, isMobile, userLoggedIn)}
            </React.Fragment>
          );
        } else return <></>;
      })}
      {showPagination && (
        <Pagination
          showSizeChanger={false}
          current={paginatedData.current}
          total={data.length}
          onChange={handleChange}
        />
      )}
    </div>
  ) : (
    <Row css={styles.emptyStateContainer} gutter={24} align="middle">
      <Col>
        <FontAwesomeIcon css={styles.solidHeart} icon={solidHeart} />
      </Col>
      <Col>
        <Typography margin={false} variant="body">
          Click the {<FontAwesomeIcon icon={lightHeart} />} next to any song to
          save it here.
        </Typography>
      </Col>
    </Row>
  );
};

export default SongList;
