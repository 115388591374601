import { ITabExplorerResponse, IUser } from "@app/api/types";
import { SongList as songList, WaitSpin } from "@app/components";
import { ITabExplorerSchema } from "@app/sanity-generated-data/types";
import {
  Tab,
  Typography,
} from "../songtradr-components";
import { Col, Row } from "antd";
import React, {
  ComponentPropsWithoutRef,
  Fragment,
  ReactNode,
  Suspense,
  useMemo,
  useState,
} from "react";
import { MediaGallery as mediaGallery } from "./media-gallery";
import {
  RightColAds,
  TabExplorerHeader,
  TabExplorerWrapper,
  tabStyles,
  profileStyles,
} from "./styles";
import "styled-components/macro";
const AdsWrapper = React.lazy(() => import("../advertisement/AdsWrapper"));

interface TabExplorerProps extends ComponentPropsWithoutRef<"div"> {
  loading?: boolean;
  schema: ITabExplorerSchema;
  data: ITabExplorerResponse;
  user?: IUser;
  isMobile: boolean;
  userLoggedIn: boolean;
}

const contentComponents: { [key: string]: any } = { songList, mediaGallery };

const TabExplorer = ({
  loading,
  schema,
  data,
  user,
  isMobile,
  userLoggedIn,
  ...rest
}: TabExplorerProps) => {

  const { headers, subHeaders, tabs: tabSchemas, rightColAds } = schema;
  const tabs = tabSchemas.map(({ _key, label }) => ({
    key: _key,
    label,
  }));
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].key);

  const contentRenders = useMemo(() => {
    if (data) {
      const renders: { [key: string]: ReactNode } = {};
      tabSchemas.forEach((schema) => {
        const Component = contentComponents[schema._type];
        renders[schema._key] = (
          <Component
            isMobile={isMobile}
            key={schema._key}
            schema={schema}
            data={data[schema.apiKey]}
          />
        );
      });
      return renders;
    }
  }, [data, isMobile, tabSchemas]);

  const rightColAdRenders = useMemo(() => {
    return !isMobile && rightColAds ? (
      <RightColAds offset={1} as={Col}>
        <Suspense>
          <AdsWrapper rightCol={true} ads={rightColAds} isMobile={isMobile} userLoggedIn={userLoggedIn} />
        </Suspense>
      </RightColAds>
    ) : null;
  }, [userLoggedIn, isMobile, rightColAds]);

  const tabClick = (key: string) => setCurrentTab(key);

  const tabsRender = (tabs: { key: string; label: string }[]) => {
    return tabs.map(({ key, label }) => (
      <Tab
        css={tabStyles.tab}
        key={key}
        active={key === currentTab}
        onClick={() => tabClick(key)}
      >
        {label}
      </Tab>
    ));
  };

  return (
    <TabExplorerWrapper {...rest}>
      {loading ? (
        <WaitSpin dark />
      ) : (
        <Fragment>
          <TabExplorerHeader as={Row}>
            {user && (
              <Col flex={1}>
                <Typography variant={headers.variant} css={profileStyles.username}>
                  {user?.username}
                </Typography>
                <Typography variant={subHeaders.variant}>
                  {user?.userSince}
                </Typography>
              </Col>
            )}
            <Col>{tabsRender(tabs)}</Col>
          </TabExplorerHeader>
          <Row wrap={false}>
            <Col xl={18} lg={16}>
              {contentRenders && contentRenders[currentTab]}
            </Col>
            {rightColAdRenders}
          </Row>
        </Fragment>
      )}
    </TabExplorerWrapper>
  );
};

export default TabExplorer;
