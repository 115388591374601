import { useLockTable, useReorderTable } from "@app/api";
import { ISong } from "@app/api/types";
import UserContext from "@app/userContext";
import { useMediaTypeText } from "@app/utils";
import {
  Button,
  Typography,
  useWindowDimensions,
} from "../songtradr-components";
import { Col, Row, Switch, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { OnDragEndResponder } from "@hello-pangea/dnd";
import "styled-components/macro";
import { useAddTombstoneButton } from "../buttons";
import useAddSongModal from "../dialog-modal/useAddSongModal";
import useAppearanceDeleteModal from "../dialog-modal/useAppearanceDeleteModal";
import useSceneDescriptionModal from "../dialog-modal/useSceneDescriptionModal";
import ShowMore from "../show-more/ShowMore";
import { SongTableWrapper, StyledEmptyState, LockSongListWrapper, LockSongTooltip, styles } from "./styles";
import TableHeader from "./TableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import TableContent from "./TableContent";


export const MAX_SONG_TABLE_LENGTH = 10;

const tooltipText = (
  <div>
    Once a song list is verified complete and accurate, the Music Supervisor can lock the list to make it final and official. Don't forget to include any music swapped in for non-broadcast versions, and music cues that may become available online or via soundtrack
  </div>
);

export interface TableProps {
  handleOpenDescModal: Function;
  handleOpenDeleteModal: Function;
  handleDragEnd: OnDragEndResponder;
  handleRowClick: Function;
  tableData: ISong[];
  expanded: boolean;
  locked: boolean;
  nudge: Function;
  isLoggedIn: boolean;
}

export interface HeaderProps {
  orderDirection: headerOrganizer[];
  handleReorder: Function;
}

type headerOrganizer = {
  property: string;
  direction: boolean;
  selected: boolean;
};

const SongTable = ({
  data,
  parentId,
  ...rest
}: {
  parentId?: string;
  data?: ISong[];
  locked: boolean;
  isSupervisor: boolean;
}) => {
  const { nudge, userState } = useContext(UserContext);
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const { AddTombstoneButton, AddTombstoneModal } = useAddTombstoneButton();
  const mediaText = useMediaTypeText();

  const [tableData, setTableData] = useState(data ?? []);
  const [selectedRow, setSelectedRow] = useState<ISong>();
  const [orderDirection, setOrderDirection] = useState([
    { property: "id", direction: true, selected: true },
    { property: "name", direction: true, selected: false },
    { property: "artists", direction: true, selected: false },
  ]);

  const expandable = tableData && tableData.length > MAX_SONG_TABLE_LENGTH;
  const [expanded, setExpanded] = useState(false);
  const { lockTableRequest } = useLockTable();
  const { reorderTableRequest } = useReorderTable();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AddSongModal, handleOpenSongModal] = useAddSongModal({
    data,
    parentId,
  });
  const [SceneDescModal, handleOpenModal] = useSceneDescriptionModal({
    type: "scene-description",
    defaultValue: selectedRow?.description ?? undefined,
    content: selectedRow,
  });
  const { DeleteModal, handleOpenDeleteModal } = useAppearanceDeleteModal();

  //keep tabledata in sync with props
  useEffect(() => {
    data && setTableData(data);
  }, [data]);

  // HANDLERS
  const handleLock = () => {
    if (parentId) lockTableRequest(parseInt(parentId), rest.locked);
  };

  const handleOpenDescModal = (row: any) => {
    setSelectedRow(row);
    nudge(handleOpenModal);
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;

    const _tableData = [...tableData];
    const [reorderedItem] = _tableData.splice(source.index, 1);
    _tableData.splice(result.destination.index, 0, reorderedItem);
    setTableData(_tableData);
    reorderTableRequest(_tableData);
  };
  type SongProperty = "likedByUser" | "confirmation";

  const handleRowClick = (row: any, property: SongProperty, value?: any) => {
    const _tableData = [...tableData];

    const _selected = _tableData.find(
      (data) => data.id.toString() === row.id.toString()
    ) as ISong;

    if (_selected) {
      _selected[property] = value ?? !row[property];
    }
  };

  type category = "id" | "artists" | "name";

  const handleReorder = (category: category) => {
    const _order = [...orderDirection];
    const _od = _order.find((x) => x.property === category);
    const _tableData = [...tableData];

    if (_od) {
      if (_od.selected) {
        _od.direction = !_od.direction;
      } else {
        _od.selected = true;
        const otherOds = _order.filter((x) => x.property !== category);
        otherOds.forEach((item) => {
          item.selected = false;
        });
      }
    }

    _tableData.sort((a, b) => {
      const nameA =
        category === "artists"
          ? a[category]
            .map((m) => m.name)
            .join()
            .toLowerCase()
          : a[category].toString().toLowerCase();
      const nameB =
        category === "artists"
          ? b[category]
            .map((m) => m.name)
            .join()
            .toLowerCase()
          : b[category].toString().toLowerCase();
      if (nameA < nameB) return _od?.direction ? -1 : 1;
      else if (nameA > nameB) return _od?.direction ? 1 : -1;
      else return 0;
    });
    setOrderDirection(_order);
    setTableData(_tableData);
  };

  const contentRender = () => {
    if (data?.length) {
      return <>
          <TableHeader
            orderDirection={orderDirection}
            handleReorder={handleReorder}
          />
            <TableContent
              {...rest}
              isLoggedIn={userState.authenticated}
              nudge={nudge}
              handleOpenDeleteModal={handleOpenDeleteModal}
              handleOpenDescModal={handleOpenDescModal}
              handleDragEnd={handleDragEnd}
              handleRowClick={handleRowClick}
              tableData={tableData}
              expanded={expanded}
            />
          {expandable && (
            <ShowMore
              expanded={expanded}
              setExpanded={setExpanded}
              moreLabel="Show all tracks"
            />
          )}
          <LockSongListWrapper>
            {rest.isSupervisor && (
              <>
                <LockSongTooltip>
                  <Typography variant="body">Lock song list?</Typography>
                  <Tooltip
                    placement="top"
                    title={
                      <Typography
                        variant="xsmall"
                        css={{ color: "var(--semantic-background-top)" }}
                      >
                        {tooltipText}
                      </Typography>
                    }
                  >
                    <FontAwesomeIcon
                      color="var(--semantic-background-top)"
                      icon={faCircleQuestion}
                    />
                  </Tooltip>
                </LockSongTooltip>

                <Switch
                  onChange={handleLock}
                  defaultChecked={rest.locked}
                />
              </>
            )}
          </LockSongListWrapper>
          {!rest.locked && (
            <Button
              variant="primary"
              css={styles.addSongButton}
              onClick={() => nudge(handleOpenSongModal)}
            >
              Add a song
            </Button>
          )}
        </>
    } else {
      return (
        <StyledEmptyState>
          <Row gutter={[0, 24]} wrap={!isMobile ? false : true}>
            <Col span={isMobile ? 24 : undefined} style={{ height: "72px" }}>
              <img
                src="https://img-sys.songtradr.com/797d115e10f947d5d7158acccb70ed3e13c964f797849d0a1120b51e69886256.svg"
                alt="Music Icon"
                css={styles.emptyStateImg}
              />
            </Col>
            <Col span={isMobile ? 24 : undefined}>
              <Row css={styles.emptyStateText}>
                <Typography margin={false} variant="h2">
                  It’s a little quiet in here. Be the first to contribute.
                </Typography>
              </Row>
              <Row css={styles.emptyStateText}>
                <Typography margin={false} variant="body">
                  Are you familiar with any songs from this {mediaText}? Please
                  feel free to suggest any you may know, we'd love to add them.
                </Typography>
              </Row>
            </Col>
          </Row>
          {!rest.locked && (
            <Row css={styles.emptyStateButtons} justify="center">
              <AddTombstoneButton titleId={parentId} />
              <Button
                variant="primary"
                onClick={() => nudge(handleOpenSongModal)}
              >
                Add a song
              </Button>
            </Row>
          )}
        </StyledEmptyState>
      );
    }
  };

  // RENDER
  return (
    <SongTableWrapper>
      <>
        {contentRender()}

        {AddSongModal()}
        {SceneDescModal()}
        {DeleteModal}
        {AddTombstoneModal}
      </>
    </SongTableWrapper>
  );
};

export default SongTable;
