import React, { Fragment, Suspense } from "react";
import styled from "styled-components";
import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useSanityData } from "@app/sanity-generated-data";
import { ISanityCache } from "@app/sanity-generated-data/types";
import hiddenRoutes from "./hiddenRoutes";
import * as PageComponents from "@app/pages";
import { HeadMeta, WaitSpin } from "@app/components";
import ScrollToTop from "./ScrollToTop";

const { Route404Page, OutOfTune } = PageComponents;

const ContentWrapper = styled.div`
  min-height: 100vh;
`;

const AppRoutes = () => {
  const sanityPages = useSanityData<ISanityCache["pages"]>("pages");

  const mappedSanityPages = sanityPages.map(({ _id, _type, route, seo }) => {
    const Component = (PageComponents as any)[
      _type as keyof typeof PageComponents
    ];

    if (Component && route) {
      const element = seo ? (
        <Fragment>
          <HeadMeta {...seo} />
          <Component sanityId={_id} />
        </Fragment>
      ) : (
        <Component sanityId={_id} />
      );

      return <Route key={_id} path={route} element={element} />;
    }
    return null;
  });

  const mappedHiddenRoutes = hiddenRoutes.map(
    ({ key, path, element, nested, redirects }) => {
      return (
        <Fragment key={key}>
          {nested ? (
            <Route path={path} element={element}>
              {nested.map((sub) => (
                <Fragment key={sub.key}>
                    <Route key={sub.key} path={sub.path} element={sub.element} />
                    {sub.redirects?.map(redirect => (
                      <Route
                      key={`${key}_redirect`}
                      path={redirect.from}
                      element={<Navigate to={redirect.to ?? "/"} />}
                    />
                    ))}
                </Fragment>
              ))}
            </Route>
          ) : (
            <Route path={path} element={element} />
          )}
          {redirects?.map(redirect => (
            <Route
              key={`${key}_redirect`}
              path={redirect.from}
              element={<Navigate to={redirect.to ?? "/"} />}
            />
          ))}
        </Fragment>
      );
    }
  );

  return (
    <ContentWrapper>
      <ErrorBoundary fallback={<OutOfTune />}>
        <Suspense fallback={<WaitSpin dark />}>
          <ScrollToTop />
          <Routes>
            {mappedSanityPages}
            {mappedHiddenRoutes}
            <Route path="not-found" element={<Route404Page />} />
            <Route path="*" element={<Route404Page />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </ContentWrapper>
  );
};

export default AppRoutes;
