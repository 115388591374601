import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Icon } from "./Icon";
import React from "react";

export const CheckBoxCheckedIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17.778 0H2.222C1 0 0 1 0 2.222v15.556C0 19 1 20 2.222 20h15.556C19 20 20 19 20 17.778V2.222C20 1 19 0 17.778 0ZM8.567 14.767a1.107 1.107 0 0 1-1.567 0l-3.989-3.99a1.107 1.107 0 0 1 0-1.566 1.107 1.107 0 0 1 1.567 0l3.2 3.2 7.644-7.644a1.107 1.107 0 1 1 1.567 1.567l-8.422 8.433Z"
      fill="currentColor"
    />
  </Icon>
));

export const CheckBoxIndeterminateIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17.778 0H2.222C1 0 0 1 0 2.222v15.556C0 19 1 20 2.222 20h15.556C19 20 20 19 20 17.778V2.222C20 1 19 0 17.778 0Zm-3.334 11.111H5.556c-.612 0-1.112-.5-1.112-1.111s.5-1.111 1.112-1.111h8.888c.612 0 1.112.5 1.112 1.111s-.5 1.111-1.112 1.111Z"
      fill="currentColor"
    />
  </Icon>
));

CheckBoxIndeterminateIcon.displayName = "CheckBoxIndeterminateIcon";

export const CheckBoxUncheckedIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect
      x={0.75}
      y={0.75}
      width={18.5}
      height={18.5}
      rx={3.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </Icon>
));

CheckBoxUncheckedIcon.displayName = "CheckBoxUncheckedIcon";
