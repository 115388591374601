import React, { useCallback, useState } from "react";
import UserPool from "@app/environments/UserPool";
import { CognitoUser }  from "amazon-cognito-identity-js"
import { Row, Col, Input} from "antd"
import { Button, Typography } from "../../components/songtradr-components";
import { useNavigate, useParams } from "react-router-dom";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import "styled-components/macro";
import { styles } from './styles';
import { initialErrorState, useErrorContext } from "@app/errorContext";


const UserConformation: React.FC = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const { errorState, setErrorState } = useErrorContext();
    const { username } = useParams();
    const navigation = useNavigate();

     const confirmSignUp = useCallback((event?: any) => {
        if (username && verificationCode !== '') {
            event?.preventDefault();
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool,
            })
    
            user.confirmRegistration(verificationCode, false, (err) => {
                if (err) {
                    if (err) {
                        const error = handleError(err);
 
                        if (error) {
                            setErrorState({message: error});
                        }
                    }
                } else {
                    setErrorState(initialErrorState);
                    navigation('/signin')
                }
            })
        }
    },[navigation, setErrorState, username, verificationCode])

    const resendCode = useCallback(() => {
        if (username) {
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool,
            })
    
            user.resendConfirmationCode((err) => {
                if (err) {
                    if (err) {
                        const error = handleError(err);
 
                        if (error) {
                            setErrorState({message: error});
                        }
                    }
                }
            });
        }
    }, [setErrorState, username])


    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            confirmSignUp();
        }
    }, [confirmSignUp])


    return <Row css={styles.topMargin} gutter={[16,16]} >
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                    <Row gutter={[0, 24]}>
                    {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                        <Col span={24}>
                            <Typography variant="h3"> Enter Verification Code </Typography>
                        </Col>
                        <Col span={24}>
                            <Input 
                                css={styles.inputHeight}
                                placeholder="Verification Code"
                                value={verificationCode}
                                onKeyDown={handleEnterKeyDown}
                                onChange={(event) => setVerificationCode(event.target.value)}
                            />
                        </Col>
                        <Col span={24}>
                        <Typography variant="body"> Lost your verification code? <span onClick={resendCode} css={styles.resendCode}>Resend code</span></Typography> 
                        </Col>
                        <Col span={24}>
                        <Button variant="primary" onClick={confirmSignUp}> Confirm </Button> 
                        </Col>
                       
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
 </Row>
}

export default UserConformation