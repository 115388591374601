interface ISliderDefinitionSize {
  width: number;
  height: number;
}

interface ISliderDefinitionSizes {
  mobile: ISliderDefinitionSize;
  desktop: ISliderDefinitionSize;
}

export interface ISliderDefinitionEntry {
  sliderItem: ISliderDefinitionSizes;
  mediaImage: ISliderDefinitionSizes;
}
interface ISliderDefinition {
  default: ISliderDefinitionEntry;
  rank1: ISliderDefinitionEntry;
  rank2: ISliderDefinitionEntry;
  rank3: ISliderDefinitionEntry;
  rank4: ISliderDefinitionEntry;
}

export const sliderDefinition: ISliderDefinition = {
  default: {
    sliderItem: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 300,
        height: 250,
      },
    },
    mediaImage: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 300,
        height: 250,
      },
    },
  },
  rank1: {
    sliderItem: {
      mobile: {
        width: 208,
        height: 0,
      },
      desktop: {
        width: 498,
        height: 0,
      },
    },
    mediaImage: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 474,
        height: 474,
      },
    },
  },
  rank2: {
    sliderItem: {
      mobile: {
        width: 208,
        height: 0,
      },
      desktop: {
        width: 284,
        height: 0,
      },
    },
    mediaImage: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 260,
        height: 474,
      },
    },
  },
  rank3: {
    sliderItem: {
      mobile: {
        width: 208,
        height: 0,
      },
      desktop: {
        width: 324,
        height: 0,
      },
    },
    mediaImage: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 300,
        height: 250,
      },
    },
  },
  rank4: {
    sliderItem: {
      mobile: {
        width: 208,
        height: 0,
      },
      desktop: {
        width: 324,
        height: 0,
      },
    },
    mediaImage: {
      mobile: {
        width: 200,
        height: 200,
      },
      desktop: {
        width: 300,
        height: 200,
      },
    },
  },
};
