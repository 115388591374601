import { css } from "styled-components";

export const styles = {
    topMargin: css`
      margin-top: 8px;
    `, 

    requirementCompleted: css`
        text-decoration: line-through;
        opacity: 0.7;
    `,

    requirementNotCompleted: css`
       text-decoration: none;
       opacity: 1;
    `,

  };
  
