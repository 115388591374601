import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
} from "react";
import { Button as ButtonBase } from "../../components/songtradr-components";
import { IButtonSchema } from "@app/sanity-generated-data/types";
import Link from "@app/components/link";
import { isNullOrEmptyString } from "@app/utils";

const SanityButton = forwardRef<
  HTMLButtonElement,
  {
    schema: IButtonSchema;
  } & ComponentPropsWithoutRef<"button" | "a">
>(({ schema, onClick: propsOnClick, ...reduced }, ref) => {
  const { _key, configured, text, variant, size, action, onClick } = schema;
  const rest = { ref, key: _key, ...reduced };

  const handleClick = useCallback(
    (e: any) => {
      propsOnClick && propsOnClick(e);
      onClick && onClick(e);
    },
    [propsOnClick, onClick]
  );

  return configured ? (
    action && !isNullOrEmptyString(action.to) ? (
      <Link
        {...(rest as ComponentPropsWithoutRef<"a">)}
        to={action?.to as string}
        target={action?.target}
        onClick={handleClick}
      >
        {variant === "link" ? (
          text
        ) : (
          <ButtonBase
            {...(rest as ComponentPropsWithoutRef<"button">)}
            variant={variant}
            size={size}
          >
            {text}
          </ButtonBase>
        )}
      </Link>
    ) : (
      <ButtonBase
        onClick={handleClick}
        {...(rest as ComponentPropsWithoutRef<"button">)}
        variant={variant}
        size={size}
      >
        {text}
      </ButtonBase>
    )
  ) : null;
});

export default SanityButton;
