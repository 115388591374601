import React from "react";
import ScrollToTop from "@app/routing/ScrollToTop";
import { ErrorPageWrapper } from "./styles";
import { Typography } from "../../components/songtradr-components";

const Route404Page = () => {

  return (
    <ErrorPageWrapper align={"middle"}>
      <ScrollToTop />
      <Typography variant={"h1"}>404!</Typography>
      <Typography variant={"body"}>
        We couldn't find a page for that url... If you believe this to be an
        error, try refreshing the page.
      </Typography>
      <Typography variant={"small"}>
        If the problem persists, please reach out to support.
      </Typography>
    </ErrorPageWrapper>
  );
};

export default Route404Page;
