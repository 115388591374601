import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import "styled-components/macro";
import { useSanityData } from "@app/sanity-generated-data";
import { IMediaDetailsPageSchema } from "@app/sanity-generated-data/types";
import { HeadMeta, WaitSpin } from "@app/components";
import MediaDetailsHero from "./MediaDetailsHero";
import { MediaDetailsProvider, useMediaDetailsPageData } from "./store";
import { MediaDetailsPageWrapper, MediaDetailsPageContent } from "./styles";
import { splitPathname, generateImageUrl } from "@app/utils/helpers/ImageUrlFormater";
import { useLocation, useParams } from "react-router-dom";
import { checkIfShouldDisplayAds } from "../../utils/helpers/shouldDisplayAds";
import { useWindowDimensions } from "@app/components/songtradr-components";
import { useUserContext } from "@app/userContext";
import { ePageTypes } from "@app/api/types";

const MediaDetailsContent = React.lazy(() => import("./MediaDetailsContent"));

// MAIN COMPONENT
const MediaDetailsPageInner = ({
  schema,
}: {
  schema: IMediaDetailsPageSchema;
}) => {
  const { apiLoading, apiRefreshing, detailsState, heroState } = useMediaDetailsPageData();
  const location = useLocation();
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const { mediaId = "", parentId = "", childId = "" } = useParams();
  const { userState } = useUserContext();
  const { name } = detailsState;


  const getUrl = useCallback(() => {
    const pathData = splitPathname(location.pathname);
    const pageType = pathData[0]
    const imageSlug = pageType === ePageTypes.AD ? pathData[2] ?? pathData[1] : pathData[1];
    const imageUrl = generateImageUrl(imageSlug, pageType, pageType, isMobile);

    return imageUrl;
  }, [isMobile, location.pathname])

  useEffect(() => {
    if (checkIfShouldDisplayAds()) {
      if (name) {
        const registerAd = () => {
          (window as any)?.amplified
            ?.setParams?.({
              artist: name,
              song: name,
              hostname: "srv.tunefindforfans.com",
            })
            .pushAdUnit(100005774)
            .run();
        };
        if ((window as any).amplified) {
          registerAd();
        } else {
          // Initialize amplified if not already initialized
          (window as any).amplified = { init: [registerAd] };
        }
      }
    }
  }, [name]);


  const memHero = useMemo(() => {
    return <MediaDetailsHero
      schema={schema.hero}
      computedImgUrl={getUrl()}
      name={heroState?.name}
      airDate={heroState?.airDate}
      songCount={heroState?.songCount}
      isFollowing={heroState?.isFollowing}
      id={heroState?.id}
    />},
    [schema.hero, heroState?.name, heroState?.airDate, heroState?.songCount, heroState?.isFollowing, heroState?.id, getUrl])

  return (
    <MediaDetailsPageWrapper>
      {heroState && memHero}
      <MediaDetailsPageContent>
        {apiLoading ? (
          <WaitSpin dark />
        ) : (
          <Fragment>
            <HeadMeta
              title={name}
              twitter={{ layout: "summary" }}
              blockCrawlers={false}
            />
            <div id="amplified_100005774"></div>
            {detailsState && <MediaDetailsContent
              apiData={detailsState}
              apiRefreshing={apiRefreshing}
              schema={schema}
              mediaId={mediaId}
              parentId={parentId}
              childId={childId}
              location={location}
              isMobile={isMobile}
              userLoggedIn={userState?.authenticated}
            />}


          </Fragment>
        )}
      </MediaDetailsPageContent>
    </MediaDetailsPageWrapper>
  );
};

// WRAPPER EXPORTED TO INCLUDE PROVIDER
const MediaDetailsPage = ({ sanityId }: { sanityId: string }) => {
  const sanitySchema = useSanityData<IMediaDetailsPageSchema>(
    "pages",
    sanityId
  );

  return (
    <MediaDetailsProvider>
      <MediaDetailsPageInner schema={sanitySchema} />
    </MediaDetailsProvider>
  );
};

export default MediaDetailsPage;
