import UserPool from "@app/environments/UserPool";
import { CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import mem from "mem";

export const refreshUserSession = async () => {
  const refresh_token = localStorage.getItem("tf_auth_refresh_token");
  const user = UserPool.getCurrentUser();
  let newAccessToken = undefined;

  if (refresh_token && user) {
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refresh_token });

    try {
      const session: CognitoUserSession = await new Promise((resolve, reject) => {
        user.refreshSession(cognitoRefreshToken, (err, session) => {
            if (err) {
              reject(err);
              return; 
            }
            resolve(session);
        });
      });

      if (session) {
        localStorage.setItem("tf_auth_access_token", session.getAccessToken().getJwtToken());
        localStorage.setItem("tf_auth_refresh_token", session.getRefreshToken().getToken());
        localStorage.setItem("tf_auth_access_token_exp", session.getAccessToken().getExpiration().toString());
        localStorage.setItem("tf_auth_id_token", session.getIdToken().getJwtToken());

        newAccessToken = session.getAccessToken().getJwtToken();
      }

    } catch (error) {
      localStorage.removeItem("tf_auth_access_token");
      localStorage.removeItem("tf_auth_access_token_exp");
      localStorage.removeItem("tf_auth_refresh_token");
      localStorage.removeItem("tf_auth_id_token");
    }
  }

  return newAccessToken;
}


export const memRefreshToken = mem(refreshUserSession);