import React, { useEffect, useState, useCallback } from "react";
import "styled-components/macro";
import { Row } from "antd";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import {
  Typography,
  useWindowDimensions,
  TypographyVariant,
} from "../songtradr-components";
import { IButtonSchema } from "@app/sanity-generated-data/types";
import { SanityButton } from "@app/sanity-components";
import { styles, SliderWrapper } from "./styles";

interface ISliderProps {
  header: sliderHeader;
  headerCta?: IButtonSchema;
  offset?: number;
  dots?: boolean;
  children: any;
  looping?: boolean;
}

export const SliderItem = ({
  children,
  ...rest
}: {
  children: any;
  width: number;
}) => {
  return <div {...rest}>{children}</div>;
};

type sliderHeader = {
  text: string;
  variant: TypographyVariant;
};

type PrevNextButtonPropType = {
  enabled: boolean;
  onClick: () => void;
};

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
};

const Slider = ({
  children,
  header,
  headerCta,
  offset,
  dots,
  looping,
}: ISliderProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const options: EmblaOptionsType = {
    speed: 7,
    loop: looping,
    align: offset ? offset / window.innerWidth : 0,
    slidesToScroll: "auto",
    ...(isMobile && { dragFree: true }),
    ...(!isMobile && { dragFree: false }),
    ...(isMobile && { draggable: true }),
    ...(!isMobile && { draggable: false }),
  };

  //STATE
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  //EMBLA VARIABLES
  const DotButton: React.FC<DotButtonPropType> = (props) => {
    const { selected, onClick } = props;

    return <div onClick={onClick} css={styles.dot(selected)} />;
  };

  const PrevButton: React.FC<PrevNextButtonPropType> = (props) => {
    const { enabled, onClick } = props;
    return (
      <img
        css={styles.emblaLeftArrow(offset ?? 0)}
        onClick={onClick}
        src={
          enabled
            ? "/images/arrowEnabled.svg"
            : "/images/arrowDisabled.svg"
        }
        alt="Left Arrow"
      />
    );
  };

  const NextButton: React.FC<PrevNextButtonPropType> = (props) => {
    const { enabled, onClick } = props;
    return (
      <img
        css={styles.emblaRightArrow(offset ?? 0)}
        onClick={onClick}
        src={
          enabled
            ? "/images/arrowEnabled.svg"
            : "/images/arrowDisabled.svg"
        }
        alt="Right Arrow"
      />
    );
  };

  //EFFECTS - to keep the embla api updated properly
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  //EMBLA API FUNCTIONS
  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  //DRAG FUNCTIONALITY
  let startingXCoord = 0;

  const handleWindowMouseMove = (event: PointerEvent) => {
    if (startingXCoord !== 0) {
      if (event.screenX < startingXCoord - 60) {
        window.removeEventListener("pointermove", handleWindowMouseMove, true);

        scrollNext();
      } else if (event.screenX > startingXCoord + 60) {
        window.removeEventListener("pointermove", handleWindowMouseMove, true);
        scrollPrev();
      }
    } else {
      startingXCoord = event.screenX;
    }
  };

  const handleDrag = (e: React.UIEvent<HTMLElement>): void => {
    e.preventDefault();
    window.addEventListener("pointermove", handleWindowMouseMove, true);
  };

  const handleMouseUp = () => {
    window.removeEventListener("pointermove", handleWindowMouseMove, true);
  };

  //RENDER
  return (
    <div>
      <SliderWrapper offset={offset ? offset : 0}>
        <Row align={"middle"}>
          <Typography variant={header.variant}>{header.text}</Typography>
          {!isMobile && headerCta?.configured && (
            <SanityButton css={styles.headerCta} schema={headerCta} />
          )}
          <Row css={styles.arrowsContainer} align="middle">
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </Row>
        </Row>
      </SliderWrapper>
      <div
        onDragStart={!isMobile ? handleDrag : undefined}
        onMouseUp={!isMobile ? handleMouseUp : undefined}
        draggable={!isMobile && true}
        css={styles.embla}
      >
        <div css={styles.emblaViewport} ref={emblaRef}>
          <div css={styles.emblaContainer()}>{children}</div>
        </div>
      </div>
      {dots && (
        <div css={styles.emblaDots}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Slider;
