/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from "react";
import {
  IMediaDetailsActionContext,
  IMediaDetailsDataContext,
  IMediaDetailsPageData,
} from "./types";
import { IMediaDetailsHeroData } from "@app/api/types";

export const initialDataState: IMediaDetailsDataContext = {
  apiLoading: true,
  apiRefreshing: true,
  detailsState: {} as IMediaDetailsPageData,
  heroState: {} as IMediaDetailsHeroData,
};

export const initialActionState: IMediaDetailsActionContext = {
  refresh: () => {},
  updateDetails: () => {},
  updateHero: () => {},
  toggleFollow: () => {},
};

// CONTEXT CONSUMERS
export const useMediaDetailsPageData = () =>
  useContext(MediaDetailsDataContext);

export const useMediaDetailsPageActions = () =>
  useContext(MediaDetailsActionContext);

// CONTEXT EXPORTS
export const MediaDetailsDataContext =
  createContext<IMediaDetailsDataContext>(initialDataState);

export const MediaDetailsActionContext =
  createContext<IMediaDetailsActionContext>(initialActionState);
