import { TabExplorer, WaitSpin } from "@app/components";
import { useSanityData } from "@app/sanity-generated-data";
import { IProfilePageSchema } from "@app/sanity-generated-data/types";
import { Col, Row } from "antd";
import React from "react";
import "styled-components/macro";
import {
  ProfilePageWrapper,
  styles,
  UserProfilePageContent,
  UserProfilePageWrapper,
} from "./styles";
// import StatusMessage from "./StatusMessage";
import MetricsBox from "./MetricsBox";
import { IUserProfileResponse } from "@app/api/types";
import { Typography, useWindowDimensions } from "../../components/songtradr-components";
import { useUserContext } from "@app/userContext";

export const UserProfilePage = ({
  sanityId,
  apiData,
}: {
  sanityId: string;
  apiData: IUserProfileResponse;
}) => {
  const { header, tabsExplorer, metrics } = useSanityData<IProfilePageSchema>(
    "pages",
    sanityId
  );
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const { userState } = useUserContext();
  
  // const mappedStatusMessages = useMemo(
  //   () =>
  //     apiData?.statusMessages?.map((message) => (
  //       <StatusMessage
  //         key={message.title}
  //         schema={statusMessages}
  //         data={message}
  //       />
  //     )),
  //   [apiData, statusMessages]
  // );

  return apiData && header && tabsExplorer && metrics ? (
    <ProfilePageWrapper>
      <UserProfilePageWrapper>
        <Typography variant={header.variant}>{header.text}</Typography>
        <UserProfilePageContent as={Row} wrap={false}>
          <Col flex={1} css={styles.leftCol}>
            <TabExplorer
              userLoggedIn={userState.authenticated}
              isMobile={isMobile}
              loading={false}
              schema={tabsExplorer}
              data={apiData?.tabs}
              user={apiData?.user}
            />
          </Col>
          <Col css={styles.rightCol}>
            {/* {mappedStatusMessages} */}
            <MetricsBox schema={metrics} data={apiData?.metrics} />
          </Col>
        </UserProfilePageContent>
      </UserProfilePageWrapper>
    </ProfilePageWrapper>
  ) : (
    <WaitSpin dark />
  );
};

export default UserProfilePage;
