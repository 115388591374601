import React from "react";

// @ts-ignore
import styled from "styled-components";
import WaitSpin from "../wait-spin/WaitSpin";
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { IAdTargets } from "@app/utils/helpers/getAdTargets";

// TYPES
type AdvertisementBaseProps = {
  id?: string;
  adValue: string;
  display: "small" | "large" | "always";
  roundCorners?: boolean;
  adSchema?: any;
  isMobile: boolean;
  adTargets: IAdTargets;
  width: number;
  height: number;

  collapse: () => void;
};

// STYLES
const AdvertisementBaseWrapper = styled.div<{
  width: number;
  height: number;
  roundCorners: AdvertisementBaseProps["roundCorners"];
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ roundCorners }) => (roundCorners ? "2px" : "0px")};
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  position: relative;

  iframe {
    width: ${({ width }) => width}px!important;
    height: ${({ height }) => height}px!important;
  }

  > div {
    position: absolute;
  }

  @keyframes preload_pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  section {
    width: ${({ width }) => width}px!important;
    height: ${({ height }) => height}px!important;
    position: absolute;
    background: #2e3941;
    animation: preload_pulse 6s infinite ease-in-out;
  }
`;

// COMPONENT
const AdvertisementBase = ({
  id,
  adValue,
  display,
  roundCorners,
  isMobile,
  adTargets,
  width,
  height,
  ...rest
}: AdvertisementBaseProps) => {

  const shouldRender = {
    always: true,
    large: !isMobile,
    small: isMobile,
  };

  if (shouldRender[display]) {
    return (width > 0 && height > 0) ? (
      <AdvertisementBaseWrapper
        {...rest}
        width={width}
        height={height}
        roundCorners={roundCorners}
      >
        <section>
          <WaitSpin />
        </section>
        <FreestarAdSlot
          placementName={adValue}
          publisher="tunefind-com"
          slotId={`${adValue}-${id}`}
          targeting={adTargets}
          // sizes={adValue.includes("TuneFind_1x1") ? [[1, 1]] : _sizes}
          // onSlotRender={(dfpEventData: any) =>
          //   dfpEventData.event.isEmpty && collapse()
          // }
        />
      </AdvertisementBaseWrapper>
    ) : null;
  } else {
    return null;
  }
};

export default AdvertisementBase;
