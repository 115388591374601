import { Modal } from "antd";
import styled, { css } from "styled-components";

export const StyledCookieModal = styled(Modal)(() => {
  return css`
    position: fixed;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;

    .ant-modal-content {
      width: min(100%, 1248px);
      box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.2);
      margin-left: auto;
      background: var(--semantic-background-top);
      border-radius: 1rem;
      color: var(--semantic-background-base);

      .ant-modal-close {
      }

      .ant-modal-body {
        padding: max(8px, min(3vw, 32px));

        button:first-child {
          margin-right: 1rem;
        }
      }

      .ant-modal-footer {
        display: none;
      }
    }
  `;
});
