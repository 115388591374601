import { ErrorCodes } from "@app/enums/ErrorCodes";
import { ErrorMessages } from "@app/enums/ErrorMessages";

export const handleError = (error: any) => {
    switch (error.code) {
        case ErrorCodes.USER_NOT_CONFIRMED:
            return ErrorMessages.USER_NOT_CONFIRMED
        case ErrorCodes.PASSWORD_RESET_REQUIRED:
            return ErrorMessages.DEFAULT
        case ErrorCodes.NOT_AUTHORIZED:
            return ErrorMessages.INVALID_CREDENTIALS;
        case ErrorCodes.INVALID_PASSWORD:
            return ErrorMessages.INVALID_CREDENTIALS;
        case ErrorCodes.USER_EXISTS: 
            return ErrorMessages.DUPLICATE_USER;
        case ErrorCodes.USER_NOT_FOUND_PASSWORD_RESET: 
            if (error.message.includes('Exception migrating user in app client')) {
                return ErrorMessages.USER_NEEDS_PASSWORD_RESET;
            }
            return ErrorMessages.INVALID_USER;
        case ErrorCodes.USER_NOT_FOUND:
            return ErrorMessages.INVALID_USER;
        case ErrorCodes.TOO_MANY_REQUESTS:
            return ErrorMessages.TOO_MANY_REQUESTS;
        case ErrorCodes.INVALID_PARAMETER:
            if (error.message.includes('Username cannot be of email format')) {
                return ErrorMessages.USERNAME_CANNOT_BE_OF_EMAIL_FORMAT;
            }
            if (error.message.includes(`Value at 'username' failed to satisfy constraint`)) {
                return ErrorMessages.USERNAME_HAS_SPACES;
            }
            return error.message;
        case ErrorCodes.LIMIT_EXCEEDED:
            return error.message;
        case ErrorCodes.USER_EXISTS_PRE_SIGNUP: 
            return ErrorMessages.USER_EXISTS_SIGN_UP;
        case ErrorCodes.WRONG_VERIFICATION_CODE:
            return error.message;
        case ErrorCodes.FACEBOOK_UNKNOWN_ACCOUNT: 
            return 'This email is already registered to a tunefind account please sign in'
        default:
            return ErrorMessages.DEFAULT;            
    }
};