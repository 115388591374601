import { maxMedium, minLarge } from "../songtradr-components";
import styled, { css } from "styled-components";

export const TabExplorerWrapper = styled.div`
  width: 100%;

  > div:first-child {
    margin-bottom: 4rem;
  }
`;

export const TabExplorerHeader = styled.div`
  @media ${minLarge} {
    flex-direction: row;
  }
  @media ${maxMedium} {
    flex-direction: column;

    > .ant-col:first-child {
      border-bottom: 1px solid var(--input-border);
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const RightColAds = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  > div {
    position: sticky;
    top: 100px;
  }
`;

export const tabStyles = {
  tab: css`
    -webkit-tap-highlight-color: transparent;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  `,
};

export const profileStyles = {
  username: css `
    word-wrap: break-word;
  `
}