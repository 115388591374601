import { AdSizes } from "@app/constants/AdSizes";

export const getAdSize = (adName: string | undefined, isMobile: boolean) => {
    if (adName) {
         if (adName.includes("_mobile")) {
        if (adName.includes("_slider")) {

            return AdSizes.AD_SIZE_200_200;
        } else if (adName.includes("_banner")) {

            return AdSizes.AD_SIZE_300_100;
        } 

    } else if (adName.includes("_desktop")) {
        if (adName.includes("_slider")) {

            return AdSizes.AD_SIZE_300_250;
        } else if (adName.includes("_banner")) {

            return AdSizes.AD_SIZE_728_90;
        } else if (adName.includes("_sidebar")) {

            return AdSizes.AD_SIZE_300_600;
        } 
    } else if (adName.includes("_anchor")) {
        
        return isMobile ? AdSizes.AD_SIZE_320_100 : AdSizes.AD_SIZE_728_100;
    }
    }
    
    return { width: 0, height: 0 }
};
