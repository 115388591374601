import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import sanityCache, { loadPage } from "./cache";
import { fetchLatestBucket, fetchLatestById } from "./queries";
import {
  IBasePageSchema,
  IFooterConfig,
  IHeaderConfig,
  ISanityCache,
  ISiteConfig,
} from "./types";


const determineIfPreview = () => {
  if (process.env.REACT_APP_STAGING === "true") {
    return window.location.host === "preview.tunefind.songtradr.cloud";
  } else if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_SANITY_PREVIEW === "true";
  }
};

export function useSanityData<T>(bucket: keyof ISanityCache, id?: string) {
  const fromCache = useMemo(
    (): T =>
      id
        ? sanityCache[bucket].find((data) => id === data._id)
        : sanityCache[bucket],
    [id, bucket]
  );

  const [data, setData] = useState<T>(fromCache);

  useEffect(() => {
    try {
      if (determineIfPreview()) {
        id ? fetchLatestById(id, setData) : fetchLatestBucket(bucket, setData);
      } else {
        setData(fromCache);
        //lazy load more data, for example privacy texts, etc ...
        if (id) {
          loadPage(id)?.then((page) => {
            setData({...fromCache, ...page});
          });
        }
      }
    } catch (e) {
      console.error(`Failed to retrieve Sanity Data`, e);
    }
  }, [id, bucket, fromCache, setData]);

  return data;
}

export const useSanitySettings = () => {
  const settings = useSanityData<ISanityCache["settings"]>("settings");
  const getType = (type: string) => settings.find((m) => m._type === type);

  return {
    siteConfig: getType("site-config") as ISiteConfig,
    headerConfig: getType("header-config") as IHeaderConfig,
    footerConfig: getType("footer-config") as IFooterConfig,
  };
};

export const useSanityPageRoute = (id: string) => {
  const { pathname } = useLocation();
  const { route } = useSanityData<IBasePageSchema>("pages", id);

  return useMemo(() => {
    return {
      route: route ?? "/",
      matches: route === pathname,
    };
  }, [route, pathname]);
};
