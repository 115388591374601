import { createGlobalStyle } from "styled-components";
import { maxMedium } from "../components/songtradr-components";

export default createGlobalStyle`
    body {       
        .ant-layout {
            background: unset;
        }

        strong {
            font-weight: bold;
        }

        a{
            color:#cfcfcf;
            transition: all ease .3s;
            :hover{
                color:#ffffff;
            }
        }
        .ant-tooltip-inner {
            padding: 6px 8px 10px 8px;
        }
        .ant-dropdown {
            z-index: 1 !important;
        }
        .ant-select-dropdown {
            z-index: 1;
        }

        //PLEASE WAIT MODAL STYLES
        .ant-modal-body {
            padding: 0;
        }
        .ant-modal-confirm-btns {
            display: none;
        }
        .ant-modal-content {
            box-shadow: none;
            background: transparent;
        }
        .ant-modal-confirm-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: white;
            span {
                margin-top: 10px;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
            }
        }
        .ant-modal-body {
            background: transparent;
        }


        /** @todo For Jira Issue Tracking Functionality */
        #atlwdg-trigger {
            margin: 20px 20px;
            background: #2c9095;
            color: white!important;
            font-size: 14px;
            padding: 12px;
            border: none;
            border-radius: .5rem;
            box-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
            transition:all ease .3s;
            :hover{
                background:#21696c;
            }
        }

        @media screen and (min-width: 961px) {
            #atlwdg-trigger {
                margin: 70px 20px;
            }
        }

        /** change freestar bottom banner and icons */
        .fs-sticky-footer {
            z-index: 10 !important;
            
            .fs-sticky-parent {
                pointer-events: none !important;

                .fs-sticky-slot-element{
                    background: transparent !important;
                    pointer-events: none !important;
                    
                    iframe {
                        pointer-events: auto !important;
                    }
                }

                .fs-close-button {
                    display: none !important;
                }
            }

            /* @media ${maxMedium} {
                display: none !important;
                visibility: hidden !important;
            } */
        }

        .__fs-branding{
            display: none !important;
            visibility: hidden !important;
        }
    }

    .grecaptcha-badge { 
        visibility: hidden;
    }

    .__non_b_w {
        pointer-events: none;
    }

  
`;
