import React, { useMemo } from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import { Typography, useWindowDimensions } from "../songtradr-components";
import { useSanitySettings } from "@app/sanity-generated-data";
import Link from "@app/components/link";
import "./styles.css";
import { useContentMargins } from "@app/styles/helpers";




const AppFooter = () => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const { margin } = useContentMargins();

  const {
    footerConfig: {
      tagline,
      copyrightAddendum,
      categorizedLinks,
      socialLinks,
    },
  } = useSanitySettings();

  // LINKS
  const mappedCategorizedLinks = useMemo(() => {
    return (
      <Row justify="space-between">
        {categorizedLinks.map((category, index) => {
          return (
            <Col key={category._key} span={8}>
              <Typography variant={"bodyBold"} margin={false}>
                {category.title}
              </Typography>
              {category.links.map(({ _key, text, _type, url }) => {
                return (
                  <Link
                    className="link"
                    key={_key}
                    to={url}
                    target={_type === "internal" ? "_self" : "_blank"}
                  >
                    <Typography variant={"body"} truncate>
                      {text}
                    </Typography>
                  </Link>
                );
              })}
              {index === categorizedLinks.length - 1 && (
                <>
                  <Row className="cmp-link">
                    <Link
                      className="link"
                      onClick={() => window.__uspapi("displayUspUi")}
                    >
                      <Typography margin={false} variant={"body"}>
                        Do Not Sell My Personal Information
                      </Typography>
                    </Link>
                  </Row>
                  <Row className="cmp-link">
                    <Link
                      className="link"
                      onClick={() =>
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        window.__tcfapi("displayConsentUi", 2, function () {})
                      }
                    >
                      <Typography margin={false} variant={"body"} truncate>
                        Change Consent
                      </Typography>
                    </Link>
                  </Row>
                </>
              )}
            </Col>
          );
        })}
      </Row>
    );
  }, [categorizedLinks]);

  // SOCIAL LINKS
  const mappedSocialLinks = useMemo(() => {
    return (
      <Row wrap={isMobile} className="social-links" justify={"start"}>
        {socialLinks.map(({ _key, url, icon }) => (
          <Link key={_key} to={url} target={"_blank"}>
            <img src={icon.src} alt={icon.alt} />
          </Link>
        ))}
      </Row>
    );
  }, [socialLinks, isMobile]);

  // RENDER
  return (
    <footer className="footer-wrapper" style={{"--margin": margin} as React.CSSProperties}>
      <Row className="main-row">
        <Col span={isMobile ? 24 : 16}>
          <Row wrap={false}>
            <img
              className="logo"
              src={'/tunefind_logo.png'}
              alt={'tunefind-logo'}
            />
            <Typography
              className="tag-line"
              variant={isMobile ? "xsmall" : "small"}
            >
              {tagline}
            </Typography>
          </Row>
        </Col>
        <Col span={isMobile ? 24 : 8}>{mappedCategorizedLinks}</Col>
      </Row>
      <Row wrap={isMobile} className="secondary-row">
        <Col flex={1}>
          <Row wrap={isMobile} className="copyright">
            <Typography variant={isMobile ? "xsmall" : "small"}>
              {isMobile
                ? `\u00a9 Tunefind LLC. All rights reserved.`
                : `Copyright ${new Date().getFullYear()} Tunefind LLC.`}
            </Typography>
            {isMobile && mappedSocialLinks}
            <Typography
              className="copyright-add"
              variant={isMobile ? "xsmall" : "small"}
            >
              {copyrightAddendum}
            </Typography>
          </Row>
        </Col>
        <Col>{!isMobile && mappedSocialLinks}</Col>
      </Row>
    </footer>
  );
};

export default AppFooter;
