import { css } from "styled-components";

export const styles = {
    topOffset: css`
      margin-top: 100px;
      padding: 32px;
    `,

    titleUnderline: css`
        width: 100%;
        border-bottom: 1px solid #5e5e5e;
        margin-bottom: 40px;
    `, 

    title: css `
        text-align: center;    
    `,

    errorMessage: css `
        color: #ff3c3c;
        border: 1px solid;
        padding: 4px;
    `,

    inputOffset: css `
        marginTop: 16px;
    `,

    inputHeight: css `
        height: 40px;
    `,

    signInLink: css `
        text-decoration: underline;
        cursor: pointer;
    `,

    facebookButtonWrapper: css `
        display: flex;
        justify-content: center;
    `,

    signUpButtons: css `
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        @media only screen and (max-width: 1139px) {
            flex-direction: column;
        }
    `
  };
  
