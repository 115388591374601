import React from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import { styles } from "./styles";
//
import { HeaderProps } from "./SongTable";
import { Typography } from "../songtradr-components";

export const TableHeader = ({ orderDirection, handleReorder }: HeaderProps) => {
  const headerBarArrow = (mediaCategory: string) => {
    const currentCategory = orderDirection.find(
      (x) => x.property === mediaCategory
    );

    if (currentCategory?.selected) {
      if (currentCategory.direction) {
        return (
          <img
            src="https://img-sys.songtradr.com/e5f2224dc9880b97c6860432156b2c8653f8763c92ead0cd2bf523f64ccf2037.svg"
            alt="Down Arrow Icon Highlighted"
          />
        );
      } else {
        return (
          <img
            css={styles.imgFlip}
            src="https://img-sys.songtradr.com/e5f2224dc9880b97c6860432156b2c8653f8763c92ead0cd2bf523f64ccf2037.svg"
            alt="Up Arrow Icon Highlighted"
          />
        );
      }
    } else {
      if (currentCategory?.direction) {
        return (
          <img
            src="https://img-sys.songtradr.com/47cc3a66b48c13a1893476e2a75df0daae98f6292d07d1d07f1b1a0ad3525dcf.svg"
            alt="Down Arrow Icon"
          />
        );
      } else {
        return (
          <img
            css={styles.imgFlip}
            src="https://img-sys.songtradr.com/47cc3a66b48c13a1893476e2a75df0daae98f6292d07d1d07f1b1a0ad3525dcf.svg"
            alt="Down Arrow Icon"
          />
        );
      }
    }
  };

  return (
    <Row wrap={false} css={styles.sortableTableHeader}>
      <Col css={styles.headerLink} onClick={() => handleReorder("id")}>
        <Typography
          css={styles.underline(
            orderDirection.find((x) => x.property === "id")?.selected ?? true
          )}
          margin={false}
          variant="small"
        >
          Order
        </Typography>
        <div css={styles.headerImgWrapper}>
           {headerBarArrow("id")}
        </div>
      </Col>
      <Col css={styles.headerLink} onClick={() => handleReorder("name")}>
        <Typography
          css={styles.underline(
            orderDirection.find((x) => x.property === "name")?.selected ?? false
          )}
          margin={false}
          variant="small"
        >
          Name
        </Typography>
        <div  css={styles.headerImgWrapper}>
          {headerBarArrow("name")}
        </div>
      </Col>
      <Col css={styles.hasClickAction} onClick={() => handleReorder("artists")}>
        <Typography
          css={styles.underline(
            orderDirection.find((x) => x.property === "artists")?.selected ??
              false
          )}
          margin={false}
          variant="small"
        >
          Artist
        </Typography>
        <div  css={styles.headerImgWrapper}>
          {headerBarArrow("artists")}
        </div>
      </Col>
    </Row>
  );
};

export default TableHeader;
