import { Button } from "@app/components/songtradr-components";
import { Col, Input } from "antd";
import React, { useState, useCallback } from "react";
import { styles } from "./styles";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import UserPool from "@app/environments/UserPool";
import { PasswordErrorsObject, checkIfAllPasswordRequirementsAreMet, checkPasswordValidity } from "@app/utils/helpers/passwordValidator";
import { constructUserForSignIn } from "@app/utils/helpers/signInFlowUtils";
import { PasswordValidation } from "@app/components";
import "styled-components/macro";

interface IResetFromProps {
    usernameOrEmail: string;
    handleSuccess: (session: CognitoUserSession) => void;
    handleFailure: (err: any) => void;
}

const ResetForm = ({ usernameOrEmail, handleFailure, handleSuccess }: IResetFromProps) => {
    const [newPassword, setNewPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<PasswordErrorsObject[]>(checkPasswordValidity(''));
    const [verificationCode, setVerificationCode] = useState('');
    const [userStartedTypingPassword, setUserStartedTypingPassword] = useState(false);

    const loginUser = useCallback(() => {

        const { user, authDetails } = constructUserForSignIn(usernameOrEmail, newPassword)
        if (user && authDetails) {
            user.authenticateUser(authDetails, {
            onSuccess: (session) => {
                handleSuccess(session)
            },
            onFailure: (err) => {
                handleFailure(err);
            },
        })
        }
    },[usernameOrEmail, newPassword, handleSuccess, handleFailure])



    const confirmPassword = useCallback(() => {
        if (verificationCode && newPassword && checkIfAllPasswordRequirementsAreMet(passwordErrors).length === 0) {
            
                const user = new CognitoUser({
                    Username: usernameOrEmail,
                    Pool: UserPool,
                })

                user.confirmPassword(verificationCode, newPassword, {
                    onFailure(err) {
                       handleFailure(err);
                    },
                    onSuccess() {
                        loginUser();
                    },
                });
        }
       
    }, [verificationCode, newPassword, passwordErrors, usernameOrEmail, loginUser, handleFailure])

    const handlePasswordChange = useCallback((password: string) => {
        setNewPassword(password);

        if (!userStartedTypingPassword) {
            setUserStartedTypingPassword(true)
        }

        const errors = checkPasswordValidity(password);
        setPasswordErrors(errors);
    }, [userStartedTypingPassword])




    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            confirmPassword();
        }
    }, [confirmPassword])

    return (
        <>
            <Col span={24}>
                <Input 
                        css={styles.inputHeight}
                        placeholder="Verification code"
                        value={verificationCode}
                        type="text"
                        onKeyDown={(event) => handleEnterKeyDown(event)}
                        onChange={(event) => setVerificationCode(event.target.value)}
                        />
                <form autoComplete="false">
                        <Input 
                            autoComplete="new-password"
                            css={[styles.inputHeight, styles.inputOffset]}
                            placeholder="New Password"
                            value={newPassword}
                            type="password"
                            onKeyDown={(event) => handleEnterKeyDown(event)}
                            onChange={(event) => handlePasswordChange(event.target.value)}
                            />
                        {userStartedTypingPassword && <PasswordValidation errors={passwordErrors} />}
                </form>

            </Col>
            <Col span={24}>
                <Button variant="primary" onClick={confirmPassword}>Update Password</Button>
            </Col>
    </>
    )     
}

export default ResetForm;