import { css } from "styled-components";

export const styles = {
    topMargin: css`
      margin-top: 100px;
    `,

    errorMessage: css `
        color: #ff3c3c;
        border: 1px solid;
        padding: 4px;
    `,

    inputOffset: css `
        margin-top: 16px;
    `,

    inputHeight: css `
        height: 40px;
    `,

    topOffset: css`
    margin-top: 100px;
    padding: 32px;
    `,
    
    titleUnderline: css`
    width: 100%;
    border-bottom: 1px solid #5e5e5e;
    margin-bottom: 40px;
    `, 

    title: css `
    text-align: center;    
    `,
  };
  
