import userPool from "@app/environments/UserPool"
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js"

export const constructUserForSignIn = ( usernameOrEmail: string, password?: string ) => {
    let authDetails = null;

    const user = new CognitoUser({
        Username: usernameOrEmail.trim(),
        Pool: userPool
    })

    if (password) {
        authDetails = new AuthenticationDetails({
            Username: usernameOrEmail.trim(),
            Password: password,
        })
    }
  

    user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    return { user, authDetails };
}

export const handleSuccessfulSignIn = (session: CognitoUserSession) => {
    const payload = {
        access_token: session.getAccessToken().getJwtToken(),
        refresh_token: session.getRefreshToken().getToken(),
        exp_token: session.getAccessToken().getExpiration(),
        id_token: session.getIdToken().getJwtToken(),
    } 
    return payload;
}