/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from "react";
import { eUserType, IUserContext, IUserState } from "./interfaces";

export const initialUserState: IUserState = {
  authLoading: false,
  authenticated: false,
  userType: eUserType.User,
  userToken: "",
  userName: "",
  isFacebook: false,
  isPartOfFeaturePreview: false,
};

const UserContext = createContext<IUserContext>({
  userState: { ...initialUserState, authLoading: true },
  setState: () => {},
  exchangeGrant: () => {},
  fetchAndSetUserContextTokens: () => {},
  signOut: () => {},
  checkLoadUserAccount: () => {},
  nudge: () => {},
});

export default UserContext;

export const useUserContext = () => useContext(UserContext);
