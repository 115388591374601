import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserContext } from '../userContext';

export default function UserRoute({ children }: any) {
  const { pathname, search, hash } = useLocation();
  const context = useUserContext();

  if (context.userState.authLoading) {
    return null;
  }

  const currentPath = `${pathname}${search}${hash}`;

  if (!context.userState.authenticated) {
    return <Navigate to={`../signin?from=${currentPath}`}/>
  }

  return children;
}
