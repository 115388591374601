import { forwardRef, ComponentPropsWithoutRef } from "react";
import { Icon } from "./Icon";
import React from "react";

export const TickIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg">
>((props, ref) => (
  <Icon
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    ref={ref}
    {...props}
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      ...props.style,
    }}
  >
    <path
      d="M7.405 15.346c-.061-.053-.29-.266-.34-.317l-3.447-3.447c-.535-.534-.652-1.284-.261-1.675.39-.39 1.14-.274 1.675.261l2.945 2.945 7.062-8.239c.414-.483 1.089-.583 1.509-.223.419.359.423 1.042.01 1.525l-7.67 8.948a2.588 2.588 0 0 1-.071.077.805.805 0 0 1-.077.089c-.109.11-.244.233-.397.265-.336.116-.676.016-.938-.209Z"
      style={{
        fill: "currentColor",
      }}
    />
  </Icon>
));

TickIcon.displayName = "TickIcon";
