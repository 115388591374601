import React, { useState, useEffect, ReactNode } from "react";
import { Row, Col, Tooltip } from "antd";
import { Typography } from "../../components/songtradr-components";
import { fetchApiDashboard, infoToast } from "../../api/requests";
import { IApiDashboard } from "@app/api/types";
import { WaitSpin } from "@app/components";
import "./styles.css"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardBrand, getCCIcon } from "@app/utils/helpers/getCCIcon";
import { Input } from "antd"
import { faClone } from "@fortawesome/pro-solid-svg-icons";

const ApiDashboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState<IApiDashboard>();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        fetchApiDashboard().then((response: IApiDashboard) => {
            if (!response.subscriptions.length) {
                navigate('/api');
            }
            setDashboardData(response);
            setError("");
        })
            .catch(() => {
                setError("Unable to load your dashboard. Please refresh the page and contact support if the issue persists.");
            }).finally(() => {
                setLoading(false);
            });
    }, [navigate]);

    const copyToClipboard = (value: string ) => {
        if (navigator?.clipboard) {
            try {
                navigator.clipboard.writeText(value);
                infoToast('Copied to clipboard');
            } catch {
                Promise.reject('The Clipboard API is not available.');
            }
        }
    }
    const getPlanRow = (title: string, value: string | number | ReactNode, asInput?: boolean) => {
        const isReactNode = React.isValidElement(value);

        return <Row className="table-row">
            <Col md={8} xs={24}>
                {title}
            </Col>
            <Col md={16} xs={24}>
                {asInput && !isReactNode ?
                    <Input
                        className="custom-input"
                        disabled
                        prefix={value as string}
                        suffix={
                                <Tooltip className="custom-tooltip" title={
                                    <Typography
                                        variant="xsmall"
                                    >
                                        Copy to clipboard
                                    </Typography>
                                }>
                                    <FontAwesomeIcon icon={faClone} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(value as string)}/>
                                </Tooltip>
                        }
                    />
                    : value}
            </Col>
        </Row>
    }

    const getInvoiceRow = (date: string, amount: string | number, downloadPdf: string) => {
        return <Row className="table-row">
            <Col span={6}>
                {date}
            </Col>
            <Col span={8}>
                {amount}
            </Col>
            <Col span={10}>
                {downloadPdf}
            </Col>
        </Row>
    }

    const generateStatusText = (status: string, willRenew: boolean, endDate: string) => {
        const statusText = status.charAt(0).toUpperCase() + status.slice(1);
        const date = new Date(endDate);

        const dateString = new Intl.DateTimeFormat(undefined, { // undefined so it formats it locally ie. in the US its going to me January 1 but in SLO its 1 January
            year: "numeric",
            month: "long",
            day: "numeric"
        }).format(date);

        return `${statusText} - will ${willRenew ? 'renew' : 'end'} on ${dateString ?? ''}`
    }

    const generateBillingMethod = (cardBrand: CardBrand, lastDigits: string) => {
        return <div className="billing-wrapper">
            <FontAwesomeIcon className="cc-icon" icon={getCCIcon(cardBrand)} /> {` ending in ************${lastDigits}`}
        </div>
    }
    return (
        <Row className="content-wrapper" gutter={[0, 16]}>
            {error && <Row>
                <Typography style={{ color: 'red' }} variant="body"> {error} </Typography>
            </Row>}
            {loading ?
                <WaitSpin dark />
                :
                dashboardData?.subscriptions?.length &&
                dashboardData.subscriptions.map((subscription, index) => (
                    <React.Fragment key={index}>
                        <Row>
                            <Col span={24}>
                                <Typography variant="h1">Your Subscriptions</Typography>
                            </Col>
                        </Row>

                        {/* Plan */}
                        <Row>
                            <Col span={24}>
                                <Typography variant="h2">Plan</Typography>
                            </Col>
                            {getPlanRow('Plan Name', subscription?.plan?.name ?? '') /* Todo add - Api Documentation (v2) link */}
                            {getPlanRow('Status', generateStatusText(subscription?.status ?? '', subscription?.is_recurring, subscription?.current_period_end ?? ''))}
                            {getPlanRow('Billing Method', generateBillingMethod(subscription?.card_brand ?? '', subscription?.card_last_four ?? ''))}
                            {getPlanRow('API Username', subscription?.user_id ?? '', true)}
                            {getPlanRow('API Password', subscription?.api_password ?? '', true)}
                            {getPlanRow('API Endpoint', subscription?.api_endpoint ?? '', true)}
                        </Row>

                        {/* Invoices */}
                        <Row style={{ paddingTop: '32px' }}>
                            <Col span={24}>
                                <Typography variant="h2">Invoices</Typography>
                            </Col>
                            {getInvoiceRow('Date', 'Amount', 'Download PDF')}
                        </Row>
                    </React.Fragment>
                ))
            }
        </Row>
    )
}

export default ApiDashboardPage