import React, { useState, useMemo } from "react";
import { styles } from "./styles";
import { ISong } from "@app/api/types";
import { Col, Row } from "antd";

import { useWindowDimensions } from "../songtradr-components/utils";
import "styled-components/macro";
import { useNavigate } from "react-router-dom";
import ShowMore from "../show-more/ShowMore";
import { Typography } from "../songtradr-components";

interface IFeaturedOnProps {
  featuredOn: ISong["featuredOn"];
}

const FeaturedOn = ({ featuredOn, ...rest }: IFeaturedOnProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const navigate = useNavigate();

  const expandable = featuredOn && featuredOn.length > 4;
  const [expanded, setExpanded] = useState(false);

  //RENDERS
  const songFeatures = useMemo(() => {
    if (featuredOn) {
      const toRender = expanded ? featuredOn : featuredOn.slice(0, 4);
      return toRender.map(
        ({ id, slug, imageUrl, title, description, date }) => {
          const dimensions = isMobile ? 80 : 96;
          const url = `${imageUrl}&w=${dimensions}&h=${dimensions}`;

          return (
            <Row
              key={id}
              wrap={false}
              css={styles.songFeature}
              onClick={() => navigate(`../${slug}`)}
            >
              <Col>
                <picture>
                  <source
                    media="(min-width: 0px)"
                    width={dimensions}
                    height={dimensions}
                    srcSet={url}
                  />
                  <img
                    css={styles.featuredMediaImage}
                    src={url}
                    alt="background"
                  />
                </picture>
              </Col>
              <Col>
                <Typography margin={false} variant="bodyBold">
                  {title}
                </Typography>
                <Row>
                  {description && (
                    <Typography margin={false} variant="small">
                      {description}
                    </Typography>
                  )}
                </Row>
                {date && (
                  <Typography margin={false} variant="small">
                    {date}
                  </Typography>
                )}
              </Col>
            </Row>
          );
        }
      );
    }
  }, [featuredOn, expanded, isMobile, navigate]);

  //RENDER
  return (
    <Row {...rest}>
      <Col css={styles.heardOnTile}>
        <Typography css={styles.heardOnText} variant="h4">
          Heard On:
        </Typography>
        <Row css={styles.songFeatureContainer}>{songFeatures}</Row>
        <Row justify="center">
          {expandable && (
            <ShowMore expanded={expanded} setExpanded={setExpanded} />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default FeaturedOn;
