import React from "react";
import "styled-components/macro";
import { SearchBar } from "@app/components";



// COMPONENT
const HeaderSearchBar = () => {
  return (
    <SearchBar
      header={true}
    />
  );
};

export default HeaderSearchBar;
