import React from "react";
import { StyledListItem } from "../styles";
import PortableTextBlock from "./Block";

const PortableTextListItem = (props: any) => (
  <StyledListItem>
    <PortableTextBlock {...props} />
  </StyledListItem>
);

export default PortableTextListItem;
