import { PasswordErrorsObject } from "@app/utils/helpers/passwordValidator";
import React from "react";
import "styled-components/macro";
import { styles } from './styles';

interface IPasswordValidationProps {
    errors: PasswordErrorsObject[]
}

const PasswordValidation: React.FC<IPasswordValidationProps> = props => {
    const { errors } = props;

    return <ul css={styles.topMargin}>
        {errors.map((error, index) => 
            <li css={error.isValid ? styles.requirementCompleted : styles.requirementNotCompleted} key={index}>{error.error}</li>
        )}
    </ul>
}

export default PasswordValidation;