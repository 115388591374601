import { createGlobalTheme, Theme } from "../components/songtradr-components";

//COLORS
const backgroundDark = "#07131C";
const backgroundMedium = "#1A242C";
const mint = "#2C9095";
const lightMint = "#cfcfcf";
const darkMint = "#21696c";
const charcoal = "#111b22";
const lightGray = "#D9D9D9";
const saltWhite = "#f3f3f3";
const white = "#ffffff";
const gray = "#2E3941";

//THEME
const tunefindTheme: Theme = {
  modes: {
    dark: {
      "semantic-primary-text": saltWhite,
      "semantic-primary-text-hover": lightGray,
      "semantic-secondary-accent": mint,
      "semantic-secondary-bg-hover": lightMint,
      "semantic-gray-bg": gray,
      "semantic-gray-bg-weak": charcoal,
      "semantic-gray-bg-hover": backgroundDark,
      "semantic-background-base": backgroundDark,
      "semantic-background-middle": backgroundMedium,
      "semantic-background-top": white,
    },
    light: {},
  },
  common: {
    "component-primary-btn-bg": mint,
    "component-primary-btn-bg-hover": darkMint,
  },
};

const TunefindTheme = createGlobalTheme(tunefindTheme);

export default TunefindTheme;
