import { transition } from "../../styles/utils";
import styled, { css } from "styled-components";

export interface InputProps {
  hasError?: boolean;
  fullWidth?: boolean;
  label?: string;
}
const labelStyles = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: block;
  padding-bottom: 0.5rem;
`;

export const Label = styled.label(() => labelStyles);
export const LabelText = styled.span(() => labelStyles);

const inputMetaStyles = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`;

export const InputInfo = styled.div`
  ${inputMetaStyles}
  text-align: end;
  color: var(--semantic-text-weak);
`;
export const InputFeedback = styled.div`
  ${inputMetaStyles}
`;

const fullWidth = css`
  width: 100%;
`;

export const inputContainerStyle = css<InputProps>`
  padding: 1rem;
  background-color: var(--component-input-bg);
  border: 1px solid
    ${({ hasError }) =>
      hasError
        ? "var(--semantic-alert-red-accent)"
        : "var(--semantic-bdr-normal)"};
  box-sizing: border-box;
  border-radius: 4px;
  ${transition(["border-color", "box-shadow"], ["box-shadow"])}
  ${({ fullWidth: fw = true }) => fw && fullWidth}
`;

export const inputContainerHoverStyle = css<Pick<InputProps, "hasError">>`
  box-shadow: var(--inputs-hover-active);
  ${({ hasError }) =>
    !hasError && "border-color: var(--semantic-bdr-strong-hover)"};
`;

export const inputContainerDisabledStyle = css`
  cursor: not-allowed;
  background-color: var(--semantic-gray-bg-disabled);
  border-color: var(--semantic-bdr-normal);
`;

export const inputContainerFocusStyle = css<Pick<InputProps, "hasError">>`
  outline: none;
  box-shadow: var(--inputs-hover-active);
  ${({ hasError }) =>
    !hasError && "border-color: var(--semantic-bdr-strong-active)"};
`;

export const inputTextStyle = css`
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5;
  color: var(--semantic-text-normal);
`;

export const inputTextDisabledStyle = css`
  color: var(--semantic-text-disabled);
`;

export const inputPlaceholderStyle = css`
  color: var(--semantic-text-disabled);
  padding-left: 0.25rem;
`;

export const inputStyles = css<InputProps>`
  ${inputTextStyle}
  ${inputContainerStyle}

  &[disabled] {
    ${inputTextDisabledStyle}
    ${inputContainerDisabledStyle}
  }

  &:hover:not([disabled]) {
    ${inputContainerHoverStyle}
  }

  &:focus:not([disabled]) {
    ${inputContainerFocusStyle}
  }

  ::placeholder {
    ${inputPlaceholderStyle}
  }
`;

export const Input = styled.input.withConfig({
  shouldForwardProp: (p) => !["hasError", "fullWidth"].includes(p),
})<InputProps>(() => inputStyles);
