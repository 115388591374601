import React, { ComponentPropsWithoutRef } from "react";

const Fallback = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    {...props}
    xmlns={"http://www.w3.org/2000/svg"}
    viewBox={"0 0 1 1"}
    preserveAspectRatio={"none"}
  >
    <path d="M0 0h1v1H0" fill="var(--pb-background)" />
  </svg>
);

export default Fallback;
