import React from "react";
import { PortableText as PortableTextBase } from "@portabletext/react";
import components from "./components";

const PortableText = ({
  value,
  components: propsComponents,
  ...reduced
}: {
  value: any;
  components?: any;
}) => (
  <PortableTextBase
    {...reduced}
    value={value}
    components={
      propsComponents ? { ...components, ...propsComponents } : components
    }
  />
);

export default PortableText;
