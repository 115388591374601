import React from "react";
import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

interface IWaitSpinProps {
  className?: string;
  dark?: boolean;
  size?: SpinProps["size"];
}

const fontSizes = {
  default: "1.5rem",
  small: "1.25rem",
  large: "1.75rem",
};

const StyledSpinner = styled(Spin).withConfig({
  shouldForwardProp: (prop) => !["dark", "size"].includes(prop),
})<IWaitSpinProps>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ dark }) =>
    dark
      ? "var(--semantic-background-top)"
      : "var(--semantic-background-base)"};
  background-color: ${({ dark }) => dark && "var(--bg-loading)"};

  .anticon {
    font-size: ${({ size }) => fontSizes[size ?? "default"]};
  }
`;

const WaitSpin = ({ dark, size, className }: IWaitSpinProps) => {
  return (
    <StyledSpinner
      indicator={<LoadingOutlined spin />}
      className={className}
      dark={dark}
      size={size}
    />
  );
};

export default WaitSpin;
