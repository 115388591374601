import React, { useState, useEffect } from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import {
  Button,
  ButtonLoadingIcon,
  Typography,
} from "../songtradr-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledModal, styles } from "./styles";
import { useAddTombstoneRequest } from "@app/api";
import { useMediaTypeText } from "@app/utils";

const useAddTombstoneModal = () => {
  const { addTombstonePending, handleAddTombstoneClick } =
    useAddTombstoneRequest();

  const mediaText = useMediaTypeText();
  const [modalOpen, setModalOpen] = useState(false);
  const [titleId, setTitleId] = useState<string>();

  // HANDLE BODY SCROLLING BEHIND MODAL
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = modalOpen ? "hidden" : "auto";
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
  };

  // EXPORTED TOGGLE
  const toggleModal = (targetTitleId?: string) => {
    if (targetTitleId) {
      setTitleId(targetTitleId);
      setModalOpen(!modalOpen);
    } else closeModal();
  };

  //MODAL EXPORT
  const AddTombstoneModal = (
    <StyledModal
      confirmationModal={false}
      destroyOnClose={true}
      mask={true}
      open={modalOpen}
      closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
      onOk={closeModal}
      onCancel={closeModal}
      footer={false}
    >
      <Typography margin={false} css={styles.gutter} variant="h2">
        Are you sure?
      </Typography>
      <Typography css={styles.gutter} variant="body">
        Excluding songs from the official score, you didn't hear any music in
        this {mediaText}?
      </Typography>

      <Row gutter={[24, 0]} justify="space-between">
        <Col span={12}>
          <Button
            css={styles.yesButton}
            variant="important"
            onClick={() => handleAddTombstoneClick(titleId)}
          >
            {addTombstonePending && <ButtonLoadingIcon />}
            Yes, not a single note!
          </Button>
        </Col>
        <Col span={12}>
          <Button
            css={styles.noButton}
            variant="destructive"
            onClick={() => closeModal()}
          >
            No, wait. I did hear music...
          </Button>
        </Col>
      </Row>
    </StyledModal>
  );

  return { AddTombstoneModal, toggleAddTombstoneModal: toggleModal };
};

export default useAddTombstoneModal;
