/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { ISearchContext, ISearchState } from "./interfaces";

export const initialSearchState: ISearchState = {
  searchResult: [],
  totals: [],
  size: 10,
  loading: false,
};

const SearchContext = createContext<ISearchContext>({
  searchState: initialSearchState,
  setState: () => {},
  search: () => {},
});

export default SearchContext;
