import { eApiKey } from "@app/api";
import {
  IUserAchievement,
  IUserEngagementStat,
  IUserProfileResponse,
  IUserSocial,
} from "@app/api/types";
import Link from "@app/components/link";

import { IAccountPageSchema } from "@app/sanity-generated-data/types";
import {
  faFacebook,
  faImdb,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, useWindowDimensions } from "../../components/songtradr-components";
import { Col, Divider, Row } from "antd";
import React, { Fragment } from "react";
import "styled-components/macro";
import { MetricsBoxWrapper, StyledCircle, styles } from "./styles";

interface IMetricsBoxProps {
  schema: IAccountPageSchema["metrics"];
  data?: IUserProfileResponse["metrics"];
}

const MetricsBox = ({ schema, data }: IMetricsBoxProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const circleGenerator = (
    statType: string,
    circleDimensions: number,
    progressDecimal: number
  ) => {
    const strokeWidth = 10;
    const radius = circleDimensions / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - progressDecimal ?? 0);

    if (circleDimensions) {
      return (
        <div css={styles.circlesContainer}>
          {/* Background Circle */}
          <svg
            width={circleDimensions}
            height={circleDimensions}
            viewBox={`0 0 ${circleDimensions.toString()} ${circleDimensions.toString()}`}
            css={styles.circleBackgroundContainer}
          >
            <circle
              css={styles.circleBackground}
              width={circleDimensions}
              height={circleDimensions}
              cx={circleDimensions / 2}
              cy={circleDimensions / 2}
              r={radius}
            />
          </svg>

          {/* Progress Circle */}
          <svg
            width={circleDimensions}
            height={circleDimensions}
            viewBox={`0 0 ${circleDimensions.toString()} ${circleDimensions.toString()}`}
            css={styles.circleContainer}
          >
            <StyledCircle
              strokeWidth={strokeWidth}
              circumference={circumference}
              offset={offset}
              cx={circleDimensions / 2}
              cy={circleDimensions / 2}
              r={radius}
            />
          </svg>
          <div css={styles.circleStatsContainer}>
            <Typography margin={false} variant="h4">
              {statType}
            </Typography>
            <Typography margin={false} variant="h1">
              {isNaN(progressDecimal)
                ? progressDecimal
                : `${progressDecimal * 100}%`}
            </Typography>
          </div>
        </div>
      );
    }
  };

  const renderSection = (
    apiKey: IAccountPageSchema["metrics"][number]["apiKey"]
  ) => {
    if (!data) return null;

    switch (apiKey) {
      case eApiKey.USER_ENGAGEMENT_STATS:
        return data[apiKey]?.map(
          ({
            title,
            accuracy,
            correct,
            incorrect,
            pending,
          }: IUserEngagementStat) => {
            return (
              <Row css={styles.circlesAndStatsContainer} key={title}>
                {circleGenerator(title, isMobile ? 168 : 192, accuracy)}
                <Col css={styles.statsContainer}>
                  <Col>
                    <Row>
                      <Typography variant="bodyBold">{correct}</Typography>
                      <Typography variant="body"> &nbsp;correct</Typography>
                    </Row>
                    <Row>
                      <Typography variant="bodyBold">{incorrect}</Typography>
                      <Typography variant="body"> &nbsp;incorrect</Typography>
                    </Row>
                    <Row>
                      <Typography variant="bodyBold">{pending}</Typography>
                      <Typography variant="body"> &nbsp;pending</Typography>
                    </Row>
                  </Col>
                </Col>
              </Row>
            );
          }
        );

      case eApiKey.USER_ACHIEVEMENTS:
        return data[apiKey]?.map(
          ({ title, description, icon }: IUserAchievement) => {
            let i;
            switch (icon) {
              case "ANSWER":
                i =
                  "https://img-sys.songtradr.com/85d5c10732833554fc7b0f4ccfd90d1761874c968cf0d5eda041140d6da5c69c.svg";
                break;
              case "BIRTHDAY":
                i =
                  "https://img-sys.songtradr.com/54bc52048984685be51e88fde3d161c008c69c634d4fc892aa3e75c5a9af5aeb.svg";
                break;
              case "SONG":
                i =
                  "https://img-sys.songtradr.com/c7cb37b537097f40720f28cb3a5f9018c11c296a90658a8742e24ed124d1314e.svg";
                break;
              case "VOTE":
                i =
                  "https://img-sys.songtradr.com/76320680337286ccd22d7cc784433c07155038a08fc137b7566d953796794b36.svg";
                break;

              default:
                i =
                  "https://img-sys.songtradr.com/76320680337286ccd22d7cc784433c07155038a08fc137b7566d953796794b36.svg";
                break;
            }
            return (
              <Row css={styles.achievementRow} key={title}>
                <div css={styles.achievementIcon}>
                  <img src={i} alt={icon} />
                </div>
                <Col>
                  <Typography margin={false} variant="bodyBold">
                    {title}
                  </Typography>
                  <Typography variant="small">{description}</Typography>
                </Col>
              </Row>
            );
          }
        );

      case eApiKey.USER_SOCIALS:
        return data[apiKey]?.map(({ title, link, icon }: IUserSocial) => {
          let i;
          switch (icon) {
            case "FACEBOOK":
              i = faFacebook;
              break;
            case "TWITTER":
              i = faTwitter;
              break;
            case "IMDB":
              i = faImdb;
              break;
            case "WEBLINKS":
              i = faLink;
              break;
            default:
              i = faLink;
              break;
          }

          return (
            <Row css={styles.achievementRow} align="middle" key={title}>
              <div css={styles.achievementIcon}>
                <FontAwesomeIcon icon={i} />
              </div>
              <Col>
                <Link target={"_blank"} to={link}>
                  <Typography
                    css={styles.link}
                    margin={false}
                    variant="bodyBold"
                  >
                    {title}
                  </Typography>
                </Link>
              </Col>
            </Row>
          );
        });
      default:
        return null;
    }
  };

  return (
    <MetricsBoxWrapper>
      {schema.map(({ header, apiKey }, index) => {
        return (
          <Fragment key={apiKey}>
            <Typography css={styles.headerText} variant={header.variant}>
              {header.text}
            </Typography>
            {renderSection(apiKey)}
            {index !== schema.length - 1 && <Divider css={styles.divider} />}
          </Fragment>
        );
      })}
    </MetricsBoxWrapper>
  );
};

export default MetricsBox;
