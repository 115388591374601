import React, { useEffect, useState } from "react";
import { SettingsPageWrapper, UserSettingsPageWrapper, styles } from "./styles";
import "styled-components/macro";

import { WaitSpin } from "@app/components";
import { IUserSettings } from "@app/api/types";

import {fetchUserSettings, saveUserSettings} from "../../api/requests";

import { Col, Row, Switch, Divider } from "antd";
import {
  Button,
  Input,
  Label,
  LabelText,
  Typography,
} from "../../components/songtradr-components";
import UserRoute from "@app/routing/UserRoute";


const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState<IUserSettings>();

  const [optOutEmail, setOptOutEmail] = useState(false);
  const [optOutWeb, setOptOutWeb] = useState(false);
  const [optOutNewsletter, setOptOutNewsletter] = useState(false);
  const [error, setError] = useState("");

  const applyApiValues = (userSettings : IUserSettings) => {
    setOptOutEmail(!userSettings.optOutEmail);
    setOptOutWeb(!userSettings.optOutWeb);
    setOptOutNewsletter(!userSettings.optOutNewsletter);
  }

  const saveData = () => {
    const newSettings = {
      ...apiData,
      optOutEmail : !optOutEmail,
      optOutNewsletter: !optOutNewsletter,
      optOutWeb: !optOutWeb
    }

    saveUserSettings(newSettings as IUserSettings);
  }

  //LOAD DATA
  useEffect(() => {
    fetchUserSettings().then((userSettings: IUserSettings) => {
        setApiData(userSettings);
        applyApiValues(userSettings);
        setError("");
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setError("Unable to load your settings. Please refresh the page and contact support if the issue persists.");
      }).finally(() =>{
        setLoading(false);
      });
  }, []);

  return (
    <UserRoute>
    <SettingsPageWrapper>
      <UserSettingsPageWrapper>
        <div style={{maxWidth: "1024px"}}>
          <Row>
            <Col>
              <Typography variant="h1">Settings</Typography>
            </Col>
          </Row>

        <Row>
          <Col flex={1}>
            <Typography variant="h2"> {apiData?.username} </Typography>
          </Col>
        </Row>
        { loading && <Row>
          <Col>
            <WaitSpin dark/>
          </Col>
        </Row> }

        <Row>
            <Typography css={styles.sectionHeaderText} variant="h2"> Profile </Typography>
        </Row>

        {error && <Row>
            <Typography style={{color: "red"}} variant="body"> {error} </Typography>
        </Row>}

        <Row>
            <Col flex={1}>
              <Label>
                <LabelText>Username</LabelText>
                <Input value={apiData?.username || ""} readOnly/>
              </Label>
            </Col>
        </Row>
        <Row>
          <Col flex={1}>
              <Label>
                <LabelText>Email Address</LabelText>
                <Input value={apiData?.email || ""} readOnly/>
              </Label>
          </Col>
        </Row>

        <Divider/>

        <Row>
          <Col span={24} css={styles.sectionContainer}>
            <Typography css={styles.sectionHeaderText} variant="h2"> Notifications </Typography>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
              <Typography variant="body"> Shows / Movies I Follow via email </Typography>
          </Col>
          <Col>
            <Switch css={styles.switch} disabled={loading}
                checked={optOutEmail}
                onClick={() => setOptOutEmail(!optOutEmail)} />
            </Col>
        </Row>

        <Row>
          <Col flex={1}> <Typography variant="body">Shows / Movies I Follow via website </Typography> </Col>
          <Col>
            <Switch css={styles.switch} disabled={loading}
                checked={optOutWeb}
                onClick={() => setOptOutWeb(!optOutWeb)} />
          </Col>
        </Row>

        <Row>
            <Col flex={1}> <Typography variant="body">Newsletter via email</Typography> </Col>
            <Col >
              <Switch css={styles.switch} disabled={loading}
                  checked={optOutNewsletter}
                  onClick={() => setOptOutNewsletter(!optOutNewsletter)}/>
              </Col>
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <Button css={styles.button} size="normal" disabled={loading} variant="primary" onClick={saveData}> Save </Button>
          </Col>
        </Row>
      </div>
      </UserSettingsPageWrapper>
    </SettingsPageWrapper>
    </UserRoute>
  );
};

export default Settings;
