import { css } from "styled-components";

export const styles = {
    facebookButton: css `
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #4267b2;
        padding: 12px;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;
        border: 0;
        font-weight: 600;
    `,
  };
  
