import { css } from "styled-components";

export const styles = {
  showMoreButtonContainer: css`
    justify-content: center;
  `,

  showMoreButton: css`
    margin-top: 1.5rem;
    border: none;
    cursor: pointer;
    background: transparent;
    /* width: max-content; */
    width: 100%;
    :hover {
      color: var(--semantic-secondary-bg-hover);
      > * {
        color: var(--semantic-secondary-bg-hover);
      }
    }
  `,
};
