import { useContentMargins } from "@app/styles/helpers";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { maxMedium, minLarge, minMedium } from "../../components/songtradr-components";
import { useWindowDimensions } from "../../components/songtradr-components";
import { eMediaType } from "@app/api/types";

// GENERAL STYLES
export const MediaDetailsPageWrapper = styled.div`
  padding-top: 5rem
  `;

export const styles = {
  detailswidgetcontainer: css`
    display: none;

    @media only screen and (max-width: 600px) {
      display: contents;
    }
  `,
};

export const MediaDetailsPageContent = styled.div(() => {
  const { margin } = useContentMargins();
  return css`
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    background-color: var(--semantic-background-middle);

    @media ${maxMedium} {
      padding: 2rem ${margin};
      row-gap: 2rem;
    }

    @media ${minLarge} {
      padding: 4rem ${margin};
      row-gap: 4rem;
    }

    .no-margin {
      margin-bottom: 0;
    }
  `;
});

export const ContentExplorerWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media ${maxMedium} {
    row-gap: 1rem;
  }
  @media ${minLarge} {
    row-gap: 2rem;
  }
`;

export const StyledCardBorder = styled.div`
  padding: 1rem;
  border: 1px solid var(--semantic-gray-bg-medium);
  border-radius: 8px;
`;

// CONTENT EXPLORER STYLES
export const contentExplorerStyles = {
  selectDropdown: css`
    position: relative;
    background-color: var(--semantic-background-base) !important; 
    border-right: 0.5px solid var(--semantic-gray-bg-medium) !important;
    border-left: 0.5px solid var(--semantic-gray-bg-medium) !important;
    border-radius: 2px;
    margin: 0 auto;
    padding: 0px 24px 0px 8px;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

    & > select {
      color: white;
      border: none;
      background-color: var(--semantic-background-base) !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-overflow: ellipsis;
    }

    & > select:active, select:focus {
      outline: none;
      box-shadow: none;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid white;
    }

    & > select > option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
  `,

  contentExplorerHeader: css`
    margin-bottom: 2rem;
    @media ${maxMedium} {
      margin-bottom: 1rem;
    }
  `,
  contentExplorerNav: css`
    column-gap: 0.75rem;
    justify-content: start;
    @media ${maxMedium} {
      justify-content: center;
    }
  `,

  listeningWidgetcontainer: css`
    display: none;

    @media only screen and (max-width: 600px) {
      display: contents;
    }
  `,

  listeningWidget: css`
    border-radius: 20px;
  `,

  contentList: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,
  leftArrowButton: css`
    background: var(--semantic-background-base) !important;
    border-radius: 0.5rem 0 0 0.5rem;
    height: 3.5rem;
  `,
  rightArrowButton: css`
    background: var(--semantic-background-base) !important;
    border-radius: 0 0.5rem 0.5rem 0;
    height: 3.5rem;
  `,

  episodeJumpButton: css`
    display: block;
    width: 100%;
    margin-left: 5px;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
    }
  `,

  listeningContainer: css`
    display: contents;
  `,

  filterOne: (mediaType: eMediaType) => css`
    margin-left: 0rem;
    height: 3.5rem;

    ${mediaType === eMediaType.Show
      ? css`
          border: 1px solid vvar(--semantic-gray-bg-medium) !important;
          border-radius: 0.5rem !important;

          .ant-select {
            width: 100%;
          }

          .ant-select-selector {
            border: 0 !important;
            border-radius: 0.5rem !important;
            background: var(--semantic-background-base) !important;
            color: var(--semantic-background-top);
            height: 3.5rem !important;
            width: 100% !important;
            align-items: center;
          }

          .ant-select-arrow {
            color: var(--semantic-background-top) !important;
          }

          .ant-select:hover {
            border-radius: 0.5rem !important;
          }

          @media ${minLarge} {
            width: 148px;
          }
        `
      : css`
          > div {
            > div:nth-child(1) {
              margin-right: 0.5rem;
            }
            > div:nth-child(3) {
              margin-left: 2rem;
            }

            input {
              @media ${minLarge} {
                max-height: 56px;
                border-radius: 0.5rem;
              }
            }
          }
        `}
  `,
  filterTwo: css`
    display: flex;
    border-radius: 0.5rem;
    height: 3.5rem;
    margin: 24px 0px 24px 0px;
    > button {
      align-self: center;
      padding: 15px;
      cursor: pointer;
    }

    > div {
      overflow: hidden;
      width: -webkit-fill-available;
      border-radius: 0 !important;

      .ant-select-selector {
        background: var(--semantic-background-base) !important;
        height: 100% !important;
        color: white;
        align-items: center;
        border-top: 0 !important;
        border-right: 0.5px solid var(--semantic-gray-bg-medium) !important;
        border-bottom: 0 !important;
        border-left: 0.5px solid var(--semantic-gray-bg-medium) !important;
        border-radius: 0 !important;
      }

      .ant-select-arrow {
        color: var(--semantic-background-top);
      }
    }

    @media ${minLarge} {
      max-height: 56px;
      max-width: 327px;
      margin: 0;
    }
  `,
  jumpLatestButton: css`
    @media ${minLarge} {
      display: flex;

      max-height: 56px;

      /* margin-bottom: 1rem; */
    }
  `,
  hoverEffect: css`
    :hover {
      cursor: pointer;
      box-shadow: 0 0 10px 0 #00000029;
    }
    :focus {
      background: white;
    }
  `,
  twoLineEllipsis: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  `,

  badgecontainer: css`
    @media only screen and (max-width: 600px) {
      // border: 1px solid green;
    }
  `,

  verifiedIcon: css`
    > div:first-child {
      margin-right: 0.75rem;
      margin-top: 3px;
    }
    @media only screen and (max-width: 600px) {
      > div:first-child {
        margin-right: 0.75rem;
        margin-top: 8px;
      }
    }

    @media ${minMedium} {
      margin-top: 0.5rem;
    }

    @media only screen and (max-width: 600px) {
      img {
        width: 26px;
        height: 26px;
        margin-top: 8px;
      }
    }
  `,
};

// SOUNDTRACK STYLES
export const StyledSoundtrackWrapper = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background: var(--semantic-background-base);

  > *:not(:first-child):not(:last-child) {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--semantic-gray-bg-medium);
  }
`;

export const soundtrackStyles = {
  soundtrackImg: css`
    margin-bottom: 1rem;

    img {
      border-radius: 0.5rem;
    }

    @media ${minMedium} {
      margin-bottom: 2rem;
    }
  `,
  soundtrackText: css`
    margin-bottom: 2rem;
  `,
  showMoreBtn: css`
    margin-top: 1.5rem;
    border: none;
    cursor: pointer;
    background: transparent;
  `,
};

// MORE INFO STYLES
export const moreInfoStyles = {
  moreInfoHeader: css`
    margin-bottom: 2rem;
  `,
  moreInfoRow: css`
    padding-top: 1rem;

    &:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 0.5px solid var(--semantic-gray-bg-medium);
    }
  `,

  moreInfoCategory: css`
    @media ${maxMedium} {
      padding-right: 1rem;
    }
  `,
  themeSong: () => css`
    padding-bottom: 1rem;
    border-bottom: 0.5px solid var(--semantic-gray-bg-medium);
  `,
  link: css`
    cursor: pointer;
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,
};

// QUESTIONS STYLES
export const DetailedQuestionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const QuestionsSliderWrapper = styled.div(() => {
  const { width } = useWindowDimensions();
  const margin =
    width > 1440
      ? Math.min((width - 1440) / 2, Math.max(16, Math.min(width * 0.7, 96)))
      : Math.max((width - 1440) / 2, Math.max(16, Math.min(width * 0.07, 96)));

  return css`
    margin: 0 -${margin}px;
  `;
});

export const StyledQuestionCardBorder = styled.div(() => {
  return css`
    padding: 2rem;
    border: 1px solid var(--semantic-gray-bg-medium);
    border-radius: 0.5rem;
    width: 18.375rem;
    height: 12.125rem;
    margin-right: 1.5rem;
  `;
});

export const StyledEmptyStateQuestions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 2rem;
  padding: 4rem 2rem;
  border: 1px solid var(--semantic-gray-bg-medium);
  border-radius: 8px;
  justify-content: space-between;
  flex-wrap: false;

  @media ${maxMedium} {
    flex-direction: column;
    padding: 2rem 1rem;
    justify-content: center;
    flex-wrap: true;
    text-align: center;
  }
`;

export const questionsStyles = {
  questionCard: css`
    max-height: 200px;
    max-width: 294px;
  `,
  questionBody: css`
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  `,
  followAndShare: css`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  `,
  repliesContainer: css`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    min-width: 100%;
  `,
  replies: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-left: 4%;
  `,
  replyLine: css`
    border-left: 4px solid var(--semantic-gray-bg-disabled);
    min-width: 100%;
  `,
  askButton: css`
    float: right;
  `,
  fullWidthWrap: css`
    min-width: 100%;
    border-bottom: 0.5px solid var(--semantic-gray-bg-medium);
    padding-bottom: 1rem;
  `,
  offset: css`
    margin-left: 2%;
    min-width: 90%;
  `,
  whiteText: css`
    color: var(--semantic-background-top);
  `,
  mintText: css`
    color: var(--semantic-secondary-accent);
  `,

  author: css`
    margin-right: 1.5rem;
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,

  desktopFloatRight: css`
    @media ${minLarge} {
      justify-content: end;
    }
  `,
  repliesButton: css`
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,
  emptyQuestionsImg: css`
    @media ${minLarge} {
      margin-right: 3rem;
    }
  `,
  emptyQuestionsText: css`
    justify-content: start;
    margin-right: 1rem;
    @media ${maxMedium} {
      justify-content: center;
      margin-right: 0rem;
    }
  `,
  textArea: css`
    resize: none;
    background: var(--semantic-gray-bg);
  `,
  textBottomMargin: css`
    margin-bottom: 0.5rem;
  `,
  deleteQuestionIcon: css`
    cursor: pointer;
    margin-left: 0.5rem;
    display: inline;
  `,
  questionSongLink: css `
    text-decoration: underline;
    &:hover, &:active, &:visited, &:focus {
      text-decoration:underline;
  }
  `,
};

// STATS STYLES
export const statsStyles = {
  headerBotMargin: css`
    margin-bottom: 2rem;
  `,
  stats: css`
    > div > div {
      height: 100%;
    }
  `,
  emptyStateContainer: css`
    text-align: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 3rem;
    right: 3rem;
  `,
  emptyStateContent: css`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  `,
  statsCardMinHeight: css`
    min-height: 15rem;
  `,
  topSongItem: css`
    margin-bottom: 2rem;
    @media ${maxMedium} {
      margin-bottom: 1rem;
    }
  `,
  link: css`
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,
};

// TOMBSTONE STYLES
export const TombstoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 2rem;
  padding: 4rem 2rem;
  border: 1px solid var(--semantic-gray-bg-medium);
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;

  @media ${maxMedium} {
    flex-direction: column;
    padding: 2rem 1rem;
    justify-content: center;
    text-align: center;
  }
`;

export const tombstoneStyles = {
  icon: css`
    color: var(--component-primary-btn-bg);
    height: 4.5rem;
    @media ${minLarge} {
      margin-right: 3rem;
    }
  `,

  text: css`
    justify-content: start;
    margin-right: 1rem;
    @media ${maxMedium} {
      justify-content: center;
      margin-right: 0rem;
    }
    :first-child {
      @media ${maxMedium} {
        margin-bottom: 1rem;
      }
    }
  `,

  buttonCol: css`
    display: flex;
    > button {
      margin-left: auto;
    }
  `,
};
