import React, { useEffect, useRef } from "react";
import pluralize from "pluralize";
import { IHeroSchema } from "@app/sanity-generated-data/types";
import { Hero } from "@app/components";
import { IArtistDetailsResponse } from "@app/api/types";
import { displayAndSwapHeroImageUrl } from "@app/utils/helpers/displayAndSwapHeroImageUrl";

interface IArtistPageHeroProps {
  schema: IHeroSchema | null;
  apiData: IArtistDetailsResponse;
  apiLoading: boolean;
  computedImgUrl: string;
}

const  ArtistPageHero = (props : IArtistPageHeroProps) => {
  const schema = props.schema;
  const { computedImgUrl } = props;
  const { name, songCount, appearanceCount } = props.apiData ?? {};
  const preloadedImageRef = useRef(document.getElementById('preload-wrapper'));

  useEffect(() => {
      if (preloadedImageRef.current) {
        displayAndSwapHeroImageUrl(preloadedImageRef.current, computedImgUrl)
      }
  }, [computedImgUrl, preloadedImageRef])

  if (!schema) {
    return <Hero loading/>
  }

  return (
    <Hero
    shouldPreloadImage={!computedImgUrl}
    loading={false}
    heroSchema={{
      ...schema,
      header: { 
        ...schema.header,
        text: name ? name + " Sync Placements"  : "",
      },
      description: {
        ...schema.description,
        text: songCount ? `${pluralize("Song", songCount, true)} | ${pluralize(
          "Appearance",
          appearanceCount,
          true
        )}` : "",
      },
      cta: { ...schema.cta },
    }}
  />
  );
};

export default ArtistPageHero;
