import React from "react";
import "styled-components/macro";
import { Row } from "antd";
import { Stores, IStoreLink } from "@app/api/types";
import { styles } from "./styles";
import StoreLink from "./StoreLink";

const StoreLinks = ({
  links,
  type,
  songId,
  artistName
}: {
  links?: IStoreLink[];
  type?: "all" | "excludeSongtradr" | "onlySongtradr";
  songId?: number,
  artistName?: string
}) => {
  const stores =
    type === undefined || type === "all"
      ? Stores
      : type === "excludeSongtradr"
      ? Stores.filter((m) => !m.startsWith("songtradr"))
      : Stores.filter((m) => m.startsWith("songtradr"));

  return (
    <Row css={styles.container} wrap={false}>
      <>
        {links &&
          stores.map((store) => {
            const { url } = links.find((link) => link?.store === store) ?? {};
            return <StoreLink key={`${artistName ?? songId}-${store}`} store={store} url={url ?? ""} />;
          })}
      </>
    </Row>
  );
};

export default StoreLinks;
