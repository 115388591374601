import React, { Suspense } from "react";
import { Col, Row } from "antd";
import HubspotForm from "react-hubspot-form";
import { Typography } from "../songtradr-components";
import { WaitSpin } from "@app/components";
import { IHubSpotFormSchema } from "@app/sanity-generated-data/types";
import { HubspotFormWrapper } from "./styles";
const AdsWrapper = React.lazy(() => import("../advertisement/AdsWrapper"));

const HubSpotForm = ({ schema, isMobile, userLoggedIn }: { schema: IHubSpotFormSchema, isMobile: boolean, userLoggedIn: boolean }) => {
  const { portalId, formId, header, description, rightColAds } = schema;

  return (
    <Row>
      <Col flex={1}>
        <HubspotFormWrapper>
          <Typography variant={header?.variant ?? "h2"}>
            {header?.text}
          </Typography>
          <Typography variant={description?.variant ?? "h4"}>
            {description?.text}
          </Typography>
          <HubspotForm
            portalId={portalId}
            formId={formId}
            loading={<WaitSpin dark />}
          />
        </HubspotFormWrapper>
      </Col>
      {rightColAds && (
        <Col>
          <Suspense>
            <AdsWrapper ads={rightColAds} isMobile={isMobile} userLoggedIn={userLoggedIn}/>
          </Suspense>
        </Col>
      )}
    </Row>
  );
};

export default HubSpotForm;
