export enum ErrorCodes {
    USER_NOT_CONFIRMED = 'UserNotConfirmedException',
    PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
    NOT_AUTHORIZED = 'NotAuthorizedException',
    USER_EXISTS = 'UsernameExistsException',
    TOO_MANY_REQUESTS = 'TooManyRequestsException',
    INVALID_PASSWORD = 'InvalidPasswordException',
    USER_NOT_FOUND_PASSWORD_RESET = 'not_found',
    USER_NOT_FOUND = 'UserNotFoundException',
    INVALID_PARAMETER = 'InvalidParameterException',
    LIMIT_EXCEEDED = 'LimitExceededException',
    USER_EXISTS_PRE_SIGNUP = 'UserLambdaValidationException',
    WRONG_VERIFICATION_CODE = 'CodeMismatchException',
    FACEBOOK_UNKNOWN_ACCOUNT = 'Unknown Account',
    DEFAULT = "Default",
}