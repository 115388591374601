import { Row } from "antd";
import styled, { css } from "styled-components";
import { maxSmall } from "../songtradr-components";

export const StyledProgressBar = styled(Row)(() => {
  return css`
    .progress-segment {
      width: 64px;
      height: 8px;
      background-color: var(--disabled-bg);
      border-radius: 8px;
      margin-right: 8px;
    }
    .filled {
      background-color: #359d9e;
    }

    display: flex;
    flex-wrap: nowrap;

    @media ${maxSmall} {
     margin-bottom: 10px;
    }
  `;
});
