import { CognitoUser } from "amazon-cognito-identity-js";

export interface IUserContext {
  userState: IUserState;
  setState: (newState: IUserState) => void;
  exchangeGrant: (payload: IExchangeGrantPayload) => void;
  fetchAndSetUserContextTokens: (code: string) => void;
  signOut: () => void;
  checkLoadUserAccount: () => void;
  nudge: (action: any) => void;
}

export enum eUserType {
  User = 0,
  Admin = 1,
}

export interface IUserState {
  authLoading: boolean;
  authenticated: boolean;
  userType: eUserType;
  userToken: string;
  userName: string;
  isFacebook: boolean;
  userAttr?: any;
  userRef?: CognitoUser;
  isPartOfFeaturePreview?: boolean;
}

export interface ITokenResponse {
  access_token: string;
  refresh_token: string;
  user_state: IUserState;
}

export interface IExchangeGrantPayload {
  access_token: string;
  refresh_token: string;
  exp_token: number;
  id_token: string;

}