import React, { useState } from "react";
import "styled-components/macro";
import { useWindowDimensions, Typography } from "../songtradr-components";
import { IContentSliderSchema } from "@app/sanity-generated-data/types";
import { IMedia } from "@app/api/types";

import SuspenseImage from "@app/components/suspense-image";
import Link from "@app/components/link";
import { styles } from "./styles";
import { isNullOrEmptyString } from "@app/utils";

interface IMediaImageProps {
  schema: IContentSliderSchema;
  data: IMedia;
  height: number;
  width: number;
}

export const MediaImage = ({
  schema: { title: titleSchema, description: descSchema },
  data: { slug, title, description, imageUrl },
  height,
  width,
}: IMediaImageProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const [linkDisabled, setLinkDisabled] = useState(false);

  const handleDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setLinkDisabled(true);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setLinkDisabled(false);
      window.removeEventListener("mouseup", handleMouseUp);
    }, 0);
  };

  return (
    <div css={styles.slide} draggable={true} onDragStart={handleDrag}>
      <Link to={`../${slug}`} disabled={linkDisabled}>
        {!isMobile && <div css={styles.imageGradient} />}
        <SuspenseImage
          css={styles.slideImage}
          src={imageUrl}
          width={width}
          height={height}
          alt={title}
        />
        <div css={styles.textContainer}>
          <Typography
            variant={titleSchema.variant}
            margin={false}
            truncate={isMobile}
          >
            {title}
          </Typography>
          {!isNullOrEmptyString(description) && (
            <Typography variant={descSchema.variant} truncate>
              {description}
            </Typography>
          )}
        </div>
      </Link>
    </div>
  );
};

export default MediaImage;
