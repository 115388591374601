import { ISong, IAddNewSong } from "@app/api/types";

const getSongFromSearchResults = (unreleasedSong: IAddNewSong, results: ISong[]): ISong | null => {
  const foundResult = results.find((searchResult) => {
    if (unreleasedSong.name.toLowerCase().trim() === searchResult.name.toLowerCase().trim() && unreleasedSong.artists.length === searchResult.artists.length) {
      return unreleasedSong.artists.every((artist, index) => {
        return artist.name.toLowerCase().trim() === searchResult.artists[index].name.toLowerCase().trim();
      });
    }
    return false;
  });

  return foundResult || null;
};

export default getSongFromSearchResults;
