import { focusVisible, transition } from "../../styles/utils";
import styled, { css } from "styled-components";

/* <a> */
export const commonAnchorStyles = css`
  --outline-offset: 1px;
  border-radius: 1px;
  cursor: pointer;
  ${focusVisible}
  ${transition(["color"])}

  text-decoration: none;

  &[disabled] {
    cursor: not-allowed;
    color: var(--semantic-text-disabled);
  }

  /* reset button and input styles */
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
`;
export const anchorStyles = css`
  color: var(--semantic-primary-text);

  &:hover:not([disabled]) {
    color: var(--semantic-primary-text-hover);
  }
  ${commonAnchorStyles}
`;
export const subtleAnchorStyles = css`
  font-weight: var(--font-weight-bold);
  color: var(--semantic-text-normal);
  &:hover:not([disabled]) {
    text-decoration: underline;
  }

  ${commonAnchorStyles}
`;
export const textAnchorStyle = css`
  color: var(--semantic-text-normal);
  &:hover:not([disabled]) {
    text-decoration: underline;
  }

  ${commonAnchorStyles}
`;
export const A = styled.a.withConfig({
  shouldForwardProp: (p) => !["variant"].includes(p),
})<{ variant?: "default" | "subtle" | "text" }>(({ variant = "default" }) =>
  variant === "text"
    ? textAnchorStyle
    : variant === "subtle"
    ? subtleAnchorStyles
    : anchorStyles
);
