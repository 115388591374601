import { maxMedium } from "../songtradr-components";
import { css } from "styled-components";

export const styles = {
  wrapper: (disabled?: boolean) => css`
    opacity: ${disabled ? 0.15 : 1};
  `,

  a: css`
    border-radius: 0.4rem;

    :hover {
      background: #07131c;
    }

    :focus-visible:not([disabled], [data-disabled]) {
      outline: 2px solid var(--semantic-bdr-strongest);
      outline-offset: var(--outline-offset, 2px);
    }
  `,

  button: css`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    height: 2.25rem;
    width: 2.25rem;

    img {
      height: 1.5rem;
      width: 1.5rem;

      @media ${maxMedium} {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  `,

  container: css`
    justify-content: normal;
    column-gap: 8px;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      margin-right: 8px;
    }
  `,

  fixAppleIcon: css`
    margin-top: -0.25rem;
  `,
};
