export interface IAdTargets {
    pgtype?: string,
    user?: string,
    "media-id"?: string;
    "artist-id"?: string;
}

export const getAdTargets = (pathname?: string, userLoggedIn?: boolean, artistId?: string | undefined, mediaId?: string | undefined) =>  {
    const adTargets: IAdTargets = {};
    let page = pathname?.split('/')?.[1];
    page = page?.replace(/s$/, ''); // if page ends in a s (games, ads, shows) we want to remove the s and the result should be game, ad, show...

    if (artistId !== undefined) {
        adTargets["artist-id"] = artistId;
    }
    if (mediaId !== undefined) {
        adTargets["media-id"] = mediaId;
    }
    if (page !== undefined) {
        adTargets.pgtype = page === "" ? "landing" : page;
    }
    if (userLoggedIn) {
        adTargets.user = "logged_in";
    }

    return adTargets;
}