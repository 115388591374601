import React from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import { Button } from "../../components/songtradr-components";
import { ISocialLinks } from "@app/api/types";
import { styles } from "@app/components/store-links/styles";

const icons: Record<string, string> = {
  instagram:
    "https://img-sys.songtradr.com/982cb048457ce780a4567829f29f21e74f877e4e761c9bb9596bb37cccd1ab97.svg",
  facebook:
    "https://img-sys.songtradr.com/d4ea7183d3681b50326b935e2f391de77a1d1dc257370b68bd508c7ad1cf63e7.svg",
  twitter:
    "https://img-sys.songtradr.com/1246198918ad0431ca9012db6ba557f455878770a65975fbf5f2a98c4c157a24.svg",
  wikipedia:
    "https://img-sys.songtradr.com/e07f9621b4869c30f7cebe435367a121bbc062a55dba8eb5fd6042f372bd6920.svg",
  official:
    "https://img-sys.songtradr.com/5434facbaa7b530dbd13c877566e3bdd69ae7829e0c65969731508bdc035c274.svg",
};

const SocialLinks = ({ links }: { links: ISocialLinks }) => (
  <Row wrap={false} gutter={[8, 0]}>
    {Object.entries(links).map(([key, value]) => (
      <Col key={key} span={6} css={styles.wrapper(!value)}>
        <Button
          css={styles.button}
          variant="subtle"
          disabled={value === "undefined"}
          onClick={() => window.open(value, "_blank")}
        >
          <img alt={`external ${key} link`} src={icons[key]} />
        </Button>
      </Col>
    ))}
  </Row>
);

export default SocialLinks;
