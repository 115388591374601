const extraHeaders: any = {};

if (process.env.REACT_APP_DISABLE_ENCODING) {
  extraHeaders['disable-enc'] = process.env.REACT_APP_DISABLE_ENCODING;
} 

if (process.env.REACT_APP_NO_API_CACHE) {
  extraHeaders['no-api-cache'] = process.env.REACT_APP_NO_API_CACHE;
}
export const Settings = {
  APIBaseURL: process.env.REACT_APP_API_HOST ?? 'https://api-v2.tunefind.com',
  extraHeaders: extraHeaders,
};
