interface Resource<Payload> {
  read: () => Payload;
}

type Status = "pending" | "success" | "error";

function createResource<Payload>(
  asyncFn: () => Promise<Payload>
): Resource<Payload> {
  let status: Status = "pending";
  let result: any;

  const promise = asyncFn().then(
    (r: Payload) => {
      status = "success";
      result = r;
    },
    (e: Error) => {
      status = "error";
      result = e;
    }
  );

  return {
    read(): Payload {
      switch (status) {
        case "pending":
          throw promise;
        case "error":
          throw result;
        case "success":
          return result;
      }
    },
  };
}

const cache = new Map<string, any>();

export function loadImage(source: string): Resource<string> {
  let resource = cache.get(source);

  if (resource) return resource;

  resource = createResource<string>(
    () =>
      new Promise((resolve) => {
        const img = new Image();
        img.src = source;
        img.onload = () => resolve(source);
        img.onerror = () => resolve(source);
      })
  );

  cache.set(source, resource);

  return resource;
}


const IMGIX_HOST = 'https://img-www.tf-cdn.com/';
export function createImageSrcSet(imageUrl: string) {
  if (!imageUrl.startsWith(IMGIX_HOST)) {
    return { src: imageUrl };
  }
  const HARDCODED_DPR = '&dpr=2';
  const dprIdx = imageUrl.indexOf(HARDCODED_DPR);
  if (dprIdx < 0) {
    return { src: imageUrl };
  }

  const prefix = imageUrl.substring(0, dprIdx);
  const postFix = imageUrl.substring(dprIdx + HARDCODED_DPR.length); 
  const src = `${prefix}&dpr=1${postFix}`;
  
  const srcset = [
    `${src} 1x`,
    `${prefix}&dpr=1${postFix} 2x`
  ].join(',');
  
  return {src, srcset};
}