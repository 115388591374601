import { IUserProfileResponse } from "@app/api/types";
import { Hero, WaitSpin } from "@app/components";
import Link from "@app/components/link";
import { useSanityData } from "@app/sanity-generated-data";
import { ISupervisorProfilePageSchema } from "@app/sanity-generated-data/types";
import { Typography, useWindowDimensions } from "../../components/songtradr-components";
import { Col, Row } from "antd";
import React, { useMemo } from "react";
import MetricsBox from "./MetricsBox";
import {
  ProfilePageWrapper,
  StyledCardBorder,
  styles,
  UserProfilePageContent,
  UserProfilePageWrapper,
} from "./styles";

export const MusicSupervisorProfilePage = ({
  sanityId,
  apiData,
}: {
  sanityId: string;
  apiData: IUserProfileResponse;
}) => {
  const { header, metrics, hero } = useSanityData<ISupervisorProfilePageSchema>(
    "pages",
    sanityId
  );
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const memoizedHero = useMemo(() => {
    if (apiData?.user) {
      const { name, profileImage, slug } = apiData.user;
      const _hero = hero?.[0];

      if (!_hero) {
        return <Hero loading />
      }

      return (
        <Hero
          shouldPreloadImage={true}
          loading={false}
          heroSchema={{
            ..._hero,
            header: {
              ..._hero.header,
              text: name,
            },
            subHeader: {
              ..._hero.subHeader,
              _key: "",
              _type: "typography",
              variant: "body",
              text: "Company",
            },
            description: {
              ..._hero.description,
              text: `${apiData?.supervisorCredits?.length} Credits`,
            },
            backgroundImg: {
              src: profileImage ?? "",
              alt: slug,
            },
            cta: { ..._hero.cta },
          }}
        />
      );
    }
  }, [apiData, hero]);

  const mappedCredits = useMemo(() => {
    const mobileCredits = (credit: any) => (
      <Link to={credit.slug}>
        <StyledCardBorder as={Col} span={24}>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <picture>
                <source
                  media="(min-width: 0px)"
                  width={80}
                  height={80}
                  srcSet={`${credit.imageUrl}&w=80&h=80`}
                />
                <img
                  style={{ borderRadius: "0.5rem" }}
                  css={styles.featuredMediaImage}
                  src={credit.imageUrl}
                  alt={credit.title}
                />
              </picture>
            </Col>
            <Col>
              <Row justify="space-between">
                <Typography variant="h4">{credit.title}</Typography>
              </Row>
              <Row>
                <Typography variant="body">{credit.seasons}</Typography>
              </Row>
              <Row>
                <Typography variant="h4">{credit.releaseDate}</Typography>
              </Row>
            </Col>
          </Row>
        </StyledCardBorder>
      </Link>
    );

    const desktopCredits = (credit: any) => (
      <Link to={credit.slug}>
        <StyledCardBorder as={Col} span={22}>
          <Row align="middle">
            <Col>
              <picture>
                <source
                  media="(min-width: 0px)"
                  width={96}
                  height={96}
                  srcSet={`${credit.imageUrl}&w=96&h=96`}
                />
                <img
                  style={{ borderRadius: "0.5rem" }}
                  css={styles.featuredMediaImage}
                  src={credit.imageUrl}
                  alt="background"
                />
              </picture>
            </Col>
            <Col offset={1} span={20}>
              <Row justify="space-between">
                <Col>
                  <Typography variant="h4">{credit.title}</Typography>
                </Col>
                <Col>
                  <Typography variant="h4">{credit.releaseDate}</Typography>
                </Col>
              </Row>
              <Row>
                <Typography variant="body">{credit.seasons}</Typography>
              </Row>
            </Col>
          </Row>
        </StyledCardBorder>
      </Link>
    );

    return apiData?.supervisorCredits?.map((credit: any) =>
      isMobile ? mobileCredits(credit) : desktopCredits(credit)
    );
  }, [apiData?.supervisorCredits, isMobile]);

  return apiData ? (
    <ProfilePageWrapper>
      {memoizedHero}
      <UserProfilePageWrapper>
        {header && <Typography variant={header.variant}>{header.text}</Typography>}
        <Typography variant="body">
          {" "}
          {apiData?.user.name} is a professional Music Supervisor and is
          responsible for selecting and licensing the music used in the
          following television shows/movies:
        </Typography>
        <UserProfilePageContent as={Row} wrap={false}>
          <Col flex={1} css={styles.leftSuperCol}>
            {header && <Typography variant={header.variant}>Credits</Typography>}
            {mappedCredits}
          </Col>
          <Col css={styles.rightCol}>
            {metrics && <MetricsBox schema={metrics} data={apiData?.metrics} /> }
          </Col>
        </UserProfilePageContent>
      </UserProfilePageWrapper>
    </ProfilePageWrapper>
  ) : (
    <WaitSpin dark />
  );
};

export default MusicSupervisorProfilePage;
