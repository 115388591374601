export enum ErrorMessages {
    INVALID_CREDENTIALS = 'Sorry seems like your Username/Email or password was incorrect, try again!',
    INVALID_USER ='Sorry a user with that username or email does not exist.',
    DUPLICATE_USER = 'Sorry a user with that username or email already exists.',
    USER_NOT_CONFIRMED = 'Sorry this user needs to be confirmed before signing in.',
    TOO_MANY_REQUESTS = 'You have sent too many failed attempts, please wait before trying again.',
    USER_EXISTS_SIGN_UP = 'Sorry a user with that username or email already exists. Please sign in.',
    USER_NEEDS_PASSWORD_RESET = 'Please check you email for your temporary login credentials.',
    USERNAME_CANNOT_BE_OF_EMAIL_FORMAT = 'Sorry, your username cannot be an email.',
    USERNAME_HAS_SPACES = 'Please make sure your username does not include any spaces',
    DEFAULT = 'Something went wrong. If this issue persists please contact support.',
}