import styled, { css } from "styled-components";
import { maxMedium } from "../../components/songtradr-components";
import { useContentMargins } from "@app/styles/helpers";

// STYLED COMPONENTS
export const SearchPageWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100%;
  padding: 5rem 0 0 0;
`;

export const BandintownPlayerContainer = styled.div`
  // background-color: green;
  padding: 10px;

  @media ${maxMedium} {
    padding: 10px;
    display: contents;
  }
`;

export const BandintownPlayer = styled.div`
  border-radius: 20px;
`;

export const MissingResultsWrapper = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    flex-direction: column;
    border: 1px solid var(--semantic-gray-bg-medium);
    border-radius: 0.5rem;
    padding: 6rem;
    text-align: center;
    margin: 0rem ${margin};

    @media ${maxMedium} {
      padding: 2rem;
    }
  `;
});
