import { useContentMargins } from "@app/styles/helpers";
import { maxMedium, minLarge } from "../songtradr-components";
import styled, { css } from "styled-components";

export const ScheduleWrapper = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    display: flex;
    flex-direction: column;

    @media ${minLarge} {
      margin: 0rem ${margin} 0 ${margin};
    }
  `;
});

export const MobileCarousel = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    .slick-track {
      display: flex;
    }

    .slick-disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    .slick-next {
      right: ${margin};
      top: -1.5rem;
      color: var(--cta-text);
      font-size: 20px;

      &:focus {
        color: var(--cta-text);
      }
      &:hover {
        color: var(--cta-interact-text);
      }
    }

    .slick-prev {
      left: calc((100vw - ${margin}) - ${margin} - 4rem);
      top: -1.5rem;
      color: var(--cta-text);
      font-size: 20px !important;
      z-index: 2;

      &:focus {
        color: var(--cta-text);
      }
      &:hover {
        color: var(--cta-interact-text);
      }
    }
  `;
});

export const styles = {
  loading: css`
    height: 11rem;
  `,

  dayBox: css`
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 1rem;
    background: var(--semantic-gray-bg-weak);

    @media ${maxMedium} {
      width: 14.375rem;
    }

    @media ${minLarge} {
      background: var(--semantic-gray-bg-weak);
    }
  `,

  episodeBox: (clickable: boolean) => {
    return css`
      background: var(--semantic-gray-bg);
      padding: 1rem;
      border-radius: 8px;
      cursor: ${clickable ? "pointer" : "default"};

      @media ${maxMedium} {
        width: 14.375rem;
      }
    `;
  },

  mediaTileEpisodeBox: css`
    background: var(--semantic-gray-bg-strong);
    padding: 1rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;

    @media ${maxMedium} {
      width: 14.375rem;
    }

    > * {
      color: var(--semantic-text-weakest);
    }
  `,

  mediaTileImage: css`
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    max-width: 100%;
  `,

  dayCol: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    @media ${minLarge} {
      min-width: 0rem;
    }

    @media ${maxMedium} {
      margin-right: 1.5rem;
      /* width: 262px; */
    }
  `,

  dayColContainer: css`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    overflow-x: hidden;
    /* z-index: -1; */
  `,

  downArrow: css`
    font-size: 1rem;
  `,

  showMoreButtonFading: css`
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      var(--bg) 100%,
      var(--bg) 100%
    );
    pointer-events: none;
    border: none;
    margin-top: -7rem;
    z-index: 0;
    padding-top: 7rem;
  `,

  slider: css`
    display: flex;
    position: relative;
    transition: transform 0.5s ease-in-out;
  `,

  sliderContainer: css`
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    white-space: nowrap;
    position: relative;
  `,

  RightArrow: (reachedSliderEnd: boolean) => {
    const { margin } = useContentMargins();

    return css`
      position: absolute;
      right: ${margin};
      opacity: ${reachedSliderEnd ? 0.25 : 1};
      color: var(--cta-text);
      font-size: 20px;
      pointer-events: ${reachedSliderEnd && "none"};
    `;
  },

  LeftArrow: (mediaImageIndex: number) => {
    const { margin } = useContentMargins();

    return css`
      position: absolute;
      left: calc((100vw) - ${margin} - 4rem);
      opacity: ${mediaImageIndex === 0 ? 0.25 : 1};
      color: var(--cta-text);
      font-size: 20px;
      pointer-events: ${mediaImageIndex === 0 && "none"};
    `;
  },

  mediaTileContainer: css`
    cursor: pointer;

    @media ${maxMedium} {
      width: 14.375rem;
    }
  `,
};
