import { useUserProfileData } from "@app/api";
import { WaitSpin } from "@app/components";
import React from "react";
import MusicSupervisorProfilePage from "./MusicSupervisorProfilePage";
import UserProfilePage from "./UserProfilePage";

export const UserProfilePageWrapper = () => {
  const [apiLoading, apiData] = useUserProfileData();

  if (!apiLoading) {
    return apiData.user.isSuper ? (
      <MusicSupervisorProfilePage
        sanityId={"pages.supervisorProfile"}
        apiData={apiData}
      />
    ) : (
      <UserProfilePage sanityId={"pages.profile"} apiData={apiData} />
    );
  } else {
    return (
      <div style={{ paddingTop: 100 }}>
        <WaitSpin dark />
      </div>
    );
  }
};

export default UserProfilePageWrapper;
