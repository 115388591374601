import { maxSmall, minMedium } from "../../utils/windowDimensions";
import styled, { css } from "styled-components";

const noBottomMargin = css`
  margin-bottom: 0;
`;

export const sectionHeaderStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => !margin && noBottomMargin}
  font-size: var(--font-size-14);
  line-height: var(--line-heights-20);
  letter-spacing: var(--letter-spacing-spaced);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
`;
export const SectionHeader = styled.h4.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => sectionHeaderStyles);

const headlineMargin = css`
  margin: 0 0 var(--spacing-medium);
`;

export const commonHeadingStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => (!margin ? noBottomMargin : headlineMargin)}
  font-weight: var(--font-weight-light);
  color: var(--semantic-text-normal);
`;

const mainHeadingOnSmallScreenStyles = css`
  font-size: var(--font-size-32);
  line-height: var(--line-heights-40);
`;

export const display1Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}
  @media ${minMedium} {
    font-size: max(var(--font-size-32), min(6.67vw, var(--font-size-80)));
    line-height: max(
      var(--line-heights-40),
      min(6.67vw, var(--line-heights-80))
    );
  }
`;
export const Display1 = styled.h1.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => display1Styles);

export const display2Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}
  @media ${minMedium} {
    font-size: max(var(--font-size-32), min(5.335vw, var(--font-size-64)));
    line-height: max(
      var(--line-heights-40),
      min(5.335vw, var(--line-heights-64))
    );
  }
`;
export const Display2 = styled.h2.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => display2Styles);

/* <h1> */
export const h1Styles = css`
  ${commonHeadingStyles}
  ${mainHeadingOnSmallScreenStyles}
  @media ${minMedium} {
    font-size: max(var(--font-size-32), min(4vw, var(--font-size-48)));
    line-height: max(var(--line-heights-40), min(4vw, var(--line-heights-48)));
  }
`;
export const H1 = styled.h1.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h1Styles);

/* <h2> */
export const h2Styles = css`
  ${commonHeadingStyles}
  font-size: var(--font-size-24);
  line-height: var(--line-heights-32);
  @media ${minMedium} {
    font-size: max(var(--font-size-24), min(2.667vw, var(--font-size-32)));
    line-height: max(
      var(--line-heights-32),
      min(3.334vw, var(--line-heights-40))
    );
  }
`;
export const H2 = styled.h2.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h2Styles);

/* <h3> */
export const h3Styles = css`
  ${commonHeadingStyles}
  font-size: var(--font-size-20);
  line-height: var(--line-heights-28);
  @media ${maxSmall} {
    font-weight: var(--font-weight-regular);
  }
  @media ${minMedium} {
    font-size: max(var(--font-size-20), min(2.667vw, var(--font-size-24)));
    line-height: max(
      var(--line-heights-28),
      min(3.334vw, var(--line-heights-32))
    );
  }
`;
export const H3 = styled.h3.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h3Styles);

const smallHeadingOnSmallScreen = css`
  font-size: var(--font-size-18);
  line-height: var(--line-heights-26);
`;

/* <h4> */
export const h4Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: var(--font-weight-regular);

  @media ${minMedium} {
    font-size: max(var(--font-size-18), min(1.667vw, var(--font-size-20)));
    line-height: max(
      var(--line-heights-26),
      min(2.334vw, var(--line-heights-28))
    );
  }
`;
export const H4 = styled.h4.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h4Styles);

/* <h5> */
export const h5Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: var(--font-weight-bold);
`;
export const H5 = styled.h5.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h5Styles);

/* <h6> */
export const h6Styles = css`
  ${commonHeadingStyles}
  ${smallHeadingOnSmallScreen}

  font-weight: var(--font-weight-regular);
`;
export const H6 = styled.h6.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => h6Styles);
