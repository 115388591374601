import { css } from "styled-components/macro";
import { maxSmall, minLarge, maxMedium } from "../songtradr-components";
import styled from "styled-components";
import { useContentMargins } from "@app/styles/helpers";

const heights = css`
  @media ${minLarge} {
    height: 20vw;
    max-height: 480px;
    min-height: 400px;
  }
  @media ${maxMedium} {
    height: 50vw;
    max-height: 480px;
  }
  @media ${maxSmall} {
    height: calc(70vw / 0.96);
  }
`;

// STYLED COMPONENTS
export const HeroSliderWrapper = styled.div`
  background-color: var(--bg-loading);
  ${heights}
`;

export const TextContainer = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    position: absolute;
    bottom: 6%;
    left: ${margin};
    max-width: 75%;
  `;
});

// CSS PROP STYLES
export const styles = {
  slideStyle: (pointer: boolean) => css`
    position: relative;
    ${pointer ? "cursor: pointer;" : ""};
    ${heights};
  `,

  imageStyle: css`
    width: 100%;
    height: 100%;
  `,

  carousel: css`
    .slick-dots-bottom {
      > li {
        height: 16px;
        background: var(--semantic-gray-bg-strong);
        border-radius: 100%;
        :hover {
          background: var(--component-primary-btn-bg-hover);
        }
        > button {
          opacity: 0;
          height: 100%;
          :focus {
            opacity: 0;
          }
          :hover {
            opacity: 0;
          }
        }
      }
    }
    .slick-dots {
      z-index: 0;
      li.slick-active {
        width: 1rem;
        background: var(--component-primary-btn-bg);
      }
    }
    li.slick-active {
      button {
        display: none;
      }
    }

    .slick-next {
      right: 25px;
      color: var(--semantic-gray-bg-strong) !important;
      font-size: 30px;
    }

    .slick-next:hover {
      right: 25px;
      color: var(--component-primary-btn-bg-hover) !important;
      font-size: 30px;
    }

    .slick-prev {
      left: 10px;
      color: var(--semantic-gray-bg-strong) !important;
      font-size: 30px !important;
      z-index: 1;
    }

    .slick-prev:hover {
      right: 25px;
      color: var(--component-primary-btn-bg-hover) !important;
      font-size: 30px;
    }

    @media screen and (max-width: 959px) {
      .slick-next,
      .slick-next:hover,
      .slick-prev,
      .slick-prev:hover,
      .slick-dots {
        visibility: hidden;
      }
    }
  `,

  slideGradient: css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(26, 36, 44, 0.7) 23.96%,
      rgba(26, 36, 44, 0.2) 50%,
      rgba(26, 36, 44, 0) 100%
    );
  `,

  featured: css`
    color: var(--semantic-secondary-accent);

    @media only screen and (max-width: 600px) {
      font-size: 19px;
    }
  `,

  titlesoundtrack: css`
    @media only screen and (max-width: 600px) {
      font-size: 26px;
    }
  `,
};
