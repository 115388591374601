import { forwardRef, ComponentPropsWithoutRef } from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "./Icon";
import React from "react";

const pulse = keyframes`
  0%, 41.66% {
    opacity: 1;
  }

  58.33% {
    opacity: 0.8;
  }

  66.66% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
`;

const PulsingPath = styled.path.withConfig({
  shouldForwardProp: (prop) => !["delay"].includes(prop),
})<{ delay: number; paused?: boolean }>`
  animation: 1.2s ${pulse} linear infinite;
  animation-play-state: ${({ paused }) => (paused ? "paused" : "running")};
  animation-delay: ${({ delay }) => `-${delay}ms`};
`;

export const LoadingIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<"svg"> & { paused?: boolean }
>((props, ref) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    role="img"
    aria-label="Loading"
    {...props}
  >
    <PulsingPath
      delay={0}
      d="M12.745 0h-1.46a.39.39 0 0 0-.39.39v2.14c0 .215.175.39.39.39h1.46a.39.39 0 0 0 .39-.39V.39a.39.39 0 0 0-.39-.39Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={100}
      d="m6.648 1.241-1.265.73a.39.39 0 0 0-.142.533l1.07 1.853a.388.388 0 0 0 .532.143l1.265-.73a.391.391 0 0 0 .143-.533l-1.07-1.853a.39.39 0 0 0-.533-.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={200}
      d="m1.989 5.36-.73 1.264a.39.39 0 0 0 .143.533l1.853 1.07a.39.39 0 0 0 .533-.143l.73-1.264a.39.39 0 0 0-.143-.533l-1.853-1.07a.39.39 0 0 0-.533.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={300}
      d="M2.545 10.88H.405a.39.39 0 0 0-.39.39v1.46c0 .215.175.39.39.39h2.14a.39.39 0 0 0 .39-.39v-1.46a.39.39 0 0 0-.39-.39Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={400}
      d="m3.249 15.766-1.853 1.07a.392.392 0 0 0-.143.533l.73 1.265a.39.39 0 0 0 .533.142l1.853-1.07a.388.388 0 0 0 .143-.532l-.73-1.265a.391.391 0 0 0-.533-.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={500}
      d="m6.304 19.643-1.07 1.853a.39.39 0 0 0 .143.533l1.264.73a.391.391 0 0 0 .533-.143l1.07-1.853a.39.39 0 0 0-.143-.533l-1.264-.73a.39.39 0 0 0-.533.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={600}
      d="M12.745 21.08h-1.46a.39.39 0 0 0-.39.39v2.14c0 .215.175.39.39.39h1.46a.39.39 0 0 0 .39-.39v-2.14a.39.39 0 0 0-.39-.39Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={700}
      d="m17.16 19.517-1.265.73a.39.39 0 0 0-.142.533l1.07 1.853a.39.39 0 0 0 .532.143l1.265-.73a.391.391 0 0 0 .143-.533l-1.07-1.853a.39.39 0 0 0-.533-.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={800}
      d="m20.204 15.931-.73 1.264a.39.39 0 0 0 .143.533l1.853 1.07a.39.39 0 0 0 .533-.143l.73-1.264a.39.39 0 0 0-.143-.533l-1.853-1.07a.391.391 0 0 0-.533.143Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={900}
      d="M23.595 10.9h-2.14a.39.39 0 0 0-.39.39v1.46c0 .215.175.39.39.39h2.14a.39.39 0 0 0 .39-.39v-1.46a.39.39 0 0 0-.39-.39Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={1000}
      d="m21.477 5.241-1.853 1.07a.388.388 0 0 0-.143.532l.73 1.265c.108.186.346.25.533.143l1.853-1.07a.39.39 0 0 0 .143-.533l-.73-1.265a.39.39 0 0 0-.533-.142Z"
      fill="currentColor"
    />
    <PulsingPath
      delay={1100}
      d="m16.823 1.401-1.07 1.853a.39.39 0 0 0 .143.533l1.264.73a.39.39 0 0 0 .533-.143l1.07-1.853a.39.39 0 0 0-.143-.533l-1.264-.73a.39.39 0 0 0-.533.143Z"
      fill="currentColor"
    />
  </Icon>
));

LoadingIcon.displayName = "LoadingIcon";
