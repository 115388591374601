import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import "styled-components/macro";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { ISearchBarSchema } from "@app/sanity-generated-data/types";
import { SuspenseImage, WaitSpin } from "@app/components";

import { HeaderSearchStyles, PreviewContainer, styles } from "./styles";
import { Link } from "react-router-dom";
import { useSanityPageRoute } from "@app/sanity-generated-data";
import { Typography } from "../songtradr-components";

import SearchContext from "@app/searchContext";
import { isNullOrEmptyString } from "@app/utils";

// TYPES
interface SearchPreviewProps {
  query: string;
  hasFocus: boolean;
  setHasFocus: React.Dispatch<React.SetStateAction<boolean>>;
  schema?: Omit<ISearchBarSchema, "header">;
  header?: boolean;
}

// COMPONENTS
const Loading = () => (
  <div css={styles.resultsLoading}>
    <WaitSpin dark />
  </div>
);

const SearchPreviewDropdown = ({
  query,
  hasFocus,
  setHasFocus,
  // schema: { categories, titles, fullSearchLink },
  schema,
  header,
  ...rest
}: SearchPreviewProps) => {
  const navigate = useNavigate();
  const searchPageRoute = useSanityPageRoute("pages.search");
  const { searchState } = useContext(SearchContext);
  const { categories, titles, fullSearchLink } = schema ?? {};

  /** @todo need to add loading functionality to the search page results - right now it's not being set */
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const maxPerCategory = 3;

  // DYNAMIC OPEN & CLOSE
  useEffect(() => {
    setTimeout(() => {
      if (header) {
        setOpen(hasFocus);
      } else {
        setOpen(hasFocus && query.length > 1);
      }
    }, 300);
  }, [hasFocus, setOpen, query, header]);

  const mediaCategories = ["show", "movie", "game", "artist", "ad"] as const;
  type MediaCategory = typeof mediaCategories[number];

  const url = (url: string, type: string) => {
    const result = url.split("/")[1];
    const number = type === "artist" ? "/10/" : "/2/";
    return `https://img-www.tf-cdn.com/${type}${number}${result}.jpeg?_&auto=compress&fm=pjpg&fit=crop&crop=faces%2Centropy%20312&dpr=2`;
  };

  const categoryLister = (category: string) => {
    switch (category) {
      case "show":
        return "Television";
      case "movie":
        return "Movies";
      case "game":
        return "Games";
      case "artist":
        return "Artists";
      case "ad":
        return "Ads";
    }
  };

  //EMPTY STATE FUNCTIONALITY
  const mapSearchResult = (category: string) => {
    return searchState.searchResult.filter((entry) => entry.type === category);
  };

  const emptyState = () => {
    return isNullOrEmptyString(query) ? (
      <></>
    ) : (
      <Row
        gutter={[0, 16]}
        justify="center"
        align="middle"
        css={styles.emptyState}
      >
        <img
          css={styles.noResultsIcon}
          src="/images/errorIcon.svg"
          alt="No Search Results"
        />
        <Typography
          css={styles.textAlign}
          margin={false}
          inverted={!header}
          variant="h3"
        >
          {navigator?.onLine ? 'No matches were found for that search' : 'Please check your internet connection and try again'}
        </Typography>
      </Row>
    );
  };

  const resultsSize = () => {
    let results = 0;
    mediaCategories.forEach((category) => {
      results += mapSearchResult(category).length;
    });
    return results;
  };

  const getMaxPerCategory = () => {
    const totalCategories = Array.from(
      new Set(searchState.searchResult.filter((m) => m.type).map((m) => m.type))
    ).length;

    if (totalCategories < 3) return maxPerCategory * 3;
    else return maxPerCategory;
  };

  //RENDER RESULTS
  const mappedCategories = useMemo(() => {
    return (
      <Row>
        <>
          {mediaCategories.map((category: MediaCategory) => {
            return (
              mapSearchResult(category).length > 0 && (
                <>
                  <Col
                    key={category}
                    css={
                      header
                        ? HeaderSearchStyles.resultsList
                        : styles.resultsList
                    }
                  >
                    <Typography
                      inverted={!header}
                      variant={categories?.variant ?? "body"}
                      css={styles.categorystyle}
                    >
                      {categoryLister(category)}
                      {mapSearchResult(category).length >
                        getMaxPerCategory() && (
                        <>
                          &nbsp;(
                          {searchState.totals.find((m) => m.type === category)
                            ?.total ?? 0}
                          )
                        </>
                      )}
                    </Typography>
                    {mapSearchResult(category)
                      .slice(0, getMaxPerCategory())
                      .map((entry) => (
                        <div
                          // wrap={false}
                          css={styles.resultsLabel}
                          onClick={() => {
                            setHasFocus(false);
                            navigate(`../${entry.url}`);
                          }}
                          key={entry.title as string}
                        >
                          <SuspenseImage
                            src={url(entry.url ?? "", category)}
                            alt={"Image Url"}
                            width={40}
                            height={40}
                            fallbackImg="https://img-sys.songtradr.com/797d115e10f947d5d7158acccb70ed3e13c964f797849d0a1120b51e69886256.svg"
                          />
                          <div>
                            <Typography
                              css={styles.link}
                              inverted={!header}
                              margin={false}
                              variant={titles?.variant ?? "body"}
                            >
                              {`${entry.title}`}
                            </Typography>
                            <Typography
                              css={styles.link2}
                              inverted={!header}
                              margin={false}
                              variant={titles?.variant ?? "body"}
                            >
                              {`${entry.songcount} song${
                                entry.songcount === 1 ? "" : "s"
                              }`}
                            </Typography>
                          </div>
                        </div>
                      ))}
                  </Col>
                </>
              )
            );
          })}
        </>
      </Row>
    );
    // eslint-disable-next-line
  }, [categories?.variant, mediaCategories, navigate, titles?.variant]);

  //RENDER
  return isNullOrEmptyString(query) ? (
    <></>
  ) : (
    <AnimateHeight duration={open ? 250 : 250} height={open ? "auto" : 0}>
      <div css={styles.searchspacing}>
        <PreviewContainer header={header ?? false} {...rest}>
          {/* <Divider /> */}
          {searchState.loading ? (
            <Loading />
          ) : (
            <Suspense fallback={<Loading />}>
              {resultsSize() === 0 ? emptyState() : mappedCategories}
            </Suspense>
          )}
          {!searchPageRoute.matches && resultsSize() > 0 && (
            <Row justify={"end"}>
              <Typography
                css={styles.fullSearchLink}
                variant={fullSearchLink?.variant ?? "body"}
                inverted={!header}
              >
                <Link to={`${searchPageRoute.route}?q=${query}`}>
                  {fullSearchLink?.text} <RightOutlined />
                </Link>
              </Typography>
            </Row>
          )}
        </PreviewContainer>
      </div>
    </AnimateHeight>
  );
};

export default SearchPreviewDropdown;
