import { useCallback, useEffect, useRef, useState } from "react";
import { notification } from "antd";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { VoteTargetType } from "@app/components/buttons/useVoteButton";
import { useMediaDetailsPageActions } from "@app/pages/media-details/store";
import { useUserContext } from "@app/userContext";
import { pleaseWaitModal } from "@app/utils";
import { Api } from "./setup";
import {
  eCorrectness,
  IApiDashboard,
  IArtistCredits,
  IArtistDetailsResponse,
  ISong,
  ITabExplorerResponse,
  IUserProfileResponse,
  IUserSettings,
  MediaOverviewResponse,
} from "./types";
import { checkIfShouldDisplayAds } from "../utils/helpers/shouldDisplayAds";

export const infoToast = (message: string) => {
  notification.info({message, duration: 1});
}
// #region *** ERROR HANDLING ***

const errorToast = (message: string, devMessage?: string) => {
  if (process.env.NODE_ENV === "development" && devMessage) {
    notification.error({ message: `${message} ${devMessage}` });
    // eslint-disable-next-line no-console
    console.error(devMessage);
  } else {
    notification.error({ message });
  }
};

const onGeneralPostError = (e: any) =>
  errorToast(
    "Uh oh! Something went wrong! Please refresh and try again.",
    `Error: ${e.message ?? e}`
  );

const onGeneralFetchError = (err: any, navigate?: NavigateFunction) => {
  if (err?.response?.status === 404) {
    navigate && navigate("/not-found");
  }else{
    // eslint-disable-next-line no-console
    console.error(
      "Unable to fetch API data!",
      JSON.stringify(err),
      err.message,
      err.config
    );
  }
};

const useAsyncError = () => {
  const [, setError] = useState();

  return useCallback(
    (e : any) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
}

// #endregion

// #region *** FETCHES ***

// MEDIA OVERVIEW PAGE
export const useMediaOverviewPageData = (
  pageId: string,
  hero: any
): [boolean, MediaOverviewResponse] => {
  const loading = useRef<boolean>(true);
  const [response, setResponse] = useState<MediaOverviewResponse>(
    {} as MediaOverviewResponse
  );
  const navigate = useNavigate();
  const throwError = useAsyncError();

  // FETCH DATA ON KEYS CHANGE
  useEffect(() => {
    if (pageId) {
      loading.current = true;
      Api.request({
        method: "GET",
        url: `/media/data?pageId=${pageId}`,
      })
        .then(({ data }: { data: MediaOverviewResponse }) => {
          if(checkIfShouldDisplayAds()) {
            try {
                (window as any).googletag?.destroySlots();
              } catch (error) {
                //NOTHING
              }
          }
          setResponse(data);
          const pageData = data[`${hero?.apiKey}_${hero?._key}`];
          const imageFromApi = pageData?.[0].imageUrl;
          if (imageFromApi) {
            const formattedPageId = pageId.replace('drafts.', '');
            if (!(window as any).heroPreloads) {
              (window as any).heroPreloads = {};
            }
            if (!(window as any).heroPreloads?.[formattedPageId]) {
              (window as any).heroPreloads = {
                  ...(window as any).heroPreloads,
                  [formattedPageId]: { src: '' }
              };
          }
            (window as any).heroPreloads[formattedPageId].src = imageFromApi
          }
        })
        .catch((e) =>{
          onGeneralFetchError(e, navigate)
          throwError(e);
        })
        .finally(() =>{
          loading.current = false;
        });
    }

    return () => {
      loading.current = true;
      setResponse({} as MediaOverviewResponse);
    };
    // eslint-disable-next-line
  }, [pageId, loading]);
  return [loading.current, response];
};

// MEDIA DETAILS PAGE
export const useMediaDetailsFetch = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const throwError = useAsyncError();

  const abortController = useRef<AbortController>();

  const fetchMediaDetails = async () => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    const { signal } = abortController.current;

    return await Api.request({
      method: "GET",
      url: `/media-details?slug=${pathname}`,
      signal,
    }).catch((e) => {
      if (e.name !== "CanceledError") {
        onGeneralFetchError(e, navigate);
        throwError(e);
        return e;
      }
    });
  };

  return { fetchMediaDetails };
};

// USER PROFILE PAGE
export const useUserProfileData = (): [boolean, IUserProfileResponse] => {
  const loading = useRef<boolean>(true);
  const [response, setResponse] = useState<IUserProfileResponse>(
    {} as IUserProfileResponse
  );
  const navigate = useNavigate();
  const { username } = useParams();
  const throwError = useAsyncError();

  // FETCH DATA ON KEYS CHANGE
  useEffect(() => {
    if (username) {
      loading.current = true;
      Api.request({
        method: "GET",
        url: `account/profile?username=${username}`,
      })
        .then(({ data }: { data: IUserProfileResponse }) => {
          setResponse(data);
        })
        .catch((e) => {
          onGeneralFetchError(e, navigate);
          throwError(e);
        })
        .finally(() => (loading.current = false));
    }
    return () => {
      loading.current = true;
      setResponse({} as IUserProfileResponse);
    };
    // eslint-disable-next-line
  }, [username, loading]);

  return [loading.current, response];
};

// ARTIST DETAILS PAGE
export const useArtistDetails = (): [boolean, boolean, IArtistDetailsResponse, IArtistCredits] => {
  const loading = useRef<boolean>(true);
  const loadingCredits = useRef<boolean>(true);
  const [data, setData] = useState<IArtistDetailsResponse>();
  const [artistCredits, setArtistCredits] = useState<IArtistCredits>();
  const navigate = useNavigate();
  const { artistId } = useParams();
  const throwError = useAsyncError();

  // FETCH DATA ON MEDIAID CHANGE
  useEffect(() => {
    if (artistId) {
      loading.current = true;

      Api.request({
        method: "GET",
        url: `/artist?slug=${artistId}&excludeFields=credits`,
      })
        .then(({ data }: { data: IArtistDetailsResponse }) => setData(data))
        .catch((e) => {
          onGeneralFetchError(e, navigate);
          throwError(e);
        })
        .finally(() => {
          loading.current = false;
          fetchCredits(artistId);
        });
    }

    return () => {
      loading.current = true;
      setData({} as IArtistDetailsResponse);
      setArtistCredits({} as IArtistCredits);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistId]);

  const fetchCredits = (artistId: string) => {
    loadingCredits.current = true;
    Api.request({
      method: "GET",
      url: `/artist?slug=${artistId}&excludeFields=id,name,bio,imageUrl,songCount,appearanceCount,socialLinks,storeLinks`,
    })
      .then(({ data }: { data: IArtistCredits }) => {
        loadingCredits.current = false;
        setArtistCredits(data)
      })
      .catch((e) => {
        loadingCredits.current = false;
        onGeneralFetchError(e, navigate);
        throwError(e);
      })

  }
  return [loading.current, loadingCredits.current, data as IArtistDetailsResponse, artistCredits as IArtistCredits];
};


// TRENDING SONGS PAGE
export const useTrendingSongs = (): [boolean, any] => {
  const loading = useRef<boolean>(true);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const { artistId } = useParams();
  const throwError = useAsyncError();

  // FETCH DATA ON MEDIAID CHANGE
  useEffect(() => {
    loading.current = true;
    Api.request({
      method: "GET",
      url: `/media/trending?pageId=pages.trending`,
      timeout: 180000, // 3min as this is a very taxing api call
    })
      .then(({ data }: { data: ITabExplorerResponse }) => setData(data))
      .catch((e) => {
        onGeneralFetchError(e, navigate);
        throwError(e);
      })
      .finally(() => {
        loading.current = false;
      });

    return () => {
      loading.current = true;
      setData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistId]);

  return [loading.current, data as ISong[]];
};

// SEARCH RESULTS
export const useFetchSearchResults = () => {
  const [pending, setPending] = useState<boolean>(false);
  const throwError = useAsyncError();

  const searchResultsGet = (query: string, limiter: number) => {
    setPending(true);
    return Api.request({
      method: "GET",
      url: `/musicsearch/songs`,
      params: {l: limiter, q: query}
    })
      .then(({ data }: { data: ISong[] }) => data)
      .catch((e) => {
        onGeneralFetchError(e);
        throwError(e);
      })
      .finally(() => setPending(false));
  };

  return { pendingSearch: pending, searchResultsGet };
};

// #endregion

// #region *** POSTS ***

// CREATE NEW APPEARANCE
export const useAppearanceAdd = () => {
  const [pending, setPending] = useState(false);
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();
  const throwError = useAsyncError();

  const addSongRequest = (
    titleId: number,
    data: any,
    sceneDescription: string,
    onFinish: Function
  ) => {
    return nudge(() => {
      const waiter = pleaseWaitModal();
      setPending(true);
      return Api.request({
          method: "POST",
          url: `/song/add-song`,
          params: {
            titleId: titleId,
            ...(sceneDescription.length > 0 ? { sceneDescription: sceneDescription } : {})
          },
          data: data,
        })
        .then(({ data }) => {
          waiter.destroy();
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          waiter.destroy();
          refresh();
          onGeneralPostError(e);
          throwError(e);
        })
        .finally(() => {
          setPending(false);
          onFinish();
        });
    });
  };

  return { pendingAddSong: pending, addSongRequest };
};

// FOLLOW TITLE
export const useFollowTitle = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();

  const postFollowRequest = (
    titleId: string,
    unfollow = false,
    callback?: Function
  ) => {
    return nudge(() => {
      return Api.request({
        method: "POST",
        url: `/media-details/follow-title?titleId=${titleId}&unfollow=${unfollow}`,
      })
        .then((res) => (callback ? callback(res) : res))
        .catch((e) => onGeneralPostError(e))
        .finally(() => setPending(false));
    });
  };

  return { pendingFollow: pending, postFollowRequest };
};

// FOLLOW QUESTION
export const useFollowQuestion = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();

  const followQuestionRequest = (threadId: number, callback?: Function) => {
    return nudge(() => {
      setPending(true);
      return Api.request({
        method: "POST",
        url: `/question/follow-thread?threadId=${threadId}`,
      })
        .then((res) => (callback ? callback(res) : res))
        .catch((e) => onGeneralPostError(e))
        .finally(() => setPending(false));
    });
  };

  return { pendingFollow: pending, followQuestionRequest };
};

// CREATE QUESTION OR REPLY
export const usePostQuestion = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const postQuestionRequest = (
    endpoint: "question" | "question-reply",
    data: any
  ) => {
    return nudge(() => {
      const waiter = pleaseWaitModal();
      setPending(true);
      return Api.request({
        method: "POST",
        url: `/question/${endpoint}`,
        data: data,
      })
        .then(({ data }) => {
          waiter.destroy();
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          waiter.destroy();
          refresh();
          onGeneralPostError(e);
        })
        .finally(() => {
          setPending(false);
        });
    });
  };

  return { pendingUpdate: pending, postQuestionRequest };
};

// LOCK TITLE
export const useLockTable = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();

  const lockTableRequest = (
    titleId: number,
    unlock: boolean,
    callback?: Function
  ) => {
    return nudge(() => {
      Api.request({
        method: "POST",
        url: `/media-details/lock-table?titleId=${titleId}&unlock=${unlock}`,
      })
        .then((res) => (callback ? callback(res) : res))
        .catch((e) => onGeneralPostError(e))
        .finally(() => setPending(false));
    });
  };

  return { pendingUpdate: pending, lockTableRequest };
};

// REORDER APPEARANCES
export const useReorderTable = () => {
  const [pending, setPending] = useState<eCorrectness | boolean>();
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const reorderTableRequest = (data: any) => {
    return nudge(() => {
      return Api.request({
        method: "POST",
        url: "/media-details/reorder-table",
        data: data,
      })
        .then(({ data }) => {
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          refresh();
          onGeneralPostError(e);
        })
        .finally(() => {
          setPending(false);
        });
    });
  };

  return { pendingUpdate: pending, reorderTableRequest };
};

// ADD/REMOVE SONG FAVORITES
interface IFavoriteRequest {
  appearanceId?: number;
  unfavorite?: boolean;
}

export function useSongFavoriting() {
  const [pending, setPending] = useState<number | boolean>();
  const { nudge } = useUserContext();

  const toggleSongFavorite = (song: ISong, callback?: Function) => {
    if (song) {
      setPending(song.id ?? true);

      const data: IFavoriteRequest = {
        appearanceId: song.appearanceId,
        unfavorite: song.likedByUser,
      };

      return nudge(() => {
        return Api.request({
          method: "POST",
          url: `/song/favorite`,
          data,
        })
          .then((res) => {
            callback && callback(res);
          })
          .catch((e) => onGeneralPostError(e))
          .finally(() => setPending(false));
      });
    }
  };

  return { pendingSongFavorite: pending, toggleSongFavorite };
}

// SONG APPEARANCE VOTING
interface IVoteRequest {
  targetId: number;
  voteValue: eCorrectness;
}

export function useVoteRequest() {
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const [pending, setPending] = useState<eCorrectness | boolean>();

  const postVoteRequest = (
    targetType: VoteTargetType,
    targetId: number,
    voteValue: eCorrectness,
    onFinish: Function
  ) => {
    if (targetId && voteValue) {
      setPending(voteValue ?? true);

      const data: IVoteRequest = {
        targetId,
        voteValue,
      };
      return nudge(() => {
        return Api.request({
          method: "POST",
          url: `/vote/${targetType}`,
          data,
        })
          .then(({ data }) => {
            if (data && typeof data === "object") {
              updateHero({
                airDate: data.airDate,
                id: data.id,
                mediaType: data.mediaType,
                name: data.name,
                songCount: data.songCount,
                isFollowing: data.isFollowing,
                supervisors: []
              })
              updateDetails(data);
            } else {
              refresh();
            }
          })
          .catch((e) => {
            refresh();
            onGeneralPostError(e);
          })
          .finally(() => {
            setPending(false);
            onFinish();
          });
      });
    }
  };

  return { pendingVote: pending, postVoteRequest };
}

// ADD TOMBSTONE
export function useAddTombstoneRequest() {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const handleAddTombstoneClick = (titleId?: string) => {
    if (titleId) {
      setPending(true);
      return nudge(() => {
        return Api.request({
          method: "POST",
          url: `/media-details/add-tombstone?titleId=${titleId}`,
        })
          .then(({ data }) => {
            if (data && typeof data === "object") {
              updateHero({
                airDate: data.airDate,
                id: data.id,
                mediaType: data.mediaType,
                name: data.name,
                songCount: data.songCount,
                isFollowing: data.isFollowing,
                supervisors: []
              })
              updateDetails(data);
            } else {
              refresh();
            }
          })
          .catch((e) => {
            refresh();
            onGeneralPostError(e);
          })
          .finally(() => {
            setPending(false);
          });
      });
    }
  };

  return { addTombstonePending: pending, handleAddTombstoneClick };
}

// #endregion

// #region *** DELETES ***

// DELETE QUESTION OR REPLY
export type IDeleteEndpoints = "question-delete" | "question-reply-delete";
export const useDeleteQuestion = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const deleteQuestionRequest = (
    endpoint: IDeleteEndpoints,
    threadId: number
  ) => {
    return nudge(() => {
      const waiter = pleaseWaitModal();
      return Api.request({
        method: "PUT",
        url: `/question/${endpoint}?threadId=${threadId}`,
      })
        .then(({ data }) => {
          waiter.destroy();
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          waiter.destroy();
          refresh();
          onGeneralPostError(e);
        })
        .finally(() => {
          setPending(false);
        });
    });
  };

  return { pendingUpdate: pending, deleteQuestionRequest };
};

// DELETE APPEARANCE
export const useAppearanceDelete = () => {
  const [pending, setPending] = useState<boolean>(false);
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const deleteSongRequest = (appearanceId: number) => {
    return nudge(() => {
      const waiter = pleaseWaitModal();
      setPending(true);
      return Api.request({
        method: "DELETE",
        url: `/song/delete-song?appearanceId=${appearanceId}`,
      })
        .then(({ data }) => {
          waiter.destroy();
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          waiter.destroy();
          refresh();
          onGeneralPostError(e);
        })
        .finally(() => {
          setPending(false);
          // onFinish();
        });
    });
  };

  return { pendingDeleteSong: pending, deleteSongRequest };
};

// #endregion

// #region *** UPDATES ***

// UPDATE APPEARANCE DESCRIPTION
export const useUpdateDescription = () => {
  const [pending, setPending] = useState<boolean>();
  const { nudge } = useUserContext();
  const { refresh, updateDetails, updateHero } = useMediaDetailsPageActions();

  const updateDescriptionRequest = (data: any) => {
    return nudge(() => {
      const waiter = pleaseWaitModal();
      setPending(true);
      return Api.request({
        method: "POST",
        url: `/media-details/scene-description`,
        data: data,
      })
        .then(({ data }) => {
          waiter.destroy();
          if (data && typeof data === "object") {
            updateHero({
              airDate: data.airDate,
              id: data.id,
              mediaType: data.mediaType,
              name: data.name,
              songCount: data.songCount,
              isFollowing: data.isFollowing,
              supervisors: []
            })
            updateDetails(data);
          } else {
            refresh();
          }
        })
        .catch((e) => {
          waiter.destroy();
          refresh();
          onGeneralPostError(e);
        })
        .finally(() => {
          setPending(false);
        });
    });
  };

  return { pendingSceneDescUpdate: pending, updateDescriptionRequest };
};

// #endregion

// #region *** PASSWORD RESET ***

// SEND PASSWORD RESET REQUEST
  export const forgotPassword = async (usernameOrEmail: string) => {
    const encodedUsername = encodeURIComponent(usernameOrEmail);
    return Api.request({
        method: "POST",
        url: `/user/forget-password?userNameOrEmail=${encodedUsername}`,
      });
  };
// #endregion

// #region *** HERO PRELOADS ***

// GET HERO IMAGES FOR LANDING PAGES
export const fetchLandingPreloadMedia = async () => {
  return Api.request({
      method: "GET",
      url: `/preload/hero-media`,
    });
};

// #endregion

export const fetchUserSettings = () => {
  return Api.request({
    method: "GET",
    url: "/user/settings",
  })
  .then(response => response.data as IUserSettings)
}

export const saveUserSettings = (newUserSettings: IUserSettings) => {
  const waiter = pleaseWaitModal();
  return Api.request({
    method: "POST",
    url: "/user/settings/save",
    data: newUserSettings
  })
  .catch((e) => {
    onGeneralPostError(e);
  })
  .finally(() => {
    waiter.destroy();
  })
}

// #region *** API DASHBOARD ***

// Fetch dashboard
export const fetchApiDashboard = () => {
  return Api.request({
    method: "GET",
    url: "/enterprise-api/dashboard",
  }).then(response => response.data as IApiDashboard)
}

// #endregion