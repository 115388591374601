import React, { ReactNode, StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { InitializeKey } from "./encryption/encryption";
import UserProvider from "./userContext/provider";
import SearchProvider from "./searchContext/provider";
import reportWebVitals from "./reportWebVitals";
import ErrorProvider from "./errorContext/provider";
import * as Sentry from "@sentry/react";
import { Settings } from "./utils/settings";

Sentry.init({
  allowUrls: [/https:\/\/tunefind.com\/static\/js/, Settings.APIBaseURL],
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https?:\/\/(local\.|beta\.)?tunefind\.com(:\d+)?\//, Settings.APIBaseURL],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 1% of the transactions
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//INITIALIZE KEY
InitializeKey();

const DEV_MODE = process.env.NODE_ENV === "development";

const wrapInStrictMode = (root: ReactNode) => {
  return DEV_MODE ?
    <StrictMode>{root}</StrictMode>
    : root;
}

const app = <BrowserRouter>
  <ErrorProvider>
    <SearchProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </SearchProvider>
  </ErrorProvider>
</BrowserRouter>;


root.render(wrapInStrictMode(app));
if (DEV_MODE) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}