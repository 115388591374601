import { createContext, useContext } from "react";
import {IErrorContext, IErrorState } from "./interfaces";

export const initialErrorState: IErrorState = {
  message: '',
};

const ErrorContext = createContext<IErrorContext>({
  errorState: initialErrorState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorState: () => {},
});

export default ErrorContext;

export const useErrorContext = () => useContext(ErrorContext);
