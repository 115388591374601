const formatCSharpDate = (date: Date | null) => {
  return date
    ? new Date(Date.parse(date.toLocaleString())).toLocaleDateString("en-us", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
};

export default formatCSharpDate;
