import React, { useEffect, useRef } from "react";
import pluralize from "pluralize";
import { IMediaDetailsPageSchema } from "@app/sanity-generated-data/types";
import { formatCSharpDate } from "@app/utils";
import { Hero } from "@app/components";
import { useFollowTitle } from "@app/api";
import { useMediaDetailsPageActions } from "./store";
import { displayAndSwapHeroImageUrl } from "@app/utils/helpers/displayAndSwapHeroImageUrl";

interface IMediaDetailsHeroProps {
  schema: IMediaDetailsPageSchema["hero"];
  name: string;
  airDate: Date;
  songCount: number;
  isFollowing: boolean | undefined;
  id: string;
  computedImgUrl: string;
}

const  MediaDetailsHero = (props : IMediaDetailsHeroProps) => {
  const { postFollowRequest } = useFollowTitle();
  const schema = props.schema?.[0];
  const { computedImgUrl, name, airDate, songCount, isFollowing, id } = props;
  const { toggleFollow } = useMediaDetailsPageActions();
  const preloadedImageRef = useRef(document.getElementById('preload-wrapper'));

  useEffect(() => {
    if (preloadedImageRef.current) {
      displayAndSwapHeroImageUrl(preloadedImageRef.current, computedImgUrl)
    }
  }, [computedImgUrl, preloadedImageRef])

  const handleCtaClick = () =>
    postFollowRequest(id, isFollowing, toggleFollow);



  if (!schema) {
    return <Hero loading/>
  }

  return (
     <Hero
      shouldPreloadImage={!computedImgUrl}
      loading={false}
      reduceCtaToButton
      heroSchema={{
        ...schema,
        header: {
          ...schema.header,
          text: name ? name + " Soundtrack" : '',
        },
        description: {
          ...schema.description,
          text: songCount ? `${formatCSharpDate(airDate) + " | "}${pluralize(
            "Song",
            songCount,
            true
          )}` : '',
        },
        cta: {
          ...schema.cta,
          text: isFollowing ? "Following" : "Follow",
          onClick: handleCtaClick,
        },
      }}
    />
  );
};

export default MediaDetailsHero;
