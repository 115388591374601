import Api from "@app/api";
import UserContext from "@app/userContext";
import React, { useContext, useState, useEffect } from "react";

const User = () => {
  const { userState } = useContext(UserContext);
  const [userID, setUserID] = useState<string>("");

  useEffect(() => {
    Api.request({
      method: "GET",
      url: "/user/test",
    })
      .then((result) => {
        setUserID(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  //RENDER
  return (
    <div style={{ marginTop: "200px", marginLeft: "30px", fontWeight: "bold" }}>
      Hello User: {userState.userName} - {userID}
    </div>
  );
};

export default User;
