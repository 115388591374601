import React, { useState } from "react";
import "styled-components/macro";
import { useWindowDimensions } from "../songtradr-components/utils";
import { IContentSliderSchema } from "@app/sanity-generated-data/types";

import SuspenseImage from "@app/components/suspense-image";
import Link from "@app/components/link";
import { styles } from "../content-slider/styles";
import { Typography } from "../songtradr-components";

interface IMediaImageProps {
  schema: IContentSliderSchema;
  title: string;
  description: string;
  slug: string;
  imageUrl: string;
  height: number;
  width: number;
}

export const MediaTile = ({
  schema: { title: titleSchema, description: descSchema },
  title,
  description,
  slug,
  imageUrl,
  height,
  width,
}: IMediaImageProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const [linkDisabled, setLinkDisabled] = useState(false);

  const handleDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setLinkDisabled(true);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setLinkDisabled(false);
      window.removeEventListener("mouseup", handleMouseUp);
    }, 0);
  };

  return (
    <div css={styles.slide} draggable={true} onDragStart={handleDrag}>
      <Link to={`../${slug}`} disabled={linkDisabled}>
        {!isMobile && <div css={styles.imageGradient} />}
        <SuspenseImage
          css={styles.slideImage}
          src={imageUrl}
          width={width}
          height={height}
          alt={title}
        />
        <div css={styles.textContainer}>
          <Typography
            variant={titleSchema.variant}
            margin={false}
            truncate={isMobile}
          >
            {title}
          </Typography>
          <Typography variant={descSchema.variant} truncate>
            {description}
          </Typography>
        </div>
      </Link>
    </div>
  );
};

export default MediaTile;
