import type { CSSProperties } from "react";
import styled from "styled-components";
import { focusVisible, transition } from "../../styles/utils";
import variant from "sc-variant";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { LoadingIcon } from "../../icons/LoadingIcon";
import React from "react";

export interface ButtonProps {
  size?: "normal" | "small" | "large" | "fullWidth";
  variant?:
    | "primary"
    | "custom"
    | "important"
    | "subtle"
    | "basic"
    | "destructive"
    | "link";
}

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["variant", "size"].includes(prop),
})<ButtonProps>`
  // BASE
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  font-weight: var(--font-weight-semi-bold);
  border: ${({ variant }) =>
    variant === "custom"
      ? "var(--button-border, none)"
      : variant === "basic" || variant === undefined
      ? `1px solid var(--semantic-bdr-strongest)`
      : "none"};
  border-radius: 0.5rem;
  ${transition(["background-color", "box-shadow", "border-color", "color"])}

  // SIZE SETTINGS
  font-size: ${variant("size", { DEFAULT: "1rem", large: "1.25rem" })};
  line-height: ${variant("size", { DEFAULT: "1.5rem", large: "1.25rem" })};
  width: ${variant("size", { fullWidth: "100%" })};
  padding: ${({ variant: kind }) =>
    kind === "link"
      ? "0"
      : variant("size", {
          DEFAULT: "1rem 1.5rem",
          small: "0.5rem 1rem",
          large: "1.25rem 3rem",
        })};

  // VARIANT SETTINGS
  color: ${variant("variant", {
    DEFAULT: "var(--semantic-text-normal)",
    custom: "var(--button-text, var(--semantic-text-normal))",
    link: "var(--semantic-primary-text)",
    primary: "var(--component-primary-btn-text)",
    important: "var(--semantic-text-weakest)",
    destructive: "var(--component-destructive-btn-text)",
  })};
  background: ${variant("variant", {
    DEFAULT: "var(--semantic-gray-bg)",
    custom: "var(--button-bg, var(--bg))",
    primary: "var(--component-primary-btn-bg)",
    important: "var(--semantic-gray-bg-strong)",
    destructive: "var(--component-destructive-btn-bg)",
    subtle: "transparent",
    link: "transparent",
  })};

  :hover:not([disabled]):not(:active) {
    cursor: pointer;
    border-color: ${({ variant }) =>
      variant === "custom"
        ? "var(--button-interact-border-color, transparent)"
        : undefined};
    color: ${variant("variant", {
      custom: "var(--button-interact-text, var(--semantic-text-normal))",
      link: "var(--semantic-primary-text-hover)",
    })};
    background: ${variant("variant", {
      DEFAULT: "var(--semantic-gray-bg-hover)",
      custom: "var(--button-interact-bg, var(--semantic-gray-bg-hover))",
      link: null,
      primary: "var(--component-primary-btn-bg-hover)",
      important: "var(--semantic-gray-bg-strong-hover)",
      destructive: "var(--component-destructive-btn-bg-hover)",
    })};
  }

  ${focusVisible}

  [disabled],
  :disabled {
    cursor: not-allowed;
    color: ${variant("variant", {
      DEFAULT: "var(--semantic-text-disabled)",
      custom: "var(--button-disabled-text, var(--semantic-text-disabled))",
    })};
    border-color: ${({ variant }) =>
      variant === "custom"
        ? "var(--button-disabled-border-color, var(--semantic-bdr-strong))"
        : variant === "basic" || variant === undefined
        ? "var(--semantic-bdr-strong)"
        : undefined};
    background: ${variant("variant", {
      DEFAULT: "var(--semantic-gray-bg-disabled)",
      custom: "var(--button-disabled-bg, var(--semantic-gray-bg-disabled))",
      link: null,
      subtle: null,
    })};
  }
`;

const Button = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<"button">
>(({ className, ...rest }, ref) => (
  <StyledButton className={`${className || ""} reset`} {...rest} ref={ref} />
)) as typeof StyledButton;

Button.displayName = "Button";

export default Button;

export interface CustomButtonColors {
  "--button-text"?: string;
  "--button-bg"?: string;
  "--button-border"?: string;
  "--button-interact-text"?: string;
  "--button-interact-bg"?: string;
  "--button-interact-border-color"?: string;
  "--button-disabled-text"?: string;
  "--button-disabled-bg"?: string;
  "--button-disabled-border-color"?: string;
  "--outline-color"?: string;
}

export const ButtonLoadingIcon = styled(LoadingIcon).withConfig({
  shouldForwardProp: (prop) => !["solo"].includes(prop),
  displayName: "ButtonLoadingIcon",
})<{ solo?: boolean }>`
  height: 1.5rem;
  margin-right: ${({ solo }) => (solo ? "2rem" : "1rem")};
  margin-left: ${({ solo }) => (solo ? "2rem" : "0")};
`;

export function customButtonStyles(colors: CustomButtonColors) {
  return colors as CSSProperties;
}
