import styled from "styled-components";
import { maxSmall } from "../songtradr-components/utils";

// STYLED COMPONENTS
export const HubspotFormWrapper = styled.div`
  width: min(800px, 100vw);

  > div {
    margin: 3rem 0;
    padding: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 8px 14px var(--semantic-background-base);
    background: var(--semantic-background-top);
  }

  @media ${maxSmall} {
    width: auto;
  }
`;
