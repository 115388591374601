import { css, keyframes } from "styled-components";

const pulse = keyframes`
    0% { opacity: 0.1; }
    50% { opacity: 0.03; }
    100% { opacity: 0.1; }
`;

export const loadingPulse = css`
  animation: ${pulse} 3s ease infinite;
`;
