import React, { useEffect, useState } from "react";
import { StyledModal, styles } from "./styles";
import "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  ButtonLoadingIcon,
  Typography,
} from "../songtradr-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Col, Row } from "antd";
import { useAppearanceDelete } from "@app/api";

const useAppearanceDeleteModal = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [titleId, setTitleId] = useState<number>(0);
  const { pendingDeleteSong, deleteSongRequest } = useAppearanceDelete();

  // HANDLE BODY SCROLLING BEHIND MODAL
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = modalOpen ? "hidden" : "auto";
  }, [modalOpen]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = (id: number) => {
    setTitleId(id);
    setModalOpen(true);
  };

  //MODAL EXPORT
  const DeleteModal = (
    <StyledModal
      confirmationModal={true}
      destroyOnClose={true}
      mask={true}
      open={modalOpen}
      closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
      onOk={closeModal}
      onCancel={closeModal}
      footer={false}
    >
      <Typography css={[styles.gutter, styles.textAlign]} variant="h4">
        Are you sure you want to delete this appearance?
      </Typography>

      <Row gutter={[24, 0]} justify="space-between">
        <Col span={12}>
          <Button
            disabled={pendingDeleteSong}
            css={styles.yesButton}
            variant="important"
            onClick={() => {
              closeModal();
              deleteSongRequest(titleId);
            }}
          >
            {pendingDeleteSong && <ButtonLoadingIcon />}
            Yes
          </Button>
        </Col>
        <Col span={12}>
          <Button
            disabled={pendingDeleteSong}
            css={styles.noButton}
            onClick={closeModal}
            variant="destructive"
          >
            {pendingDeleteSong && <ButtonLoadingIcon />}
            No
          </Button>
        </Col>
      </Row>
    </StyledModal>
  );

  return { modalOpen, DeleteModal, handleOpenDeleteModal: handleOpenModal };
};

export default useAppearanceDeleteModal;
