import { useContentMargins } from "@app/styles/helpers";
import { maxSmall, minLarge } from "../songtradr-components";
import { maxMedium } from "../songtradr-components/utils";
import styled, { css } from "styled-components";

// STYLED COMPONENTS
export const SearchBarContainer = styled.div`
  position: relative;
  min-height: 5rem;

  @media ${maxMedium} {
    min-height: 3.625rem;
  }

  .clickguard {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
  }
`;

export const SearchBarContent = styled.div(
  ({
    header,
    headerAndFocused,
  }: {
    header: boolean;
    headerAndFocused: boolean;
  }) => {
    return css`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition-delay: 750ms;
      transition-property: ${headerAndFocused ? "none" : "width"};
      width: ${headerAndFocused ? "52rem" : "100%"};
      min-height: 100%;
      background: ${header ? "transparent" : "var(--semantic-background-top)"};
      border-radius: 8px;
      @media screen and (max-width: 1250px) {
        width: ${headerAndFocused ? "48rem" : "100%"};
      }
      @media screen and (max-width: 1199px) {
        width: ${headerAndFocused ? "44rem" : "100%"};
      }
      @media screen and (max-width: 1095px) {
        width: ${headerAndFocused ? "42rem" : "100%"};
      }
      @media screen and (max-width: 1050px) {
        width: ${headerAndFocused ? "40rem" : "100%"};
      }
      @media screen and (max-width: 1015px) {
        width: ${headerAndFocused ? "38rem" : "100%"};
      }
      @media screen and (max-width: 986px) {
        width: ${headerAndFocused ? "36rem" : "100%"};
      }
    `;
  }
);

export const PreviewContainer = styled.div(
  ({ header }: { header: boolean }) => {
    return css`
      overflow: hidden;

      box-shadow: ${header ? "0px 8px 14px black" : "none"};
      max-height: 95vh;
      /* overflow-y: scroll; */ /*CAUSED BIG SCROLL BAR ON WINDOWS*/

      padding: ${header ? "5rem 1.5rem 1.5rem 1.5rem" : "0.6rem"};
      padding-top: ${!header && "0"};
      color: var(--fg);
      background: ${header
        ? "var(--semantic-background-middle)"
        : "var(--semantic-background-top)"};
      // .ant-divider {
      //   margin: 0;
      //   padding: 0;
      //   background: ${header && "var(--semantic-gray-bg)"};
      // }

      @media only screen and (max-width: 600px) {
        overflow: hidden;
        overflow-y: auto; /* Add this line to enable vertical scroll */
        overflow-x: hidden;

        box-shadow: ${header ? "0px 8px 14px black" : "none"};
        max-height: 60vh;
        /* overflow-y: scroll; */ /*CAUSED BIG SCROLL BAR ON WINDOWS*/

        padding: ${header ? "0rem 0rem 0rem 0rem" : "0.7rem"};
        padding-top: ${!header && ""};
        color: var(--fg);
        background: ${header
          ? "var(--semantic-background-middle)"
          : "var(--semantic-background-top)"};
        // .ant-divider {
        //   margin: 0;
        //   padding: 0;
        //   background: none;
        // }
      }
    `;
  }
);

export const StyledSearchBarSection = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    padding: 4rem ${margin};
    color: var(--semantic-background-top);
    background-color: var(--semantic-background-base);

    @media ${maxMedium} {
      padding: 1rem ${margin};
    }
  `;
});

// CSS PROP STYLES
export const styles = {
  searchBar: css`
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    color: black;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: 1; /* Firefox */
    }

    @media only screen and (max-width: 600px) {
      // background-color: orange;
      margin-top: 0px;
    }
  `,
  resultsLoading: css`
    padding: 2rem 0;
  `,

  resultsList: css`
    padding: 0;
    padding-top: 0rem;
    width: 100%;

    @media screen and (min-width: 1301px) {
      flex: 1 1 25%;
    }
    @media screen and (max-width: 1300px) {
      flex: 1 1 33%;
    }
    @media ${maxMedium} {
      flex: 1 1 50%;
      padding-top: 0.1rem;
      padding-bottom: 1.2rem;
    }
    @media ${maxSmall} {
      flex: 1 1 100%;
    }

    &:not(:last-child) {
      /* margin-right: 1rem; */
    }
  `,
  resultsLabel: css`
    margin-bottom: 1rem;
    cursor: pointer;
    // border: 1px solid green;
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 1rem;
      border-radius: 0.25rem;
      min-width: 40px;
    }

    p {
      margin: 0;
    }
  `,

  categorystyle: css`
    font-size: 19px;
    color: #4b8e94;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  `,

  fullSearchLink: css`
    margin: 1rem 0 0.5rem 0;
    text-align: center;
  `,

  searchspacing: css`
    padding: 0rem 1.2rem 0rem 1.2rem;
  `,

  dropDownImage: css`
    border-radius: 0.25rem;
  `,
  emptyState: css`
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    column-gap: 2rem;

    @media only screen and (max-width: 600px) {
      column-gap: 0rem;
    }
  `,

  noResultsIcon: css`
    max-width: 40px;
  `,
  textAlign: css`
    text-align: center;
  `,
  link: css`
    @media only screen and (max-width: 600px) {
      // background-color: blue;
    }

    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,
  link2: css`
  font-size: 14px;

    @media only screen and (max-width: 600px) {
      color
      font-size: 12px;
    }

    // :hover {
    //   color: var(--semantic-secondary-bg-hover);
    // }
  `,

  bandsintownsearchwidget: css`
    padding: 0 0;
  `,
};

export const SearchPageWrapper = styled.div`
  background: var(--semantic-background-middle);
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  // min-height: 100vh;

  @media ${maxMedium} {
    padding: 2rem 0;
    row-gap: 2rem;
  }
  @media ${minLarge} {
    padding: 4rem 0;
    row-gap: 4rem;
  }
`;

export const SliderWrapper = styled.div(({ offset }: { offset: number }) => {
  return css`
    margin: 0 0 0 ${offset / 16}rem;
  `;
});

export const HeaderSearchBoxWrapper = styled.div(
  ({ focused }: { focused: boolean }) => {
    return css`
      z-index: 1;
      position: absolute;

      * {
        transition: all ease 0.5s;
      }

      input:focus::placeholder {
        /* color: transparent; */
      }

      input {
        margin-left: ${focused ? "2rem" : "1rem"};
        margin-top: 1.5rem;

        color: var(--semantic-background-top);
        background: var(--semantic-gray-bg);
        height: 2rem;
        font-size: 1rem;
        border-radius: 1rem;
        border: none;
        outline: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0px 32px 0px 32px;

        :focus {
          outline: none !important;
          box-shadow: none;
        }

        @media screen and (min-width: 1600px) {
          width: ${focused ? "48rem" : "11rem"};
        }
        @media screen and (max-width: 1599px) {
          width: ${focused ? "48rem" : "8.5rem"};
        }
        @media screen and (max-width: 1250px) {
          width: ${focused ? "44rem" : "8.5rem"};
        }
        @media screen and (max-width: 1199px) {
          width: ${focused ? "40rem" : "7rem"};
        }
        @media screen and (max-width: 1095px) {
          width: ${focused ? "38rem" : "7rem"};
        }
        @media screen and (max-width: 1050px) {
          width: ${focused ? "36rem" : "7rem"};
        }
        @media screen and (max-width: 1015px) {
          width: ${focused ? "34rem" : "4rem"};
        }
        @media screen and (max-width: 986px) {
          width: ${focused ? "32rem" : "4rem"};
        }
      }

      .ais-SearchBox-submit {
        position: absolute;
        width: 0;
        height: 0;
        background: none;
        top: 71%;
        transform: translate(0, -50%);
        transition: left 0.5s;
        left: 0rem;
        border: none;
        fill: var(--semantic-gray-bg-strong);
      }

      .__icon {
        fill: var(--semantic-background-top);
        position: absolute;
        width: 1rem;
        height: 1.5rem;
        left: ${focused ? "2.5rem" : "1.5rem"};
        cursor: pointer;
        margin-top: calc(1.75rem + 1px);
        z-index: 1;
      }

      .ais-SearchBox-reset {
        position: absolute;
        width: 0;
        height: 0;
        background: none;
        top: 71%;
        transform: translate(0, -50%);
        right: 0rem;
        border: none;
      }

      .search-wrapper {
        display: flex;
        align-items: center;
      }
      
      .__clear {
        color: #fff;
        position: absolute;

        fill: white;
        margin-top: 2px;
        width: 0.75rem;
        height: 0.75rem;
        margin-top: 24px;
        right: 0.75rem;
        cursor: pointer;
      }

      .ais-SearchBox-loadingIcon {
        position: absolute;
        right: 0;
        fill: var(--pb-progress);
        width: 1rem;
        height: 1rem;
        top: 50%;
        transform: translate(0, -50%);
        right: 1.5rem;
      }

      .ais-SearchBox-input::placeholder {
        position: relative;
        /* color: var(--pb-progress); */
        color: black;
        font-size: 1rem;
        width: 90%;
        display: block;
      }
    `;
  }
);

export const HeaderSearchBarSpacer = styled.div`
  margin-right: 1.5rem;
  @media screen and (min-width: 1600px) {
    width: 11rem;
  }
  @media screen and (max-width: 1599px) {
    width: 8.5rem;
  }
  @media screen and (max-width: 1199px) {
    width: 7rem;
  }
  @media screen and (max-width: 1015px) {
    width: 4rem;
  }
  @media only screen and (max-width: 600px) {
    width: 2rem;
  }
`;

export const HeaderSearchStyles = {
  resultsLoading: css`
    padding: 3rem 0;
  `,

  resultsLabel: css`
    margin-bottom: 1rem;
    cursor: pointer;

    > div:first-child {
      margin-right: 1rem;
      border-radius: 0.25rem;
      min-width: 40px;
    }

    p {
      margin: 0;
    }
  `,
  resultsList: css`
    padding: 0;
    padding-top: 1.5rem;
    width: 100%;
    flex: 1 1 50%;

    &:not(:last-child) {
      /* margin-right: 1rem; */
    }
  `,
};

export const SearchBoxWrapper = styled.div`
  position: relative;

  input:focus::placeholder {
    color: transparent;
  }

  input {
    height: 5rem;
    width: 100%;
    font-size: 1rem;
    border-radius: 0.5rem;
    border-radius: none;
    color: black;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 48px 0px 48px;

    @media ${maxMedium} {
      height: 3.625rem;
    }

    :focus {
      outline: none !important;
      box-shadow: none;
    }

    @media only screen and (max-width: 600px) {
      height: 4rem;
      width: 100%;
      font-size: 1rem;
      border-radius: 0.5rem;
      border-radius: none;
      color: black;
      text-indent: 2rem;
      border: none;
      outline: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ais-SearchBox-submit {
    position: absolute;
    width: 0;
    height: 0;
    background: none;
    top: 50%;
    transform: translate(0, -50%);
    left: 0rem;
    border: none;
  }

  .__icon {
    /* fill: var(--pb-progress); */
    fill: black;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    left: 1.5rem;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      /* fill: var(--pb-progress); */
      fill: black;
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      transform: translate(0, -50%);
      left: 1rem;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .search-wrapper {
    display: flex;
    align-items: center;
  }

  .__clear {
    color: #000000;
    position: absolute;

    fill: #000000;
    margin-top: 2px;
    width: 1.3rem;
    height: 1.3rem;
    right: 1.3rem;
    cursor: pointer;
  }

  .ais-SearchBox-resetIcon {
    position: absolute;
    right: 0;
    /* fill: var(--pb-progress); */
    fill: black;
    width: 1rem;
    height: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    right: 1.5rem;
    cursor: pointer;
  }

  .ais-SearchBox-loadingIcon {
    position: absolute;
    right: 0;
    fill: var(--pb-progress);
    width: 1rem;
    height: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    right: 1.5rem;
  }

  .ais-SearchBox-input::placeholder {
    position: relative;
    /* color: var(--pb-progress); */
    color: black;
    font-size: 1rem;
    width: 90%;
    display: block;
  }
`;
