import Api from "@app/api";
import { useSignUpModal } from "@app/components/dialog-modal/useSignUpModal";
import { uuid } from "../components/songtradr-components";
import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import UserContext, { initialUserState } from ".";
import { IExchangeGrantPayload, ITokenResponse, IUserState } from "./interfaces";
import UserPool from "@app/environments/UserPool";
import { parseJwt } from "@app/utils/helpers/parseJWT";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import { useErrorContext } from "@app/errorContext";
import { useNavigate } from "react-router-dom";
import { checkIfUserPartOfFeaturePreview } from "@app/utils";


const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [userState, setState] = useState({ ...initialUserState, authLoading : true});
  const [openSignUp, setOpenSignUp] = useState(false);
  const navigate = useNavigate();
  const { setErrorState } = useErrorContext();
  const { SignUpModal } = useSignUpModal({
    isOpen: openSignUp,
    handleClose: () => setOpenSignUp(!openSignUp),
  });

  const pushUserSignInEvent = (username: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "UserSignIn",
      username: username,
    });
  };

  const fetchAndSetUserContextTokens = (code: string) => {
    Api.request({
      method: "POST",
      url: `/auth/grant?code=${code}`,
      headers: {
        referrer:
          (window as any).location.origin + (window as any).location.pathname,
      },
    })
      .then((result) => result.data as ITokenResponse)
      .then((tokenResponse) => {

        localStorage.setItem(
          "tf_auth_access_token",
          tokenResponse.access_token
        );
        localStorage.setItem(
          "tf_auth_refresh_token",
          tokenResponse.refresh_token
        );
        localStorage.setItem(
          "tf_auth_access_token_exp",
          parseJwt(tokenResponse.access_token).exp.toString()
        );
        setState({
          ...userState,
          ...tokenResponse.user_state,
          isPartOfFeaturePreview: false
        });

        pushUserSignInEvent(tokenResponse.user_state.userName);
      }).catch((error) => {
          const errorObj = {code: error.response.data};
          const errorMessage = handleError(errorObj);

          if (errorMessage) {
            setErrorState({ message: errorMessage });
            navigate('/signin');
          }
      });
  };

  const exchangeGrant = (session: IExchangeGrantPayload) => {
    localStorage.setItem(
      "tf_auth_access_token",
      session.access_token
    );
    localStorage.setItem(
      "tf_auth_refresh_token",
      session.refresh_token
    );
    localStorage.setItem(
      "tf_auth_access_token_exp",
      session.exp_token.toString()
    );
    localStorage.setItem(
      "tf_auth_id_token",
      session.id_token
    )


    checkLoadUserAccount();
  };

  const checkLoadUserAccount = useCallback(() => {
    if (localStorage.getItem("tf_auth_refresh_token") !== null) {
      setState((state : IUserState) => ({ ...state, authLoading : true }));

      Api.request({
        method: "POST",
        url: `/user/account`,
      })
        .then((result) => result.data as IUserState)
        .then((userState) => {
          const isPartOfFeaturePreview = checkIfUserPartOfFeaturePreview(localStorage.getItem("tf_auth_id_token") ?? '')
          setState({
            ...userState,
            ...userState,
            isPartOfFeaturePreview,
            authLoading: false
          });
          pushUserSignInEvent(userState.userName);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("Failed to get user's account", e);
          setState((state : IUserState) => ({ ...state, authLoading : false }));
        });
    } else {
      setState((state : IUserState) => ({ ...state, authLoading : false }));
    }
    if (localStorage.getItem("tf_ltt") === null) {
      localStorage.setItem("tf_ltt", uuid().toString());
    }
  }, []);

  const signOut = () => {
    localStorage.removeItem("tf_auth_access_token");
    localStorage.removeItem("tf_auth_access_token_exp");
    localStorage.removeItem("tf_auth_refresh_token");
    localStorage.removeItem("tf_auth_id_token")
    setState({
      ...userState,
      ...initialUserState,
      authLoading: false,
    });
    UserPool.getCurrentUser()?.signOut();
  };

  const nudge = (action: any) => {
    if (userState.authenticated) {
      action();
    } else {
      handleOpenSignUp();
    }
  };

  const handleOpenSignUp = () => setOpenSignUp(!openSignUp);

  return (
    <UserContext.Provider
      value={{
        userState,
        setState,
        exchangeGrant,
        fetchAndSetUserContextTokens,
        signOut,
        checkLoadUserAccount,
        nudge,
      }}
    >
      {children}
      {SignUpModal}
    </UserContext.Provider>
  );
};
export default UserProvider;
