import { CardBrand } from "@app/utils/helpers/getCCIcon";
import { eApiKey } from "./apiKeys";

// API KEYED RESPONSES
export interface IContentSliderResponse {
  [key: string]: IMedia[];
}

export interface ITVScheduleResponse {
  [key: string]: IScheduleDay[];
}

export interface ITabExplorerResponse {
  [key: string]: ISong[] | IMediaGallery;
}

export interface IUserStatsResponse {
  [eApiKey.USER_ACHIEVEMENTS]: IUserAchievement[];
  [eApiKey.USER_ENGAGEMENT_STATS]: IUserEngagementStat[];
  [eApiKey.USER_SOCIALS]: IUserSocial[];
}

export type MediaOverviewResponse = IContentSliderResponse &
  ITVScheduleResponse;

// MEDIA DETAILS TYPES
export interface IMediaDetailsResponse extends ITitleBase {
  parents?: ITitleBase[];
  children?: ITitleBase[];
  songs?: ISong[];
  tombstone?: ITombstone;
  soundtracks?: ISoundtrack[];
  info?: {
    theme: ISong | null;
    composers: IArtist[];
    supervisors: IUser[];
    merchUrl: IStoreLink;
  };
  questions?: IQuestion[];
  recentQuestions?: IQuestion[];
  stats?: {
    topSongs: ISong[];
    relatedMedia: { id: number; title: string; slug: string }[];
    topContributers: IUser[];
  };
  latestChild?: ITitleBase
}

export interface IMediaDetailsHeroData {
  airDate: Date;
  id: string;
  mediaType: eMediaType;
  name: string;
  songCount: number;
  supervisors: IUser[];
  isFollowing?: boolean;
}
export interface ITombstone extends IVotingBase {
  id: number;
}

export enum eMediaType {
  Show = "T",
  Season = "S",
  Episode = "E",
  Movie = "M",
  Game = "G",
  Brand = "B",
  Ad = "A",
}

export enum ePageTypes {
  AD = "ad",
  GAME = "game",
  MOVIE = "movie",
  SHOW = "show"
}
export interface ITitleBase {
  id: string;
  parentId?: string;
  airDate: Date;
  mediaType: eMediaType;
  name: string;
  description?: string;
  imageUrl?: string;
  songCount: number;
  episodeCount?: number;
  questionCount?: number;
  locked?: boolean;
  verified?: boolean;
  isFollowing?: boolean;
  isSupervisor?: boolean;
  supervisors: IUser[];
}

export interface ISoundtrack {
  id: number;
  name: string;
  imageUrl?: string;
  storeLinks: IStoreLink[];
  songs: ISong[];
}

export interface IQuestion {
  id: number;
  parentId?: string;
  childId?: string;
  title: string;
  author: string;
  date: Date;
  followers: number;
  content: string;
  canDelete: boolean;
  isFollowing: boolean;
  status: string;
  replies: IReply[];
}

export interface IReply {
  id: number;
  author: string;
  date: Date;
  content: string;
  canDelete: boolean;
  status: boolean;
  song?: ISong;
}

export interface IUser {
  id: string;
  name: string;
  username?: string;
  slug?: string;
  userSince?: string;
  isSuper?: boolean;
  profileImage?: string;
  achievements?: number;
}


export interface IUserSettings {
  email: string;
  username: string;
  created: Date;
  optOutNewsletter: boolean;
  optOutEmail: boolean;
  optOutWeb: boolean;
}

// USER PROFILE TYPES
export interface IUserProfileResponse {
  user: IUser;
  statusMessages?: { title: string; message: string }[]; // likely not visible publicly
  tabs: ITabExplorerResponse;
  metrics: IUserStatsResponse;
  supervisorCredits?: ISupervisorCredit[];
}

export interface ISupervisorCredit {
  title: string;
  slug: string;
  releaseDate: string;
  seasons: string;
  imageUrl: string;
}

export interface IUserEngagementStat {
  title: string;
  accuracy: number;
  correct: number;
  incorrect: number;
  pending: number;
}

export interface IUserAchievement {
  title: string;
  description: string;
  icon: string;
}

export interface IUserSocial {
  title: string;
  link: string;
  icon: string;
}

// ARTIST DETAILS TYPES
export interface IArtistDetailsResponse {
  id: number;
  name: string;
  bio: string;
  imageUrl: string;
  songCount: number;
  appearanceCount: number;
  credits: ISong[];
  socialLinks: ISocialLinks;
  storeLinks?: IStoreLink[];
}

export interface IArtistCredits {
  credits: ISong[];
}

export interface ISocialLinks {
  official: string;
  instagram: string;
  twitter: string;
  facebook: string;
  wikipedia: string;
}

// MEDIA OVERVIEW TYPES
export interface IMedia {
  id: string;
  type?: "media" | "ad";
  adValue?: string;
  displayOn?: "small" | "large" | "always";
  slug: string;
  title: string;
  description?: string;
  imageUrl: string;
  date?: string;
  rank?: 1 | 2 | 3 | 4;
}

export interface IMediaGallery {
  media: IMedia[];
  sections: { key: string; label: string; mediaIds: string[] }[];
}

export interface IScheduleDay {
  date: Date | string;
  media: IMedia[];
}

// SONG TYPES
export interface ISong extends IVotingBase {
  id: number;
  appearanceId?: number;
  name: string;
  description?: string;
  artists: IArtist[];
  featuredOn?: IMedia[];
  ranking?: number;
  trend?: "rising" | "falling" | "static" | "new";
  audioPreview?: string;
  duration?: string;
  storeLinks?: IStoreLink[];
  imageUrl?: string;
  album?: string;
  appearances?: ISongAppearance[];
  parentId?: number;
  likedByUser?: boolean;
  canDelete?: boolean;
  durationMs?: number;
  isrc?: string;
}


export interface IAddNewSong {
  id?: number;
  appearanceId?: number;
  name: string;
  description?: string;
  artists: {
   id?: number;
   name: string;
   slug?: string;
  }[];
  featuredOn?: IMedia[];
  ranking?: number;
  trend?: "rising" | "falling" | "static" | "new";
  audioPreview?: string;
  duration?: string;
  storeLinks?: IStoreLink[];
  imageUrl?: string;
  album?: string;
  appearances?: ISongAppearance[];
  parentId?: number;
  likedByUser?: boolean;
  canDelete?: boolean;
  durationMs?: number;
  isrc?: string;
}
export interface ISongAppearance extends IVotingBase {
  id: string;
  title: string;
  description: string;
  airDate: string;
  appearanceLink: string;
}

// MISC.
export enum eCorrectness {
  right = 1,
  wrong = -1,

  neutral = 0,
}

export interface IArtist {
  id: number;
  name: string;
  slug: string;
}

export type ISearchResult = Array<{
  category: string;
  total: number;
  results: IMedia[];
}>;

export const Stores = [
  "amazon",
  "applemusic",
  "spotify",
  "songtradr",
  "songtradr_search",
  "youtube",
] as const;

export interface IStoreLink {
  store: typeof Stores[number];
  url: string;
}

export interface IVotingBase {
  openForVote?: boolean;
  confirmation?: eCorrectness;
  userVote?: eCorrectness;
}

export interface IApiDashboard {
  subscriptions: IApiSubscription[];
}

export interface IApiSubscription {
  api_endpoint: string;
  is_recurring: boolean;
  api_password: string;
  card_brand: CardBrand;
  card_last_four: string;
  current_period_end: string;
  current_period_start: string;
  id: number;
  plan: IApiSubscriptionPlan;
  quantity: number;
  status: string;
  stripe_subscription_id: string;
  type: string;
  user_id: string;
}

export interface IApiSubscriptionPlan {
  name: string;
  codename: string;
  days_max: number;
  days_min: number;
  capability: {
    commercial: boolean;
    api: boolean;
    airdate: boolean;
    "appearance-endpoint": boolean;
    "artist-endpoint": boolean;
    episode: boolean;
    game: boolean;
    "game-endpoint": boolean;
    movie: boolean;
    "movie-endpoint": boolean;
    ad: boolean;
    "ad-endpoint": boolean;
    show: boolean;
    "show-endpoint": boolean;
    "song-endpoint": boolean;
    syndicationdate: boolean;
    "syndication-all": boolean;
    musicube: boolean;
    search: boolean;
    "scene-descriptions": boolean;
    "song-order": boolean;
    "theme-songs": boolean;
    attribution: boolean;
    "service-links": boolean;
    "search-realtime": boolean;
    "service-ids": boolean;
    domain: boolean;
    cors: boolean;
    trending: boolean;
    confidence: boolean;
    batch: boolean;
    "date-updated": boolean;
    auth: boolean;
}
}