import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "../songtradr-components";
import { Row } from "antd";
import React, { useEffect } from "react";
import "styled-components/macro";
import { StyledModal, styles } from "./styles";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

export const useSignUpModal = (props: {
  isOpen: boolean;
  handleClose: Function;
}) => {
  const { isOpen, handleClose } = props;
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const fromParam = searchParams.get('from');

  // HANDLE BODY SCROLLING BEHIND MODAL
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  const SignUpModal = (
    <StyledModal
      confirmationModal={false}
      destroyOnClose={true}
      mask={true}
      open={isOpen}
      closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
      footer={false}
    >
      <Typography margin={false} css={styles.gutter} variant="h2">
        Please Sign in
      </Typography>
      <Typography css={styles.gutter} variant="body">
        You need to be logged in to use this feature.
      </Typography>
      <Typography css={styles.gutter} variant="body">
        Please follow the appropriate link to continue, or close the popup and
        continue browsing!
      </Typography>

      <Row css={styles.gutter} gutter={[24, 24]} justify="center">
        <Button
          onClick={() => {
            handleClose();
            navigation(`/signin?from=${fromParam ?? pathname}`);
          }
          }
          variant="link"
        >
          If you have an account please Sign In
        </Button>
      </Row>
      <Row css={styles.gutter} gutter={[24, 24]} justify="center">
        <Button
          onClick={() => {
            handleClose();
            navigation(`/signup?from=${fromParam ?? pathname}`);
          }
          }
        >
          Sign Up
        </Button>
      </Row>
    </StyledModal>
  );

  return { SignUpModal };
};
