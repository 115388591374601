import React, { Fragment, useMemo, useState } from "react";
import "styled-components/macro";
import { ScheduleWrapper, styles } from "./styles";
import { Row } from "antd";
import { Typography, useWindowDimensions } from "../songtradr-components";
import { IScheduleSchema } from "@app/sanity-generated-data/types";

import DayCol from "./DayCol";
import { IScheduleDay } from "@app/api/types";
import Slider, { SliderItem } from "../slider/Slider";
import ShowMore from "../show-more/ShowMore";

interface IScheduleProps {
  schema: IScheduleSchema;
  loading: boolean;
  data: IScheduleDay[];
}

const Schedule = ({ schema, loading, data }: IScheduleProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  // const margin = Math.max(
  //   (width - 1440) / 2,
  //   Math.max(16, Math.min(width * 0.07, 96))
  // );
  const margin = isMobile ? 20 : 92;

  //STATE
  const [expanded, setExpanded] = useState(false);
  const expandable = useMemo(
    () => data?.some((datum) => datum.media.length > 3),
    [data]
  );

  //RENDERS
  const mappedDayCols = useMemo(() => {
    return data?.map((day) => {
      const colData =
        expandable && !expanded
          ? { ...day, media: day.media.slice(0, 3) }
          : day;
      return (
        <DayCol
          key={day.date.toString()}
          schema={schema}
          data={colData}
          expanded={expanded}
        />
      );
    });
  }, [data, schema, expandable, expanded]);

  const mobileRender = useMemo(() => {
    if (loading) return <></>;
    else
      return (
        <Slider
          header={{
            text: schema.header.text ?? "",
            variant: schema.header.variant,
          }}
          offset={margin}
        >
          {data?.map((day) => {
            return (
              <SliderItem key={day.date.toString()} width={254}>
                <DayCol schema={schema} data={day} expanded={true} />
              </SliderItem>
            );
          })}
        </Slider>
      );
  }, [data, schema, loading, margin]);

  const desktopRender = () => {
    if (loading) return <></>;
    else
      return (
        <ScheduleWrapper>
          <Typography variant={schema.header.variant}>
            {schema.header.text}
          </Typography>
          {contentRender}
        </ScheduleWrapper>
      );
  };

  const contentRender = useMemo(() => {
    return (
      <Fragment>
        <Row gutter={24} css={styles.dayColContainer}>
          {mappedDayCols}
        </Row>
        {expandable && (
          <Fragment>
            {!expanded && <div css={styles.showMoreButtonFading} />}
            <ShowMore expanded={expanded} setExpanded={setExpanded} />
          </Fragment>
        )}
      </Fragment>
    );
  }, [mappedDayCols, expandable, expanded]);

  return <>{isMobile ? mobileRender : desktopRender()}</>;
};

export default Schedule;
