import styled from "styled-components";
import { maxMedium, minLarge } from "../../components/songtradr-components";

// STYLED COMPONENTS
export const MediaOverviewWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  padding: 5rem 0 0 0;
`;

export const MediaOverviewContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${maxMedium} {
    margin: 2rem 0;
    row-gap: 2rem;
  }
  @media ${minLarge} {
    margin: 4rem 0;
    row-gap: 4rem;
  }
`;
