import React, { useCallback, useContext, useState } from "react";
import UserPool from "@app/environments/UserPool";
import { CognitoUser }  from "amazon-cognito-identity-js"
import { Row, Col, Input} from "antd"
import { Button, Typography } from "../../components/songtradr-components";
import { useNavigate } from "react-router-dom";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import { PasswordErrorsObject, checkPasswordValidity, checkIfAllPasswordRequirementsAreMet } from "@app/utils/helpers/passwordValidator";
import { PasswordValidation } from "../../components";
import "styled-components/macro";
import { styles } from './styles';
import UserContext from "@app/userContext";
import { initialErrorState, useErrorContext } from "@app/errorContext";

const ChangePassword: React.FC = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const { errorState, setErrorState } = useErrorContext();
    const [passwordErrors, setPasswordErrors] = useState<PasswordErrorsObject[]>(checkPasswordValidity(''));
    const [userStartedTypingPassword, setUserStartedTypingPassword] = useState(false);
    const { userState } = useContext(UserContext);

    const navigation = useNavigate();

    const handleNewPasswordFieldChange = useCallback((newPass: string) => {
        setNewPassword(newPass);

        if (!userStartedTypingPassword) {
            setUserStartedTypingPassword(true)
        }

        const errors = checkPasswordValidity(newPass);
        setPasswordErrors(errors);
    }, [userStartedTypingPassword])
    
    const handlePasswordChange = useCallback((event?: any) => {
        if (userState.userName && oldPassword && newPassword && checkIfAllPasswordRequirementsAreMet(passwordErrors).length === 0) {
            setErrorState(initialErrorState);
            event?.preventDefault();
            const userData = {
                Username: userState.userName,
                Pool: UserPool,
            }
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.getSession((err: any) =>  {
                    if (err) {
                        const error = handleError(err);
    
                        if (error) {
                            setErrorState({message: error});
                        }
                        return;
                    }
                    cognitoUser.changePassword(oldPassword, newPassword, (err) => {
                        if (err) {
                            const error = handleError(err);
        
                            if (error) {
                                setErrorState({message: error});
                            }
                            return;
                        }
                        navigation('/');
                        setErrorState(initialErrorState)
                    });
                });
        }
    },[navigation, newPassword, oldPassword, passwordErrors, setErrorState, userState.userName])

    
    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handlePasswordChange();
        }
    },[handlePasswordChange])
    
   
    
    return <Row css={styles.topMargin} gutter={[16,16]} >
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                    <Row gutter={[0, 24]}>
                    {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                        <Col span={24}>
                            <Typography variant="h3"> Change Password </Typography>
                        </Col>
                        <Col span={24}>
                            <form>
                                <Input 
                                    autoComplete="current-password"
                                    css={styles.inputHeight}
                                    placeholder="Old password"
                                    type="password"
                                    value={oldPassword}
                                    onChange={(event) => setOldPassword(event.target.value)}
                                    onKeyDown={handleEnterKeyDown}
                                />
                                <Input 
                                    autoComplete="new-password"
                                    css={[styles.inputHeight, styles.inputOffset]}
                                    type="password"
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={(event) => handleNewPasswordFieldChange(event.target.value)}
                                    onFocus={() => { handleNewPasswordFieldChange(newPassword)}}
                                    onKeyDown={handleEnterKeyDown}
                                />
                                {userStartedTypingPassword && <PasswordValidation errors={passwordErrors} />}   
                            </form> 
                        </Col>
                        <Col span={24}>
                        <Button variant="primary" onClick={handlePasswordChange}> Confirm </Button> 
                        </Col>
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
 </Row>
}

export default ChangePassword