import React, { ReactNode } from "react";
import "styled-components/macro";
import { Tooltip } from "antd";
import { IconButton, Typography } from "../songtradr-components";
import { ISong } from "@app/api/types";
import { useSongFavoriting } from "@app/api";
import WaitSpin from "../wait-spin/WaitSpin";

interface IFavoriteButtonProps {
  target: ISong;
  onSuccess: Function;
  nudge: Function;
}

// BUTTON COMPONENT
const FavoriteButton = ({ target, onSuccess, nudge }: IFavoriteButtonProps) => {
  const { pendingSongFavorite, toggleSongFavorite } = useSongFavoriting();

  const isPending = pendingSongFavorite === target.id;

  let iconSrc = "";
  let altText = "";
  let tooltipText: string | ReactNode = "";

  // change icon and text based on favorited status
  switch (target.likedByUser) {
    case true:
      iconSrc =
        "https://img-sys.songtradr.com/cf88b21d4a74460d3080cb09f1ac845888392bef7bb90a695cc41a9df7cfb417.svg";
      altText = "Song Favorited";
      tooltipText = "Unfavorite Song";
      break;
    case false:
      iconSrc =
        "https://img-sys.songtradr.com/2e35872df4a17a3adff5b646a1732280893e15858db675c3ec323e896a4c2e1f.svg";
      altText = "Song Not Favorited";
      tooltipText = "Favorite Song";
      break;
  }

  const handleClick = () => {
    if (!isPending)
      toggleSongFavorite(target, () => onSuccess(target, "likedByUser"));
  };

  return (
    <Tooltip
      placement="top"
      title={
        <Typography
          variant="xsmall"
          css={{ color: "var(--semantic-background-top)" }}
        >
          {tooltipText}
        </Typography>
      }
      destroyTooltipOnHide
    >
      <IconButton onClick={() => nudge(handleClick)}>
        {isPending ? (
          <WaitSpin dark size={"small"} />
        ) : (
          <img src={iconSrc} alt={altText} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default FavoriteButton;
