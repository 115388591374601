import React, { ReactNode, useMemo, useState } from "react";
import "styled-components/macro";
import { Tooltip } from "antd";
import {
  IconButton,
  Typography,
  useWindowDimensions,
} from "../songtradr-components";
import { eCorrectness, ISong, ITombstone } from "@app/api/types";
import useVoteModal from "../dialog-modal/useVoteModal";
import { voteButtonstyles as styles } from "./styles";
import { useUserContext } from "@app/userContext";
import { eUserType } from "@app/userContext/interfaces";

// TYPES
export type VoteTargetType = "appearance" | "tombstone";

interface IVoteButtonProps {
  target?: ISong | ITombstone;
  small?: boolean;
}

interface IVoteButtonInnerProps extends IVoteButtonProps {
  targetType: VoteTargetType;
  toggleModal: (x: any) => void;
}

const userVoteToPlainText = {
  [eCorrectness.right]: "you did hear it.",
  [eCorrectness.wrong]: "you didn't hear it.",
  [eCorrectness.neutral]: "n/a",
};

// BUTTON COMPONENT
const VoteButtonInner = ({
  targetType,
  toggleModal,
  target,
  small = false,
}: IVoteButtonInnerProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const {
    nudge,
    userState: { userType },
  } = useUserContext();

  const [tooltipOpen, setTooltipOpen] = useState<boolean>();

  const canVote = useMemo(() => {
    if (userType === eUserType.Admin) return true;
    if (target?.openForVote && !!target.userVote) return true;
    if (target?.confirmation === eCorrectness.neutral) return true;
    return false;
  }, [target, userType]);

  if (!target) return null;

  let iconSrc = "";
  let altText = "";
  let tooltipText: string | ReactNode = "";

  // change icon and text based on confirmation status
  switch (target.confirmation) {
    case eCorrectness.right:
      iconSrc =
        "https://img-sys.songtradr.com/45ce6a21b1317000bb36d8bf989203ce54c01315820989c604d9434f3506b6a7.svg";
      tooltipText = "Confirmed Correct";
      altText = "Confirmed Correct";
      break;
    case eCorrectness.wrong:
      iconSrc =
        "https://img-sys.songtradr.com/96414c247c4909dfccc4a5ff873286755d390476c5e4f0c3670d72b30aa04d4f.svg";
      tooltipText = "Confirmed Incorrect";
      altText = "Confirmed Incorrect";
      break;
    case eCorrectness.neutral:
    default:
      iconSrc =
        "https://img-sys.songtradr.com/49f8ddf01768cfa11f88bcb73ca9e06a4242156740b8875ba1625f5c3ecef919.svg";
      tooltipText = (
        <div className={"light-theme"}>
          <Typography variant={"smallBold"} margin={false}>
            {target.userVote ? "Thanks For Voting!" : "Your Vote Matters!"}
          </Typography>
          <div>
            <Typography variant={"small"} margin={false}>
              {target.userVote
                ? `You voted that ${userVoteToPlainText[target.userVote]}`
                : `Click to confirm whether you heard ${
                    targetType === "appearance" ? "this song" : "any songs"
                  }.`}
            </Typography>
          </div>
        </div>
      );
      altText = target.userVote ? "Thanks for voting!" : "Unconfirmed";
      break;
  }

  const handleClick = () => {
    if (canVote) {
      toggleModal(
        targetType === "appearance"
          ? (target as ISong).appearanceId
          : (target as ITombstone).id
      );
    }
  };

  return (
    <IconButton
      disabled={!canVote}
      onClick={() => nudge(handleClick)}
      onMouseOver={() => setTooltipOpen(true)}
      onMouseOut={() => setTooltipOpen(false)}
    >
      <Tooltip
        placement={isMobile ? "topRight" : "top"}
        arrowPointAtCenter
        title={
          <Typography
            variant="xsmall"
            css={{ color: "var(--semantic-background-top)" }}
          >
            {tooltipText}
          </Typography>
        }
        open={tooltipOpen}
        destroyTooltipOnHide
      >
        <img css={styles.confirmIcon(small)} src={iconSrc} alt={altText} />
      </Tooltip>
    </IconButton>
  );
};

// EXPORTED HOOK
const useVoteButton = (targetType: VoteTargetType) => {
  const { VoteModal, toggleVoteModal } = useVoteModal(targetType);

  // BUTTON COMPONENT
  const VoteButton = (props: IVoteButtonProps) => {
    return (
      <VoteButtonInner
        {...props}
        targetType={targetType}
        toggleModal={toggleVoteModal}
      />
    );
  };

  // RETURN
  return { VoteButton, VoteModal };
};

export default useVoteButton;
