import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Button, TextArea, Typography } from "../songtradr-components";
import { StyledModal, styles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "styled-components/macro";
import { usePostQuestion, useUpdateDescription } from "@app/api";
import { IQuestion, ISong } from "@app/api/types";

type ModalType = "scene-description" | "question";

interface DescModalProps {
  type: ModalType;
  content?: IQuestion[] | ISong;
  titleId?: string;
  defaultValue?: string;
}

const useSceneDescriptionModal = (props: DescModalProps) => {
  const { type, content, titleId, defaultValue } = props;
  const [value, setValue] = useState(defaultValue);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { pendingUpdate, postQuestionRequest } = usePostQuestion();
  const { pendingSceneDescUpdate, updateDescriptionRequest } =
    useUpdateDescription();

  useEffect(() => {
    if (defaultValue === undefined) {
      setValue(undefined);
    } else if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleOpenSceneDescModal = () => {
    setIsModalOpen(!isModalOpen);
    // If the user closes the dialog without saving new description reset it to the original value
    if (isModalOpen && !pendingSceneDescUpdate) {
      setValue(defaultValue);
    }
  };

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    handleOpenSceneDescModal();
    if (type === "question") {
      postQuestionRequest(type, {
        questionContent: value,
        titleId: titleId ?? "",
      });
    } else {
      updateDescriptionRequest({
        content: value,
        titleId: (content as ISong).parentId,
        appearanceId: (content as ISong).appearanceId,
      });
    }
  };

  //Handle body scrolling behind the modal
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = isModalOpen ? "hidden" : "auto";
  }, [isModalOpen]);

  const SceneDescModal = () => {
    const modalText =
      type === "scene-description"
        ? {
            title: "Enter a scene description",
            inputLabel: "Description",
            disclaimer: (
              <Typography variant="small">
                Don't copy from copyrighted sources!
              </Typography>
            ),
          }
        : { title: "Ask a question", inputLabel: "Question" };
    return (
      <StyledModal
        confirmationModal={false}
        destroyOnClose
        mask={true}
        open={isModalOpen}
        closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
        onOk={handleOpenSceneDescModal}
        onCancel={handleOpenSceneDescModal}
        footer={
          <Row gutter={24} justify="end">
            <Button onClick={handleOpenSceneDescModal} variant="link">
              Cancel
            </Button>
            <Col>
              <Button
                disabled={pendingSceneDescUpdate || pendingUpdate}
                onClick={handleSubmit}
                variant="primary"
              >
                {type === "scene-description" ? "Save" : "Submit"}
              </Button>
            </Col>
          </Row>
        }
      >
        <div css={styles.verticalSpacing}>
          <Typography variant="h3">{modalText.title}</Typography>
          <Col>
            <Row>
              <Col span={24}>
                <Typography
                  css={styles.inputHeader}
                  margin={false}
                  variant="body"
                >
                  {modalText.inputLabel}
                </Typography>

                <TextArea
                  disabled={pendingSceneDescUpdate || pendingUpdate}
                  value={value}
                  onChange={handleInput}
                  rows={5}
                  maxLength={200}
                  css={styles.textArea}
                  placeholder={
                    type === "question"
                      ? "Ask your question here"
                      : "Add scene description"
                  }
                />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Typography variant="body">{modalText?.disclaimer}</Typography>
              </Col>
              <Col>
                <Typography variant="body">{value?.length}/200</Typography>
              </Col>
            </Row>
          </Col>
        </div>
      </StyledModal>
    );
  };

  return [SceneDescModal, handleOpenSceneDescModal];
};

export default useSceneDescriptionModal;
