import React, { ComponentPropsWithoutRef, useMemo, useState } from "react";
import styled from "styled-components";
import { useWindowDimensions, WindowSize } from "../songtradr-components";
import Fallback from "./Fallback";
import { loadImage, createImageSrcSet} from "./helpers";
interface ISuspenseImage extends ComponentPropsWithoutRef<"img"> {
  dimensions?: Record<WindowSize, [number | string, number | string]>;
  useRaw?: boolean;
  useDiv?: boolean;
  fallbackImg?: string;
  shouldPreloadImage?:boolean;
}

const SuspenseImageWrapper = styled.div<{
  width?: number | string;
  height?: number | string;
}>`
  overflow: hidden;

  width: ${({ width }) => `${width}${typeof width === "number" ? "px" : ""}`};
  height: ${({ height }) =>
    `${height}${typeof height === "number" ? "px" : ""}`};

  img {
    vertical-align: unset;
    width: 100%;
    height: 100%;
  }
`;

const SuspenseImage = ({
  src,
  alt,
  dimensions,
  className,
  useRaw,
  useDiv,
  fallbackImg,
  shouldPreloadImage,
  ...rest
}: ISuspenseImage) => {
  const { size } = useWindowDimensions();
  const [width, height] = dimensions
    ? dimensions[size]
    : [rest.width, rest.height];

  const [useFallback, setUseFallback] = useState<boolean>(false);
  const url = `${src}&w=${width}&h=${height}&auto=format`;
  const imgSrc = useMemo(() => createImageSrcSet(url), [url]);
  
  if (shouldPreloadImage) { // if we show a loader we should preload the image 
    loadImage(url).read();
  }

  return (
    <SuspenseImageWrapper className={className} width={width} height={height}>
      {!useFallback ? (
        useDiv ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: `url("${useRaw ? src : url}") center top / cover no-repeat`,
            }}
          >
            <img
              style={{ display: "none" }}
              alt={alt}
              src={useRaw ? src : url}
              onError={() => setUseFallback(true)}
            />
          </div>
        ) : (
          <img
            {...rest}
            src={useRaw ? src : imgSrc.src}
            srcSet={useRaw ? undefined : imgSrc.srcset}
            alt={alt}
            width={width}
            height={height}
            onError={() => setUseFallback(true)}
          />
        )
      ) : fallbackImg !== undefined ? (
        useDiv ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: `url("${`${fallbackImg}?_v=0&w=${width}&h=${height}&auto=compress&fm=pjpg&fit=crop&crop=faces%2Centropy&dpr=1`}") center top / cover no-repeat`,
            }}
          ></div>
        ) : (
          <img
            src={`${fallbackImg}?_v=0&w=${width}&h=${height}&auto=compress&fm=pjpg&fit=crop&crop=faces%2Centropy&dpr=1`}
            alt="fallback-img"
            width={width}
            height={height}
          />
        )
      ) : (
        <Fallback width={width} height={height} />
      )}
    </SuspenseImageWrapper>
  );
};

export default SuspenseImage;
