import { useMediaTypeText } from "@app/utils";
import { Button, Typography } from "../songtradr-components";
import { Tooltip } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import useAddTombstoneModal from "../dialog-modal/useAddTombstoneModal";

// TYPES
interface ITombButtonProps {
  titleId?: string;
}

interface ITombButtonInnerProps {
  toggleModal: () => void;
}

// BUTTON COMPONENT
const TombButtonInner = ({ toggleModal }: ITombButtonInnerProps) => {
  const { pathname } = useLocation();
  const mediaTypeText = useMediaTypeText();

  return mediaTypeText === "episode" ? (
    <Tooltip
      // css={{ color: "var(--semantic-background-top)" }}
      placement="top"
      title={
        <div>
          <Typography
            style={{ color: "var(--semantic-background-top)" }}
            variant={"small"}
          >
            Click here if you didn't hear any music in this {mediaTypeText}.
          </Typography>
          <br />
          <br />
          <div>
            <Typography
              style={{ color: "var(--semantic-background-top)" }}
              variant={"xsmall"}
            >
              (excluding songs from the {pathname.split("/")[1]}'s official
              soundtrack)
            </Typography>
          </div>
        </div>
      }
    >
      <Button variant="subtle" onClick={toggleModal}>
        No songs in this {mediaTypeText}?
      </Button>
    </Tooltip>
  ) : null;
};

// EXPORTED HOOK
const useAddTombstoneButton = () => {
  const { AddTombstoneModal, toggleAddTombstoneModal } = useAddTombstoneModal();

  const AddTombstoneButton = ({ titleId, ...rest }: ITombButtonProps) => {
    return (
      <TombButtonInner
        {...rest}
        toggleModal={() => toggleAddTombstoneModal(titleId)}
      />
    );
  };

  return { AddTombstoneButton, AddTombstoneModal };
};

export default useAddTombstoneButton;
