import { IArtist } from "@app/api/types";
import React from "react";
import styled from "styled-components";

const CHAR_LIMIT = 280;

const Link = styled.img`
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
`;

const replaceAt = (artists: string, index: number) =>
  artists?.substring(0, index) + "...";

const useTwitter = () => {
  const handleClick = (
    title: string,
    artists?: IArtist[] | string,
    question?: string
  ) => {
    let text = "";
    let flattenedArtists = "";

    if (artists === undefined) {
      text = `&hashtags=Music ❓ from ${title}: ${question}`;
    } else {
      if (Array.isArray(artists)) {
        flattenedArtists = artists.map((artist) => artist.name).toString();
      } else flattenedArtists = artists;

      text = `Listen to ${title} by ${flattenedArtists} on Tunefind \n ${window.location.href}`;
    }

    if (text.length > CHAR_LIMIT) {
      const overLimit = text.length - CHAR_LIMIT;

      flattenedArtists = replaceAt(
        flattenedArtists,
        flattenedArtists.length - overLimit
      );
    }

    const twitterShareUrl = [
      "https://twitter.com/intent/tweet?text=",
      text,
      "&hashtags=Tunefind",
    ].join("");
    window.open(twitterShareUrl, "share_twitter", "width=550, height=300");
  };

  const TwitterShareButton = ({
    title,
    artists,
    question,
  }: {
    title: string;
    artists?: IArtist[] | string;
    question?: string;
  }) => (
    <Link
      src={
        "https://img-sys.songtradr.com/a5350fa2c6511c09b90d2d39eae4d36f439f4f27be526008184d7588e40bdf2c.svg"
      }
      onClick={() =>
        handleClick(title, artists ?? undefined, question ?? undefined)
      }
      alt="Twitter Icon"
    />
  );

  return TwitterShareButton;
};

export default useTwitter;
