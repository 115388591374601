import styled, { css } from "styled-components";

export const SliderWrapper = styled.div(({ offset }: { offset: number }) => {
  return css`
    margin: 0 0 0 ${offset / 16}rem;
    display: flex;
  `;
});

export const styles = {
  embla: css`
    overflow: hidden;
  `,
  emblaContainer: () => {
    return css`
      display: flex;
    `;
  },
  emblaSlide: css`
    flex: 0 0 100%;
    min-width: 0;
  `,
  emblaViewport: css`
    overflow: hidden;
  `,
  emblaLeftArrow: (offset: number) => {
    return css`
      position: absolute;
      cursor: pointer;
      left: calc((100vw) - ${offset / 16}rem - 4.5rem);
    `;
  },
  emblaRightArrow: (offset: number) => {
    return css`
      position: absolute;
      cursor: pointer;
      right: ${offset / 16}rem;
      transform: scaleX(-1);
    `;
  },
  emblaDots: css`
    display: flex;
    justify-content: center;
  `,
  headerCta: css`
    margin: 0 0 0.2rem 1rem;
  `,

  dot: (highlighted?: boolean) => {
    return css`
      height: 1rem;
      width: 1rem;
      background: ${highlighted ? "var(--cta-bg)" : "var(--input-border)"};
      border-radius: 100%;
      margin: 2rem 1rem 2rem 0;
      cursor: pointer;
    `;
  },
  arrowsContainer: css`
    margin-bottom: 0.5rem;
  `,
};
