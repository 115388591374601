import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useRef } from "react";
import { HelmetProvider } from "react-helmet-async";
// import Analytics from "@songtradr/analytics";
import { AppFooter, AppHeader } from "@app/components";
import AppRoutes from "@app/routing/appRoutes";
import { GlobalStyles } from "@app/styles";
import { WindowDimensionsProvider } from "./components/songtradr-components/utils";
import { TunefindTheme } from "./styles";
// @ts-expect-error because
import { DFPSlotsProvider } from "react-dfp";
import { uuid } from "./components/songtradr-components/utils/";
import Analytics from "@songtradr/analytics";
import "./components/songtradr-components/styles/base-css/base.css"
import "antd/dist/antd.min.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AudioProvider } from "./context/AudioContext";
import { useUserContext } from "./userContext";
import { checkIfShouldDisplayAds } from "./utils/helpers/shouldDisplayAds";
import { isNullOrEmptyString } from "./utils";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";
import OutOfTune from "./pages/errors/OutOfTune";
import { checkIfPageShouldHavePreloadedImage } from "./utils/helpers/checkIfPageShouldHavePreloadedImage";
import { fetchLandingPreloadMedia } from "./api";

function App() {
  //TODO - remove this login stuff -- just for prototype functionality for now
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  //AUTH
  const { checkLoadUserAccount, fetchAndSetUserContextTokens } = useUserContext();
  const preloadedImageRef = useRef(document.getElementById('preload-wrapper'));
  
  const getLandingPreloadMedia = async () => {
    try {
      const res = await fetchLandingPreloadMedia();
      (window as any).heroPreloads = res.data;
    } catch (error) {
      (window as any).heroPreloads = null;
    }
   
  }
  const generateRandomID = () => {
    return uuid().toString();
  };

  const getRandomIDFromLocalStorage = () => {
    let visitorID = localStorage.getItem("visitorID");
    if (!visitorID) {
      visitorID = generateRandomID();
      localStorage.setItem("visitorID", visitorID);
    }
    return visitorID;
  };

  useEffect(() => {
    const visitorID = getRandomIDFromLocalStorage();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "AppInitialization",
      visitorID: visitorID,
    });
     
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const generateRandomID = () => {
  //   return Math.floor(Math.random() * 1000000).toString(); // Generates a random number between 0 and 999999
  // };

  // useEffect(() => {
  //   const randomID = generateRandomID();

  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: "AppInitialization",
  //     randomID: randomID,
  //   });
  // }, []);

  useEffect(() => {
    //LOAD USER IF HAS REFRESH TOKEN
    checkLoadUserAccount();
   
  }, [checkLoadUserAccount]);

  // PAGE TRACKING
  useEffect(() => {
    //UPDATE AND CHECK FOR STONLY
    if (pathname === "/forward") {
      const query = searchParams.get("url");
      if (query !== null) window.location.href = query;
    }

    if (!(window as any).heroPreloads) {
      getLandingPreloadMedia();
    }

    if (preloadedImageRef.current && !checkIfPageShouldHavePreloadedImage(pathname)) {
      preloadedImageRef.current.style.display = 'none';
    }
    // else {
    //   let path = pathname;
    //   if (pathname === "/trending") {
    //     path = "/popular/new";
    //   }


    if(checkIfShouldDisplayAds()) {
      try {
        FreestarAdSlot.trackPageview();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("freestar page tracking error: ", err);
      }
    }

    //PAUSE AUDIO
  }, [pathname, searchParams]);

  //AD REFRESH
  // React.useState(() => {
  //   const interval = setInterval(() => {
  //     DFPManager.refresh();
  //   }, 30000);
  //   return () => clearInterval(interval);
  //   // @ts-ignore
  // }, []);

  //RENDER

  useEffect(() => {
    if (pathname === "/auth/grant") {
      const code = searchParams.get("code");
      if (!isNullOrEmptyString(code)) {
        code && fetchAndSetUserContextTokens(code);
        navigate('/');
      }
    }
  }, [fetchAndSetUserContextTokens, navigate, pathname, searchParams])
  
  useEffect(() => {
    if ((window as any).amplified && !(window as any).amplified.isInitialized) {
      const initAmplified = () => {
        (window as any).amplified.init.forEach((fn: Function) => fn());
        (window as any).amplified.isInitialized = true;
      };

      if (document.readyState === "complete") {
        initAmplified();
      }
    }
  }, []);

  return (
    <HelmetProvider>
      <WindowDimensionsProvider>
        <DFPSlotsProvider
          lazyLoad={{
            fetchMarginPercent: 500,
            renderMarginPercent: 200,
            mobileScaling: 2.0,
          }}
          singleRequest={true}
          dfpNetworkId="31169121"
        >
          <Analytics
            google={{ analytics: "G-29HMH9CPKL", tagManager: "GTM-TZHGSJC" }}
          />
          <AudioProvider>
            <ErrorBoundary fallback={<OutOfTune />}>
              <GlobalStyles />
              <TunefindTheme theme="dark" />
              <AppHeader />
              {/* <AdSlot
                adUnit={"TuneFind_1x1"}
                slotId={"TuneFind_1x1"}
                sizes={[[1, 1]]}
              /> */}
              <AppRoutes />
              <AppFooter />
              {/* <CookieModal /> */}
            </ErrorBoundary>
          </AudioProvider>
        </DFPSlotsProvider>
      </WindowDimensionsProvider>
    </HelmetProvider>
  );
}

export default App;
