import React, { forwardRef } from "react";
import "styled-components/macro";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

interface LinkProps {
  to?: RouterLinkProps["to"];
  target?: RouterLinkProps["target"];
  disabled?: boolean;
}

const Link = forwardRef<
  HTMLAnchorElement,
  LinkProps & Omit<RouterLinkProps, "to">
>(
  (
    { to = "#", target = "_self", disabled = false, children, ...rest },
    ref
  ) => {
    const props = {
      ...rest,
      ref,
      disabled,
      onClick: (e: any) => {
        disabled && e.preventDefault();
        rest.onClick && rest.onClick(e);
      },
    };

    switch (target) {
      case "_self":
        return (
          <RouterLink {...props} to={to as string}>
            {children}
          </RouterLink>
        );
      case "_blank":
        return (
          <a
            {...props}
            href={to as string}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            {children}
          </a>
        );
      default:
        return null;
    }
  }
);

export default Link;
