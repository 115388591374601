import styled, { css } from "styled-components";

export const StyledListItem = styled.li(() => {
  return css`
    li {
      margin-top: 1rem;
    }
  `;
});

export const StyledTable = styled.table(() => {
  return css`
    tr {
      display: flex;
    }

    th {
      text-align: start;
    }

    th,
    td {
      flex: 1;
      padding: 2rem 3rem;
      border: var(--input-border) 1px solid;
    }
  `;
});
