import React, { useEffect, useRef, useState } from "react";
import "styled-components/macro";
import { Col, Row, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { IAddNewSong, ISong } from "@app/api/types";
import Link from "../link";
import { StyledCircle, styles } from "./styles";
import {
  useAudioPlaying,
  useAudioTag,
  useUpdateAudioTag,
  useAudioDuration,
} from "@app/context/AudioContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  scrollIntoViewWithOffset,
  useForwardUrl,
} from "@app/utils";
import {
  IconButton,
  Typography,
  useWindowDimensions,
} from "../songtradr-components";
import { formatDurationFromMs } from "@app/utils/helpers/formatDurationFromMs";
import UnplayableIcon from "../unplayable-icon/UnplayableIcon";
const playIcon =
  "https://img-sys.songtradr.com/c444fc399070429f337a9dec3a70423d100ce849c29726329da11839e7873c5d.svg";
const pauseIcon =
  "https://img-sys.songtradr.com/02a064a84cb156df7f60405feb69769a003df98be0796270ea6afa1609ef6178.svg";

interface ISongPreviewPlayerProps {
  id?: ISong["id"];
  name: ISong["name"];
  album?: ISong["album"];
  artists?: ISong["artists"] | IAddNewSong["artists"];
  audioPreview?: ISong["audioPreview"];
  playerTitleAligned?: boolean;
  handleOpenDeleteModal?: Function;
  canDelete?: boolean;
  image?: string;
  durationMs?: number;
  isrc?: string;
  userIsAdmin?: boolean;
}

const SongPreviewPlayer = ({
  id,
  name,
  album,
  artists,
  audioPreview,
  playerTitleAligned,
  handleOpenDeleteModal,
  canDelete,
  image,
  durationMs,
  isrc,
  userIsAdmin,
  ...rest
}: ISongPreviewPlayerProps) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const preparedAudioPreview = useForwardUrl(audioPreview);

  const clickedPlay = () => {
    if (!isPlaying) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "PlaySong",
        songId: id,
        songName: name,
        songArtist: artists,
        // You can also add user.username to the dataLayer push if needed
      });
    }
  };

  // CONTEXT HOOKS
  const toggleAudio = useUpdateAudioTag();
  const audioState = useAudioPlaying();
  const contextAudioTag = useAudioTag();
  const trackProgress = useAudioDuration();

  // STATE & REFS
  const componentRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // EFFECT - Listen for songId in url and scroll to this player if matches
  useEffect(() => {
    const parsedHash = Number(hash.replace("#", ""));
    if (id === parsedHash) {
      scrollIntoViewWithOffset(componentRef, -32);
      navigate(`#`, { replace: true });
    }
  }, [id, hash, navigate]);

  // EFFECT - IF audio is playing, stop the audio context when the user navigates to a new page
  useEffect(() => {
    return () => {
      setIsPlaying(false);
      toggleAudio(undefined);
    };
    // eslint-disable-next-line
  }, [pathname]);

  // EFFECT - Change the pause/play button accordingly on click
  useEffect(() => {
    contextAudioTag === preparedAudioPreview && setIsPlaying(!isPlaying);
    // eslint-disable-next-line
  }, [audioState]);

  // EFFECT - Change the appropriate pause/play buttons when clicking on a new track during playback
  useEffect(() => {
    isPlaying && setIsPlaying(false);
    contextAudioTag === preparedAudioPreview && setIsPlaying(true);
    // eslint-disable-next-line
  }, [contextAudioTag]);

  const songDuration = (circleDimensions: number, progressDecimal: number) => {
    const strokeWidth = 2;
    const radius = circleDimensions / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - progressDecimal);

    return (
      <>
        <Tooltip
          placement={isMobile ? "topLeft" : "top"}
          title={
            <Typography
              variant="xsmall"
              css={{ color: "var(--semantic-background-top)" }}
            >
              {preparedAudioPreview !== undefined
                ? "Play Preview"
                : "Preview Not Available"}
            </Typography>
          }
        >
          <div
            onClick={() => {
              toggleAudio(preparedAudioPreview ?? "");
              clickedPlay();
            }}
            css={styles.playPauseButton(
              circleDimensions,
              preparedAudioPreview !== undefined,
              playerTitleAligned
            )}
          />
        </Tooltip>
        <svg
          width={circleDimensions}
          height={circleDimensions}
          viewBox={`0 0 ${circleDimensions.toString()} ${circleDimensions.toString()}`}
          css={styles.circleContainer(playerTitleAligned)}
        >
          <circle
            css={styles.backgroundCircle}
            cx={circleDimensions / 2}
            cy={circleDimensions / 2}
            r={radius}
          />
        </svg>
        {contextAudioTag === preparedAudioPreview && trackProgress > 0 && (
          <svg
            width={circleDimensions}
            height={circleDimensions}
            viewBox={`0 0 ${circleDimensions.toString()} ${circleDimensions.toString()}`}
            css={styles.circleContainer(playerTitleAligned)}
          >
            <StyledCircle
              strokeWidth={strokeWidth}
              circumference={circumference}
              offset={offset}
              cx={circleDimensions / 2}
              cy={circleDimensions / 2}
              r={radius}
            />
          </svg>
        )}
      </>
    );
  };

  const audioPlayer = () => {
    return (
      <>
        <Row
          align={playerTitleAligned ? "top" : "middle"}
          justify="center"
          css={styles.playerContainer(playerTitleAligned)}
        >
          {preparedAudioPreview ? (
            <img
              css={styles.playIcon(isPlaying)}
              src={isPlaying ? pauseIcon : playIcon}
              alt="Play/Pause Button"
            />
          ) : (
            <UnplayableIcon isMobile={isMobile} />
          )}
          {songDuration(
            40,
            trackProgress !== 0 ? progressCalculator(trackProgress) : 0
          )}
        </Row>
      </>
    );
  };

  const progressCalculator = (progress: number) => progress / 30;

  return (
    <Row {...rest} ref={componentRef} wrap={false} css={styles.songPreviewRow}>
      {image && <img src={image} alt={name} css={styles.previewImage} />}
      {audioPlayer()}
      <Col>
        <Row>
          <Typography
            css={styles.songNameLink}
            variant={"bodyBold"}
            margin={false}
            onClick={() => {
              toggleAudio(preparedAudioPreview ?? "");
            }}
          >
            {name}
          </Typography>
        </Row>
        <Row>{album && <Typography variant="small">{album}</Typography>}</Row>
        <Row css={styles.artistRow}>
          {artists &&
            artists.map((m, i, arr) => (
              <Link key={m.name} to={m.slug ? `../artist/${m.slug}`: undefined}>
                <Typography variant="small">
                  {m.name}
                  {arr.length > 1 && i !== arr.length - 1 ? ", " : ""}
                  {i + 1 === arr.length &&
                    handleOpenDeleteModal &&
                    canDelete && (
                      <Tooltip
                        css={{ color: "var(--semantic-background-top)" }}
                        placement="top"
                        title="Delete Appearance"
                      >
                        <IconButton
                          css={styles.deleteSongIcon}
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenDeleteModal();
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </Tooltip>
                    )}
                </Typography>
              </Link>
            ))}
        </Row>
        {durationMs !== undefined && durationMs > 0 && (
          <Row css={styles.songMetadataRow}>
            <Typography variant="small" >
              {formatDurationFromMs(durationMs)}
            </Typography> 
          </Row>
         )}
        
        {userIsAdmin && isrc && (
          <Row css={styles.songMetadataRow}>
            <Typography variant="small">
              {isrc}
            </Typography>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SongPreviewPlayer;
