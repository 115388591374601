import React from "react";
import "styled-components/macro";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { HeroSliderWrapper, styles, TextContainer } from "./styles";
import {
  IHeroSchema,
  IHeroSliderSchema,
} from "@app/sanity-generated-data/types";
import { IMedia } from "@app/api/types";
import { SanityButton } from "@app/sanity-components";
import Link from "@app/components/link";
import SuspenseImage from "@app/components/suspense-image";
import { isNullOrEmptyString } from "@app/utils";
import { Typography } from "../songtradr-components";
import WaitSpin from "../wait-spin";

interface IHeroSliderProps {
  sliderSchema?: IHeroSliderSchema;
  heroSchema?: IHeroSchema;
  loading: boolean;
  data?: IMedia[];
  reduceCtaToButton?: boolean;
  shouldPreloadImage?: boolean;
}

const Loading = () => <WaitSpin dark />;

const SlickArrowLeft = ({ ...reduced }: any) => (
  <LeftOutlined {...reduced} />
);

const SlickArrowRight = ({ ...reduced }: any) => (
  <RightOutlined {...reduced} />
);

const HeroSlider = ({
  sliderSchema,
  heroSchema,
  loading,
  data,
  reduceCtaToButton,
  shouldPreloadImage,
}: IHeroSliderProps) => {
  
  //EITHER OR
  const schema: IHeroSliderSchema =
    sliderSchema ??
    ({
      _type: "hero-slider",
      slugs: [],
      prefix: {
        text: "            ",
        variant: "body",
      },
      title: { variant: heroSchema?.header.variant },
      description: { variant: heroSchema?.description.variant },
      cta: {
        ...heroSchema?.cta,
      },
      apiKey: "" as any,
    } as IHeroSliderSchema);
  const { prefix } = schema;
  
  //SLIDE DATA
  const slideData: IMedia[] =  data ?? [
    {
      title: heroSchema?.header.text,
      description: heroSchema?.description.text,
      id: "",
      imageUrl: heroSchema?.backgroundImg?.src ?? '',
      slug: "",
    } as IMedia,
  ] ??
  [];
  //RENDER SLIDER
  const renderSlideContent = ({ imageUrl, title, description }: IMedia) => (
    <span key={`slide-content-${imageUrl}`}>
      {imageUrl ?
      <React.Fragment>
         <div css={styles.slideGradient} /> 
      <SuspenseImage
        css={styles.imageStyle}
        src={imageUrl}
        useDiv
        dimensions={{
          small: [500, 360],
          medium: [500, 360],
          large: [1920, 540],
          xlarge: [1920, 540],
        }}
        alt={title}
        shouldPreloadImage={shouldPreloadImage}
      />
      </React.Fragment>
      : <></>
      }
      
      <TextContainer>
        {(prefix && !isNullOrEmptyString(title)) && (
          <Typography
            css={styles.featured}
            variant={prefix.variant}
            margin={false}
            truncate
          >
            {prefix.text}&nbsp;
          </Typography>
        )}
        <Typography css={styles.titlesoundtrack} variant={schema.title.variant}>
          {title}
        </Typography>
        {!isNullOrEmptyString(description) && (
          <Typography variant={schema.description.variant}>
            {description}
          </Typography>
        )}

        {schema.cta && !isNullOrEmptyString(schema.cta.text) && !isNullOrEmptyString(title) && (
          <SanityButton schema={schema.cta} />
        )}
      </TextContainer>
    </span>
  );

  const renderSlide = (item: IMedia) =>
    reduceCtaToButton ? (
      <div key={item.id} css={styles.slideStyle(false)}>
        {renderSlideContent(item)}
      </div>
    ) : (
      <Link key={item.id} css={styles.slideStyle(true)} to={`${item.slug ? `../${item.slug}` : ""}`}>
        {renderSlideContent(item)}
      </Link>
    );

  return (
    <HeroSliderWrapper>
      {
      loading ? (
          <Loading />
        ) : (
          <Carousel
            css={styles.carousel}
            dots
            dotPosition="bottom"
            arrows
            nextArrow={<SlickArrowRight />}
            prevArrow={<SlickArrowLeft />}
            autoplay={slideData.length > 1}
          >
            {slideData?.map((item) => renderSlide(item))}
          </Carousel>
        )}
    </HeroSliderWrapper>
  );
};

export default HeroSlider;
