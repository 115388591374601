import { ISong, ISongAppearance } from "@app/api/types";
import { ShowMore, SongPreviewPlayer, StoreLinks, SuspenseImage } from "@app/components";
import ShareButton from "@app/components/buttons/ShareButton";
import { Row, Col, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { Typography, useWindowDimensions } from "@app/components/songtradr-components";
import { useNavigate } from "react-router-dom";
import "./styles.css"
import { useContentMargins } from "@app/styles/helpers";

export const Credits = ({ creditsSchema, credits, expandable, name  }: { creditsSchema: any, credits: any, expandable: boolean | undefined, name: string }) => {
    const [expanded, setExpanded] = useState(false);
    const { size } = useWindowDimensions();
    const isMobile = size === "small" || size === "medium";
    const navigate = useNavigate();
    const filteredCredits = expanded ? credits : credits.slice(0, 30);
    const { margin } = useContentMargins();

    const mappedCredits = useMemo(() => {
        const mobileCredits = (credit: ISong) => (
          <>
            <Row align={"middle"}>
              <Col>
                <SongPreviewPlayer
                  playerTitleAligned={true}
                  name={credit.name}
                  album={credit.album}
                  audioPreview={credit.audioPreview}
                />
              </Col>
            </Row>
            <Row
              className="appearance-information-mobile"
              justify="space-between"
              align={"middle"}
            >
              <Col span={isMobile ? 21 : undefined}>
                <StoreLinks links={credit.storeLinks}  songId={credit.appearanceId}/>
              </Col>
              <Col sm={3} xs={2}>
                <ShareButton title={credit.name} artists={name} />
              </Col>
            </Row>
          </>
        );
    
        const desktopAppearances = (appearance: ISongAppearance, index:number) => (
          <Col sm={24} md={24} xl={10} key={`${index}-${appearance.id}`}>
            <Row
              onClick={() => navigate(appearance.appearanceLink)}
              wrap={false}
              align="middle"
              justify="space-between"
              className="appearance"
            >
              <Col>
                <Typography margin={false} variant="bodyBold">
                  {appearance.title}
                </Typography>
                <Typography margin={false} variant="body">
                  {appearance.description}
                </Typography>
                <Typography margin={false} variant="body">
                  {appearance.airDate}
                </Typography>
              </Col>
              <Col>
                <Tooltip
                  title={
                    <Typography
                      variant="xsmall"
                      css={{ color: "var(--semantic-background-top)" }}
                    >
                      {appearance.confirmation ? "Confirmed" : "Under Review"}
                    </Typography>
                  }
                >
                  <img
                    src={
                      appearance.confirmation
                        ? "https://img-sys.songtradr.com/45ce6a21b1317000bb36d8bf989203ce54c01315820989c604d9434f3506b6a7.svg"
                        : "https://img-sys.songtradr.com/49f8ddf01768cfa11f88bcb73ca9e06a4242156740b8875ba1625f5c3ecef919.svg"
                    }
                    alt="Confirmation Icon"
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        );
    
        const desktopCredits = (credit: ISong) => (
          <Row
            gutter={[0, 24]}
            className="credit-item-song"
            justify="space-between"
            align={"middle"}
          >
            <Col>
              <Row gutter={24} align="middle" wrap={false}>
                <Col>
                  <SuspenseImage
                    src={credit.imageUrl}
                    useRaw={true}
                    alt={credit.name}
                    height={128}
                    width={128}
                    fallbackImg="https://www.tunefind.com/i/album-art-empty.png"
                  />
                </Col>
                <Col>
                  <SongPreviewPlayer
                    playerTitleAligned={true}
                    name={credit.name}
                    album={credit.album}
                    audioPreview={credit.audioPreview}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="right-credits-justify" flex={1}>
              <StoreLinks links={credit.storeLinks} songId={credit.appearanceId}/>
              <ShareButton title={credit.name} artists={name} />
            </Col>
          </Row>
        );
    
        const mobileApperances = (appearance: ISongAppearance, index: number) => (
          <div key={`${index}-${appearance.id}`} onClick={() => navigate(appearance.appearanceLink)}>
            <Col className="mobile-appearance" sm={20} xs={18}>
              <Typography margin={false} variant="bodyBold">
                {appearance.title}
              </Typography>
              <Typography margin={false} variant="body">
                {appearance.description}
              </Typography>
              <Typography margin={false} variant="body">
                {appearance.airDate}
              </Typography>
            </Col>
            <Col sm={3} xs={2}>
              <img
                src={
                  appearance.confirmation
                    ? "https://img-sys.songtradr.com/45ce6a21b1317000bb36d8bf989203ce54c01315820989c604d9434f3506b6a7.svg"
                    : "https://img-sys.songtradr.com/49f8ddf01768cfa11f88bcb73ca9e06a4242156740b8875ba1625f5c3ecef919.svg"
                }
                alt="Confirmation Icon"
              />
            </Col>
          </div>
        );
    
        return filteredCredits.map((credit: any) => {
          return (
            <div className="credit-item" key={credit.id}>
              {isMobile ? mobileCredits(credit) : desktopCredits(credit)}
              <Row
                className="styled-card-border"
                style={{"--margin": margin} as React.CSSProperties}
                gutter={[0, 32]}
                justify="space-between"
                align={"middle"}
              >
                {credit.appearances?.map((appearance: any, index: number) =>
                  isMobile
                    ? mobileApperances(appearance, index)
                    : desktopAppearances(appearance, index)
                )}
              </Row>
            </div>
          );
        });
      }, [filteredCredits, isMobile, margin, name, navigate]);

    // RENDER
    return (
        <>
        <Typography variant={creditsSchema.header.variant}>
            {creditsSchema.header.text}
        </Typography>
        { mappedCredits }
        {expandable && <ShowMore expanded={expanded} setExpanded={setExpanded} moreLabel="Show all credits"/>}

        </>
  )
  
};

export default Credits;
