import { faInfo } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface IUnplayableIconProps {
    isMobile: boolean
}
const UnplayableIcon = ({ isMobile }: IUnplayableIconProps ) => {
    return (
        <FontAwesomeIcon icon={faInfo} size="lg" style={{color: "white", zIndex: 1, position: "relative", right: isMobile ? "1px" : "0px", bottom: "3px", opacity: "0.50"}}/>
    )
}

export default UnplayableIcon