import { useEffect, useState } from "react";

let globalMounted = false;

export const useMounted = () => {
  const [mounted, setMounted] = useState(globalMounted);
  useEffect(() => {
    globalMounted = true;
    setMounted(true);
  }, []);

  return mounted;
};
