import { DataReducerAction, eActionType, IMediaDetailsPageData } from "./types";

// REDUCER
export function dataReducer(
  prevState: IMediaDetailsPageData,
  action: DataReducerAction
) {
  switch (action.type) {
    case eActionType.MERGE_ALL:
      return { ...prevState, ...action.data };

    case eActionType.REPLACE_ALL:
      return action.data;

    case eActionType.TOGGLE_FOLLOW:
      return { ...prevState, isFollowing: !prevState.isFollowing };

    default:
      throw new Error("Invalid reducer action!");
  }
}
