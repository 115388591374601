import React, { useRef, useEffect, useCallback } from "react";
import "styled-components/macro";
import { Col, Row, Tooltip } from "antd";
import { Typography, useWindowDimensions } from "../songtradr-components";
import {
  StoreLinks,
  SongPreviewPlayer,
  useVoteButton,
  FavoriteButton,
} from "@app/components";
import { TableProps } from "./SongTable";
import { styles } from "./styles";
import ShareButton from "../buttons/ShareButton";
import { useMediaTypeText } from "@app/utils";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

export const TableContent = ({
  handleOpenDescModal,
  handleDragEnd,
  handleRowClick,
  tableData,
  expanded,
  locked,
  nudge,
  isLoggedIn,
  handleOpenDeleteModal,
}: TableProps) => {
  const { size } = useWindowDimensions();
  const { songId } = useParams();
  const formattedSongIdFromUrl = songId?.split('-')[1];
  const songWithScrollRef = useRef<any>(null);

  const isMobile = size === "small" || size === "medium";

  const mediaText = useMediaTypeText();
  const { VoteButton, VoteModal } = useVoteButton("appearance");
  const filteredTableData = expanded ? tableData : tableData.slice(0, 10);

  useEffect(() => {
    if (songWithScrollRef.current) {
      songWithScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  const getContent = useCallback(() => {
    return isMobile ? 
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {filteredTableData.map((row, i) => (
                <Draggable
                  key={row.id}
                  draggableId={row.id.toString()}
                  index={i}
                >
                  {(provided) => (
                    <div
                      css={styles.mobileRow}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Row justify="space-between" wrap={false}>
                        <Col>
                          <Row
                            wrap={false}
                            justify="space-between"
                            align="top"
                            css={styles.playerMargins}
                          >
                            <Col>
                              <SongPreviewPlayer
                                id={row.id}
                                name={row.name}
                                artists={row.artists}
                                audioPreview={row.audioPreview}
                                playerTitleAligned={true}
                                handleOpenDeleteModal={() =>
                                  handleOpenDeleteModal(row.appearanceId)
                                }
                                canDelete={row.canDelete}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col css={styles.playerMargin}>
                              <Row wrap={false}>
                                <Typography
                                  // css={styles.twoLineEllipsis}
                                  variant="small"
                                  margin={false}
                                  className="block"
                                >
                                  {row.description ?? (
                                    <em>Add scene description</em>
                                  )}
                                  {!locked && (
                                    <img
                                      css={styles.editButton}
                                      onClick={() => handleOpenDescModal(row)}
                                      src="https://img-sys.songtradr.com/9ef4c28fbb596a723a77d86eaa232cdcc977e530b1f9aad9e7ab4431f9736b11.svg"
                                      alt="Edit Icon"
                                    />
                                  )}
                                </Typography>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        {!locked && isLoggedIn && (
                          <Col
                            css={styles.topRowPadding}
                            {...provided.dragHandleProps}
                          >
                            <img
                              css={styles.hasClickAction}
                              src="https://img-sys.songtradr.com/7b51fa9a850dd281e8da0287a7293f6d187d93e7f00d56ec84e8db7f49af5564.svg"
                              alt="Drag Icon"
                            />
                          </Col>
                        )}
                      </Row>
                      <Row
                        css={styles.mobileBottomRow}
                        wrap={false}
                        align="middle"
                        justify="space-between"
                      >
                        <Col>
                          <StoreLinks links={row.storeLinks} songId={row.appearanceId}/>
                        </Col>
                        <Col>
                          <Row
                            css={styles.bottomRightRow}
                            wrap={false}
                            justify="space-between"
                          >
                            <Row align="middle">
                              <FavoriteButton
                                nudge={nudge}
                                onSuccess={handleRowClick}
                                target={row}
                              />
                            </Row>
                            <ShareButton
                              artists={row.artists}
                              title={row.name}
                            />
                            <Col>
                              <VoteButton target={row} small />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
    :
    <>
    <Row css={styles.separator} wrap={false} justify="end">
      <Col css={styles.playerMargin} span={7}>
        <Typography variant="xsmall">Track</Typography>
      </Col>
      <Col span={7}>
        <Typography variant="xsmall">Description</Typography>
      </Col>
      <Col span={10}>
        <Row justify="space-between" align="middle" wrap={false}>
          <Col xl={9} lg={12} md={12} sm={12}>
            <Typography variant="xsmall">Listen on</Typography>{" "}
          </Col>
          <Col css={styles.songtradrLeftMargin} span={2}>
            <Typography css={styles.licenseOnText} variant="xsmall">
              License on
            </Typography>
          </Col>
          <Col css={styles.topRowPadding} xl={13} lg={10} md={10} sm={10}>
            <Row align="middle" wrap={false}>
              <Col span={6} />
              <Col span={6} />
              <Col span={6}>
                <Typography css={styles.confirmedText} variant="xsmall">
                  Confirmed?
                </Typography>{" "}
              </Col>
              <Col span={6} />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="table-body">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {filteredTableData.map((row, i) => {
              return (
                <Draggable
                  key={row.id}
                  draggableId={row.id.toString()}
                  index={i}
                >
                  {(provided) => {
                    return (
                      <Row
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        wrap={false}
                        justify="start"
                        css={styles.desktopRow}
                      >
                        <Col css={styles.colSpacing} span={7} ref={parseInt(formattedSongIdFromUrl ?? '-1') === row.appearanceId ? songWithScrollRef : null}>
                          <SongPreviewPlayer
                            id={row.id}
                            name={row.name}
                            artists={row.artists}
                            audioPreview={row.audioPreview}
                            playerTitleAligned={true}
                            handleOpenDeleteModal={() =>
                              handleOpenDeleteModal(row.appearanceId)
                            }
                            canDelete={row.canDelete}
                          />
                        </Col>
                        <Col css={styles.colSpacing} span={7}>
                          <Row wrap={false}>
                            <Typography variant="small" margin={false}>
                              {row.description ?? (
                                <em>Add scene description</em>
                              )}
                              <Tooltip
                                placement="top"
                                title={
                                  <Typography
                                    variant="xsmall"
                                    css={{ color: "var(--semantic-background-top)" }}
                                  >
                                    {locked
                                      ? `Can't edit scene descriptions for ${mediaText}s verified by music supervisors`
                                      : "Edit Description"}
                                  </Typography>
                                }
                              >
                                <img
                                  css={styles.descEditIcon(locked)}
                                  onClick={() =>
                                    !locked && handleOpenDescModal(row)
                                  }
                                  src="https://img-sys.songtradr.com/9ef4c28fbb596a723a77d86eaa232cdcc977e530b1f9aad9e7ab4431f9736b11.svg"
                                  alt="Edit Icon"
                                />
                              </Tooltip>
                            </Typography>
                          </Row>
                        </Col>
                        <Col span={10}>
                          <Row
                            justify="space-between"
                            align="middle"
                            wrap={false}
                          >
                            <Col xl={9} lg={12} md={12} sm={12}>
                              <StoreLinks
                                songId={row.appearanceId}
                                links={row.storeLinks}
                                type="excludeSongtradr"
                              />
                            </Col>
                            <Col span={2} css={styles.songtradrLeftMargin}>
                              <StoreLinks
                                songId={row.appearanceId}
                                links={row.storeLinks}
                                type="onlySongtradr"
                              />
                            </Col>
                            <Col
                              css={styles.topRowPadding}
                              xl={13}
                              lg={10}
                              md={10}
                              sm={10}
                            >
                              <Row align="middle" wrap={false}>
                                <Col span={6}>
                                  <FavoriteButton
                                    nudge={nudge}
                                    onSuccess={handleRowClick}
                                    target={row}
                                  />
                                </Col>
                                <Col span={6}>
                                  <ShareButton
                                    artists={row.artists}
                                    title={row.name}
                                  />
                                </Col>
                                <Col span={6}>
                                  <VoteButton target={row} small />
                                </Col>
                                <Col span={6} {...provided.dragHandleProps}>
                                  {!locked && isLoggedIn ? (
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <Typography
                                          variant="xsmall"
                                          css={{ color: "var(--semantic-background-top)" }}
                                          >
                                          Drag to adjust order
                                        </Typography>
                                      }
                                    >
                                      <img
                                        css={styles.hasClickAction}
                                        src="https://img-sys.songtradr.com/7b51fa9a850dd281e8da0287a7293f6d187d93e7f00d56ec84e8db7f49af5564.svg"
                                        alt="Drag Icon"
                                      />
                                    </Tooltip>
                                  ) : (
                                    <div css={styles.dragSpacer} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </>
  }, [VoteButton, filteredTableData, formattedSongIdFromUrl, handleDragEnd, handleOpenDeleteModal, handleOpenDescModal, handleRowClick, isLoggedIn, isMobile, locked, mediaText, nudge])
  // DESKTOP RENDER

  // RENDER
  return (
    <>
      {getContent()}
      {VoteModal}
    </>
  );
};

export default TableContent;
