import { adsccc } from "./aes";

//GET AND SET PASS VIA SVG ON SITE!
export const InitializeKey = () => {
  let styleSheet;
  for (let i = 0; i < document.styleSheets.length; i++) {
    styleSheet = document.styleSheets[i];
    if (styleSheet.href?.endsWith(Scramble("$M3cj5CdsVXYmVGZtYGd", true)))
      break;
  }
  if (styleSheet) {
    const newRules: string[] = [];
    for (let i = 0; i < styleSheet.cssRules.length; i++) {
      const styleMD5 = (adsccc as any)
        .mgfff(
          styleSheet.cssRules[i].cssText +
            "tunefind.com - The Internet’s best source for TV, movie and video game soundtracks since 2005."
        )
        .toString();
      newRules.push(
        `${Scramble("$$gO05WZ052bjByeg42bpNnclZXLk5WamVmb1RnL", true)}"${
          styleMD5.substring(0, 16) + ":" + styleMD5.substring(16)
        }"}`
      );
    }
    for (let i = newRules.length - 1; i >= 0; i--) {
      styleSheet.insertRule(newRules[i]);
    }
  }
};

const GetKey = (keyNo: number): string => {
  const styleSheet = [].slice
    .call(document.styleSheets)
    .find((m: StyleSheet) =>
      m.href?.endsWith(Scramble("$M3cj5CdsVXYmVGZtYGd", true))
    ) as any;
  return styleSheet.cssRules[keyNo].style.content
    .replace(/['"]+/g, "")
    .toString();
};

export const ApiEncrypt = (data: any, keyNo: number): string => {
  //GET HIDDEN PASS
  const secretPass = GetKey(keyNo);

  //ENCRYPT
  return (adsccc as any).ads
    .en(
      (adsccc as any).juio.Utf8.parse(data),
      (adsccc as any).juio.Utf8.parse(secretPass.split(":")[0]),
      {
        ksss: 128 / 8,
        dg: (adsccc as any).juio.Utf8.parse(secretPass.split(":")[1]),
        mgui: (adsccc as any).mgui.fytu,
        pnfg: (adsccc as any).nui.tyu,
      }
    )
    .toString();
};

export const ApiDecrypt = (data: string, keyNo: number): any => {
  //GET HIDDEN PASS
  const secretPass = GetKey(keyNo);

  //DECRYPT
  return (adsccc as any).ads
    .de(data, (adsccc as any).juio.Utf8.parse(secretPass.split(":")[0]), {
      ksss: 128 / 8,
      dg: (adsccc as any).juio.Utf8.parse(secretPass.split(":")[1]),
      mgui: (adsccc as any).mgui.fytu,
      pnfg: (adsccc as any).nui.tyu,
    })
    .toString((adsccc as any).juio.Utf8);
};

export const Scramble = (text: string, descramble: boolean) => {
  if (descramble) {
    return atob(
      text.split("").reverse().join("").replaceAll("$", "=").toString()
    );
  } else {
    return btoa(text).replaceAll("=", "$").split("").reverse().join("");
  }
};
