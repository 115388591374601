import React from "react";

import ScrollToTop from "@app/routing/ScrollToTop";
import { ErrorPageWrapper } from "./styles";
import { Typography } from "../../components/songtradr-components";
import { LinkContactUs } from "./styles";

const OutOfTune = () => {

  return (
    <ErrorPageWrapper align="middle">
      <ScrollToTop />
      <Typography variant="h1">Out of tune!</Typography>
      <Typography variant="body">
        Sorry, there was an error loading this page. 
      </Typography>
      <Typography variant="small">
        Try to refresh this page or feel free to <LinkContactUs target='_blank' to={"//support.tunefind.com/hc/en-us/requests/new"}>contact us</LinkContactUs> if the problem persists.
      </Typography>
    </ErrorPageWrapper>
  );
};

export default OutOfTune;
