import { focusVisible, transition } from "../../styles/utils";
import styled from "styled-components";

export const Tab = styled.button<{
  active?: boolean;
}>`
  cursor: pointer;
  padding: 0.5rem 0.1rem;
  border: none;
  background: transparent;
  color: ${({ active }) =>
    active ? "var(--semantic-text-normal)" : "var(--semantic-text-weak)"};
  border-bottom: 2px solid
    ${({ active }) =>
      active ? "var(--component-tab-secondary-active)" : "transparent"};

  :hover:not([disabled]) {
    color: var(--semantic-text-normal);
    border-bottom: ${({ active }) =>
      !active && "2px solid var(--component-tab-secondary-hover)"};
  }

  ${focusVisible}
  ${transition(["border-color", "color"])}
`;
Tab.displayName = "Tab";

export const TabBar = styled.div<{ fitted: boolean }>`
  display: flex;
  border-bottom: ${({ fitted }) =>
    fitted
      ? "2px solid var(--semantic-bdr-weak)"
      : "1px solid var(--semantic-bdr-normal)"};

  > ${Tab} {
    flex: ${({ fitted }) => fitted && 1};
    margin-bottom: ${({ fitted }) => (fitted ? "-2px" : "-1px")};

    &:not(:last-child) {
      margin-right: ${({ fitted }) => (!fitted ? "3rem" : 0)};
    }
  }
`;
TabBar.displayName = "TabBar";
