import React, { useEffect, useState } from "react";
import "styled-components/macro";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { styles } from "./styles";
import { Typography } from "../songtradr-components";

interface IShowMore {
  expanded: boolean;
  setExpanded: Function;
  moreLabel?: string;
  lessLabel?: string;
}

const ShowMore = ({
  expanded,
  setExpanded,
  moreLabel,
  lessLabel,
}: IShowMore) => {
  const [currentScrollTop, setCurrentScrollTop] = useState(
    document.documentElement.scrollTop
  );

  useEffect(() => {
    if (expanded) {
      window.scrollTo(0, currentScrollTop);
    }
  }, [currentScrollTop, expanded]);

  return (
    <button
      css={styles.showMoreButton}
      onClick={() => {
        setCurrentScrollTop(document.documentElement.scrollTop);
        setExpanded(!expanded);
      }}
    >
      <Typography margin={false} variant={"bodyBold"}>
        {!expanded ? moreLabel ?? "Show more" : lessLabel ?? "Show less"}
      </Typography>
      {!expanded ? <DownOutlined /> : <UpOutlined />}
    </button>
  );
};

export default ShowMore;
