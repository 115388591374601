import React, { Fragment } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { css } from "styled-components/macro";

export const PleaseWaitStyledContainer = styled.div(() => {
  return css`
    .ant-modal-content {
      box-shadow: none;
      background: transparent;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: white;
      span {
        margin-top: 10px;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
      }
    }
    .ant-modal-confirm-btns {
      display: none;
    }
    .___subtext {
      font-size: 14px;
      margin-top: 0px !important;
    }
  `;
});

const PleaseWaitModal = (
  message?: string,
  subMessage?: string,
  width?: number
) => {
  const setWidth = width
    ? `${width}px`
    : message === undefined
    ? "auto"
    : "215px";

  return Modal.info({
    // className: `css-${styles.pleaseWaitDialogLogo.name}`,
    icon: (
      <Fragment>
        {/* <SongtradrLogo size="half" pulse showSongtradr={false} /> */}
        <img
          src="https://img-sys.songtradr.com/36d3ffca02cb88405ed2010cb6fe7278f96be6bfc315463660ec4bf78ab49aeb.svg"
          alt="Tunefind Logo"
        />

        <span className="___text">{message ? message : "Please wait..."}</span>
        {subMessage && <span className="___subtext">{subMessage}</span>}
        {/* <div css={styles.pleaseWaitDialogLogo}></div> */}
      </Fragment>
    ),
    okButtonProps: { style: { display: "none" } },
    maskClosable: false,
    width: setWidth,
  });
};

// const PleaseWaitModal = () => {
//   return (
//     <PleaseWaitStyledContainer>
//       {pleaseWaitModalInner()}
//     </PleaseWaitStyledContainer>
//   );
// };

export default PleaseWaitModal;
