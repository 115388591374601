import React from "react";
import "styled-components/macro";
import { Col, Tooltip } from "antd";
import { Typography } from "../songtradr-components";
import { IStoreLink } from "@app/api/types";
import { Link } from "@app/components";
import { styles } from "./styles";
import { isNullOrEmptyString, useForwardUrl } from "@app/utils";

interface IStoreInfo {
  imageUrl: string;
  tooltip: string;
}
const icons: Record<IStoreLink["store"], IStoreInfo> = {
  amazon: {
    imageUrl:
      "https://img-sys.songtradr.com/f0910286ec2f63e0412189a786ffeddbb16e6e662f0d1c166a6cc0aee7647cdf.svg",
    tooltip: "Listen on Amazon Music",
  },
  applemusic: {
    imageUrl:
      "https://img-sys.songtradr.com/9dfa10361d23fc66a60a6be9f310dfb186a7c5fc2dcf1cfc55bf2cfe757600e5.svg",
    tooltip: "Listen on Apple Music",
  },

  spotify: {
    imageUrl:
      "https://img-sys.songtradr.com/7a13324438c2820f41f663a5c2398d59ec913ea6f91e2a659c630e1778a5b410.svg",
    tooltip: "Listen on Spotify",
  },

  youtube: {
    imageUrl:
      "https://img-sys.songtradr.com/6ea5c5376cc6c63174eb3277398a7e67bdaadda9900308d26a79dd210d4d1725.svg",
    tooltip: "Watch on YouTube",
  },

  songtradr: {
    imageUrl:
      "https://img-sys.songtradr.com/76a43e0735e4f072740d52e4acff8888fd763f729e3f898f676a13bf76990417.svg",
    tooltip: "Request a license on Songtradr",
  },

  songtradr_search: {
    imageUrl:
      "https://img-sys.songtradr.com/c4d31ed2d5e5c3346f7fe557769046eee7ad93210220d65466f5b9358c69d40b.svg",
    tooltip: "Find on Songtradr",
  },
};

const StoreLink = (props: IStoreLink) => {
  const preparedUrl = useForwardUrl(props.url);

  //RENDER
  return props.store === "songtradr" || props.store === "songtradr_search" ? (
    isNullOrEmptyString(preparedUrl) ? (
      <></>
    ) : (
      <Col key={props.store} css={styles.wrapper(!preparedUrl)}>
        <Tooltip
          title={
            <Typography
              variant="xsmall"
              css={{ color: "var(--semantic-background-top)" }}
            >
              {icons[props.store].tooltip}
            </Typography>
          }
        >
          {/*we want opener and referrer exposed > dont change */}
          {/* eslint-disable-next-line */}
          <a css={[styles.button, styles.a]} href={preparedUrl} target="_blank">
            <img
              alt={`external ${props.store} link`}
              src={icons[props.store].imageUrl}
            />
          </a>
        </Tooltip>
      </Col>
    )
  ) : (
    <Col key={props.store} css={styles.wrapper(!preparedUrl)}>
      <Tooltip
        title={
          <Typography
            variant="xsmall"
            css={{ color: "var(--semantic-background-top)" }}
          >
            {icons[props.store].tooltip}
          </Typography>
        }
      >
        <Link
          css={[styles.button, styles.a]}
          to={preparedUrl ?? ""}
          disabled={!preparedUrl}
          target={"_blank"}
        >
          <img
            css={props.store === "applemusic" ? styles.fixAppleIcon : undefined}
            alt={`external ${props.store} link`}
            src={icons[props.store].imageUrl}
          />
        </Link>
      </Tooltip>
    </Col>
  );
};

export default StoreLink;
