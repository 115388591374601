export interface PasswordErrorsObject {
    error: string, 
    isValid: boolean
}

export enum PasswordErrors {
    TOO_SHORT = 'Password needs to be at least 8 characters long',
    NO_NUMBER = 'Contains at least 1 number',
    NO_SPECIAL_CHARACTER = 'Contains at least 1 special character',
    NO_UPPERCASE = 'Contains at least 1 uppercase character',
    NO_LOWERCASE = 'Contains at least 1 lowercase character',
}

export const checkPasswordValidity = (password: string) => {
    const errors: PasswordErrorsObject[] = [];

    if (password.length < 8) {
        errors.push({ error: PasswordErrors.TOO_SHORT, isValid: false });
    } else {
        errors.push({ error: PasswordErrors.TOO_SHORT, isValid: true });
    }

    if (!/[0-9]/.test(password)) {
        errors.push({error: PasswordErrors.NO_NUMBER, isValid: false});
    } else {
        errors.push({error: PasswordErrors.NO_NUMBER, isValid: true});
    }

    if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
        errors.push({error: PasswordErrors.NO_SPECIAL_CHARACTER, isValid: false});
    } else {
        errors.push({error: PasswordErrors.NO_SPECIAL_CHARACTER, isValid: true});
    }

    if (!/[A-Z]/.test(password)) {
        errors.push({error: PasswordErrors.NO_UPPERCASE, isValid: false});
    } else {
        errors.push({error: PasswordErrors.NO_UPPERCASE, isValid: true});
    }

    if (!/[a-z]/.test(password)) {
        errors.push({ error: PasswordErrors.NO_LOWERCASE, isValid: false});
    } else {
        errors.push({ error: PasswordErrors.NO_LOWERCASE, isValid: true});
    }

    return errors;
} 

export const checkIfAllPasswordRequirementsAreMet = (errors: PasswordErrorsObject[]) => {
    return errors.filter((error) => !error.isValid);
}