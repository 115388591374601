import React, {useCallback, useState, useEffect} from "react";
import UserPool from "@app/environments/UserPool";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, Input } from "antd";
import { Button, Typography } from "../../components/songtradr-components";
import { initialUserState, useUserContext } from "@app/userContext";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import { ErrorCodes } from "@app/enums/ErrorCodes";
import "styled-components/macro";
import { styles } from './styles';
import { checkIfAuthenticationAlreadyExists } from "@app/utils/helpers/Session";
import { refreshUserSession } from "@app/userContext/refreshToken";
import { FacebookButton } from "@app/components";
import { handleSignInFacebook } from "../../utils/helpers/FacebookRedirect";
import { initialErrorState, useErrorContext } from "@app/errorContext";
import { constructUserForSignIn, handleSuccessfulSignIn } from "@app/utils/helpers/signInFlowUtils";

const SignIn: React.FC = () => {
    const [usernameEmailField, setUsernameEmailField] = useState('');
    const [passwordField, setPasswordField] = useState('');
    const navigate = useNavigate();
    const { checkLoadUserAccount,exchangeGrant, userState, setState } = useUserContext();
    const { errorState, setErrorState } = useErrorContext();
    const [searchParams] = useSearchParams();
    const fromParam = searchParams.get('from'); 

    useEffect(() => {
        if (checkIfAuthenticationAlreadyExists()) {
            if (!userState.userToken) {
                const user = UserPool.getCurrentUser();
                const session = user?.getSignInUserSession();
            
                if (session && !session.isValid()) {
                  refreshUserSession();
                }
                checkLoadUserAccount();
            } else {
                navigate('/')
            }
        }
    }, [checkLoadUserAccount, navigate, userState.userToken]);
    
    const clearState = useCallback(() => {
        setUsernameEmailField('');
        setPasswordField('');
        setErrorState(initialErrorState);
    }, [setErrorState])

    const handleSwitch = useCallback(() => {
        clearState();
        navigate(`/signup?from=${fromParam ?? '/'}`)
    }, [clearState, navigate, fromParam])

    const handleSignInErrors = useCallback((err: any, username?: string) => {
        if (err) {
            const errorMessage = handleError(err);
            switch (err.code) {
                case ErrorCodes.USER_NOT_CONFIRMED:
                    setErrorState(initialErrorState);
                    navigate(`/signup/confirm/${usernameEmailField}`)
                    break;
                
                case ErrorCodes.PASSWORD_RESET_REQUIRED:
                    setErrorState(initialErrorState);
                    setState({
                        ...initialUserState,
                        userName: username ?? ''
                    })
                    navigate(`/signin/reset-password/`);
                    break;
                default: 
                    if (errorMessage) {
                        setErrorState({ message: errorMessage })
                    }
            }
        }
    }, [navigate, setErrorState, setState, usernameEmailField])

    const handleSignIn = useCallback((event?: any) => {
        event?.preventDefault();
        setErrorState(initialErrorState);
        if (usernameEmailField && passwordField) {
            
            const { user, authDetails } = constructUserForSignIn(usernameEmailField, passwordField);
            if (user && authDetails) {
                user.authenticateUser(authDetails, {
                onSuccess: (session) => {
                    const payload = handleSuccessfulSignIn(session);
                    exchangeGrant(payload)
                    navigate(fromParam ?? '/');           
                },
                newPasswordRequired: userAttr => {
                    delete userAttr.email_verified;
                    delete userAttr.email;

                    setState({
                        ...initialUserState,
                        userName: user.getUsername(),
                        userAttr,
                        userRef: user,
                    });
                    
                    navigate(`/signin/reset-password/force`);
                },
                onFailure: (err) => {
                   handleSignInErrors(err, usernameEmailField)
                },
              });
            }
           
        } 
    }, [setErrorState, usernameEmailField, passwordField, exchangeGrant, navigate, fromParam, setState, handleSignInErrors])

    
    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSignIn();
        }
    }, [handleSignIn])


    const handleForgotPasswordClick = () => {
        setErrorState(initialErrorState);
        navigate('/signin/reset-password');
    }
    
    return (
        <div css={styles.topOffset}>
            <div css={styles.titleUnderline}>
                <Typography variant="h2" css={styles.title}>Sign In</Typography>
            </div>
            <Row gutter={[16,16]} >
                <Col  xs={1} sm={1} md={1} lg={7} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={10} xl={8}>
                    <Row gutter={[0, 24]}>
                        {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                        <Col span={24}>
                        <Input
                                autoComplete="username"
                                css={styles.inputHeight}
                                value={usernameEmailField}
                                placeholder="Username or Email"
                                onKeyDown={handleEnterKeyDown}
                                onChange={(event) => setUsernameEmailField(event.target.value)} />
                        </Col>
                        <Col span={24}>
                            <Input
                                autoComplete="password" 
                                css={styles.inputHeight}
                                value={passwordField}
                                type="password"
                                placeholder="Password"
                                onKeyDown={handleEnterKeyDown}
                                onFocus={() => {setPasswordField(passwordField)}}
                                onChange={(event) =>  setPasswordField(event.target.value)} /> 
                            {<Typography onClick={handleForgotPasswordClick} variant="body" css={styles.forgetPassword}>Forgot your password?</Typography>}
                        </Col>
                        <Col span={24}>
                            <div css={styles.signInButtons}>
                                    <Button variant="primary" onClick={handleSignIn}>Sign In</Button> 
                                    <Typography variant="body" style={{ margin: '0px'}}>or</Typography>
                                    <FacebookButton onClickCallback={handleSignInFacebook} value="Continue With Facebook" />                             
                            </div>
                        </Col>
                        <Col>
                            <Typography variant="body">Need an account? <span css={styles.signUpLink} onClick={() => handleSwitch()}>Sign Up</span></Typography>
                        </Col>
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={7} xl={8}></Col>
            </Row>
        </div>
    )
}

export default SignIn