import { css } from "styled-components";
import { minLarge } from "../../songtradr-components";

export const styles = {
  tileSection: css`
    row-gap: 1rem;
    margin-bottom: 6rem;
  `,

  tileWrapper: css`
    cursor: pointer;
    position: relative;

    @media ${minLarge} {
      width: 100%;
      height: 100%;
    }

    > div,
    > div > img {
      width: 100%;
      height: 100%;
    }

    transition: transform 0.3s ease-in-out;
    :hover {
      transform: scale(1.03);
    }
  `,

  tileText: css`
    > * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0.5rem 0 1rem 0;

      @media ${minLarge} {
        z-index: 1;
        position: absolute;
        left: 1.5rem;
        bottom: 1.5rem;

        margin: 0;
        color: var(--semantic-primary-text);
        width: calc(100% - 2.5rem);
      }
    }
  `,

  eyeIcon: css`
    width: 4rem;
    height: 4rem;
  `,

  emptyStateContainer: css`
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
  `,
};
