import React, { ReactElement, ReactNode,  useReducer } from "react";
import { IErrorState } from "./interfaces";
import ErrorContext, {initialErrorState} from ".";

const reducer = (state: IErrorState, newState: IErrorState) => {
  return {
    ...state,
    ...newState,
  };
};

const ErrorProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [errorState, dispatch] = useReducer(reducer, initialErrorState);

    
  const setErrorState = (newState: IErrorState) => {
    //RETURN
    dispatch(newState);
  };

  return (
    <ErrorContext.Provider
      value={{
        errorState,
        setErrorState,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
export default ErrorProvider;
