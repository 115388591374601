import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { maxMedium, minLarge } from "../songtradr-components";
import { loadingPulse } from "@app/styles/helpers";

const ContentSliderLoading = styled(Row)`
  @media ${maxMedium} {
    height: 200px;
  }
  @media ${minLarge} {
    height: 474px;
  }

  > div {
    flex: 1;
    margin-right: 1.5rem;
    background-color: var(--bg-loading);
    border-radius: 8px;
    ${loadingPulse}
  }
`;

const Loading = () => {
  return (
    <ContentSliderLoading>
      <Col />
      <Col />
      <Col />
    </ContentSliderLoading>
  );
};

export default Loading;
