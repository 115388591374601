import {
  PropsWithoutRef,
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef,
} from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { boldStyles, P, semiBoldStyles, Small, XSmall } from "./Blocks.Common";
import {
  Display1,
  Display2,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SectionHeader,
} from "./Blocks.Headings";
import React from "react";

type Bold = "regular" | "semi-bold" | "bold";

export type TypographyVariant =
  | "display1"
  | "display2"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "sectionHeader"
  | "body"
  | "paragraph"
  | "bodySemiBold"
  | "paragraphSemiBold"
  | "bodyBold"
  | "paragraphBold"
  | "small"
  | "smallSemiBold"
  | "smallBold"
  | "xsmall"
  | "xsmallSemiBold"
  | "xsmallBold";

type GenericHtmlProps = DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>;

interface TypographyFeatureProps {
  variant: TypographyVariant;
  truncate?: boolean;
  inverted?: boolean;
  margin?: boolean;
}

export type TypographyProps = GenericHtmlProps & TypographyFeatureProps;

const truncateStyles = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const invertedStyles = css`
  color: var(--semantic-text-weakest);
`;

const fontWeightStyles: Record<Bold, FlattenSimpleInterpolation | null> = {
  bold: boldStyles,
  "semi-bold": semiBoldStyles,
  regular: null,
};

const FeatureStyles = styled.p.withConfig({
  shouldForwardProp: (p) => !["bold", "inverted", "truncate"].includes(p),
})<TypographyFeatureProps & { bold?: Bold }>`
  ${({ bold = "regular" }) => fontWeightStyles[bold]}
  ${({ inverted }) => inverted && invertedStyles}
  ${({ truncate }) => truncate && truncateStyles}
`;

export const Typography = forwardRef<
  HTMLElement,
  PropsWithoutRef<TypographyProps>
>(({ variant, ...props }, ref) => {
  switch (variant) {
    case "display1":
      return <FeatureStyles {...props} ref={ref} as={Display1} />;
    case "display2":
      return <FeatureStyles {...props} ref={ref} as={Display2} />;
    case "h1":
      return <FeatureStyles {...props} ref={ref} as={H1} />;
    case "h2":
      return <FeatureStyles {...props} ref={ref} as={H2} />;
    case "h3":
      return <FeatureStyles {...props} ref={ref} as={H3} />;
    case "h4":
      return <FeatureStyles {...props} ref={ref} as={H4} />;
    case "h5":
      return <FeatureStyles {...props} ref={ref} as={H5} />;
    case "h6":
      return <FeatureStyles {...props} ref={ref} as={H6} />;
    case "sectionHeader":
      return <FeatureStyles {...props} ref={ref} as={SectionHeader} />;
    // body is not referring to the <body /> html element but the typographic body
    case "body": // intentional fallthrough since this is an alias
    case "paragraph":
      return <FeatureStyles {...props} ref={ref} as={P} />;
    // body is not referring to the <body /> html element but the typographic body
    case "bodySemiBold": // intentional fallthrough since this is an alias
    case "paragraphSemiBold":
      return <FeatureStyles {...props} ref={ref} as={P} bold="semi-bold" />;
    // body is not referring to the <body /> html element but the typographic body
    case "bodyBold": // intentional fallthrough since this is an alias
    case "paragraphBold":
      return <FeatureStyles {...props} ref={ref} as={P} bold="bold" />;
    case "small":
      return <FeatureStyles {...props} ref={ref} as={Small} />;
    case "smallSemiBold":
      return <FeatureStyles {...props} ref={ref} as={Small} bold="semi-bold" />;
    case "smallBold":
      return <FeatureStyles {...props} ref={ref} as={Small} bold="bold" />;
    case "xsmall":
      return <FeatureStyles {...props} ref={ref} as={XSmall} />;
    case "xsmallSemiBold":
      return (
        <FeatureStyles {...props} ref={ref} as={XSmall} bold="semi-bold" />
      );
    case "xsmallBold":
      return <FeatureStyles {...props} ref={ref} as={XSmall} bold="bold" />;
  }
});
