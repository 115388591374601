import type { CssCustomPropertyName } from "../custom-properties";
import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  FC,
  forwardRef,
} from "react";
import {
  designLanguageThemeClassName,
  useDesignLanguageBodyClass,
} from "./DesignLanguageThemeSwitcher";
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
} from "styled-components";
import React from "react";

export function themeToCssVariables({
  theme,
}: {
  theme?: Record<string, string> | DefaultTheme;
}) {
  if (!theme) {
    return "";
  }
  return Object.entries(theme)
    .filter(([_, v]) => Boolean(v))
    .map(([key, val]) => `--${key}: ${val};`)
    .join("\n");
}

export type DesignLanguageOverwrite = Partial<
  Record<CssCustomPropertyName, string>
>;
export type ThemeMode = "dark" | "light" | "system";
export type Theme<
  CustomTheme extends Record<string, string> = Record<string, string>
> = {
  modes?: {
    dark: DesignLanguageOverwrite & CustomTheme;
    light: DesignLanguageOverwrite & CustomTheme;
  };
  common?: DesignLanguageOverwrite & Record<string, string>;
};

type ThemeStyleProps = Theme & {
  mode: ThemeMode;
};
const systemThemeStyles = css<ThemeStyleProps>`
  ${(props) => themeToCssVariables({ theme: props.modes?.light || {} })};
  @media (prefers-color-scheme: dark) {
    ${(props) => themeToCssVariables({ theme: props.modes?.dark || {} })};
  }
`;

const GlobalTheme = createGlobalStyle<ThemeStyleProps>`
  :root {
    ${({ mode, modes }) =>
      modes &&
      (mode === "system"
        ? systemThemeStyles
        : themeToCssVariables({ theme: modes[mode] }))}

    ${({ common: theme }) => theme && themeToCssVariables({ theme })}
  }

  .light-theme {
    ${({ modes }) => themeToCssVariables({ theme: modes?.light })}
  }

  .dark-theme.dark-theme {
    ${({ modes }) => themeToCssVariables({ theme: modes?.dark })}
  }

  .light-theme, .dark-theme.dark-theme {
    ${({ common }) => themeToCssVariables({ theme: common })}
  }
`;

const ScopedTheme = styled.div.withConfig({
  shouldForwardProp: (prop) => !["mode", "modes", "common"].includes(prop),
})<ThemeStyleProps>`
  ${({ mode, modes }) =>
    modes &&
    (mode === "system"
      ? systemThemeStyles
      : themeToCssVariables({ theme: modes[mode] }))}
  ${({ common: theme }) => theme && themeToCssVariables({ theme })}

  .light-theme {
    ${({ modes }) => themeToCssVariables({ theme: modes?.light })}
  }

  .dark-theme.dark-theme {
    ${({ modes }) => themeToCssVariables({ theme: modes?.dark })}
  }

  .light-theme,
  .dark-theme.dark-theme {
    ${({ common }) => themeToCssVariables({ theme: common })}
  }
`;

export function createGlobalTheme<
  CustomThemeValues extends Record<string, string>
>(theme: Theme<CustomThemeValues>): FC<{ theme?: ThemeMode }> {
  const Global: FC<{ theme?: ThemeMode }> = ({ theme: mode = "system" }) => {
    useDesignLanguageBodyClass(mode);

    return <GlobalTheme {...theme} mode={mode} />;
  };

  Global.displayName = "GlobalTheme";

  return Global;
}

export function createScopedTheme<
  CustomThemeValues extends Record<string, string>,
  Element extends ElementType = "div"
>(
  theme: Theme<CustomThemeValues>,
  Component?: Element
): FC<Omit<ComponentPropsWithRef<Element>, "theme"> & { theme?: ThemeMode }> {
  const Comp = Component || "div";

  const Scoped = forwardRef<
    HTMLDivElement,
    ComponentPropsWithoutRef<"div"> & { theme?: ThemeMode }
  >(({ theme: mode = "system", className, ...props }, ref) => {
    return (
      <ScopedTheme
        {...props}
        {...theme}
        as={Comp}
        mode={mode}
        ref={ref}
        className={designLanguageThemeClassName({ className, theme: mode })}
      />
    );
  });

  Scoped.displayName = "ScopedTheme";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Scoped as any;
}
