import styled, { css } from "styled-components";
import { maxMedium, minLarge } from "../../components/songtradr-components";
import { useContentMargins } from "@app/styles/helpers";

export const SettingsPageWrapper = styled.div`
margin-top: 5rem;
`;

export const UserSettingsPageWrapper = styled.div(() => {
const { margin } = useContentMargins();

return css`
  background: var(--semantic-background-middle);
  min-height: 100vh;

  @media ${minLarge} {
    padding: 6.5rem ${margin} 4rem ${margin};
    /* margin-top: 5rem; */
  }
  @media ${maxMedium} {
    padding: 2.5rem ${margin} 2rem ${margin};
    /* margin-top: 5rem; */
  }
`;
});

export const StyledCardBorder = styled.div(() => {
return css`
  padding: 2rem;
  background: var(--semantic-background-base);
  border-radius: 8px;
  margin-bottom: 3rem;
`;
});

export const UserSettingsPageContent = styled.div`
@media ${minLarge} {
  margin-top: 3.5rem;
  flex-direction: row;
}
@media ${maxMedium} {
  margin-top: 1rem;
  row-gap: 2rem;
  flex-direction: column;
}
`;

export const styles = {
  headerText: css`
    margin-bottom: 3.5rem;
  `,

  sectionHeaderText: css`
    margin-bottom: 1.5rem;
  `,

  sectionContainerWithRowGap: css`
    display: flex;
    flex-direction: column;
    row-gap: 2.25rem;
  `,

  sectionContainer: css`
    display: flex;
    flex-direction: column;
  `,

  formTitle: css`
    margin-bottom: 0.5rem;
  `,

  form: css`
    width: 100%;
    border: 1px solid #b3b7bb;
    border-radius: 4px;
    height: 3.5rem;
  `,

  button: css`
    max-width: 50%;
    margin-left: auto;
    right: 0;
  `,

  switch: css`
    margin-left: auto;
    right: 0;
  `,
};
