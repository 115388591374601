import axios, { AxiosError, Method } from "axios";
import { Settings } from "@app/utils/settings";
import { ApiDecrypt, Scramble } from "../encryption/encryption";
import { isNullOrEmptyString } from "@app/utils";
import { memRefreshToken } from "@app/userContext/refreshToken";

export interface ApiError extends AxiosError {
  caught: any;
}

export interface IApiPipelineRequest {
  key: string;
  path: string;
  method: Method;
  data?: any;
}

export interface IApiManyInPipelineRequest {
  requests: IApiPipelineRequest[];
  data: any;
  done: any;
  error: any;
}

// URL EXTEND
export const getFullUrl = (path: string) => {
  return `${Settings.APIBaseURL}/${path}`;
};

// BASE API
export const Api = axios.create({
  baseURL: Settings.APIBaseURL,
  timeout: 60000,
  headers: (window as any).__uncached
    ? ({
        "no-api-cache": "true",
      } as any)
    : Settings.extraHeaders,
  // withCredentials: true,
});

// API MANY
export const ApiMany = (requests: any[], SpreadResolve: any) =>
  new Promise((resolve, reject) => {
    if (SpreadResolve) {
      axios.all(requests).then(axios.spread(SpreadResolve)).catch(reject);
    } else {
      axios.all(requests).then(resolve).catch(reject);
    }
  });

export const ApiManyInPipeline = (request: IApiManyInPipelineRequest) => {
  ApiPipeline(
    request.requests[0],
    request.requests,
    request.data,
    request.done,
    request.error
  );
};

const ApiPipeline = (
  request: IApiPipelineRequest,
  requests: IApiPipelineRequest[],
  data: any,
  done: any,
  error: any
) => {
  Api.request({
    url: GetApiURI(request.path),
    method: request.method,
    data: request.data,
  })
    .then((result) => {
      data(result, request.key);

      // CURRENT INDEX
      const newIndex = requests.indexOf(request) + 1;
      if (requests[newIndex]) {
        ApiPipeline(requests[newIndex], requests, data, done, error);
      } else {
        done();
      }
    })
    .catch(() => error(request.key));
};

// EXTEND STRING TO API URI
export const GetApiURI = (path: string) =>
  `${Settings.APIBaseURL}/${path.replace(/^\/|\/$/g, "")}`;

// API PROTECT
// (window as any).grecaptcha?.enterprise?.ready(() => {
//   (window as any).grecaptcha.enterprise.isReady = true;
// });

// INTERCEPTORS
Api.interceptors.request.use(
  async (config) => {
    config.headers = config.headers ?? {};

    // USER COUNTRY
    const userCountry =
      navigator.language.split("-").length === 2
        ? navigator.language.split("-")[1]
        : navigator.language;

    config.headers["x-user-country"] = userCountry;
    config.headers["x-slug"] = window.location.pathname;

    //AUTH
    if (!isNullOrEmptyString(localStorage.getItem("tf_auth_access_token"))) {
      config.headers["authorization"] = `Bearer ${localStorage.getItem(
        "tf_auth_access_token"
      )}`;
    }

    if (!isNullOrEmptyString(localStorage.getItem("tf_ltt"))) {
      config.headers["x-user-ltt"] = localStorage.getItem("tf_ltt");
    }

    // GRC
    // if (config.httpAgent === "grc") {
    //   config.httpAgent = undefined;

    //   // TRIPLE CHECK > TRIPLE TO ALLOW FOR MAX 1 SEC
    //   if (!(window as any).grecaptcha.enterprise.isReady)
    //     await new Promise((r) => setTimeout(r, 250));
    //   if (!(window as any).grecaptcha.enterprise.isReady)
    //     await new Promise((r) => setTimeout(r, 250));
    //   if (!(window as any).grecaptcha.enterprise.isReady)
    //     await new Promise((r) => setTimeout(r, 500));
    //   if (!(window as any).grecaptcha.enterprise.isReady) throw new Error();

    //   // TRIPLE CHECK #######################################
    //   const token = await (window as any).grecaptcha.enterprise.execute(
    //     "6LeZoY0fAAAAAILHLwcCZGRZgdfGqseHXIOIY3wA",
    //     { action: "homepage" }
    //   );
    //   if (config.headers) {
    //     config.headers["x-grc-auth"] = token;
    //   } else {
    //     config.headers = {
    //       "x-grc-auth": token,
    //     };
    //   }
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    //DECRYPT CONTENT
    const checkHeader = Scramble("$$QZ0FGZtUGajF2Y", true); //DISGUISE TO HIDE ENC
    if (response.headers[checkHeader]) {
      // GET DATE

      const date = Date.parse(response.headers[checkHeader] ?? "");
      const keyNo = Number(date.toString().charAt(date.toString().length - 1));

      // DECRYPT RESPONSE
      response.data = JSON.parse(JSON.parse(ApiDecrypt(response.data, keyNo)));
    }
    return response;
  },
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const accessToken = await memRefreshToken()
      
      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return Api(config);
    }
    return Promise.reject(error);
  }
);

export enum ApiStatus {
  Loading,
  Success,
  Failed,
}
