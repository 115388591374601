import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "../songtradr-components";
import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import "styled-components/macro";

import { StyledModal, styles } from "./styles";

interface IReplyWithSongProps {
    fallbackTitle: string;
    fallbackMessage: string;
    errorModalForAddSong?: boolean;
}

const useErrorDialogModal = (
    {
        fallbackTitle,
        fallbackMessage,
        errorModalForAddSong
        
    }: IReplyWithSongProps
): [JSX.Element, (errorTitle?: string, errorMessage?: string) => void, boolean] => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState(fallbackTitle ?? '');
    const [message, setMessage] = useState(fallbackMessage ?? '');

    const handleOpenErrorDialog = (errorTitle?: string, errorMessage?: string) => {
        setTitle(errorTitle ?? fallbackTitle);
        setMessage(errorMessage ?? fallbackMessage);
        handleOpen();
    }

    const handleOpen = () => {
        setIsModalOpen(!isModalOpen);
    };

    //Handle body scrolling behind the modal
    useEffect(() => {
        const body = document.querySelector("body");
        if (body) body.style.overflow = isModalOpen ? "hidden" : "auto";
    }, [isModalOpen]);



    const Modal = (
        <StyledModal
            errorModalForAddSong={errorModalForAddSong}
            confirmationModal={false}
            destroyOnClose
            mask={true}
            open={isModalOpen}
            closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
            onOk={handleOpen}
            onCancel={handleOpen}
            footer={null}
        >
            <Typography margin={false} css={styles.gutter} variant="h3">
                {title}
            </Typography>
            <Typography css={styles.gutter} variant="body">
                {message}
            </Typography>

            <Row justify="space-between">
                <Col span={24}>
                    <Button
                        css={styles.errorOkButton}
                        variant="primary"
                        onClick={() => handleOpen()}
                    >
                        Ok
                    </Button>
                </Col>

            </Row>
        </StyledModal>
    );

    return [Modal, handleOpenErrorDialog, isModalOpen];
};

export default useErrorDialogModal;
