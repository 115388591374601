import { IArtistCredits, IArtistDetailsResponse } from "@app/api/types";
import StoreLinks from "@app/components/store-links";
import { RightColAds } from "@app/components/tab-explorer/styles";
import { IArtistPageSchema } from "@app/sanity-generated-data/types";
import { Typography } from "../../components/songtradr-components";
import { Col, Row } from "antd";
import React, { Suspense, useMemo } from "react";
import SocialLinks from "./SocialLinks";
import Credits from "./Credits";
import { WaitSpin } from "@app/components";
import { Location } from "react-router-dom";
import "./styles.css"
import { useContentMargins } from "@app/styles/helpers";
const AdsWrapper = React.lazy(() => import("../../components/advertisement/AdsWrapper"));

const ArtistPageContent = ({
  schema: { credits: creditsSchema, bio: bioSchema },
  data: { name, bio, socialLinks, storeLinks },
  creditsData,
  creditsLoading,
  expandable,
  isMobile,
  location,
  artistId,
  userLoggedIn,
}: {
  schema: IArtistPageSchema;
  data: IArtistDetailsResponse;
  expandable?: boolean;
  creditsData: IArtistCredits;
  creditsLoading: boolean;
  isMobile: boolean;
  location?: Location;
  artistId?: string;
  userLoggedIn: boolean;
}) => {
  const { rightColAds } = creditsSchema;
  const { margin } = useContentMargins();

  const artistLinks = (
    <div className="styled-card-border" style={{"--margin": margin} as React.CSSProperties}>
      <Row justify="space-between" gutter={[0, 24]}>
        <Row>
          <Col span={24}>
            <Typography variant="h2">Connect</Typography>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <SocialLinks links={socialLinks} />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography variant="h2">Listen</Typography>
          </Col>
          <Col span={24}>
            <StoreLinks links={storeLinks} artistName={name}/>
          </Col>
        </Row>
      </Row>
    </div>
  );

  const rightColAdRenders = useMemo(() => {
    return !isMobile && rightColAds ? (
      <RightColAds
        className="right-col-wrapper"
        xl={{ span: 7 }}
        lg={{ span: 9 }}
        as={Col}
      >
        {creditsData?.credits?.length < 2 ? null : creditsData?.credits?.length >= 2 &&
          creditsData?.credits?.length < 5 ? (
            <Suspense>
              <AdsWrapper ads={rightColAds.slice(1, 2)} artistId={artistId} location={location} isMobile={isMobile} userLoggedIn={userLoggedIn} />
            </Suspense>
        ) : creditsData?.credits?.length <= 5 ? (
          <Suspense>
            <AdsWrapper ads={rightColAds.slice(1)} artistId={artistId} location={location} isMobile={isMobile} userLoggedIn={userLoggedIn}/>
          </Suspense>
        ) : (
          <Suspense>
            <AdsWrapper ads={rightColAds} artistId={artistId} location={location} isMobile={isMobile} userLoggedIn={userLoggedIn}/>
          </Suspense>
        )}
      </RightColAds>
    ) : null;
  }, [isMobile, rightColAds, creditsData?.credits?.length, artistId, location, userLoggedIn]);

  return (
    <>
      <Row gutter={[0, 24]} wrap={true}>
        <Col
          xl={16}
          lg={14}
          md={!isMobile ? 14 : undefined}
          span={isMobile ? 24 : undefined}
          className="credits-row-gap"
        >
          {bio != null && bioSchema && (
            <div className="bio">
              <Typography variant={bioSchema.header.variant}>
                About {name}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: bio }}
                variant={bioSchema.content.variant}
              ></Typography>
            </div>
          )}
          {isMobile && artistLinks}
          {creditsLoading ? 
            <WaitSpin dark />
          :
            <Col className="credits-list">
              <Credits name={name} credits={creditsData?.credits} creditsSchema={creditsSchema} expandable={expandable} />
            </Col>
          }

        </Col>
        <Col
          xl={6}
          lg={8}
          md={!isMobile ? 8 : undefined}
          span={isMobile ? 24 : undefined}
          className="right-col-wrapper"
        >
          {!isMobile && artistLinks}
          {creditsSchema.rightColAds && rightColAdRenders}
        </Col>
      </Row>
    </>
  );
};

export default ArtistPageContent;
