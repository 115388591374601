import { RefObject } from "react";

const scrollIntoViewWithOffset = (
  targetRef: RefObject<HTMLElement>,
  offset?: number
) => {
  if (targetRef?.current && offset) {
    const signedOffset = offset >= 0 ? offset * -1 : offset * 1;

    window.scrollTo({
      behavior: "smooth",
      top:
        targetRef.current.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top +
        signedOffset,
    });
  }
};

export default scrollIntoViewWithOffset;
