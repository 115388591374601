import React, { useCallback, useMemo, useRef, useEffect } from "react";
import { useSanityData } from "@app/sanity-generated-data";
import { IHeroSchema, IMediaOverviewPageSchema } from "@app/sanity-generated-data/types";
import { useMediaOverviewPageData } from "@app/api";
import { ContentSlider, Schedule } from "@app/components";
import { MediaOverviewContent, MediaOverviewWrapper } from "./styles";
import MediaOverviewHero from "./MediaOverviewHero";
import { useLocation } from "react-router-dom";
import { useWindowDimensions } from "@app/components/songtradr-components";
import { useUserContext } from "@app/userContext";

export const MediaOverviewPage = ({ sanityId }: { sanityId: string }) => {
  const sanityData = useSanityData<IMediaOverviewPageSchema>("pages", sanityId);
  const {
    contentSliders,
    schedule,
  } = sanityData;
  const hero = sanityData.hero?.[0];
  const [apiLoading, apiData] = useMediaOverviewPageData(sanityId, hero);
  const preloadedImage = (window as any).heroPreloads?.[sanityId.replace('drafts.', '')]?.src;
  const preloadedImageRef = useRef(document.getElementById('preload-wrapper'));
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const location = useLocation();
  const { userState } = useUserContext();

  useEffect(() => {
    if (!apiLoading && preloadedImageRef.current) {
      preloadedImageRef.current.style.display = 'none';
    }
  }, [apiLoading]);

  const mappedContentSliders = useMemo(() => {
    if (apiData) {
      return contentSliders?.map((schema, index) => (
        <ContentSlider
          userLoggedIn={userState.authenticated}
          location={location}
          isMobile={isMobile}
          key={schema._key}
          schema={schema}
          loading={apiLoading}
          data={apiData[`${schema.apiKey}_${schema._key}` ?? ""] ?? []}
          sliderIndex={index}
        />
      ));
    }
  }, [apiData, contentSliders, location, isMobile, apiLoading, userState]);

 
  const getTempSchema = useCallback(() => {
    
    const tempSliderData = {
      header: {
        text: ''
      },
      description: {
        text: ''
      },
      backgroundImg: {
        src: preloadedImage ?? ''
      }
    } as IHeroSchema;

   return tempSliderData;

  }, [preloadedImage])

  return (
    <MediaOverviewWrapper>
      <MediaOverviewHero tempSchema={getTempSchema()} data={apiData[`${hero?.apiKey}_${hero?._key}`]} sliderSchema={hero}/>
      {/* {!isMobile && searchbar ? <SearchBarSection schema={searchbar} /> : null} */}
      <MediaOverviewContent>
        {mappedContentSliders}
        {schedule && (
          <Schedule
            schema={schedule}
            loading={apiLoading}
            data={apiData[schedule.apiKey]}
          />
        )}
      </MediaOverviewContent>
    </MediaOverviewWrapper>
  );
};

export default MediaOverviewPage;
