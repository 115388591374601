import styled from "styled-components";
import { InputProps, inputStyles } from "../input/Input";

export type TextAreaProps = InputProps & {
  resize?: "vertical" | "horizontal" | "both" | "none";
};

export const TextArea = styled.textarea<TextAreaProps>`
  ${inputStyles}
  resize: ${({ resize }) => resize};
`;
