import { maxMedium, minLarge } from "../songtradr-components";
import styled, { css } from "styled-components";

export const StyledCircle = styled.circle<{
  offset: number;
  circumference: number;
  strokeWidth: number;
}>`
  fill: none;
  stroke: var(--semantic-background-top);
  stroke-width: ${(props) => props.strokeWidth};
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.offset};
`;

export const styles = {
  songMetadataRow: css`
    color: #cfcfcf;
    & > small {
      margin-right: 0.25rem
    }
  `,

  backgroundCircle: css`
    fill: var(--semantic-background-base);
    stroke: var(--semantic-background-base);
    stroke-width: 2;
    z-index: -10;
  `,

  playerContainer: (titleAligned?: boolean) => {
    return css`
      min-width: 3rem;
      position: relative;
      margin-right: 1.5rem;
      margin-left: 1rem;
      min-height: 2rem;
      margin-top: ${titleAligned && "11px"};

      @media ${maxMedium} {
        margin-top: ${titleAligned && "5px"};
      }
    `;
  },

  songPreviewRow: css`
    margin-right: 2rem;
    align-items: center;

    @media ${maxMedium}{
      margin-right: 0;
    }
  `,

  previewImage: css`
    margin-right: 20px;
    height: 80px;
    width: 80px;

    @media ${maxMedium}{
      margin-right: 0;
      height: 60px;
      width: 60px;
    }
  `,

  playerMargin: css`
    margin-right: 2rem;
  `,

  songNameLink: css`
    cursor: pointer;
    word-break: break-word;
    :hover {
      color: var(--semantic-secondary-bg-hover);
    }
  `,

  playIcon: (playing: boolean) => {
    return css`
      cursor: pointer;
      z-index: 1;
      margin-left: ${playing ? "0rem" : "0.20rem"};
      opacity: 1;
    `;
  },

  artistRow: css`
    > a {
      :not(:last-child) {
        margin-right: 0.25rem;
      }
    }
  `,

  playPauseButton: (
    dimensions: number,
    active: boolean,
    titleAligned?: boolean
  ) => {
    return css`
      position: absolute;
      min-width: ${dimensions}px;
      min-height: ${dimensions}px;
      z-index: 1;
      top: ${titleAligned ? "-0.75rem" : "none"};
      border-radius: 50%;
      cursor: ${active ? "pointer" : "default"};
    `;
  },

  circleContainer: (titleAligned?: boolean) => {
    return css`
      position: absolute;
      transform: rotate(-90deg);
      pointer-events: none;
      /* top: -16px; */
      top: ${titleAligned ? "-0.75rem" : "none"};
      /* left: -0.1rem; */
    `;
  },

  circlesContainer: css`
    position: relative;
  `,

  noSongPreviewIcon: (titleAligned?: boolean) => {
    return css`
      color: var(--semantic-background-top);
      width: 1.5rem;
      height: 1.5rem;
      /* margin-top: ${titleAligned && "11px"}; */

      @media ${minLarge} {
        margin-top: ${titleAligned && "6px"};
      }
    `;
  },

  deleteSongIcon: css`
    cursor: pointer;
    margin-left: 0.5rem;
    display: inline;
  `,
};
