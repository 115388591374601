import React, { useContext, useEffect, useRef, useState } from "react";
import "styled-components/macro";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, InputProps } from "antd";
import { useSanityPageRoute } from "@app/sanity-generated-data";
import { ISearchBarSchema } from "@app/sanity-generated-data/types";
import SearchPreviewDropdown from "./PreviewDropdown";
import {
  SearchBarContainer,
  SearchBarContent,
  styles,
  SearchBoxWrapper,
  HeaderSearchBoxWrapper,
} from "./styles";
import { useDebounce } from "@app/utils";
import SearchContext from "@app/searchContext";
import { CloseIcon } from "../songtradr-components/icons";

// TYPES
interface ISearchBarProps extends InputProps {
  schema?: Omit<ISearchBarSchema, "header">;
  focused?: boolean;
  header: boolean;
}

// COMPONENT
const SearchBar = ({
  schema,
  header,
  className,
}: ISearchBarProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPageRoute = useSanityPageRoute("pages.search");
  const { search, searchState } = useContext(SearchContext);
  const [query, setQuery] = useState(searchParams.get("q") ?? "");
  const debouncedQuery = useDebounce(query, 500);
  const focusBlocked = useRef<boolean>(false);
  const [hasFocus, setHasFocus] = useState(false);
  // const [hasFocus2, setHasFocus2] = useState(false);
  // const [clicked, setClicked] = useState(focused);

  // const searchBoxRef = useRef<HTMLInputElement>(null);

  const handleFocus = (header: boolean) => {
    if (header) {
      !focusBlocked.current && setHasFocus(true);
      // handleExtension && handleExtension();
      // setTimeout(() => {
      // setClicked(!clicked);
      // searchBoxRef.current?.focus();
      // }, 1000);
    }

    !header && !focusBlocked.current && setHasFocus(true);
  };

  const handleBlur = () => setHasFocus(false);

  const blockFocus = () => {
    focusBlocked.current = true;
    setTimeout(() => {
      focusBlocked.current = false;
    }, 1000);
  };

  useEffect(() => {
    if (searchPageRoute.matches) {
      setQuery(searchParams.get("q") ?? "");
      blockFocus();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const searchDelay = useRef<any>(null);
  useEffect(() => {
    clearTimeout(searchDelay.current);
    if (searchState.q !== query || searchState.size !== (header ? 5 : 25))
      searchDelay.current = setTimeout(() => {
        search(query, header ? 5 : 25);
      }, 200);
    return () => {
      clearTimeout(searchDelay.current);
    };
    // eslint-disable-next-line
  }, [query, search]);

  useEffect(() => {
    if (debouncedQuery) handleFocus(false);
    else handleSubmit();
    // eslint-disable-next-line
  }, [debouncedQuery]);

  // EVENT HANDLERS
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
  };


  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (searchPageRoute.matches) {
      blockFocus();
      handleBlur();
      setSearchParams({ ...searchParams, q: query });
    } else if (query.length) {
      navigate(`../${searchPageRoute.route}?q=${query}`);
    }
  };

  const clearSearch = () => {
    setQuery("");
    search("");
  };

  // RENDER
  return (
    <SearchBarContainer key="searchBarContainer" className={className}>
      {hasFocus && <div className="clickguard" onClick={handleBlur} />}
      <SearchBarContent header={header} headerAndFocused={header && hasFocus}>
        {header ? (
          <>
            <HeaderSearchBoxWrapper focused={hasFocus}>
              <div css={styles.searchBar}>
                <svg
                  className="__icon"
                  width="10"
                  height="10"
                  viewBox="0 0 40 40"
                >
                  <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
                </svg>
                <div className="search-wrapper">
                  <Input
                    type="search"
                    enterKeyHint="search"
                    placeholder={schema?.placeholder}
                    onChange={handleInputChange}
                    onClick={() => handleFocus(header)}
                    value={query}
                    defaultValue={searchParams.get("q") ?? ""}
                    onKeyUp={handleEnter}
                />
                {query.length > 0 && (
                  <CloseIcon
                    className="__clear"
                    onClick={() => clearSearch()}
                  />
                )}
                </div>
                
              </div>
            </HeaderSearchBoxWrapper>

            <>
              <SearchPreviewDropdown
                query={debouncedQuery}
                hasFocus={hasFocus}
                setHasFocus={setHasFocus}
                schema={schema}
                header
                // loading={connectStateResults(({ isSearchStalled })}
                // searchResults={TestResults()}
              />
            </>
          </>
        ) : (
          <>
            <SearchBoxWrapper>
              <div>
                <svg
                  className="__icon"
                  width="10"
                  height="10"
                  viewBox="0 0 40 40"
                >
                  <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
                </svg>
                <div className="search-wrapper">                  
                <Input
                    type="search"
                    enterKeyHint="search"
                    autoFocus
                    css={styles.searchBar}
                    placeholder={schema?.placeholder}
                    onChangeCapture={handleInputChange}
                    defaultValue={searchParams.get("q") ?? ""}
                    value={query}
                    onKeyUp={handleEnter}
                  />
                   {query.length > 0 && (
                  <CloseIcon
                    className="__clear"
                    onClick={() => clearSearch()}
                  />
                  )}
                </div>
                
              </div>
            </SearchBoxWrapper>
            <>
              <SearchPreviewDropdown
                query={debouncedQuery}
                hasFocus={hasFocus}
                setHasFocus={setHasFocus}
                schema={schema}
                // loading={connectStateResults(({ isSearchStalled })}
                // searchResults={TestResults()}
              />
            </>
          </>
        )}
      </SearchBarContent>
    </SearchBarContainer>
  );
};

export default SearchBar;
