export enum eApiKey {
  FEATURED_ALL = "featured-all",
  FEATURED_TV = "featured-tv",        // deprecated
  FEATURED_MOVIES = "featured-movies",// deprecated
  FEATURED_GAMES = "featured-games",  // deprecated
  FEATURED_SLIDER = "featured-slider",
  SHORTLIST_ALL = "shortlist-all",
  SHORTLIST_TV = "shortlist-tv",
  SHORTLIST_ADS = "shortlist-ads",
  SHORTLIST_MOVIES = "shortlist-movies",
  SHORTLIST_GAMES = "shortlist-games",
  RECENT_TV = "recent-tv",          // deprecated
  RECENT_MOVIES = "recent-movies",  // deprecated
  RECENT_GAMES = "recent-games",    // deprecated
  NEW_TV = "new-tv",                // deprecated
  NEW_MOVIES = "new-movies",        // deprecated
  NEW_GAMES = "new-games",          // deprecated
  NEW_ADS = "new-ads",
  UPCOMING_TV = "tv-next",
  SONGS_USER_FAVORITES = "songs-user-favorites",
  SONGS_TRENDING = "songs-trending",
  SONGS_TRENDING_PREVIOUS = "songs-trending-week",
  USER_ENGAGEMENT_STATS = "user-stats-engagement",
  USER_ACHIEVEMENTS = "user-achievements",
  USER_FOLLOWING = "user-follows",
  TOP_BRANDS = "top-brands",
  POPULAR_PRODUCTS = "popular-products",
  USER_SOCIALS = "user-socials",

  RECENTLY_AIRED_TV = "recently-aired-tv",
  RECENTLY_AIRED_MOVIES = "recently-aired-movies",
  RECENTLY_AIRED_GAMES = "recently-aired-games",
  RECENTLY_ADDED_TV = "recently-added-tv",
  RECENTLY_ADDED_MOVIES = "recently-added-movies",
  RECENTLY_ADDED_GAMES = "recently-added-games",  
  TRENDING_TV = "trending-tv",
  TRENDING_MOVIES = "trending-movies",
  TRENDING_GAMES = "trending-games",  
  POPULAR_TV = "popular-tv",
  POPULAR_MOVIES = "popular-movies",
  POPULAR_GAMES = "popular-games",
  HELP_CONTRIBUTE_TV = "help-contribute-tv",
  HELP_CONTRIBUTE_MOVIES = "help-contribute-movies",
  HELP_CONTRIBUTE_GAMES = "help-contribute-games",
}
