import React, { useState } from "react";
import "styled-components/macro";
import { Col } from "antd";
import { IScheduleSchema } from "@app/sanity-generated-data/types";
import { IMedia, IScheduleDay } from "@app/api/types";

import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { Typography } from "../songtradr-components";

interface IDayColProps {
  schema: IScheduleSchema;
  data: IScheduleDay;
  expanded?: boolean;
}

const DayCol = ({ schema, data: { date, media }, expanded }: IDayColProps) => {
  const navigate = useNavigate();

  const [onClickDisabled, setOnClickDisabled] = useState(false);

  const handleClick = (slug: string) =>
    onClickDisabled ? null : navigate(`../${slug}`);

  const handleDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setOnClickDisabled(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setOnClickDisabled(false);
    }, 0);
  };

  const mediaTile = ({ id, slug, imageUrl, title }: IMedia) => {
    const [width, height] = [230, 230 / 1.53];
    const url = `${imageUrl}&w=${width}&h=${height}&format=auto`;
    return (
      <div
        onClick={() => handleClick(slug)}
        css={styles.mediaTileContainer}
        key={id}
      >
        <img css={styles.mediaTileImage} src={url} alt={title} />
        <div css={styles.mediaTileEpisodeBox}>
          <Typography variant={"body"} margin={false} truncate>
            {title}
          </Typography>
        </div>
      </div>
    );
  };

  const mediaLister = () => {
    return media.map((item, index) => {
      if (index === 0) {
        return mediaTile(item);
      } else {
        return (
          <div
            onClick={() => {
              (expanded || index === 1) && handleClick(item.slug);
            }}
            key={item.id}
            css={styles.episodeBox(expanded || index === 1)}
          >
            <Typography variant={schema.title.variant} margin={false} truncate>
              {item.title}
            </Typography>
          </div>
        );
      }
    });
  };

  //RENDER
  return (
    <Col
      draggable={true}
      onDragStart={handleDrag}
      onMouseUp={handleMouseUp}
      css={styles.dayCol}
    >
      <div css={styles.dayBox}>
        <Typography variant={schema.day.variant} margin={false} truncate>
          {new Date(date).toLocaleString("en-us", { weekday: "long" })}
        </Typography>
      </div>
      {mediaLister()}
    </Col>
  );
};

export default DayCol;
