import styled, { css } from "styled-components";
import { maxMedium, minLarge } from "../songtradr-components";

export const LockSongListWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 1.5rem;
`;

export const LockSongTooltip = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  & > p {
    margin-bottom: 0;
  }
`
export const SongTableWrapper = styled.div`
  table {
    width: 100%;
    border-spacing: 30px;
  }
`;

export const StyledEmptyState = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 2rem;
  padding: 4rem 2rem;
  border: ${({ border = true }) => (border ? "1px" : "0px")} solid
    var(--semantic-gray-bg-medium);
  border-radius: 8px;
  justify-content: space-between;
  flex-wrap: false;

  @media ${maxMedium} {
    flex-direction: column;
    padding: 2rem 1rem;
    justify-content: center;
    flex-wrap: true;
    text-align: center;
  }
`;

export const styles = {
  sortableTableHeader: css`
    background-color: var(--semantic-background-base);
    border-radius: 8px;
    padding: 1rem 0rem;
    justify-content: center;

    @media ${minLarge} {
      padding: 1rem 0 1rem 1.5rem;
      justify-content: start;
    }
  `,

  editDescriptionBtn: css`
    cursor: pointer;
    margin-left: 0.5rem;
  `,

  playerMargin: css`
    padding-left: 4.5rem;
  `,

  colSpacing: css`
    padding-right: 4rem;
  `,

  desktopRow: css`
    border-bottom: 1px solid var(--semantic-gray-bg-medium);
    padding: 1.5rem 0;
    /* background-color: #07131c; */
  `,

  playerMargins: css`
    margin-bottom: 1rem;
    margin-right: 1rem;
  `,

  addSongButtonOffset: css `
    margin-top: 1.5rem;
  `,

  addSongButton: css`
    float: right;
  `,

  mobileRow: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--semantic-gray-bg-medium);
  `,

  underline: (selected: boolean) => {
    return css`
      margin-right: 0.25rem;
      border-bottom: ${selected
        ? "1px solid var(--semantic-background-top)"
        : "none"};
    `;
  },

  headerLink: css`
    cursor: pointer;
    margin-right: 1.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
  `,

  headerImgWrapper: css`
    width: 16px;
    height: 26px;
  `,

  hasClickAction: css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
  `,

  editButton: css `
  cursor: pointer;
  `,

  dragIcon: css`
    cursor: pointer;
    margin-bottom: 1px;
  `,

  separator: css`
    margin: 1rem 0;
    width: 100%;
  `,

  licenseOnText: css`
    white-space: nowrap;
    margin-left: -0.65rem;
  `,

  confirmedText: css`
    margin-left: -1rem;
  `,

  songtradrLeftMargin: css`
    margin-left: 1.5rem;

    @media screen and (max-width: 1200px) {
      margin-left: 0.5rem;
    }
  `,

  mobileBottomRow: css`
    justify-content: space-between;
    height: 36px;
    @media screen and (max-width: 480px) {
      justify-content: center;
    }
  `,

  bottomRightRow: css`
    max-width: 9rem;
    column-gap: 8px;
  `,

  topRowPadding: css`
    padding-left: 0.5rem;
    @media screen and (min-width: 1200px) {
      padding-left: 2rem;
    }
    @media screen and (min-width: 1440px) {
      padding-left: 5rem;
    }
  `,

  imgFlip: css`
    transform: scaleY(-1);
  `,

  twoLineEllipsis: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  dragSpacer: css`
    width: 14.4px;
  `,
  emptyStateImg: css`
    @media ${minLarge} {
      margin-right: 3rem;
    }
  `,
  emptyStateText: css`
    justify-content: start;
    margin-right: 1rem;
    @media ${maxMedium} {
      justify-content: center;
      margin-right: 0rem;
    }
    :first-child {
      @media ${maxMedium} {
        margin-bottom: 1rem;
      }
    }
  `,
  emptyStateButtons: css`
    gap: 1rem;
  `,
  descEditIcon: (verified: boolean) => {
    return css`
      opacity: ${verified ? "0.25" : "1"};
      transform: scale(0.75);
      margin-top: -3px;
      cursor: ${verified ? "normal" : "pointer"};
    `;
  },
};
