import React from "react";
import { PortableTextBlock as IPortableTextBlock } from "@portabletext/types";
import { Typography } from "../../../components/songtradr-components";

const PortableTextBlock = (props: any) => {
  const value: IPortableTextBlock = props.value;
  const text = props.children?.filter((child: string) => child !== "");

  return text.length ? (
    <Typography
      variant={
        value.style === "normal" ? "body" : value.style ?? ("body" as any)
      }
    >
      {text}
    </Typography>
  ) : (
    <br />
  );
};

export default PortableTextBlock;
