import React from "react";
import { ISanityTableSchema } from "@app/sanity-generated-data/types";

import { StyledTable } from "../styles";
import { Typography } from "../../../components/songtradr-components";

const PortableTextTable = (props: any) => {
  const value: ISanityTableSchema = props.value;

  if (!value.rows) return null;
  const header = value.rows[0].cells;
  const dataRows = value.rows.slice(1);

  return (
    <StyledTable>
      <thead>
        <tr>
          {header?.map((text) => (
            <th key={(text[0].toLowerCase() + text.slice(1)).replace(" ", "")}>
              <Typography variant={"bodyBold"}>{text}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataRows.map((row) => (
          <tr key={row._key}>
            {row.cells?.map((cell) => (
              <td key={`${row._key}-${cell.toLowerCase()}`}>
                <Typography variant={"body"}>{cell}</Typography>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PortableTextTable;
