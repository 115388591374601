import React, { useContext, useState, useRef } from "react";

const audioPlaying = React.createContext(false);
const audioTag = React.createContext("");
const updateAudioTag = React.createContext(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  (audioTag: string | undefined) => {}
);
const audioDuration = React.createContext(0);

export function useAudioPlaying() {
  return useContext(audioPlaying);
}

export function useAudioTag() {
  return useContext(audioTag);
}

export function useUpdateAudioTag() {
  return useContext(updateAudioTag);
}

export function useAudioDuration() {
  return useContext(audioDuration);
}

export function AudioProvider({ children }: { children: any }) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentAudioTag, setCurrentAudioTag] = useState<string>("");
  const [trackProgress, setTrackProgress] = useState(0);

  const audioRef = useRef(new Audio(""));
  const intervalRef = useRef(0);

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = window.setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        setTrackProgress(0);
        clearInterval(intervalRef.current);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 50);
  };

  const handleAudio = (audioTag: string | undefined) => {
    if (!audioTag) {
      audioRef.current.pause();
      setIsPlaying(false);
      clearInterval(intervalRef.current);
      setCurrentAudioTag("");
      return;
    } else if (audioTag !== currentAudioTag) {
      const oldAudioRef = audioRef.current;
      audioRef.current.pause();
      setCurrentAudioTag(audioTag);
      audioRef.current = new Audio(audioTag);
      setTrackProgress(audioRef.current.currentTime);
      setIsPlaying(true);
      startTimer();
      audioRef.current.play().catch(() => {
        oldAudioRef.pause();
      });
    } else if (!isPlaying) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
        startTimer();
      }).catch(() => {
        audioRef.current.pause();
      });
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  return (
    <audioPlaying.Provider value={isPlaying}>
      <updateAudioTag.Provider value={handleAudio}>
        <audioTag.Provider value={currentAudioTag}>
          <audioDuration.Provider value={trackProgress}>
            {children}
          </audioDuration.Provider>
        </audioTag.Provider>
      </updateAudioTag.Provider>
    </audioPlaying.Provider>
  );
}
