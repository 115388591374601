import Api from "@app/api";
import React, { ReactElement, ReactNode, useReducer } from "react";
import SearchContext, { initialSearchState } from ".";
import { ISearchResult, ISearchState } from "./interfaces";
import { isNullOrEmptyString } from "@app/utils";

const reducer = (state: ISearchState, newState: ISearchState) => {
  return {
    ...state,
    ...newState,
  };
};

const SearchProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [searchState, dispatch] = useReducer(reducer, initialSearchState);

  const setState = (newState: ISearchState) => {
    //RETURN
    dispatch(newState);
  };

  const search = (q: string, size?: number) => {
    if (isNullOrEmptyString(q)) {
      setState({ ...searchState, size: size ?? 10, q: q, searchResult: [] });
    } else {
      setState({ ...searchState, q: q, searchResult: [], loading: true });
      Api.request({
        method: "GET",
        url: `/search`,
        params: {'q': q, 'size': size ?? searchState.size}
      })
        .then((result) => {
          const data: ISearchResult = result.data;
          setState({
            ...searchState,
            q: q,
            size: size ?? searchState.size,
            searchResult: data.searchEntries,
            totals: data.totals,
            loading: false,
          });
        })
        .catch(() => {
          setState({ ...searchState, q, loading: false });
        });
    }
  };

  return (
    <SearchContext.Provider
      value={{
        searchState,
        setState,
        search,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export default SearchProvider;
