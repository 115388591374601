import {
  useContext,
  createContext,
  FC,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useMounted } from "../hooks/useMounted";
import { getWindowDimensions, WindowDimensions } from "./getWindowDimensions";
import { isServer } from "./isServer";
import React from "react";

const UNAVAILABLE = Symbol();

export const WindowDimensionsContext = createContext<
  WindowDimensions | null | typeof UNAVAILABLE
>(null);

export const WindowDimensionsProvider: FC<{
  children?: ReactNode | undefined;
}> = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState<
    WindowDimensions | null | typeof UNAVAILABLE
  >(() => (isServer() ? UNAVAILABLE : getWindowDimensions()));

  useEffect(() => {
    if (isServer()) {
      return;
    }
    const handleResize = () => {
      // TODO: maybe wrap with requestAnimationFrame?
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowDimensionsContext.Provider value={windowDimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export const useWindowDimensions = (
  serverDimensions?: WindowDimensions
): WindowDimensions => {
  const mounted = useMounted();
  const windowDimensions = useContext(WindowDimensionsContext);

  if (windowDimensions === UNAVAILABLE) {
    if (serverDimensions) {
      return serverDimensions;
    }
    throw new Error("Can not useWindowDimensions in this environment");
  }

  if (!mounted && serverDimensions) {
    return serverDimensions;
  }

  if (!windowDimensions) {
    throw new Error(
      "Can not useWindowDimensions outside of <WindowDimensionsProvider />"
    );
  }

  return windowDimensions;
};
