import React, {useCallback, useState, useEffect} from "react";
import UserPool from "@app/environments/UserPool";
import { CognitoUserAttribute }  from "amazon-cognito-identity-js"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, Input } from "antd";
import { Button, Typography } from "../../components/songtradr-components";
import { PasswordErrorsObject, checkPasswordValidity, checkIfAllPasswordRequirementsAreMet } from "@app/utils/helpers/passwordValidator";
import { PasswordValidation } from "../../components";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import "styled-components/macro";
import { styles } from './styles';
import { checkIfAuthenticationAlreadyExists } from "@app/utils/helpers/Session";
import { FacebookButton } from "@app/components";
import { handleSignInFacebook } from "../../utils/helpers/FacebookRedirect";
import { initialErrorState, useErrorContext } from "@app/errorContext";

const SignUp: React.FC = () => {
    const [signUpUsername, setSignUpUsername] = useState('');
    const [signUpEmail, setSignUpEmail] = useState('');
    const [passwordField, setPasswordField] = useState('');
    const [userStartedTypingPassword, setUserStartedTypingPassword] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState<PasswordErrorsObject[]>(checkPasswordValidity(''));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const fromParam = searchParams.get('from');
    const { errorState, setErrorState } = useErrorContext();

    useEffect(() => {
        if (checkIfAuthenticationAlreadyExists()) {
            navigate('/signin')
        }
    }, [navigate, setErrorState]);

    const clearInputs = useCallback(() => {
        setPasswordField('');
        setSignUpEmail('');
        setSignUpUsername('');
    }, [])

    const handleSwitch = useCallback(() => {
        clearInputs();
        setUserStartedTypingPassword(false);
        navigate(`/signin?from=${fromParam ?? '/'}`)
        setErrorState(initialErrorState);
    }, [clearInputs, navigate, fromParam, setErrorState])

    const handlePasswordChange = useCallback((password: string) => {
        setPasswordField(password);

        if (!userStartedTypingPassword) {
            setUserStartedTypingPassword(true)
        }

        const errors = checkPasswordValidity(password);
        setPasswordErrors(errors);
    }, [userStartedTypingPassword])

    const handleSignUp = useCallback((event?: any) => {
        event?.preventDefault();
        setErrorState(initialErrorState);
        if ( signUpEmail && signUpUsername && passwordField && checkIfAllPasswordRequirementsAreMet(passwordErrors).length === 0 ) {
            const attributeList: CognitoUserAttribute[] = [
                new CognitoUserAttribute({
                    Name: 'email',
                    Value: signUpEmail,
                }),
            ];
                UserPool.signUp(signUpUsername, passwordField, attributeList, [], (err, data) => {
                    if (err) {
                        const error = handleError(err);
 
                        if (error) {
                            setErrorState({message: error});
                        }
                    }
                    if (!err && !data?.userConfirmed) {
                        setErrorState(initialErrorState);
                        navigate(`/signup/confirm/${signUpUsername}`)
                    }
                })
        } 
    }, [navigate, passwordErrors, passwordField, setErrorState, signUpEmail, signUpUsername])

    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSignUp();
        }
    }, [handleSignUp])


    
    return (
        <div css={styles.topOffset}>
            <div css={styles.titleUnderline}>
                 <Typography variant="h2" css={styles.title}>Sign Up</Typography>
            </div>
           
            <Row  gutter={[16,16]} >
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                    <Row gutter={[0, 24]}>
                        {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                       
                        
                        <Col span={24}> 
                            <Input
                                autoComplete="username"
                                css={styles.inputHeight}
                                placeholder="Username"
                                value={signUpUsername}
                                onKeyDown={handleEnterKeyDown}
                                onChange={(event) => setSignUpUsername(event.target.value)} />
                        </Col>
                        <Col span={24}>
                            <Input     
                                autoComplete="email"                           
                                css={styles.inputHeight}
                                placeholder="Email"
                                type="email"
                                value={signUpEmail}
                                onKeyDown={handleEnterKeyDown}
                                onChange={(event) => setSignUpEmail(event.target.value)} />
                        </Col>
                        <Col span={24}>
                                <form autoComplete="false">
                                <>
                                    <Input
                                        autoComplete="new-password"
                                        css={styles.inputHeight}
                                        value={passwordField}
                                        type="password"
                                        placeholder="Password"
                                        onKeyDown={handleEnterKeyDown}
                                        onFocus={() => { handlePasswordChange(passwordField)}}
                                        onChange={(event) => handlePasswordChange(event.target.value)} /> 
                                    {userStartedTypingPassword && <PasswordValidation errors={passwordErrors} />}   
                                    </>     
                                </form>
                        </Col>
                        <Col span={24}>
                            <div css={styles.signUpButtons}>
                                <Button variant="primary" onClick={handleSignUp}>Sign Up</Button> 
                                <Typography variant="body" style={{ margin: '0px'}}>or</Typography>
                                <FacebookButton onClickCallback={handleSignInFacebook} value="Continue With Facebook" />                             
                            </div>
                        </Col>
                        <Col>
                            <Typography variant="body">Already have an account? <span css={styles.signInLink} onClick={() => handleSwitch()}>Sign In</span></Typography>
                        </Col>
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
            </Row>
           
        </div>
    )
}

export default SignUp