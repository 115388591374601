import { Button } from "@app/components/songtradr-components";
import { Col , Input} from "antd";
import React, { useState, useCallback } from "react";
import { styles } from "./styles";
import { initialErrorState, useErrorContext } from "@app/errorContext";
import "styled-components/macro";
import { forgotPassword } from "@app/api";
import { IForgotPasswordResponse } from "./ResetPassword";
import { ResetType } from "@app/enums/ResetType";
import { ErrorMessages } from "@app/enums/ErrorMessages";
import { useNavigate } from "react-router-dom";

interface IResetRequestProps {
    onSuccessfulResetRequest: (data: IForgotPasswordResponse, usernameOrEmail: string) => void;
    handleFailure: (err: any) => void;
}

const ResetRequest = ({ onSuccessfulResetRequest, handleFailure }: IResetRequestProps) => {
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const { setErrorState } = useErrorContext();
    const navigate = useNavigate();

    const handleResetPasswordRequest = useCallback(async () => {
        if (usernameOrEmail) {
            try {
                const response = await forgotPassword(usernameOrEmail);

                if (!response?.data) return;

                const forgotPasswordObj = response.data as IForgotPasswordResponse;
                const { resetType } = forgotPasswordObj;

                if (resetType === ResetType.CODE){
                    setErrorState(initialErrorState);
                    onSuccessfulResetRequest(forgotPasswordObj, usernameOrEmail);
                } else if (resetType === ResetType.FORCED) {
                    setErrorState({message: ErrorMessages.USER_NEEDS_PASSWORD_RESET});
                    navigate('/signin');
                }

              } catch (error: any) {
                 handleFailure(error?.response?.data)
              }
           
        }
    }, [handleFailure, navigate, onSuccessfulResetRequest, setErrorState, usernameOrEmail])


    const handleEnterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleResetPasswordRequest();
        }
    }, [handleResetPasswordRequest])

    return (
        <>
            <Col span={24}>
            <Input 
                css={styles.inputHeight}
                placeholder="Username or Email"
                value={usernameOrEmail}
                onKeyDown={(event) => handleEnterKeyDown(event)}
                onChange={(event) => setUsernameOrEmail(event.target.value)}
                />
            </Col>
            <Col span={24}>
                <Button variant="primary" onClick={handleResetPasswordRequest}> Reset my password </Button> 
            </Col>
        </>
       
    )
}

export default ResetRequest;