import React from "react";
import { Row } from "antd";
import { ISearchPageSchema } from "@app/sanity-generated-data/types";
import { PortableText } from "@app/sanity-components";
import { MissingResultsWrapper } from "../../pages/search/styles";

interface IMissingResultsProps {
  schema: ISearchPageSchema["missingResults"];
}

const MissingResults = ({ schema }: IMissingResultsProps) => {
  return (
    <MissingResultsWrapper as={Row} align={"middle"} justify={"center"}>
      <PortableText value={schema} />
    </MissingResultsWrapper>
  );
};

export default MissingResults;
