import styled, { css, createGlobalStyle } from "styled-components";

/**
 * Use this to apply the current theme text-color to an element
 */
export const textStyles = css`
  color: var(--semantic-text-normal);
`;

/**
 * Use this to apply the current theme background-color to an element
 */
export const bgStyles = css`
  background-color: var(--semantic-background-base);
`;

export const bodyFontStyles = css`
  font-size: var(--font-size-16);
  line-height: var(--line-heights-24);
`;

/**
 * Use this to apply the current theme font-settings to an element
 */
export const documentFontStyles = css`
  font-family: var(--font-sans-serif);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  ${bodyFontStyles}
`;

/* <html>, <body> */
export const documentStyles = css`
  ${textStyles}
  ${bgStyles}
  ${documentFontStyles}
  -webkit-tap-highlight-color: var(--semantic-background-base);
`;
/**
 * Attention: Use either `DocumentStyles` or `Body`
 */
export const DocumentStyles = createGlobalStyle<{
  targetElement?: "body" | "html";
}>`
${({ targetElement = "html" }) => targetElement} {
  ${documentStyles}
}
`;
/**
 * Attention: Use either `DocumentStyles` or `Body`
 */
export const Body = styled.body(() => documentStyles);

/* <hr> */
const hrMargin = css`
  margin: var(--spacing-small) var(--spacing-medium);
`;
export const hrStyles = css<{
  margin?: boolean;
}>`
  ${({ margin = true }) => margin && hrMargin};
  border: none;
  border-top: 1px solid var(--semantic-text-normal);
`;
export const Hr = styled.hr.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => hrStyles);

/* <strong> */
export const boldStyles = css`
  font-weight: var(--font-weight-bold);
`;
export const semiBoldStyles = css`
  font-weight: var(--font-weight-semi-bold);
`;
export const Bold = styled.strong.withConfig({
  shouldForwardProp: (p) => !["semiBold"].includes(p),
})<{ semiBold?: boolean }>(({ semiBold }) =>
  semiBold ? semiBoldStyles : boldStyles
);

/* <code> */
export const monoStyles = css`
  font-family: var(--font-monospace);
`;
export const Code = styled.code(() => monoStyles);

/* In the DL this is currently called "body" but I find that confusing
   ref https://app.asana.com/0/1203059499831427/1203928601105084/f */

const noBottomMargin = css`
  margin-bottom: 0;
`;
export const paragraphStyles = css<{
  margin?: boolean;
}>`
  ${bodyFontStyles}
  ${textStyles}
  ${({ margin = true }) => !margin && noBottomMargin}
`;
export const P = styled.p.withConfig({
  shouldForwardProp: (p) => !["margin"].includes(p),
})(() => paragraphStyles);

/* <small> */
export const smallStyles = css`
  font-size: var(--font-size-14);
  line-height: var(--line-heights-20);
`;
export const xSmallStyles = css`
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
`;
export const Small = styled.small.withConfig({
  shouldForwardProp: (p) => !["xs"].includes(p),
})<{ xs?: boolean }>(({ xs }) => (xs ? xSmallStyles : smallStyles));
export const XSmall = styled.small(() => xSmallStyles);
