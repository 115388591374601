import groq from "groq";
import sanityClient from "@sanity/client";
import { ISanityCache } from "./types";

const client = sanityClient({
  projectId: "berdyqaf",
  dataset: "production",
  useCdn: false,
  withCredentials: true,
  token:
    "skAJCzl6d331m1r0zrFNbAMX9C1AFoA9k1dCN655l8q4KWgIZ9hlHrSgqvihpEeLqtlktK92ZUuOCx84FWdVvl01kKM6NkXPfHuazCwXGFQiU3C3ov02sQFqyM9ybHJvMD0TisvUgPs5hTOEdqMZnmMe5rFILGKsqc2HmIi8Smxme3mqtJ1x",
  apiVersion: "2022-12-01",
});

const bucketQueries: Record<keyof ISanityCache, string> = {
  settings: `(_id in path("settings.**") || _id in path("drafts.settings.**"))`,
  pages: `((_id in path("pages.**") || _id in path("drafts.pages.**")) || _type == "legals")`,
  hubSpotForms: `_type == "hubSpotForm"`,
};

const bucketProjections: Record<keyof ISanityCache, string> = {
  settings: groq`{..., 
              _type == "header-config" => {
                links[] {..., _type == "internal" => { "url": route-> url.current}}
              },
              _type == "footer-config" => {
                categorizedLinks[] {..., links[] {..., _type == "internal" => { "url": route-> url.current}}},
                socialLinks[] {..., _type == "internal" => { "url": route-> url.current}},
                stickyMobileLinks[] {..., _type == "internal" => { "url": route-> url.current}},
              }
            }`,
  pages: groq`{..., "route": route-> url.current, "seo": seo{..., "blockCrawlers": ^.route-> blockCrawlers }}`,
  hubSpotForms: `{...}`,
};

export const fetchCacheBucket = async (key: keyof ISanityCache) =>
  await client.fetch(
    `*[${bucketQueries[key]} && !(_id in path("drafts.**"))]${bucketProjections[key]}`
  );

export const fetchLatestById = (id: string, callback: Function) => {
  return client
    .fetch(`*[_id == "${id}" || _id == "drafts.${id}"]`)
    .then((res: any) => callback(filterForLatest(res)[0] ?? {}));
};

export const fetchLatestBucket = (
  key: keyof ISanityCache,
  callback: Function
) => {
  return client
    .fetch(`*[${bucketQueries[key]}]${bucketProjections[key]}`)
    .then((res: any) => callback(filterForLatest(res) ?? []));
};

function filterForLatest(res: any) {
  return res.filter(
    (p: any) =>
      p._id.includes("drafts") ||
      !res.some((x: any) => x._id.includes("drafts") && x._id.includes(p._id))
  );
}
