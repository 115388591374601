import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcJcb } from "@fortawesome/free-brands-svg-icons";
import { faCreditCardFront } from "@fortawesome/pro-solid-svg-icons";
export enum CardBrand {
    VISA = 'Visa',
    MASTERCARD = 'Mastercard',
    AMERICAN_EXPRESS = 'American Express',
    DISCOVER_DINERS_CLUB = 'Discover & Diners Club',
    CHINA_UNIONPAY = 'China UnionPay',
    JAPAN_CREDIT_BUREAU = 'Japan Credit Bureau (JCB)',
    CARTES_BANCAIRES = 'Cartes Bancaires',
    EFTPOS = 'eftpos'
}

export const getCCIcon = (cardBrand: CardBrand) => {
    switch(cardBrand) {
        case CardBrand.VISA:
            return faCcVisa
        case CardBrand.MASTERCARD: 
            return faCcMastercard
        case CardBrand.AMERICAN_EXPRESS:
            return faCcAmex
        case CardBrand.DISCOVER_DINERS_CLUB: 
            return faCcDiscover
        case CardBrand.JAPAN_CREDIT_BUREAU: 
            return faCcJcb
         default: 
            return faCreditCardFront

    }
}