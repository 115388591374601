import React from "react";
import { Link } from "@app/components";
import PortableTextBlock from "./Block";
import PortableTextTable from "./Table";
import PortableTextListItem from "./ListItem";

const components = {
  types: {
    table: PortableTextTable,
  },
  marks: {
    link: ({ value, children }: any) => {
      return <Link to={value?.href}>{children}</Link>;
    },
  },
  block: PortableTextBlock,
  listItem: {
    bullet: PortableTextListItem,
    number: PortableTextListItem,
  },
};

export default components;
