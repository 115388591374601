import React from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import { Typography, useWindowDimensions } from "../songtradr-components";
import { ISearchBarSchema } from "@app/sanity-generated-data/types";
import { SearchBar } from "@app/components";
import { StyledSearchBarSection } from "./styles";
import SearchPageResults from "./SearchPageResults";

// TYPES
interface ISearchBarSectionProps {
  schema: ISearchBarSchema;
  searchPageSanityId?: string;
}

// COMPONENT
const SearchBarSection = ({
  schema,
  searchPageSanityId,
  ...rest
}: ISearchBarSectionProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  //RENDER
  return (
    <>
      <StyledSearchBarSection as={Row} {...rest}>
        <Col flex={1}>
          <Typography variant={schema?.header?.variant ?? "h2"}>
            {isMobile ? "Search" : schema?.header?.text ?? "Search"}
          </Typography>
          <SearchBar header={false} schema={schema} />
        </Col>
      </StyledSearchBarSection>
      {searchPageSanityId && (
        <SearchPageResults sanityId={searchPageSanityId} />
      )}
    </>
  );
};

export default SearchBarSection;
