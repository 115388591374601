import { useAppearanceAdd, useFetchSearchResults } from "@app/api";
import { IAddNewSong, ISong } from "@app/api/types";
import ProgressBar from "@app/components/progress-bar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleQuestion, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Input,
  TextArea,
  Typography,
} from "../songtradr-components";
import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "styled-components/macro";
import { SongPreviewPlayer } from "../song-player";
import WaitSpin from "../wait-spin/WaitSpin";
import { StyledModal, styles } from "./styles";
import { GenerateSongObject } from "@app/utils/helpers/SongModal";
import useErrorDialogModal from "./useErrorDialogModal";
import { getSongFromSearchResults } from "@app/utils";
import { useUserContext } from "@app/userContext";
import { eUserType } from "@app/userContext/interfaces";

enum Steps {
  SONG_SEARCH_STEP = 1,
  SONG_REVIEW_STEP = 2,
  CONFIRMATION_STEP = 3,
  UNRELEASED_SONG_STEP = 4,
}

const steps = [
  {
    title: "Select a song",
    step: Steps.SONG_SEARCH_STEP,
    buttonText: "Search",
  },
  {
    title: "Review",
    step: Steps.SONG_REVIEW_STEP,
    buttonText: "Add Song",
  },
  {
    title: "Confirmation",
    step: Steps.CONFIRMATION_STEP,
    buttonText: "Submit Without Description",
  },
  {
    title: "New Song",
    step: Steps.UNRELEASED_SONG_STEP,
    buttonText: "Add Song",
  },
];

interface IAddSongProps {
  data: ISong[] | undefined;
  parentId?: string;
}

const useAddSongModal = (props: IAddSongProps) => {
  //STATE
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.SONG_SEARCH_STEP);
  const [selectedTrack, setSelectedTrack] = useState<IAddNewSong>();
  const [results, setResults] = useState<ISong[]>();
  const [queryCombined, setQueryCombined] = useState("");
  const [querySong, setQuerySong] = useState("");
  const [queryArtist, setQueryArtist] = useState("");
  const [oldQuerySong, setOldQuerySong] = useState("");
  const [oldQueryArtist, setOldQueryArtist] = useState("");
  const [displayResults, setDisplayResults] = useState(false);
  const { pendingAddSong, addSongRequest } = useAppearanceAdd();
  const { pendingSearch, searchResultsGet } = useFetchSearchResults();
  const [ErrorDialog, handleOpenErrorDialog] = useErrorDialogModal({
    fallbackTitle: "Error adding song",
    fallbackMessage: "",
    errorModalForAddSong: true
  });
  const { userState } = useUserContext();
  const textField = useRef(null);
  const SongNameField = useRef<HTMLInputElement>(null);

  const handleOpenSongModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentStep(Steps.SONG_SEARCH_STEP);
    setResults(undefined);
    setQueryCombined("");
    setQueryArtist("");
    setOldQueryArtist("");
    setQuerySong("");
    setOldQuerySong("");
    setDisplayResults(false);
  };

  const checkIfUnreleasedFormWasEdited = () => {
    if (oldQueryArtist.trim() !== queryArtist.trim() || oldQuerySong.trim() !== querySong.trim()) {
      return true
    }
    return false
  }

  const checkIfSongAlreadyExists = (results: ISong[] | undefined) => {
    const unreleasedObject: IAddNewSong = GenerateSongObject(querySong, queryArtist);
    const trackObject = results ? getSongFromSearchResults(unreleasedObject, results) : null;
    if (trackObject) {
      setSelectedTrack(trackObject);
      return trackObject;
    } else {
      setSelectedTrack(unreleasedObject);
      return unreleasedObject;
    }
  }

  // FETCH SEARCH PREVIEW
  const handleSearchPreview = () => {
    if (currentStep !== Steps.UNRELEASED_SONG_STEP) { // if we are doing another fetch on the unreleased song step we don't want to reset the results because we don't display the new results
      setResults(undefined);
    }
    setDisplayResults(true);
    if (queryCombined.length) {
      searchResultsGet(queryCombined.toLowerCase(), 10)
        .then((res) => {
          if (currentStep === Steps.UNRELEASED_SONG_STEP) {
            handleUnreleasedSongSearch(res as ISong[])
          } else {
            setResults(res as ISong[])
          }

        })  
        // eslint-disable-next-line no-console
        .catch((e) => console.log(e))
        .finally();
    } else {
      setDisplayResults(false);
      if (currentStep !== Steps.UNRELEASED_SONG_STEP) {
        setResults(undefined);
      }
    }
  };

  const handleUnreleasedSongSearch = (result: ISong[]) => {
    const songObject = checkIfSongAlreadyExists(result);
    if (description === '') {
      setCurrentStep(Steps.CONFIRMATION_STEP)
    } else {
      handleSubmitSong(songObject);
    }
  }
  
  const handleSwitchToUnreleasedSongStep = (isNavigatingFromConfirmation?: boolean) => {
    if (!isNavigatingFromConfirmation) { // this is here because we don't want to override the original input when navigating back from confirmation page
      setOldQueryArtist(queryArtist);
      setOldQuerySong(querySong);
    }
    setSelectedTrack(undefined);
    setCurrentStep(Steps.UNRELEASED_SONG_STEP);
  }

  const handleStepSwitchBackwards = () => {
    switch (currentStep) {
      case Steps.SONG_SEARCH_STEP:
        handleOpenSongModal();
        break;
      case Steps.SONG_REVIEW_STEP:
        handleInput(oldQuerySong, oldQueryArtist);
        setCurrentStep(Steps.SONG_SEARCH_STEP);
        break;
      case Steps.UNRELEASED_SONG_STEP:
        if (selectedTrack) {
          setSelectedTrack(undefined);
        }
        handleInput(oldQuerySong, oldQueryArtist);
        setCurrentStep(Steps.SONG_SEARCH_STEP);
        break;
      case Steps.CONFIRMATION_STEP:
        if (!selectedTrack) {
          handleSwitchToUnreleasedSongStep(true);
        } else {
          setCurrentStep(Steps.SONG_REVIEW_STEP);
        }
        break;
      default:
        setCurrentStep(Steps.SONG_REVIEW_STEP);
    }
  }
  const handleStepSwitchForward = () => {
    switch (currentStep) {
      case Steps.SONG_SEARCH_STEP:
        handleSearchPreview();
        break;
      case Steps.SONG_REVIEW_STEP:
        if (description === "") {
          setCurrentStep(Steps.CONFIRMATION_STEP);
        } else {
          handleSubmitSong(selectedTrack);
        }
        break;
      case Steps.UNRELEASED_SONG_STEP:
        if (selectedTrack) {
          setSelectedTrack(undefined);
        }

        if (checkUnreleasedForm()) {
          if (checkIfUnreleasedFormWasEdited()) {
            handleSearchPreview();
          } else {
            const songObject = checkIfSongAlreadyExists(results)
            if (description === "") {
              setCurrentStep(Steps.CONFIRMATION_STEP);
            } else {
              handleSubmitSong(songObject);
            }
          }
        }
        break;
      default:
        handleSubmitSong(selectedTrack);
    }
  }
  const checkUnreleasedForm = () => {
    let errorMessage, errorTitle;

    if (!queryArtist && !querySong) {
      errorTitle = "Artist Name & Song name";
      errorMessage = "Please enter an artist and song name.";
    } else if (!querySong) {
      errorTitle = "Song name";
      errorMessage = "Please enter an song name.";
    } else if (!queryArtist) {
      errorTitle = "Artist Name";
      errorMessage = "Please enter an artist name.";
    }

    if (errorMessage) {
      handleOpenErrorDialog(errorTitle, errorMessage);
      return false;
    }

    return true;
  }

  //Handle body scrolling behind the modal
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = isModalOpen ? "hidden" : "auto";
    if (isModalOpen) {
      setTimeout(() => { // I guess you have to wait for the modal animation ti finish
        if (SongNameField.current)
          SongNameField.current.focus()
      }, 100);
      
    }
  }, [isModalOpen]);

  const handleDescription = (e: any) => {
    setDescription(e.target.value);
  };


  //SUBMIT SONG
  const handleSubmitSong = (songObject: IAddNewSong | undefined) => {
    setDescription("");
    handleOpenSongModal();
    if (songObject) {
      addSongRequest(
        Number(props.parentId),
        songObject,
        description,
        handleOpenSongModal
      )
    }
  };

  const handleSetSelectedTrack = (id: string) => {
    const _st = results?.find((song: ISong) => song.description === id);
    if (_st) {
      setSelectedTrack(_st);
      setCurrentStep(2);
    }
  };

  const handleTrimAndSetQueryCombined = (song: string, artist: string) => {
    if (song && artist && song.trim().length > 0 && artist.trim().length > 0) {
      setQueryCombined(`${song.trim()}, ${artist.trim()}`);
    } else if (song && song.trim().length > 0) {
      setQueryCombined(song.trim());
    } else if (artist && artist.trim().length > 0) {
      setQueryCombined(artist.trim());
    } else {
      setQueryCombined("");
    }
  }
  const handleInput = (song: string, artist: string) => {
    setQuerySong(song);
    setQueryArtist(artist);
    handleTrimAndSetQueryCombined(song, artist);
  }
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (currentStep === Steps.SONG_SEARCH_STEP) {
        handleSearchPreview();
      } else {
        handleStepSwitchForward();
      }
    }
  };

  const tooltipText = (
    <div>
      Tip: Less is more! For better results:
      <ul>
        <li>use only key words in song names,</li>
        <li>leave out punctuation like: ( . ' , ! " - ] &,</li>
        <li>skip (feat. artists) and multiple artist names.`</li>
      </ul>
    </div>
  );

  const displayedResults = (
    <Col span={24}>
      {results ? (
        results.length > 0 ? (
          results.map((song: ISong) => {
            return (
              <Row css={styles.songList} key={song.description}>
                <div css={styles.insidesonglist}>
                  <Col>
                    <SongPreviewPlayer
                      name={song.name}
                      artists={song.artists}
                      audioPreview={song.audioPreview}
                      album={song.album}
                      image={song.imageUrl}
                      durationMs={song.durationMs}
                      isrc={song.isrc}
                      userIsAdmin={userState?.userType === eUserType.Admin}
                    />
                  </Col>
                  <Col>
                    <Button
                      css={styles.btnsixing}
                      disabled={pendingAddSong ?? false}
                      onClick={() =>
                        handleSetSelectedTrack(song?.description ?? "")
                      }
                      size="small"
                      variant={
                        selectedTrack?.id === song.id ? "subtle" : "basic"
                      }
                    >
                      Select
                    </Button>
                  </Col>
                </div>
              </Row>
            );
          })
        ) : (
          <div css={styles.noResultsContainer}>
            <div>
              <div css={styles.noresultscount}>0 matching songs</div>
              <div css={styles.noresultsoptions}>
                We didn't find any matches of that song. Continue your search
                above!
              </div>
            </div>
          </div>
        )
      ) : (
        <div css={styles.loading}>
          <WaitSpin dark />
        </div>
      )}
    </Col>
  );

  const UnreleasedForm = (
    <Col span={24}>
      <div css={styles.inputcontainer}>
        <div>
          <div>
            <div css={styles.unreleasedinput}>Song Name</div>
            <Input
              ref={SongNameField}
              disabled={pendingSearch}
              autoFocus={querySong === ''}
              value={querySong}
              onChange={(e) => handleInput(e.target.value, queryArtist)}
              onKeyDown={handleEnter}
            />
          </div>
        </div>
        <div css={styles.inputcontainer}>
          <div>
            <div css={styles.unreleasedinput}>Artist Name</div>
            <Input
              disabled={pendingSearch}
              autoFocus={queryArtist === '' && querySong !== ''}
              value={queryArtist}
              onChange={(e) => handleInput(querySong, e.target.value)}
              onKeyDown={handleEnter}

            />
          </div>
        </div>
        <div css={styles.inputcontainer}>
          <div>
            <div css={styles.unreleasedinput}>Scene Description</div>
            <TextArea
              disabled={pendingSearch}
              autoFocus={queryArtist !== '' && querySong !== ''}
              ref={textField}
              onChange={handleDescription}
              css={styles.textArea}
              rows={5}
              maxLength={200}
            />
          </div>
        </div>
      </div>
    </Col>
  );

  const AddSongModal = () => {
    return (
      <>
        {ErrorDialog}
        <StyledModal
          renderAtTopOfScreen
          confirmationModal={false}
          destroyOnClose
          mask={true}
          open={isModalOpen}
          closeIcon={<FontAwesomeIcon icon={faXmark as IconProp} />}
          onOk={handleOpenSongModal}
          onCancel={handleOpenSongModal}
          footer={
            <Row align="middle">
              <Col span={6}>
                <ProgressBar
                  totalSteps={currentStep === 4 ? 2 : 4}
                  progress={currentStep}
                />
              </Col>
              <Col css={styles.cancelButton}>
                <Button
                  disabled={pendingSearch || pendingAddSong}
                  onClick={handleStepSwitchBackwards}
                  variant="subtle"
                >
                  {currentStep === Steps.SONG_SEARCH_STEP ? "Cancel" : "Back"}
                </Button>
                <Button
                  disabled={pendingSearch || pendingAddSong}
                  onClick={handleStepSwitchForward}
                  variant="primary"
                >
                  {steps[currentStep - 1]?.buttonText ?? ""}
                </Button>
              </Col>
            </Row>
          }
        >
          <Typography css={styles.gutter} variant="h3">
            {steps.find(({ step }) => step === currentStep)?.title}
          </Typography>
          <Typography css={styles.gutter} variant="body">
            {currentStep === Steps.SONG_SEARCH_STEP
              ? "Enter artist name/song title to search our database and iTunes. "
              : currentStep === Steps.SONG_REVIEW_STEP
                ? "Enter a Scene Description"
                : currentStep === Steps.UNRELEASED_SONG_STEP
                  ? `Enter Unreleased Song Details`
                  : "Are you sure you want to submit this song without a scene description?"}
            {currentStep === Steps.SONG_SEARCH_STEP && (
              <Tooltip
                placement="top"
                title={
                  <Typography
                    variant="xsmall"
                    css={{ color: "var(--semantic-background-top)" }}
                  >
                    {tooltipText}
                  </Typography>
                }
              >
                <FontAwesomeIcon
                  color="var(--semantic-background-top)"
                  icon={faCircleQuestion}
                />
              </Tooltip>
            )}
          </Typography>
          {currentStep === Steps.SONG_SEARCH_STEP ? (
            <>
              <Row
                css={styles.addSongFirstInputs}
                justify="space-between"
                gutter={[24, 0]}
              >
                <Col span={12}>
                  <Typography

                    css={styles.inputHeader}
                    margin={false}
                    variant="body"
                  >
                    Song Name
                  </Typography>
                  <Input
                    autoFocus
                    ref={SongNameField}
                    css={[styles.fullWidth, styles.input]}
                    onChange={(e) => handleInput(e.target.value, queryArtist)}
                    value={querySong ? querySong : undefined}
                    onKeyDown={handleEnter}
                  />
                </Col>
                <Col span={12}>
                  <Typography
                    css={styles.inputHeader}
                    margin={false}
                    variant="body"
                  >
                    Artist Name
                  </Typography>
                  <Input
                    css={[styles.fullWidth, styles.input]}
                    onChange={(e) => handleInput(querySong, e.target.value)}
                    value={queryArtist ? queryArtist : undefined}
                    onKeyDown={handleEnter}
                  />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Typography
                    css={styles.inputHeader}
                    margin={false}
                    variant="body"
                  >
                    {results && displayResults ? "Results" : <>&nbsp;</>}
                  </Typography>
                </Col>
                {displayResults && results && (
                  <Col>
                    <Typography
                      css={styles.inputDescription}
                      margin={false}
                      variant="small"
                    >
                      Scroll below to view results
                    </Typography>
                  </Col>
                )}
              </Row>
              {displayResults ? (
                <>
                  <Row css={styles.resultsContainer}>{displayedResults}</Row>
                   <div css={styles.unreleasedSongButtonContainer}>
                    <Typography variant="body" >Don't see the song above? Sure you're right?</Typography>
                    <Button size="small" onClick={() => handleSwitchToUnreleasedSongStep()} disabled={pendingSearch}>
                      Add Track!
                    </Button>
                  </div>
                </>
              ) : null}


            </>
          ) : currentStep === Steps.SONG_REVIEW_STEP ? (
            <>
              <Row style={{ gap: "2rem" }} align="middle" justify="space-between">
                <Col span={24}>
                  {selectedTrack && (
                    <SongPreviewPlayer
                      name={selectedTrack.name}
                      artists={selectedTrack.artists}
                      audioPreview={selectedTrack.audioPreview}
                      album={selectedTrack.album}
                    />
                  )}
                </Col>
                <Col span={8}>
                  <Typography variant="body">
                    {selectedTrack && selectedTrack?.duration}
                  </Typography>
                </Col>
                <Col span={4} />
              </Row>
              <Row>
                <Col span={24}>
                  <Typography variant="body">Scene Description</Typography>
                  <TextArea
                    autoFocus
                    ref={textField}
                    onChange={handleDescription}
                    css={styles.textArea}
                    rows={5}
                    maxLength={200}
                  />
                </Col>
              </Row>

              <Row style={{ justifyContent: "space-between" }}>
                <Col>
                  <Typography variant="body">
                    Don't copy from copyrighted sources!
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body">{description.length}/200</Typography>
                </Col>
              </Row>
            </>
          ) : currentStep === Steps.UNRELEASED_SONG_STEP ? (
            <>
              <Row>
                <Col span={24}>
                  {pendingSearch &&
                    <div css={styles.unreleasedLoading}>
                      <WaitSpin dark />
                      <Typography css={styles.gutter} variant="body"> (Please wait while we search for the song data.)</Typography>

                    </div>
                  }
                  {UnreleasedForm}</Col>
              </Row>
            </>
          ) : currentStep === Steps.CONFIRMATION_STEP ? (
            <Row style={{ gap: "2rem", marginBottom: '24px' }} align="middle" justify="space-between">
              <Col span={24}>
                {selectedTrack && (
                  <SongPreviewPlayer
                    name={selectedTrack.name}
                    artists={selectedTrack.artists}
                    audioPreview={selectedTrack.audioPreview}
                    album={selectedTrack.album}
                  />
                )}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </StyledModal>
      </>
    );
  };

  return [AddSongModal, handleOpenSongModal];
};

export default useAddSongModal;
