import { css } from "styled-components";

export const styles = {
    topMargin: css`
      margin-top: 100px;
    `, 

    errorMessage: css`
        color: #ff3c3c;
        border: 1px solid;
        padding: 4px;
    `,

    inputHeight: css`
        height: 40px;
    `,

    inputOffset: css `
        margin-top: 16px;
    `,
    resendCode: css `
        textDecoration: underline;
        cursor: pointer;
    `,

  };
  
