import { useMemo } from "react";
import { useUserContext } from "@app/userContext";
import { Settings } from "../settings";
import { isNullOrEmptyString } from "../helpers";

const useForwardUrl = (baseUrl: string | undefined): string | undefined => {
  const { userState } = useUserContext();

  // GET USER TOKEN
  const lifetimeToken = localStorage.getItem("tf_ltt");
  let userToken = userState.userToken;

  if (isNullOrEmptyString(userToken) && !isNullOrEmptyString(lifetimeToken)) {
    userToken = `ltt::${lifetimeToken}`;
  }

  // GET USER TOKEN
  const userCountry =
    navigator.language.split("-").length === 2
      ? navigator.language.split("-")[1]
      : navigator.language;

  // RETURN MEMOIZED FORWARD URL WITH ADDED PARAMS
  return useMemo(() => {
    if (baseUrl === undefined || !baseUrl.includes("forward")) return baseUrl;

    const hrefEncoded = encodeURIComponent(window.location.href);
    return `${Settings.APIBaseURL}${baseUrl}&r=${hrefEncoded}&ut=${
      isNullOrEmptyString(userToken)
        ? `ltt::${lifetimeToken ?? "none"}`
        : userToken
    }&uc=${userCountry}`;
  }, [baseUrl, userToken, lifetimeToken, userCountry]);
};

export default useForwardUrl;
