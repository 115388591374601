import React from "react";
import { IHeroSchema, IHeroSliderSchema } from "@app/sanity-generated-data/types";
import { Hero } from "@app/components";
import { IMedia } from "@app/api/types";

interface IMediaOverviewHeroProps {
  tempSchema: IHeroSchema;
  data: IMedia[] | undefined;
  sliderSchema: IHeroSliderSchema | undefined;
}

const  MediaOverviewHero = (props : IMediaOverviewHeroProps) => {
    const { tempSchema, data, sliderSchema } = props;

    if (!sliderSchema) {
        return <Hero loading/>
    }
    return (
            <Hero
                shouldPreloadImage={false}
                sliderSchema={sliderSchema}
                loading={false}
                data={data}
                heroSchema={!data ? tempSchema : undefined}
            />
        
       
    );
};

export default MediaOverviewHero;
