import { parseJwt } from "./parseJWT";


const checkIfUserPartOfFeaturePreview = (idToken: string) => {
    try {
        const userData = parseJwt(idToken);
        if (userData["cognito:groups"]) {
            for (const group of userData["cognito:groups"]) {
                if (group === "feature-preview") {
                    return true;
                }
            }
        }
        return false
    } catch {
        return false
    }
}

export default checkIfUserPartOfFeaturePreview;