import { createGlobalStyle } from "styled-components";
import { themeToCssVariables } from "./Theme";

/**
 * @deprecated please migrate to Theme
 */
export interface CSSThemeProps {
  light: Record<string, string>;
  dark: Record<string, string>;
  mode?: "light" | "dark" | "system";
}

/**
 * @deprecated please migrate to createGlobalTheme or createScopedTheme
 */
export const CSSTheme = createGlobalStyle<CSSThemeProps>(
  ({ light, dark, mode = "system" }) => {
    if (mode === "system") {
      return [
        `:root{${themeToCssVariables({ theme: light })}};`,
        "@media (prefers-color-scheme: dark) {",
        `  :root{${themeToCssVariables({
          theme: dark,
        })}};`,
        "}",
      ].join("\n");
    }
    return `:root{${themeToCssVariables({
      theme: mode === "dark" ? dark : light,
    })}}`;
  }
);
