import { IMediaDetailsResponse, IMediaDetailsHeroData } from "@app/api/types";

export type IMediaDetailsDataContext = {
  apiLoading: boolean;
  apiRefreshing: boolean;
  detailsState: IMediaDetailsPageData;
  heroState: IMediaDetailsHeroData;
};

export type IMediaDetailsActionContext = {
  refresh: () => void;
  updateDetails: (data: IMediaDetailsPageData) => void;
  updateHero: (heroData: IMediaDetailsHeroData) => void;
  toggleFollow: () => void;
};

export enum eActionType { 
  REPLACE_ALL,
  MERGE_ALL,
  TOGGLE_FOLLOW,
}

export type DataReducerAction =
  | {
      type: eActionType.REPLACE_ALL | eActionType.MERGE_ALL;
      data: IMediaDetailsPageData;
    }
  | { type: eActionType.TOGGLE_FOLLOW };

export type IMediaDetailsPageData = IMediaDetailsResponse;
