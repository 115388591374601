import { useArtistDetails } from "@app/api";
import { HeadMeta, WaitSpin } from "@app/components";
import { useSanityData } from "@app/sanity-generated-data";
import { IArtistPageSchema } from "@app/sanity-generated-data/types";
import React, { Fragment, useEffect, useCallback } from "react";
import ArtistPageContent from "./ArtistPageContent";
import { checkIfShouldDisplayAds } from "../../utils/helpers/shouldDisplayAds";
import { useWindowDimensions } from "@app/components/songtradr-components";
import { useLocation, useParams } from "react-router-dom";
import { useUserContext } from "@app/userContext";
import ArtistPageHero from "./ArtistPageHero";
import { splitPathname, generateImageUrl } from "@app/utils/helpers/ImageUrlFormater";
import "./styles.css"
import { useContentMargins } from "@app/styles/helpers";

const MAX_CREDITS_ON_PAGE_LOAD = 30;

export const ArtistPage = ({ sanityId }: { sanityId: string }) => {
  const sanitySchema = useSanityData<IArtistPageSchema>("pages", sanityId);
  const [apiLoading, loadingCredits, apiData, artistCredits] = useArtistDetails();
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const location = useLocation();
  const { artistId } = useParams();
  const { userState } = useUserContext();
  const { margin } = useContentMargins();
  const heroSchema = sanitySchema.hero ? sanitySchema.hero[0] : null;

  const expandable = artistCredits?.credits?.length > MAX_CREDITS_ON_PAGE_LOAD;

  useEffect(() => {
    if(checkIfShouldDisplayAds()) {
      if (apiData?.name) {
        const registerAd = () => {
          (window as any)?.amplified
            ?.setParams?.({
              artist: apiData?.name,
              song: apiData?.name,
              hostname: "srv.tunefindforfans.com",
            })
            .pushAdUnit(100005775)
            .run();
        };

        if ((window as any).amplified) {
          registerAd();
        } else {
          // Initialize amplified if not already initialized
          (window as any).amplified = { init: [registerAd] };
        }
      }
    }
  }, [apiData?.name]);

  const getUrl = useCallback(() => {
    const pathData = splitPathname(location.pathname);
    const imageUrl =  generateImageUrl(pathData[1], pathData[0], pathData[0], isMobile);

    return imageUrl;
  }, [isMobile, location.pathname])
  
  // RENDER
  return (
    <div className="artist-page-wrapper">
      <ArtistPageHero schema={heroSchema} computedImgUrl={getUrl()} apiLoading={apiLoading} apiData={apiData} />
      <div className="artist-page-content-wrapper" style={{"--margin": margin} as React.CSSProperties}> 
        {apiLoading ?
          <WaitSpin />
          : 
         <Fragment>
            <div className="listening-widget-container">
              <div className="listening-widget" id="amplified_100005775"></div>
            </div>
            <HeadMeta
              title={apiData.name}
              description={apiData.bio}
              twitter={{ layout: "summary" }}
              blockCrawlers={false}
            />
            <ArtistPageContent schema={sanitySchema} data={apiData} expandable={expandable} creditsData={artistCredits} creditsLoading={loadingCredits} isMobile={isMobile} location={location} artistId={artistId} userLoggedIn={userState.authenticated}/>
          </Fragment>
        }
      </div>
    </div>
  );
};

export default ArtistPage;
