import { useWindowDimensions } from "../../components/songtradr-components";
import { white } from "../../components/songtradr-components";
// import { TWindowSize } from "@app/sanity-generated-data/types";

// todo: consider changing this to theme provider pattern

// todo: move these colors to component library
export const black = "#010216";
export const peach = "#EC7A59";
export const grey = "#80808a";
export const blue = "#8CD7E9";
export const charcoal = "#1A242C";

/** @deprecated prefer using theme values */
export const getSanityDefaults = (size?: any) => ({
  paddingX: size === "small" ? "16px" : "max(16px, min(9vw, 120px))",
  paddingY: size === "small" ? "24px" : "max(24px, min(6vw, 120px))",
  contentMaxWidth: 1600,
  header: {
    background: charcoal,
  },
  content: {
    background: black,
  },
  typography: {
    color: white,
  },
  button: { hover: { color: peach } },
  divider: {
    color: grey,
    size: "1px",
  },
  drawer: {
    background: peach,
  },
});

/** @deprecated prefer using theme values */
const useSanityStyleDefaults = () => {
  const { size } = useWindowDimensions();
  return getSanityDefaults(size);
};

export default useSanityStyleDefaults;
