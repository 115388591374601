import React from "react";
import "styled-components/macro";
import { IMediaGallerySchema } from "@app/sanity-generated-data/types";
import { IMedia } from "@app/api/types";

import { styles } from "./styles";
import SuspenseImage from "@app/components/suspense-image";
import { Typography, useWindowDimensions } from "../../songtradr-components";
import { useNavigate } from "react-router-dom";

interface IMediaGalleryTileProps {
  data: IMedia;
  schema: IMediaGallerySchema;
  width: number;
  height: number;
}

const MediaGalleryTile = ({
  data,
  schema,
  width,
  height,
  ...rest
}: IMediaGalleryTileProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const navigate = useNavigate();
  const { mediaTitles } = schema;
  const { slug, title, imageUrl } = data;

  return (
    <div
      {...rest}
      css={styles.tileWrapper}
      onClick={() => navigate(`../${slug}`)}
    >
      <SuspenseImage src={imageUrl} width={width} height={height} alt={title} />
      <div css={styles.tileText}>
        <Typography
          variant={mediaTitles.variant}
          inverted={!isMobile}
          margin={false}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default MediaGalleryTile;
