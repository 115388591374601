import { maxMedium, maxSmall, minLarge } from "../songtradr-components";
import { Modal } from "antd";
import styled, { css } from "styled-components";

interface IStyledModalProps {
  confirmationModal: boolean;
  renderAtTopOfScreen?: boolean;
  accountModal?: boolean;
  errorModalForAddSong?: boolean;
  // replyWithSongModal?: boolean;
  isSignUpModal?: boolean; // Add the isSignUpModal prop here
}

export const StyledModal = styled(Modal).withConfig({
  shouldForwardProp: (prop) => !["confirmationModal"].includes(prop),
})(
  ({
    renderAtTopOfScreen,
    errorModalForAddSong,
    confirmationModal,
    accountModal,
    isSignUpModal, // New prop for useSignUpModal
  }: IStyledModalProps) => {
    return css`
      padding: 0rem;

      &.ant-modal {
        min-width: 100vw;
        display: flex;
        justify-content: center;
      }

      //Make the sign in and sign up modals show up higher on the screen than the other modals
      @media ${minLarge} {
        top: ${renderAtTopOfScreen ? "1rem" : errorModalForAddSong ? "13rem" : ''};
      }
      @media ${maxMedium} {
        top: 0rem;
        margin: 0;
      }
      @media ${maxSmall} {
        top: 0rem;
        margin: 0;
      }

      .ant-modal-body {
        padding: 0rem;
      }

      .ant-modal-content {
        background: var(--semantic-background-middle);
        width: ${confirmationModal
          ? "360px;"
          : accountModal
          ? "450px"
          : "680px;"};
        padding: 2.5rem;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        @media ${maxMedium} {
          /* border-radius: ${!accountModal ? "0.5rem" : "0rem"}; */
          /* width: ${accountModal ? "10vw" : "auto"}; */
          /* min-height: ${accountModal ? "100vh" : "auto"}; */
          border-radius: 0rem;
          width: 100vw;
          top: ${!accountModal ? "0.5rem" : "2rem"};
          min-height: ${!accountModal && "40vh"};
          margin: ${!accountModal ? "0.2rem" : "0.5rem"};
          padding: ${!accountModal ? "1rem" : "0.5rem"};
          min-height: 97vh;
          > .ant-modal-body > ul {
            margin-left: ${!accountModal && "-4rem"};
          }

          border-radius: 10px;
        }
      }

      ${isSignUpModal &&
      css`
        .ant-modal-content {
          background: var(--semantic-background-middle);
          width: ${confirmationModal
            ? "360px;"
            : accountModal
            ? "450px"
            : "680px;"};
          padding: 2.5rem;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
          border-radius: 0.5rem;
          @media ${maxMedium} {
            border-radius: 0rem;
            width: 100vw;
            // min-height: 100vh;
            > .ant-modal-body > ul {
              margin-left: ${!accountModal && "-4rem"};
            }
            top: 4.2rem;
            padding: 1.2rem;
            margin: 0.5rem;
            border: 0.5px solid #4b8d94;
            border-radius: 10px;
            min-height: 100%;
          }
        }
      `}

      .ant-modal-footer {
        border-top: 0;
        padding: 1.5rem 0 0 0;
      }
      .ant-modal-close {
        color: var(--semantic-background-top);
      }
      .ant-typography {
        margin-bottom: 0;
      }
    `;
  }
);

export const styles = {
  loading: css`
    height: 11rem;
  `,
  paddingRight: css`
    padding-right: 20px;
  `,

  gutter: css`
    padding: 0.7rem 0 0.75rem 0;
  `,

  flexcol: css`
    padding: 10px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 600px) {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // border: 1px solid green;
      margin-top: 10px;
      gap: 20px;
    }
  `,

  headertext: css`
    font-size: 42px;
    color: #4b8d94;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
      font-size: 35px;
      color: #4b8d94;
    }
  `,

  subtitle: css`
    font-size: 20px;
    font-weight: 300;

    @media only screen and (max-width: 600px) {
      padding: 0px;
      width: 100%;
      font-size: 19px;
      font-weight: 400;
    }
  `,

  descriptiontext: css`
    font-size: 17px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width: 600px) {
      padding: 0px;
      width: 100%;
      font-size: 17px;
      margin-bottom: 10px;
      margin-top: -15px;
      text-align: start;
      font-weight: 200;
    }
  `,

  btmbuttons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;

    @media only screen and (max-width: 600px) {
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
    }
  `,

  btnsettings: css`
    display: flex;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 270px;
    }
  `,

  signinicon: css`
    font-size: 16px;

    @media only screen and (max-width: 600px) {
      font-size: 17px;
    }
  `,

  createaccounticon: css`
    font-size: 16px;

    @media only screen and (max-width: 600px) {
      font-size: 17px;
    }
  `,

  textAlign: css`
    text-align: center;
  `,

  addSongFirstInputs: css`
    padding: 0.75rem 0 0.75rem 0;
    /* margin-bottom: 1rem; */
  `,

  textArea: css`
    resize: none;
    background: var(--semantic-gray-bg);
  `,

  fullWidth: css`
    width: 100%;
  `,

  input: css`
    background: var(--semantic-gray-bg);
    margin-bottom: 1rem;
  `,

  yesButton: css`
    background: var(--semantic-success-green-accent);
    width: 100%;
    &:hover {
      background: var(--semantic-success-green-accent-inverse) !important;
    }
  `,

  noButton: css`
    width: 100%;
  `,

  buttonLogin: css`
    background: linear-gradient(97.63deg, #215273 0%, #2c9095 100%);
  `,

  buttonGoogle: css`
    width: 100%;
    background-color: var(--bg-social);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    margin-top: 0.75rem;

    &:focus {
      background-color: var(--bg-social) !important;
    }
    &:hover {
      background-color: var(--bg-social) !important;
    }
  `,

  contentsGoogle: css`
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
  `,

  songList: css`
    margin-top: 1rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 9px;
    flex-wrap: nowrap;
    // border: 2px solid green;
    width: 100%;

    @media only screen and (max-width: 600px) {
      padding: 1rem 0.6rem;
      // border: 2px solid green;
      background-color: rgba(130, 216, 205, 0.06);
      gap: 10px;
    }
  `,

  btnsixing: css`
    // border: 2px solid green;
    @media only screen and (max-width: 600px) {
      padding: 0.4rem 0.6rem 0.4rem 0.6rem;
      font-size: 15px;
    }
  `,

  insidesonglist: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;

    @media only screen and (max-width: 600px) {
      // border: 1px solid green;
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: nowrap;
    }
  `,

  surround: css`
    display: flex;
    justify-content: space-between;
  `,

  imageLink: css`
    height: 36px;
    width: 36px;
  `,

  textLink: css`
    color: var(--pb-progress);
    &:hover {
      cursor: pointer;
    }
  `,

  verticalSpacing: css`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  `,

  songArtwork: css`
    border-radius: 0.3rem;
    height: 55px;
    width: 55px;
    margin-right: 1rem;
  `,

  cancelButton: css`
    display: flex;
    gap: 0.5rem;
    margin-left: auto;
    right: 0rem;
  `,

  inputHeader: css`
    margin-bottom: 0.8rem;
  `,

  inputDescription: css`
    color: var(--semantic-background-top);
    margin-top: 0.15rem;
  `,

  noResultsContainer: css`
    padding: 1rem;
    display: flex;
    justify-content: center;
    // background-color: #1c242b;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      border: 1px solid green;
    }
  `,

  noresultstextcontainer: css`
    text-align: center;
    color: white;
    font-size: 16px;
    margin-bottom: 0.7rem;
  `,

  noresultscount: css`
    display: flex;
    justify-content: center;
    color: white;
    font-size: 16px;
    margin-bottom: 0.7rem;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  `,

  noresultsoptions: css`
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 16px;
    margin-bottom: 1rem;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  `,

  unreleasedSongButtonContainer: css `
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 10px;
  `,

  addSongContainer: css `
    display: flex;
    flex-wrap: nowrap;
  `,
  
  btnsurround: css`
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  `,

  // rowbottom: css`
  //   border: 1px solid green;
  // `,

  // progressbarcontainer: css`
  //   border: 1px solid green;
  // `,

  resultsContainer: css`
    max-height: 300px;
    overflow-y: scroll;
    /* overflow: scroll; */
    min-width: 100%;
    background: var(--semantic-gray-bg);
    border-radius: 0.5rem;
    padding: 0 1rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 600px) {
      max-height: 300px;
      overflow-y: scroll;
      /* overflow: scroll; */
      min-width: 100%;
      background: transparent;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-bottom: 2rem;
    }
  `,

  inputcontainer: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,

  unreleasedinput: css`
    color: white;
    margin-bottom: 0.5rem;
  `,

  errorOkButton: css `
    display: flex;
    margin-left: auto;
    right: 0rem;
  `,

  unreleasedLoading: css `
    height: 24rem;
    position: absolute;
    z-index: 1;
    width: 100%;


    & > p {
      top: 13rem;
      position: absolute;
      text-align: center;
      right: 0;
      left: 0;
    }
  `,
};
