import { useContentMargins } from "@app/styles/helpers";
import { maxMedium, minLarge } from "../songtradr-components";
import styled, { css } from "styled-components";

export const StyledContentSliderContainer = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    margin: 0 0 0 ${margin};
  `;
});

export const SliderWrapper = styled.div(() => {
  const { margin } = useContentMargins();

  return css`
    margin: 0 0 0 ${margin};
  `;
});

export const styles = {
  topAds: css`
    margin-bottom: 4rem;
  `,

  carouselHeader: css`
    > * {
      &:nth-child(1) {
        margin-bottom: 1.5rem;
      }

      &:nth-child(2) {
        margin: 0 0 1.7rem 1rem;
      }
    }
  `,

  viewAllLink: css`
    display: inline;
    color: black;
  `,

  CarouselStyle: () => {
    const { margin } = useContentMargins();

    return css`
      .slick-track {
        display: flex;
      }

      .slick-disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      .slick-next {
        right: ${margin};
        top: -1.5rem;
        color: var(--cta-text);
        font-size: 20px;

        &:focus {
          color: var(--cta-text);
        }
        &:hover {
          color: var(--cta-interact-text);
        }
      }

      .slick-prev {
        left: calc((100vw - ${margin}) - ${margin} - 4rem);
        top: -1.5rem;
        color: var(--cta-text);
        font-size: 20px;

        &:focus {
          color: var(--cta-text);
        }
        &:hover {
          color: var(--cta-interact-text);
        }
      }
    `;
  },

  RightArrow: (reachedSliderEnd: boolean) => {
    const { margin } = useContentMargins();

    return css`
      position: absolute;
      right: ${margin};
      opacity: ${reachedSliderEnd ? 0.25 : 1};
      color: var(--cta-text);
      font-size: 20px;
      pointer-events: ${reachedSliderEnd && "none"};
    `;
  },

  LeftArrow: (mediaImageIndex: number) => {
    const { margin } = useContentMargins();

    return css`
      position: absolute;
      left: calc((100vw) - ${margin} - 4rem);
      opacity: ${mediaImageIndex === 0 ? 0.25 : 1};
      color: var(--cta-text);
      font-size: 20px;
      pointer-events: ${mediaImageIndex === 0 && "none"};
    `;
  },

  slider: css`
    display: flex;
    /* align-items: center; */
    /* position: relative; */
    /* position: absolute; */
    transition: transform 0.5s ease-in-out;
  `,

  sliderContainer: css`
    max-width: 100%;
    overflow-x: hidden;
    /* scroll-behavior: smooth; */
    /* white-space: nowrap; */
    position: relative;
  `,

  slide: css`
    position: relative;
    margin-right: 1rem;
    cursor: pointer;

    @media ${maxMedium} {
      max-width: 12.5rem;
      margin-right: 0.5rem;
    }

    > a {
      transition: all ease 0.3s;
      cursor: pointer;
      margin: 10px 0;
      display: block;
    }
    :hover {
      > a {
        transform: scale(1.05);
      }
    }
  `,

  slideImage: css`
    border-radius: 2px;
    z-index: -1;
    position: relative;
  `,

  textContainer: css`
    position: relative;
    width: 100%;

    @media ${minLarge} {
      position: absolute;
      bottom: 5%;
      left: 5%;
      max-width: 75%;
    }
  `,

  mediaImageContainer: css`
    > div:nth-of-type(2) {
      margin-top: 1.5rem;
    }
  `,

  imageGradient: css`
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(7, 19, 28, 0) 45.31%,
      rgba(7, 19, 28, 0.6) 100%
    );
    position: absolute;
    border-radius: 2px;

    @media ${maxMedium} {
      height: 12.5rem;
    }
  `,
};
