import {
  ArtistPage,
  MediaDetailsPage,
  UserProfilePageWrapper,
  UserConfirmedPage,
  UserConformationPage,
  SignInPage,
  SignUpPage,
  ChangePasswordPage,
  ResetPasswordPage,
} from "@app/pages";
import React, { Suspense } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import User from "@app/pages/user";
import UserGuard from "@app/components/user-guard/UserGuard";
const ApiDashboardPage = React.lazy( () => import("../pages/api-dashboard/ApiDashboardPage"));

type ReducedRouteProps = Pick<RouteProps, "path" | "element">;

interface IHiddenRoute extends ReducedRouteProps {
  key: string;
  nested?: IHiddenRoute[];
  redirects?: { from: RouteProps["path"]; to: RouteProps["path"] }[];
}

const mediaDetailsPage = <MediaDetailsPage sanityId={"pages.mediaDetails"} />;

const hiddenRoutes: IHiddenRoute[] = [
  {
    key: "movie",
    path: "/movie/:mediaId",
    element: mediaDetailsPage,
    redirects: [
      { from: "/movie/:mediaId/*", to: "./"},
      { from: "/movie", to: "/movies" },
    ],
  },
  {
    key: "game",
    path: "/game/:mediaId",
    element: mediaDetailsPage,
    redirects: [{ from: "/game", to: "/games" }],
  },
  {
    key: "show",
    path: "/show/:mediaId",
    element: mediaDetailsPage,
    nested: [
      {
        key: "season",
        path: ":parentId",
        element: mediaDetailsPage,
      },
      {
        key: "episode",
        path: ":parentId/:childId",
        element: mediaDetailsPage,
      },
      {
        key: "specific-song",
        path: ":parentId/:childId/:songId",
        element: mediaDetailsPage,
      },
    ],
    redirects: [{ from: "/show", to: "/shows" }],
  },
  {
    key: "brand",
    path: "/ad/:mediaId",
    element: mediaDetailsPage,
    nested: [
      {
        key: "ad",
        path: ":childId",
        element: mediaDetailsPage,
      },
    ],
    redirects: [{ from: "/ad", to: "/ads" }],
  },
  {
    key: "artist",
    path: "/artist/:artistId",
    element: <ArtistPage sanityId={"pages.artist"} />,
    redirects: [{ from: "/artist", to: "/" }],
  },
  {
    key: "user-profile",
    path: "/user/profile/:username",
    element: <UserProfilePageWrapper />,
  },
  {
    key: "usertest",
    path: "/user/test",
    element: <User />,
  },
  {
    key: "signIn",
    path: "/signin",
    element: <SignInPage />
  },
  {
    key: "signUp",
    path: "/signup",
    element: <SignUpPage />
  },
  {
    key: "userConformation",
    path: "/signup/confirm/:username",
    element: <UserConformationPage />
  },
  {
    key: "userConfirmed",
    path: "/signup/confirmed/:username",
    element: <UserConfirmedPage />
  },
  {
    key: "password-reset",
    path: "/signin/reset-password",
    element: <ResetPasswordPage />
  },
  {
    key: "force-password-reset",
    path: "/signin/reset-password/force",
    element: <ResetPasswordPage />
  },
  {
    key: "password-change",
    path: "/user/profile/:username/change-password",
    element: <UserGuard> <ChangePasswordPage /> </UserGuard>
  },
  {
    key: "notification-unsubscribe",
    path: "/user/unsub/*",
    element: <Navigate to="/settings"/>
  },
  {
    key: "api-dashboard",
    path: "/subscription/dashboard",
    element: <UserGuard>
        <Suspense>
          <ApiDashboardPage />
        </Suspense>
      </UserGuard>
  }

];

export default hiddenRoutes;
