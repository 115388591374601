import { css } from "styled-components";

export const voteButtonstyles = {
  confirmIcon: (small?: boolean) => {
    if (small) {
      return css`
        max-height: 1.5rem;
        max-width: 1.5rem;
      `;
    }
  },
};

export const shareButtonStyles = {
  overlay: (mobile: boolean) => {
    return css`
      display: flex;
      flex-direction: column;
      background-color: var(--semantic-background-top);
      border-radius: 1rem;
      row-gap: 1rem;
      padding: 1rem 1.5rem 0rem 1rem;
      box-shadow: 0px 16px 50px black;

      .ant-typography-copy {
        margin-left: 0px;
      }

      [data-tooltip]:before {
        position: absolute;
        content: attr(data-tooltip);
        opacity: 0;

        width: 125px;
        transition: all 0.25s ease;
        padding: 10px;
        color: var(--semantic-background-base);
      }

      [data-tooltip]:hover:before {
        opacity: 1;

        background: var(--semantic-background-top);
        margin-top: -70px;
        margin-left: ${mobile ? "0.25rem" : "0.5rem"};
      }

      [data-tooltip]:not([data-tooltip-persistent]):before {
        pointer-events: none;
      }
    `;
  },

  copyableContent: css`
    padding: 1rem 0.5rem;
    border-radius: 0.25rem;
    overflow: auto;
    white-space: nowrap;
    max-width: 78%;
    border: 0.75px solid var(--semantic-background-base);
  `,
  copyableContainer: css`
    display: flex;
    align-items: center;

    .ant-typography-copy {
      flex-grow: 1;
    }
  `,
  copyIcons: css`
    justify-content: space-around;
    align-items: center;
    display: flex;
  `,
  socialMediaIcon: css`
    width: 40px;
    height: 40px;
  `,
  socialContainer: () => {
    return css`
      min-width: 15%;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
    `;
  },
};
