import { lazy } from "react";

/** Sanity Page Exports...
 * @IMPORTANT the following export names need to exactly match (case sensitive)
 * the _type values of the corresponding sanity page schemas. */
export const about = lazy(() => import("./information/About"));
export const apiOverview = lazy(() => import("./information/APIOverview"));
export const communityGuidelines = lazy(
  () => import("./information/CommunityGuidelines")
);
export const contact = lazy(() => import("./contact/ContactPage"));
export const faq = lazy(() => import("./information/FAQPage"));
export const legals = lazy(() => import("./legals/LegalPage"));
export const search = lazy(() => import("./search/SearchPage"));
export const settings = lazy(() => import("./settings/SettingsPage"));
export const trending = lazy(() => import("./trending/TrendingPage"));

export {
  default as games,
  default as landing,
  default as movies,
  default as shows,
  default as ads,
} from "./media-overview/MediaOverviewPage";

/** Hidden Route & Error Page Exports... */
export { default as ArtistPage } from "./artist/ArtistPage";
export { default as MediaDetailsPage } from "./media-details/MediaDetailsPage";
export { default as Route404Page } from "./errors/Route404";
export { default as OutOfTune} from "./errors/OutOfTune";
export { default as UserProfilePageWrapper } from "./profile/UserProfilePageWrapper";
export { default as SignInPage } from "./sign-in/SignIn";
export { default as SignUpPage } from "./sign-up/SignUp";
export { default as UserConformationPage } from "./user-conformation/UserConformation";
export { default as UserConfirmedPage } from "./user-confirmed/UserConfirmedScreen";
export { default as ResetPasswordPage } from "./reset-password/ResetPassword";
export { default as ChangePasswordPage } from "./change-password/ChangePassword";
export { default as SettingsPage } from "./settings/SettingsPage";
export { default as ApiDashboardPage } from "./api-dashboard/ApiDashboardPage";