import React, { useEffect, useContext, Suspense } from "react";
import "styled-components/macro";
import { useSanityData } from "@app/sanity-generated-data";
import { IGoogleAd, ISearchPageSchema } from "@app/sanity-generated-data/types";
import { WaitSpin } from "@app/components";
import { styles, SearchPageWrapper } from "./styles";
import Slider, { SliderItem } from "../slider/Slider";
import MediaTile from "./MediaTile";
import { useWindowDimensions, uuid } from "../songtradr-components";
import MissingResults from "./MissingResults";
import SearchContext from "@app/searchContext";
import { ISearchEntry } from "@app/searchContext/interfaces";
import { isNullOrEmptyString } from "@app/utils";
import { useUserContext } from "@app/userContext";
import { useLocation } from "react-router-dom";
const AdsWrapper = React.lazy(() => import("../advertisement/AdsWrapper"));


// TYPES
interface ISearchPageResultsProps {
  sanityId: string;
}

// COMPONENTS
const Loading = () => (
  <div css={styles.resultsLoading}>
    <WaitSpin dark />
  </div>
);


const SearchPageResults = ({ sanityId }: ISearchPageResultsProps) => {
  const sanityData = useSanityData<ISearchPageSchema>("pages", sanityId);
  const { contentSliders, missingResults } = sanityData;
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";
  const margin = isMobile ? 20 : 92;
  const { searchState } = useContext(SearchContext);
  const { userState } = useUserContext();
  const location = useLocation();

  const getBannerAd = () => {
    const searchBannerAds: IGoogleAd[] = [
      {
          displayOn: "small",
          adValue: "tunefind-com_mobile_tvshows_banner_1",
          _type: "advertisement",
          _key: uuid().toString(),
      },
      {
          displayOn: "large",
          adValue: "tunefind-com_desktop_tvshows_banner_1",
          _type: "advertisement",
          _key: uuid().toString(),
      }
    ]
    return searchBannerAds;

  }
  const url = (url: string, type: string) => {
    const result = url.split("/")[1];
    const number = type === "artist" ? "/10/" : "/2/";
    return `https://img-www.tf-cdn.com/${type}${number}${result}.jpeg?_&auto=compress&fm=pjpg&fit=crop&crop=faces%2Centropy%20312&dpr=2`;
  };

  //CATEGORY VARIABLES
  const mediaCategories = [
    "show",
    "movie",
    "game",
    "artist",
    "ad",
    "brand",
  ] as const;
  type MediaCategory = typeof mediaCategories[number];
  const categoryLister = (category: string) => {
    switch (category) {
      case "show":
        return "TV Shows";
      case "movie":
        return "Movies";
      case "game":
        return "Video Games";
      case "artist":
        return "Artists";
      case "ad":
        return "Ads";
      case "brand":
        return "Brands";
    }
  };
  const mapSearchResult = (category: string) => {
    return searchState.searchResult.filter((entry) => entry.type === category);
  };

  useEffect(() => {
    if (isNullOrEmptyString(searchState.q)) {
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}?q=${searchState.q}`
      );
    }
  }, [searchState.q]);

  // //EFFECT - For when the search page initially renders
  // useEffect(() => {
  //   if (hits.length > 0) {
  //     if (!hitsLoaded.current) {
  //       setCachedHits(hits);
  //       hitsLoaded.current = true;
  //     }
  //   }
  // }, [hits]);

  // //EFFECT - for when the user presses enter on the search bar while already in the search page
  // useEffect(() => {
  //   setCachedHits(hits);
  //   // eslint-disable-next-line
  // }, [searchQuery]);

  const contentRender = () => {
    return (
      <>
        {mediaCategories.map((category: MediaCategory, index) => {
          return (
            mapSearchResult(category).length > 0  && contentSliders && (
              <React.Fragment key={`${index}-${category}`}>
                <Suspense>
                  <AdsWrapper ads={getBannerAd()} centered={true} negativeMargin={true} location={location} isMobile={isMobile} userLoggedIn={userState.authenticated} />
                </Suspense>
                <Slider
                  header={{
                    text: categoryLister(category) ?? "",
                    variant: contentSliders.header.variant,
                  }}
                  offset={margin}
                >
                  {mappedSlides(category)}
                </Slider>
              </React.Fragment>
            )
          );
        })}
        {<MissingResults schema={missingResults} />}
      </>
    );
  };

  const mappedSlides = (category: string) => {
    const sliderRender = (entry: ISearchEntry) => {
      if (!contentSliders) {
        return null;
      }
      return (
        <SliderItem key={entry.id} width={isMobile ? 208 : 324}>
          <MediaTile
            schema={contentSliders}
            title={entry.title}
            description={`${
              entry.subtitle !== undefined ? entry.subtitle : ""
            }${isNullOrEmptyString(entry.subtitle) ? "" : " | "}${
              entry.songcount
            } song${entry.songcount === 1 ? "" : "s"}`}
            slug={entry.url}
            imageUrl={url(entry.url ?? "", entry.type as string)}
            height={isMobile ? 200 : 250}
            width={isMobile ? 200 : 300}
          />
        </SliderItem>
      );
    };
    return mapSearchResult(category).map(sliderRender);
  };

  return (
    <SearchPageWrapper>
      {searchState.loading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>{contentRender()}</Suspense>
      )}
    </SearchPageWrapper>
  );
};

export default SearchPageResults;
