import React, { useCallback, Fragment } from "react";
import "styled-components/macro";
import { Col, Row } from "antd";
import { Typography, useWindowDimensions } from "../../songtradr-components";
import { IMediaGallerySchema } from "@app/sanity-generated-data/types";
import { IMediaGallery } from "@app/api/types";
import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MediaGalleryTile from "./MediaGalleryTile";
import { styles } from "./styles";

interface IMediaGalleryProps {
  schema: IMediaGallerySchema;
  data: IMediaGallery;
}

const MediaGallery = ({ schema, data }: IMediaGalleryProps) => {
  const { size } = useWindowDimensions();
  const isMobile = size === "small" || size === "medium";

  const { media, sections } = data;
  const { mediaHeaders } = schema;

  // RENDERS
  const mapMediaTiles = useCallback(
    (mediaIds: string[]) => {
      const [width, height] = [300, 300 / 1.24];
      const enrichedMedia = mediaIds?.map((id) =>
        media.find((m) => m.id === id)
      );
      return (
        enrichedMedia && (
          <Row css={styles.tileSection} gutter={isMobile ? 4 : 12}>
            {enrichedMedia.length ? (
              enrichedMedia.map((item) =>
                item ? (
                  <Col key={item.id} span={isMobile ? 12 : 8}>
                    <MediaGalleryTile
                      data={item}
                      schema={schema}
                      width={width}
                      height={height}
                    />
                  </Col>
                ) : null
              )
            ) : (
              <Row
                wrap={false}
                css={styles.emptyStateContainer}
                gutter={24}
                align="middle"
              >
                <Col>
                  <FontAwesomeIcon css={styles.eyeIcon} icon={faEye} />
                </Col>
                <Col>
                  <Typography margin={false} variant="body">
                    Use the Follow buttons to be sent new music from your
                    favorite media
                  </Typography>
                </Col>
              </Row>
            )}
          </Row>
        )
      );
    },
    [media, schema, isMobile]
  );

  return sections?.map(({ key, label, mediaIds }) => (
    <Fragment key={key}>
      <Typography variant={mediaHeaders.variant}>{label}</Typography>
      {mapMediaTiles(mediaIds)}
    </Fragment>
  ));
};

export default MediaGallery;
