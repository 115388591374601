import { IMedia } from "@app/api/types";
import { IContentSliderSchema } from "@app/sanity-generated-data/types";
import React, { Fragment, Suspense, useMemo, useState } from "react";
import "styled-components/macro";
import { AdvertisementBase } from "../advertisement";
import Slider, { SliderItem } from "../slider/Slider";
import Loading from "./Loading";
import MediaImage from "./MediaImage";
import { ISliderDefinitionEntry, sliderDefinition } from "./sliderDefinition";
import { styles } from "./styles";
import { checkIfShouldDisplayAds } from "@app/utils/helpers/shouldDisplayAds";
import { Location } from "react-router-dom";
import { getAdSize } from "@app/utils/helpers/getAdSize";
import { getAdTargets } from "@app/utils/helpers/getAdTargets";
const AdsWrapper = React.lazy(() => import("../advertisement/AdsWrapper"));

interface IContentSliderProps {
  schema: IContentSliderSchema;
  loading: boolean;
  data: IMedia[];
  sliderIndex: number;
  isMobile: boolean;
  location: Location;
  userLoggedIn: boolean;
}

interface IMediaSelection {
  index: number;
  item: IMedia;
  definition: ISliderDefinitionEntry;
}

const ContentSlider = ({
  schema,
  loading,
  data,
  sliderIndex,
  location,
  isMobile,
  userLoggedIn,
}: IContentSliderProps) => {
  const { topAds } = schema;

  
  const margin = isMobile ? 20 : 92;
  const ignoreRankAndUseDefault = true;
  const [hideKeys, setHideKeys] = useState<string[]>([]);

  // RENDERS
  const mappedSlides = useMemo(() => {
    let tempItem: IMediaSelection | undefined;
    const renderMediaItem = (
      item: IMedia,
      index: number,
      definition: ISliderDefinitionEntry
    ) => {
      const { width, height } = getAdSize(item?.adValue, isMobile);
      const adTargets = getAdTargets(location?.pathname, userLoggedIn);

      return item.type === "media" ? (
        <MediaImage
          schema={schema}
          data={item}
          height={
            isMobile
              ? definition.mediaImage.mobile.height
              : definition.mediaImage.desktop.height
          }
          width={
            isMobile
              ? definition.mediaImage.mobile.width
              : definition.mediaImage.desktop.width
          }
          />
      ) : (
          checkIfShouldDisplayAds() && 
          <div
            css={{
              marginRight: "1rem",
              overflow: "hidden",
              marginTop: "10px",
              height: isMobile
                ? `${definition.mediaImage.mobile.height}px`
                : `${definition.mediaImage.desktop.height}px`,
              width: isMobile
                ? `${definition.mediaImage.mobile.width}px`
                : `${definition.mediaImage.desktop.width}px`,
              display: "block",
            }}
          >
            {item.adValue && item.displayOn && (
              
                <AdvertisementBase
                  isMobile={isMobile}
                  width={width}
                  height={height}
                  adTargets={adTargets}
                  key={`${item.id}__${index}`}
                  adValue={item.adValue}
                  display={item.displayOn}
                  id={`${sliderIndex}-${index}`}
                  roundCorners
                  collapse={() =>
                    setHideKeys([...hideKeys, `${item.id}__${index}`])
                  }
                />
              )
            }   
          </div>   
      );
    };
    const renderSlides = (item: IMedia, index: number) => {
      const definition = ignoreRankAndUseDefault
        ? sliderDefinition["default"]
        : sliderDefinition[`rank${item.rank ?? 1}`];
      if (
        !ignoreRankAndUseDefault &&
        !isMobile &&
        (item.rank ?? 1) > 2 &&
        tempItem === undefined
      ) {
        tempItem = { index, item, definition };
      } else {
        const render = (
          <SliderItem
            css={styles.mediaImageContainer}
            key={`${item.id}__${index}`}
            width={
              isMobile
                ? definition.sliderItem.mobile.width
                : definition.sliderItem.desktop.width
            }
          >
            {tempItem &&
              renderMediaItem(
                tempItem.item,
                tempItem.index,
                tempItem.definition
              )}
            {renderMediaItem(item, index, definition)}
          </SliderItem>
        );
        tempItem = undefined;
        return render;
      }
    };

    return isMobile
      ? data
          ?.filter((m) => m.type !== "ad" || m.displayOn === "small")
          .map(renderSlides)
      : data
          ?.filter(
            (m) =>
              m.type !== "ad" ||
              m.displayOn === "always" ||
              m.displayOn === "large"
          )
          .map(renderSlides);
    // eslint-disable-next-line
  }, [data, isMobile, schema, sliderIndex, hideKeys]);

  return data.length > 0 ? (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          { checkIfShouldDisplayAds() && 
            <AdsWrapper ads={topAds} location={location} isMobile={isMobile} userLoggedIn={userLoggedIn} centered negativeMargin/> 
           }
          
          <Slider
            header={{
              text: schema.header.text ?? "",
              variant: schema.header.variant,
            }}
            headerCta={schema.headerCta}
            offset={margin}
            looping={true}
          >
            {mappedSlides}
          </Slider>
        </Suspense>
      )}
    </Fragment>
  ) : null;
};

export default ContentSlider;
