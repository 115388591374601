import styled, { css } from "styled-components";

// STYLED COMPONENTS
export const SongListItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

// CSS PROP STYLES
export const styles = {
  songTextContainer: css`
    max-width: max-content;
    display: inline-block;
  `,

  headerBar: css`
    gap: 1rem;
    margin-bottom: 1.5rem;
  `,

  leftSideOfHeaderBar: css`
    display: flex;
    align-items: center;
  `,

  iconsContainer: css`
    display: flex;
    column-gap: 1rem;
    width: 100%;

    > div {
      cursor: pointer;
    }
  `,

  socialMediaContainer: css`
    /* flex-grow: 1;
    display: flex;
    column-gap: 1.5rem; */
    margin-right: 1rem;
  `,

  heardOnText: css`
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0;
  `,

  playIcon: css`
    display: inline-block;
    margin-right: 2rem;
  `,

  rankingContainer: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
  `,

  rankingContent: css`
    margin-right: 1rem;
  `,

  rankingNumber: css`
    margin-bottom: 0.5rem;
  `,

  heardOnTile: css`
    /* border: 1px solid var(--pb-background); */
    background: var(--semantic-background-base);
    border-radius: 8px;
    padding: 0.75rem;
    width: 100%;

    display: flex;
    flex-direction: column;

    :hover {
      box-shadow: 0 0 10px 0 #00000029;
    }
  `,

  featuredMediaImage: css`
    border-radius: 0.5rem;
    margin-right: 1.5rem;
  `,

  songFeature: css`
    cursor: pointer;
    margin: 0.25rem;
    padding: 0.5rem;
    /* flex: 1 0 46.8%; */
    flex: 1 0 49.005%;
    display: flex;
    /* border: 1px solid white; */

    &:hover {
      * {
        color: var(--trending-hover);
      }
    }
  `,

  songFeatureContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,

  // showMoreButton: css`
  //   margin-top: 1.5rem;
  //   border: none;
  //   cursor: pointer;
  //   background: var(--bg);
  //   width: max-content;
  // `,

  bottomMargin: css`
    margin-top: -10px;
  `,

  link: css`
    cursor: pointer;
  `,

  solidHeart: css`
    width: 4rem;
    height: 4rem;
  `,

  emptyStateContainer: css`
    width: 100%;
    justify-content: center;
  `,
};
