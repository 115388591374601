import type { ElementType, ComponentPropsWithoutRef, FC } from "react";
import { useEffect } from "react";
import { ThemeMode } from "./Theme";
import React from "react";

type DesignLanguageThemeSwitchProps = {
  className?: string;
  theme?: ThemeMode;
};

/**
 * Set the design language theme globally
 *
 * use this when react has no direct control over the body
 */
export function useDesignLanguageBodyClass(mode: ThemeMode = "system") {
  useEffect(() => {
    if (typeof document === "undefined") {
      return;
    }
    if (mode === "dark") {
      document.body.classList.add("dark-theme");
      return () => {
        document.body.classList.remove("dark-theme");
      };
    }
    if (mode === "light") {
      document.body.classList.add("light-theme");
      return () => {
        document.body.classList.remove("light-theme");
      };
    }
  }, [mode]);
}

/**
 * Set the design language theme via className
 *
 * @example
 * <div
 *   className={designLanguageThemeClassName({
 *     theme: "dark",
 *     className: "my-other-class",
 *   })}
 * />
 */
export function designLanguageThemeClassName({
  className,
  theme,
}: DesignLanguageThemeSwitchProps) {
  return [
    className,
    theme === "dark"
      ? "dark-theme"
      : theme === "light"
      ? "light-theme"
      : undefined,
  ]
    .filter(Boolean)
    .join(" ");
}

/**
 * Argument a component to support setting the design language theme via theme prop
 *
 * @example
 * const ScopedTheme = withDesignLanguageThemeSwitcher("div");
 *
 * function App() {
 *   return (
 *     <ScopedTheme theme="dark">
 *       <h1>Hello</h1>
 *     </ScopedTheme>
 *   );
 * }
 */
export function withDesignLanguageThemeSwitcher<Element extends ElementType>(
  Comp: Element
): FC<
  Omit<ComponentPropsWithoutRef<Element>, "theme"> & {
    theme?: ThemeMode;
  }
> {
  return ({ theme, className, ...props }) => {
    return (
      <Comp
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(props as any)}
        className={designLanguageThemeClassName({ theme, className })}
      />
    );
  };
}
