import React from "react";
import { Row, Col} from "antd"
import { Button, Typography } from "../../components/songtradr-components";
import { useNavigate, useParams } from "react-router-dom";
import "styled-components/macro";
import { styles } from './styles';

const UserConfirmedScreen: React.FC = () => {
    const { username } = useParams();
    const navigation = useNavigate();

    return <Row css={styles.topMargin} gutter={[16,16]} >
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
                <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Typography variant="h3"> User successfully confirmed! </Typography>
                        </Col>
                        <Col>
                            <Typography variant="body">{`User ${username} has successfully been created. Please navigate back to login.`}</Typography>
                        </Col>
                        <Col span={24}>
                        <Button variant="primary" onClick={() => navigation('/signin')}> Back to Login </Button> 
                        </Col>
                    </Row>
                </Col>
                <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
 </Row>
}

export default UserConfirmedScreen