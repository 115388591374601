import React, {
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useMediaDetailsFetch } from "@app/api";
import { MediaDetailsActionContext, MediaDetailsDataContext } from "./context";
import { eActionType, IMediaDetailsPageData } from "./types";
import { dataReducer } from "./reducer";
import { IMediaDetailsHeroData } from "@app/api/types";

const MediaDetailsProvider = ({ children }: { children: ReactNode }) => {
  const { mediaId, parentId, childId } = useParams();
  const mediaIdRef = useRef(mediaId);

  const [apiLoading, setLoading] = useState(true);
  const [apiRefreshing, setRefreshing] = useState(true);

  const { fetchMediaDetails } = useMediaDetailsFetch();
  const [detailsState, dispatch] = useReducer(
    dataReducer,
    {} as IMediaDetailsPageData
  );

  const [heroState, heroStateDispatch] = useReducer(
    dataReducer,
    {} as IMediaDetailsHeroData
  )

  // ACTION CONTEXT FUNCTIONS
  const updateDetailsData = useCallback(
    (newState: IMediaDetailsPageData) => {
      dispatch({ type: eActionType.REPLACE_ALL, data: newState });
    },
    [dispatch]
  );

  const updateHeroData = useCallback(
    (newState: IMediaDetailsHeroData) => {
      heroStateDispatch({ type: eActionType.REPLACE_ALL, data: newState });
    },
    [heroStateDispatch]
  )
  const loadApiData = useCallback(async () => {
    setRefreshing(true);

    const request = await fetchMediaDetails();

    if (request?.data) {
      updateHeroData({
        airDate: request.data.airDate,
        id: request.data.id,
        mediaType: request.data.mediaType,
        name: request.data.name,
        songCount: request.data.songCount,
        isFollowing: request.data.isFollowing,
        supervisors: []
      });
      setLoading(false);
      setTimeout(() => {
        updateDetailsData(request.data);
        setRefreshing(false);
      }, 0);
    }
  }, [fetchMediaDetails, updateDetailsData, updateHeroData]);

  // EFFECT: FETCH FRESH API DATA ON MEDIAID CHANGE
  useEffect(() => {
    if (mediaId) {
      if (mediaId !== mediaIdRef.current) {
        mediaIdRef.current = mediaId;

        setLoading(true);
      }

      // MAKE REQUEST
      loadApiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId, parentId, childId]);

  return (
    <MediaDetailsDataContext.Provider
      value={{
        apiLoading,
        apiRefreshing,
        detailsState,
        heroState,
      }}
    >
      <MediaDetailsActionContext.Provider
        value={{
          refresh: loadApiData,
          updateDetails: updateDetailsData,
          updateHero: updateHeroData,
          toggleFollow: () => heroStateDispatch({ type: eActionType.TOGGLE_FOLLOW }),
        }}
      >
        {children}
      </MediaDetailsActionContext.Provider>
    </MediaDetailsDataContext.Provider>
  );
};

export default MediaDetailsProvider;
