import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }: any) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!window.scrollToTopBlocked) {
      const canControlScrollRestoration = "scrollRestoration" in window.history;
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = "manual";
      }

      window.scrollTo(0, 0);
    }

    window.scrollToTopBlocked = false;
  }, [pathname]);

  return children;
}
