import React from "react";
import { Helmet } from "react-helmet-async";
import { IPageSeoSchema } from "@app/sanity-generated-data/types";
import { useSanitySettings } from "@app/sanity-generated-data";

const HeadMeta = ({
  title: propsTitle,
  description,
  keywords,
  openGraphImg: propsOGImg,
  twitter: propsTwitter,
  blockCrawlers,
}: IPageSeoSchema) => {
  const { siteConfig } = useSanitySettings();

  // PARSE DATA FROM SANITY
  const title = `${propsTitle ? `${propsTitle} | ` : ""}${siteConfig.title}`;
  const openGraphImg = propsOGImg ?? siteConfig.seoDefaults.openGraphImg;
  const twitter = {
    layout: propsTwitter.layout ?? "summary",
    username: siteConfig.seoDefaults.twitterUsername,
    title: propsTwitter.title ?? title,
    description: propsTwitter.description ?? description,
    img: propsTwitter.img,
  };

  // ADD METADATA TO HEADER WITH REACT-HELMET
  return (
    <Helmet>
      <title>{title}</title>
      {siteConfig.fbAppId && (
        <meta property="fb:app_id" content={siteConfig.fbAppId} />
      )}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords.join(", ")} />}
      {openGraphImg.src && (
        <meta property="og:image" content={openGraphImg.src} />
      )}
      {openGraphImg.alt && (
        <meta property="og:image:alt" content={openGraphImg.alt} />
      )}
      <meta property="twitter:card" content={twitter.layout} />
      {twitter.username && (
        <meta property="twitter:site" content={`@${twitter.username}`} />
      )}
      {twitter.title && (
        <meta property="twitter:title" content={twitter.title} />
      )}
      {twitter.description && (
        <meta property="twitter:description" content={twitter.description} />
      )}
      {twitter.img?.src && (
        <meta property="twitter:image" content={twitter.img.src} />
      )}
      {twitter.img?.alt && (
        <meta property="twitter:image:alt" content={twitter.img.alt} />
      )}
      {blockCrawlers && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default HeadMeta;
