import { Row } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const ErrorPageWrapper = styled(Row)`
  min-height: 100vh;
  flex-direction: column;
  padding: 15rem 2rem 0 2rem;
  text-align: center;

  h1 {
    margin-bottom: 2rem;
    color: var(--semantic-secondary-accent);
  }

  p,
  small {
    color: var(--semantic-primary-text-hover);
  }

  button {
    margin-top: 4rem;
  }
`;

export const LinkContactUs = styled(Link)`
  text-decoration: underline;
  color: #fff;

  &:hover{
    text-decoration: underline;
    color: #e6e1e1;
  }
`;