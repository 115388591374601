import { focusVisible, transition } from "../..//styles/utils";
import styled, { css } from "styled-components";

export interface IconButtonProps {
  small?: boolean;
  unread?: boolean;
}
const unreadDot = css`
  ::after {
    content: "";
    position: absolute;
    width: 0.42rem;
    height: 0.42rem;
    background: var(--semantic-secondary-bg);
    border-radius: 100%;
    top: 0.38rem;
    right: 0.4375rem;
  }

  [disabled]::after,
  :disabled::after {
    background: var(--semantic-text-disabled);
  }
`;
export const IconButton = styled.button<IconButtonProps>`
  position: relative;
  background-color: transparent;
  height: var(--icon-button-size, 2rem);
  width: var(--icon-button-size, 2rem);
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  ${transition(["background-color"])}

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    background-color: var(--component-icon-hover);
  }

  &:active:not([disabled]) {
    background-color: transparent;
  }

  ${focusVisible}
  ${({ unread }) => unread && unreadDot}

  [disabled], :disabled {
    color: var(--semantic-text-disabled);
  }
`;
