export const formatDurationFromMs = (ms: number) => {
      const totalSeconds = Math.floor(ms / 1000);
    
      const hours = Math.floor(totalSeconds / 3600);
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;

      const formattedHours = hours > 0 ? hours.toString().padStart(2, '0') + ':' : '';
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
  
      return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}