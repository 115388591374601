import styled, { css } from "styled-components";
import { peach } from "@app/styles/sanity-defaults/useSanityStyleDefaults";
import { ExitIconProps } from "./index";

export const StyledExit = styled.div.withConfig({
  shouldForwardProp: (prop) => !["size", "color"].includes(prop),
})(({ size, color }: ExitIconProps) => {
  return css`
    cursor: pointer;
    max-height: ${size}px;

    > svg {
      height: ${size}px;
      width: ${size}px;

      &:hover path {
        stroke: ${peach};
      }

      path {
        fill: none;
        stroke: ${color};
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

        &:nth-of-type(1) {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
        &:nth-of-type(2) {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
          stroke-width: 6;
        }
        &:nth-of-type(3) {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
      }
    }
  `;
});
