import React, { useMemo, useState } from "react";
import { Row } from "antd";
import { IGoogleAd } from "@app/sanity-generated-data/types";
import { AdvertisementBase } from "./";
import { Location } from "react-router-dom";
import { getAdTargets } from "@app/utils/helpers/getAdTargets";
import { getAdSize } from "@app/utils/helpers/getAdSize";

interface IAdsWrapperProps {
  id?: string;
  ads?: IGoogleAd[];
  centered?: boolean;
  rightCol?: boolean;
  negativeMargin?: boolean;
  isMobile: boolean;
  location?: Location;
  mediaId?: string;
  artistId?: string;
  userLoggedIn: boolean;
  collapse?: () => void;
}

const AdsWrapper = ({
  id,
  ads,
  centered,
  rightCol,
  negativeMargin,
  isMobile,
  mediaId,
  artistId,
  location,
  userLoggedIn,
  collapse,
}: IAdsWrapperProps) => {
  const [hide, setHide] = useState<boolean>(false);
  const adTargets = getAdTargets(location?.pathname, userLoggedIn, artistId, mediaId);
  const mappedAds = useMemo(() => {
    const negativeMarginStyle = isMobile ? "-0.5rem" : "-2rem";
    const adStyle = {
      marginLeft: rightCol ? "2em" : "0",
      marginTop: negativeMargin ? negativeMarginStyle : "0",
      marginBottom: negativeMargin ? negativeMarginStyle : "0",
    };
    if (!hide && Array.isArray(ads)) {
      return (
        <Row style={adStyle} justify={centered ? "center" : "start"}>
          {ads.map((ad, index) => {
            const { width, height } = getAdSize(ad?.adValue, isMobile);
            return (
              <AdvertisementBase
                isMobile={isMobile}
                width={width}
                height={height}
                adTargets={adTargets}
                key={ad?._key}
                adValue={ad?.adValue}
                display={ad?.displayOn}
                id={(id ?? ad?._key.substring(0, 3)) + index.toString()}
                collapse={() => {
                  setHide(true);
                  collapse && collapse();
                }}
              />
            );
          })}
        </Row>
      );
    } else {
      return <></>;
    }
  }, [isMobile, rightCol, negativeMargin, hide, ads, centered, adTargets, id, collapse]);

  return mappedAds;
};

export default AdsWrapper;
