import { useWindowDimensions } from "../../components/songtradr-components";

const useContentMargins = (
  contentMaxWidth = 2880,
  targetMargin = "max(1rem, min(7vw, 6rem))"
) => {
  const { width: screenWidth } = useWindowDimensions();

  const atContentMax = screenWidth >= contentMaxWidth;

  const maxWidthCompensation = `calc(${screenWidth}px - ${contentMaxWidth}px)`;
  const calculatedMargin = atContentMax
    ? `max(calc(${maxWidthCompensation} / 2), ${targetMargin})`
    : targetMargin;

  return {margin: calculatedMargin, maxWidth: atContentMax};
};

export default useContentMargins;
