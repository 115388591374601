import React, {useCallback, useMemo, useState} from "react";
import { Row, Col} from "antd";
import { Typography } from "../../components/songtradr-components";

import { CognitoUserSession } from "amazon-cognito-identity-js";
import { handleError } from "@app/utils/helpers/ErrorHandler";
import "styled-components/macro";
import { styles } from './styles'
import { ErrorCodes } from "@app/enums/ErrorCodes";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "@app/userContext";
import { initialErrorState, useErrorContext } from "@app/errorContext";
import { handleSuccessfulSignIn } from "@app/utils/helpers/signInFlowUtils";
import ResetForm from "./ResetForm";
import ResetRequest from "./ResetRequest";
import ForceReset from "./ForceReset";
import { ResetType } from "@app/enums/ResetType";


export interface IForgotPasswordResponse {
    destination: string;
    medium: "email" | "sms";
    resetType: ResetType;
  }
  
const ResetPassword: React.FC = () => {
    const { userState, exchangeGrant } = useUserContext();
    const [usernameOrEmail, setUsernameOrEmail] = useState(userState.userName); 
    const [data, setData] = useState<IForgotPasswordResponse>();
    const { errorState, setErrorState } = useErrorContext();
    const navigate = useNavigate();
    const location = useLocation()
    const showForceReset = location?.pathname.includes('/force');
    const showResetForm = useMemo(() => usernameOrEmail && !showForceReset, [usernameOrEmail, showForceReset]);

    
    const handleFailure = useCallback((err: any) => {
        if (err) {
            const error = handleError(err);
            
            if (error === ErrorCodes.PASSWORD_RESET_REQUIRED) {
                setErrorState(initialErrorState);
                if (userState?.userAttr && userState?.userName && userState?.userRef) { // means login was "successful" but user needs to reset password
                    navigate(`/signin/reset-password/force`);
                } else { // means user was inserted from old database and user received new login credentials
                    navigate('/signin');
                }
            }
            if (err.code === ErrorCodes.USER_NOT_CONFIRMED) {
                navigate(`/signup/confirm/${usernameOrEmail}`)
                setErrorState(initialErrorState);

            }

            if (error) {
                setErrorState({message: error});
            }
        }
    }, [navigate, setErrorState, userState?.userAttr, userState?.userName, userState?.userRef, usernameOrEmail])

    const handleSuccess = useCallback((session: CognitoUserSession) => {
        const payload = handleSuccessfulSignIn(session);
        exchangeGrant(payload)
        navigate('/');     
        setErrorState(initialErrorState);    
    },[exchangeGrant, navigate, setErrorState])
    
    const onSuccessfulResetRequest = useCallback((data: IForgotPasswordResponse, usernameEmail: string) => {
        setData(data);
        setUsernameOrEmail(usernameEmail);
    }, [])

    const getSubtitle = useCallback(() => {
        if (!showResetForm) {
            return `Enter your Username or Email below and we will send a message to reset your password.`
        }
        if (data?.medium && data?.destination) {
            return `We have sent a password code by ${data.medium} to ${data.destination}. Enter it below to reset your password.`;
        }

        return `We have sent a verification code by email. Enter it bellow to reset your password.`;
    },[showResetForm, data])

    return (
        showForceReset ? 
        <ForceReset handleSuccess={handleSuccess} handleFailure={handleFailure} />
        :
        <div css={styles.topOffset}>
            <div css={styles.titleUnderline}>
                <Typography css={styles.title} variant="h2">{showResetForm ? 'Please check your inbox!' : 'Forgot your password?'}</Typography>
            </div>
           <Row gutter={[16,16]} >
            <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
            <Col xs={22} sm={22} md={22} lg={8} xl={8}>
                <Row gutter={[0, 24]}>
                    {errorState.message && <Col span={24} css={styles.errorMessage}>{errorState.message}</Col>}
                    <Col span={24}>
                        <Typography variant="body">{getSubtitle()}</Typography>
                    </Col>

                   {showResetForm ? 
                        <ResetForm 
                            usernameOrEmail={usernameOrEmail} handleSuccess={handleSuccess} handleFailure={handleFailure}                        
                        />
                        :
                        <ResetRequest 
                            onSuccessfulResetRequest={onSuccessfulResetRequest}  handleFailure={handleFailure}                
                        />   
                }
                </Row>
            </Col>
            <Col  xs={1} sm={1} md={1} lg={8} xl={8}></Col>
        </Row> 
        </div>
        
    )
}

export default ResetPassword;