import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Col, Dropdown, Row, MenuProps, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Typography,
  useWindowDimensions,
} from "../songtradr-components";
import {
  useSanitySettings,
  useSanityPageRoute,
} from "@app/sanity-generated-data";
import { ILinkSchema } from "@app/sanity-generated-data/types";
import Link from "@app/components/link";
import HamburgerIcon from "./hamburgerIcon";
import ExitIcon from "./exitIcon";
import UserContext from "@app/userContext";
import HeaderSearchBar from "../search-bar/HeaderSearchBar";
import { HeaderSearchBarSpacer } from "../search-bar/styles";
import ErrorContext, { initialErrorState } from "@app/errorContext";
import "./styles.css"
const AppHeader = () => {
  const { size } = useWindowDimensions();
  const isMobile = size === "medium" || size === "small";
  const preloadHeaderRef = useRef(document.getElementById('header-preload'));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const fromParam = searchParams.get('from');

  const logoUrl = '/tunefind_logo.png';
  const { headerConfig } = useSanitySettings();
  const landingPageRoute = useSanityPageRoute("pages.landing");
  const searchPageRoute = useSanityPageRoute("pages.search");

  //USER
  const { userState, signOut } = useContext(UserContext);
  const { setErrorState } = useContext(ErrorContext);

  //Hamburger Menu
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    if (preloadHeaderRef.current) {
     preloadHeaderRef.current.remove();
    }
  }, [preloadHeaderRef]);

  //Handle body scrolling behind the mobile navigation menu
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) body.style.overflow = showMenu ? "hidden" : "auto";
  }, [showMenu]);

  // ROUTES
  const mapHeaderRoutes = () => {
    return headerConfig.links?.map(
      ({ _key, _type, text, url }: ILinkSchema) => {
        return (
          <Col
            key={_key}
            onClick={() => {
              setShowMenu(false);
            }}
            // style={{ marginBottom: "3rem" }}
            className="mobile-nav-spacer"
          >
            <Link
              to={url}
              target={_type === "external" ? "_blank" : "_self"}
              className="header-selected"
              style={{ color: pathname.includes(url) ? "var(--semantic-secondary-accent)" : "var(--semantic-primary-text)"}}
            >
              <Typography
                variant={isMobile ? "h3" : "body"}
                margin={false}
                className="nav-link"
              >
                {text.indexOf("(Beta)") > -1 ? (
                  <>
                    <span>{text.replace("(Beta)", "").trim()}</span>
                    <sup>BETA</sup>
                  </>
                ) : (
                  <span>{text}</span>
                )}
              </Typography>
            </Link>
          </Col>
        );
      }
    );
  };

  //PROFILE MENU
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  //EFFECT - CLOSE PROFILE MENU IF OPEN WHEN SCROLLING
  useEffect(() => {
    document.addEventListener("scroll", closeProfileMenu);
    return () => {
      document.removeEventListener("scroll", closeProfileMenu);
    };
  }, []);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const closeProfileMenu = () => {
    setShowProfileMenu(false);
  };

  const handleSignInCleanup = (isMobile: boolean) => {
    setErrorState(initialErrorState);
    isMobile ? setShowMenu(false) : setShowProfileMenu(false);
  }
  // PROFILE MENU
  const HandleProfileMenu = () => {
    const items = [
      { label: "Profile", key: `user/profile/${userState.userName}` }, // remember to pass the key prop
      { label: "Settings", key: "settings" },
      userState.isFacebook
        ? null
        : { label: "Change Password", key: `user/profile/${userState.userName}/change-password` },
      { label: "Logout", key: "logout" },
    ];

    const onClick: MenuProps["onClick"] = ({ key }) => {
      if (key === "logout") {
        signOut();
        navigate(landingPageRoute.route);
      } else {
        navigate(key);
      }
      closeProfileMenu();
    };

    return (
      <Dropdown
        onOpenChange={toggleProfileMenu}
        open={showProfileMenu}
        placement="bottomRight"
        trigger={["click"]}
        menu={{ items, onClick }}
        dropdownRender={(menu) => <div className="profile-menu">{menu}</div>}
        className="user-avatar-menu"
      >
        <Row className="nav-hover" gutter={[16, 0]}>
          <Col>
            <Typography variant={"bodyBold"} margin={false}>
              {userState.userName}
            </Typography>
          </Col>
          <FontAwesomeIcon icon={faUserCircle} className="user-avatar" />
        </Row>
      </Dropdown>
    );
  };

  // HEADER SEARCH FUNCTIONALITY
  const search = () => {
    if (searchPageRoute.matches) {
      return null;
    } else {
      return (
        <>
          <HeaderSearchBar
          />
          <HeaderSearchBarSpacer />
        </>
      );
    }
  };

  // DESKTOP
  const DesktopHeader = (
    <Row
      key="row3"
      className="header-css"
      wrap={true}
      gutter={[24, 0]}
      justify={"space-between"}
    >
      <Col key="col1" span={24}>
        <Row key="row2" align="middle" gutter={[24, 0]}>
          <Col key="col2">
            <Row key="row3" align="middle">
              <Link className="header-logo-link" to={landingPageRoute.route}>
                <img
                  src={logoUrl}
                  alt={'Tunefind logo'}
                />
              </Link>
              {search()}
            </Row>
          </Col>
          {mapHeaderRoutes()}
        </Row>
        {userState.authLoading ? (<Col></Col>)
          : userState.authenticated ? (
            <Col className="profile-container">
              <Col>{HandleProfileMenu()}</Col>
            </Col>
          ) : (
            <Row
              wrap={false}
              justify="space-around"
              align="middle"
              gutter={[24, 24]}
            >
              <Link to={`../signin?from=${fromParam ?? pathname}`} onClick={() => handleSignInCleanup(false)}>
                      <Typography className="nav-hover" variant="bodyBold" margin={false}>
                        Sign In
                      </Typography>
                </Link>
              <Col>
              <Link to={`../signup?from=${fromParam ?? pathname}`} onClick={() => handleSignInCleanup(false)}>
                  <Button variant="primary">
                    {window.innerWidth > 1200 ? "Sign Up For Free" : "Sign Up"}
                  </Button>
                </Link>
              </Col>

            </Row>
          )}
      </Col>
    </Row>
  );

  // MOBILE
  const MobileHeader = (
    <Row className="header-css" justify={"space-between"}>
      <Col span={24}>
        <Row justify="space-between">
          <Link className="header-logo-link" to={landingPageRoute.route}>
            <img
              src={logoUrl}
              alt={'Tunefind logo'}
            />
          </Link>
        </Row>
        <Row gutter={[24, 0]} justify="space-between" align="middle">
          <Col>
            <Link
              style={{ color: searchPageRoute.matches ? "var(--semantic-secondary-accent)" : "var(--semantic-primary-text)"}}
              className="header-selected"
              to={searchPageRoute.route}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Link>
          </Col>
          <Col>
            <HamburgerIcon
              size={32}
              color={"var(--semantic-primary-text)"}
              onClick={() => setShowMenu(!showMenu)}
            />
          </Col>
        </Row>
      </Col>
      <Drawer
        className="drawer"
        title="Tunefind"
        width={"100%"}
        placement="right"
        open={showMenu}
        closable
      >
        <Row align="middle" className="exit-icon-container">
          <ExitIcon
            size={32}
            color={"var(--semantic-primary-text)"}
            onClick={() => setShowMenu(!showMenu)}
          />
        </Row>
        <Col
          // css={styles.hamburgerMenuSpacing(false)}
          className="mobile-nav-spacer"
          onClick={() => setShowMenu(false)}
        >
          <Link
             style={{ color: landingPageRoute.matches ? "var(--semantic-secondary-accent)" : "var(--semantic-primary-text)"}}
             className="header-selected"
            to={landingPageRoute.route}
          >
            <Typography variant="h3" margin={false}>
              Home
            </Typography>
          </Link>
        </Col>
        {mapHeaderRoutes()}
        {userState.authenticated ? (
          <>
            <Col className="mobile-nav-spacer">
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                style={{ color:   pathname.includes(`/user/profile/${userState.userName}`) ? "var(--semantic-secondary-accent)" : "var(--semantic-primary-text)"}}
                className="header-selected"
                to={`user/profile/${userState.userName}`}
              >
                <Typography margin={false} variant="h3">
                  Profile
                </Typography>
              </Link>
            </Col>
            {/** @todo Will probably phase this back in once the user settings page is functional and ready for beta */}
            {/* <Row onClick={() => setShowMenu(false)}>
              <Link to={routes.settings}>
                <Typography
                  margin={false}
                  css={headerSelected()}
                  variant="h3"
                >
                  Settings
                </Typography>
              </Link>
            </Row> */}
            {userState.isFacebook ? null : (
              <Col className="mobile-nav-spacer">
                 <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                style={{ color:  pathname.includes(`user/profile/${userState.userName}/change-password`) ? "var(--semantic-secondary-accent)" : "var(--semantic-primary-text)"}}
                className="header-selected"
                to={`user/profile/${userState.userName}/change-password`}
              >
                <Typography margin={false} variant="h3">
                  Change Password
                </Typography>
              </Link>
              </Col>
            )}
            <Row
              onClick={() => {
                setShowMenu(false);
                signOut();
                navigate(landingPageRoute.route);
              }}
            >
              <Typography className="link" variant="h3" margin={false}>
                Log Out
              </Typography>
            </Row>
          </>
        ) : (
          <>
            <Col className="mobile-nav-spacer" >
              <Link to={`../signin?from=${fromParam ?? pathname}`}  onClick={() => handleSignInCleanup(true)}>
                    <Typography className="link" variant="h3" margin={false}>
                      Sign In
                    </Typography>
              </Link>
            </Col>
            <Col className="mobile-nav-spacer">
              <Button
                onClick={() => {
                  setShowMenu(false);
                }}
                variant={"primary"}
              >
               <Link to={`../signup?from=${fromParam ?? pathname}`}  onClick={() => handleSignInCleanup(true)}>
                    <Typography className="link" variant="h3" margin={false}>
                      Sign Up For Free
                    </Typography>
              </Link>
              </Button>
            </Col>
          </>
        )}
      </Drawer>
    </Row>
  );

  // RENDER
  return (
    <header className="header" key="styledHeader">
      {isMobile ? MobileHeader : DesktopHeader}
    </header>
  );
};

export default AppHeader;
