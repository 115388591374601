import React from "react";
import { FacebookIcon } from "../songtradr-components/icons";
import { CSSProp } from "styled-components";
import "styled-components/macro";
import { styles } from './styles';

interface IFacebookButtonProps {
    value: string;
    customCss?: CSSProp | undefined;
    onClickCallback: () => void;
}

const FacebookButton = ({
    value,
    customCss,
    onClickCallback
}: IFacebookButtonProps) => {
    return (
        <button css={[styles.facebookButton, customCss]} onClick={onClickCallback}>
            <FacebookIcon />
            {value}
        </button>
    )
}

export default FacebookButton;