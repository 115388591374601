import { Col } from "antd";
import React from "react";
import { StyledProgressBar } from "./styles";

interface IProgressBar {
  totalSteps: number;
  progress: number;
}

const ProgressBar = (props: IProgressBar) => {
  const { progress, totalSteps } = props;

  const ProgressSegment = ({ completed }: any) => {
    return (
      <Col span={10} className={`progress-segment ${completed && "filled"}`} />
    );
  };
  return (
    <StyledProgressBar justify="space-between">
      {Array(totalSteps)
        .fill("")
        .map((x: any, index: number) => (
          <ProgressSegment key={index} completed={progress >= index + 1}/>
        ))}
    </StyledProgressBar>
  );
};
export default ProgressBar;
